import { Component, Injector, Type } from '@angular/core';
import { Observable } from 'rxjs';

import { PqrComponentData } from '../../shared/entities/base/pqr-component-data';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { SchoolDayPerWeekService } from './services/school-day-per-week.service';
import { SchoolDayPerWeek } from './entities/school-day-per-week';
import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { NavigableComponentData } from '../../shared/entities/base/navigable-component-data';
import { SimulationType } from '../../simulator/entities/enums/simulation-type.enum';
import { UtilitiesService } from '../../../shared/services/utilities/utilities.service';

@Component({
  selector: 'app-school-day-per-week',
  templateUrl: './school-day-per-week.component.html',
  styleUrls: ['./school-day-per-week.component.scss']
})
export class SchoolDayPerWeekComponent extends BaseNavigableComponent<SchoolDayPerWeek> implements PqrComponentData {

  data: SchoolDayPerWeek = new SchoolDayPerWeek();
  functionality: FunctionalityInfo = Functionality.schoolDayPerWeek;
  inconsistencies: Array<Inconsistency>;
  pqrAdminMode: boolean;
  pqrMode: boolean;
  pqrModalMode: boolean;
  componentType: Type<PqrComponentData> = SchoolDayPerWeekComponent;

  constructor(private schoolDayPerWeekService: SchoolDayPerWeekService, private injector: Injector) {
    super(injector);
  }

  processData(): Observable<SchoolDayPerWeek> {
    return this.schoolDayPerWeekService.getData();
  }

  getPqrData(): NavigableComponentData {
    return this.data;
  }

  setPqrData(pqrData: any) {
    this.data = pqrData;
  }

  getPreviousStepRoute(): Array<string> {
    const simulationType: SimulationType = this.sessionService.getItem<SimulationType>(UtilitiesService.simulationTypeSessionKey);

    if (simulationType === SimulationType.caq) {
      return undefined;
    } else {
      return this.functionality.previousStep;
    }
  }

  processValidation(): void {
    super.processValidation();

    for (const schoolDayPerWeek of this.data.schoolDaysWeek) {
      if (isNaN(schoolDayPerWeek.value) || schoolDayPerWeek.value === null || schoolDayPerWeek.value === undefined || schoolDayPerWeek.value < 1 || schoolDayPerWeek.value > 7) {
        this.inconsistencies.push(new Inconsistency({
          message: `O número de dias letivos por semana da etapa ${schoolDayPerWeek.stageDescription}  não é válido. Informe um valor de 1 a 7 dias.`
        }));
      }
    }
  }

}
