export class ExpenseByCity {

  cityId: number;
  currentExpenseTotal: number;
  potentialRevenue: number;
  complementation: number;

  constructor(init?: Partial<ExpenseByCity>) {
    Object.assign(this, init);
  }

}
