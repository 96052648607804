import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

import { BaseUnsubscribe } from '../../shared/entities/base/base-unsubscribe';
import { UserService } from '../services/user.service';
import { Role } from '../entities/role';
import { Segment } from '../entities/segment';
import { Schooling } from '../entities/schooling';
import { State } from '../entities/state';
import { City } from '../entities/city';
import { User } from '../entities/user';
import { CpfValidatorService } from '../services/cpf-validator.service';
import { NotificationService } from '../../shared/services/notification/notification.service';

@Component({
  selector: 'app-user-new',
  templateUrl: './user-new.component.html',
  styleUrls: ['./user-new.component.scss']
})
export class UserNewComponent extends BaseUnsubscribe implements OnInit {

  form: FormGroup;
  states: Array<State> = new Array<State>();
  schoolings: Array<Schooling> = new Array<Schooling>();
  segments: Array<Segment> = new Array<Segment>();
  roles: Array<Role> = new Array<Role>();
  cities: Array<City> = new Array<City>();
  user: User = new User();

  selectedSegment: Segment;
  selectedSchooling: Schooling;
  selectedState: State;
  selectedRole: Role;
  selectedCity: City = new City();

  cpfMask: any = { mask: [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/] };

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private cpfValidatorService: CpfValidatorService,
    private notificationService: NotificationService
  ) {
    super();
  }

  ngOnInit() {

    this.createForm();
    this.processInformations();

  }

  createForm(): void {

    this.form = this.formBuilder.group({
      name: [undefined, Validators.required],
      cpf: [undefined, Validators.required],
      schooling: [undefined, Validators.required],
      course: [{ value: undefined, disabled: true }, Validators.required],
      segment: [undefined, Validators.required],
      segmentText: [undefined],
      role: [undefined, Validators.required],
      roleText: [undefined],
      institution: [undefined, Validators.required],
      uf: [undefined, Validators.required],
      city: [undefined, Validators.required],
      email: [undefined, [Validators.required, Validators.email]],
      confirmationEmail: [undefined, [Validators.required, Validators.email]],
      password: [undefined, Validators.required],
      confirmationPassWord: [undefined, Validators.required],
      receiveEmails: [false],
      cep: [undefined, Validators.required],
      nickname: [undefined, Validators.required]
    });
  }

  clearForm(): void {
    this.form.reset();
    this.form.get('schooling').patchValue(undefined);
    this.form.get('segment').patchValue(undefined);
    this.form.get('role').patchValue(undefined);
    this.form.get('uf').patchValue(undefined);
  }

  processInformations(): void {

    this.userService.getState().pipe(
      takeUntil(this.unsubscribe))
      .subscribe(state => {
        this.states = state.sort((a, b) => {
          return (a.abbreviation > b.abbreviation) ? 1 : ((b.abbreviation > a.abbreviation) ? -1 : 0);
        });
      });

    this.userService.getSchooling().pipe(
      takeUntil(this.unsubscribe))
      .subscribe(schooling => {
        this.schoolings = schooling;
      });

    this.userService.getSegment().pipe(
      takeUntil(this.unsubscribe))
      .subscribe(segment => {
        this.segments = segment;
      });
  }

  onSubmit() {
    let titleMessage: string;
    let message: string = '';

    if (this.form.valid) {
      this.prepareUserForSend();

      this.userService.addUser(this.user).pipe(
        takeUntil(this.unsubscribe))
        .subscribe(
          result => {
            titleMessage = 'CADASTRO';
            if (result.msg) {
              this.notificationService.showSuccess('Usuário cadastrado com sucesso.', titleMessage);
            } else {
              this.notificationService.showError('Ocorreu um erro!', titleMessage);
            }
          },
          error => {
            if (error.errorBody.errors.length) {
              error.errorBody.errors.forEach(e => {
                message === '' ? message = e : message = message + ' ' + e;
              });
              this.notificationService.showError(message, titleMessage);
            } else if (error.errorBody.err.code === 11000) {
              this.notificationService.showError('Já existe um cadastro para o usuário informado', titleMessage);
            }
          }
        );

    } else {
      Object.keys(this.form.controls).forEach(fieldName => {
        const control = this.form.get(fieldName);
        if (control.enabled) {
          control.markAsTouched();
        }
      });
    }
  }

  isCPFValid(): boolean {

    if (this.form.get('cpf').valid) {
      return this.cpfValidatorService.validate(this.form.get('cpf').value);
    }
    return true;

  }

  retiraCaractereCPF(): void {
    if (this.form.get('cpf').value !== null && this.form.get('cpf').value !== undefined) {
      const valor = this.form.get('cpf').value.replace(/\D/g, '');
      this.user.cpf = valor;
    }
  }

  prepareUserForSend(): void {

    this.retiraCaractereCPF();

    this.user.schooling = this.selectedSchooling.description;
    this.user.state = this.selectedState.description;
    this.user.segment = !this.selectedSegment.flagOther ? this.selectedSegment.description : this.form.get('segmentText').value;

    if (this.selectedRole) {
      this.user.role = !this.selectedRole.flagOther ? this.selectedRole.description : this.form.get('roleText').value;
    } else {
      this.user.role = this.form.get('roleText').value;
    }

    this.user.receive_emails = this.form.get('receiveEmails').value;

  }

  verifyValidTouched(fieldName: string): boolean {
    return !this.form.get(fieldName).valid && this.form.get(fieldName).touched;
  }

  verifyInvalidEmail(fieldName: string): boolean {
    if (this.form.get(fieldName).value !== undefined && this.form.get(fieldName).value !== null) {
      if (this.form.get(fieldName).errors) {
        return this.form.get(fieldName).errors['email'] && this.form.get(fieldName).touched;
      }
    }
  }

  verifyConfirmationValue(fieldName1: string, fieldName2: string): boolean {
    if (this.form.get(fieldName1).valid && this.form.get(fieldName2).valid) {
      if (this.form.get(fieldName1).value !== this.form.get(fieldName2).value) {
        // this.form.get(fieldName2).setErrors({ 'Not-Equals': true });
        this.form.get(fieldName2).markAsTouched();
        return true;
      } else {
        return false;
      }
    }
  }

  verifyConfirmationEmail(): boolean {
    if (this.verifyValidTouched('confirmationEmail')) {
      return true;
    } else if (this.verifyInvalidEmail('confirmationEmail')) {
      return true;
    } else if (this.verifyConfirmationValue('email', 'confirmationEmail')) {
      return true;
    }
  }

  verifyConfirmationPassword(): boolean {
    if (this.verifyValidTouched('confirmationPassWord')) {
      return true;
    } else if (this.verifyInvalidEmail('confirmationPassWord')) {
      return true;
    } else if (this.verifyConfirmationValue('password', 'confirmationPassWord')) {
      return true;
    }
  }

  verifySuccess(fieldName: string): boolean {

    if (this.form.get(fieldName).value !== null && this.form.get(fieldName).value !== undefined) {
      if (fieldName === 'confirmationEmail') {
        if (this.form.get(fieldName).value === this.form.get('email').value) {
          return true;
        }
      } else if (fieldName === 'confirmationPassWord') {
        if (this.form.get(fieldName).value === this.form.get('password').value) {
          return true;
        }
      }
    }
    return false;
  }

  segmentChange(): void {

    if (typeof this.selectedSegment === 'string') {
      this.form.get('segment').patchValue(undefined);
      this.form.get('segment').markAsUntouched();
    } else {

      if (this.selectedSegment !== undefined && this.selectedSegment !== null) {

        this.form.get('role').patchValue(undefined);
        this.form.get('role').markAsUntouched();

        if (this.selectedSegment.flagOther) {
          this.form.get('role').setErrors(null);
          this.form.get('segmentText').setValidators([Validators.required]);
          this.form.get('roleText').setValidators([Validators.required]);
        } else {
          this.form.get('segmentText').markAsUntouched();
          this.form.get('segmentText').setErrors(null);
          this.form.get('roleText').markAsUntouched();
          this.form.get('roleText').setErrors(null);
        }

        this.userService.getRole(this.selectedSegment.description).pipe(
          takeUntil(this.unsubscribe))
          .subscribe(role => {
            this.roles = role;
          });

      }
    }
  }

  roleChange(): void {

    if (typeof this.selectedRole === 'string') {
      this.form.get('role').patchValue(undefined);
      this.form.get('role').markAsUntouched();
    } else {
      if (this.selectedRole !== undefined && this.selectedRole !== null) {

        if (this.selectedRole.flagOther) {
          this.form.get('roleText').setValidators([Validators.required]);
        } else {
          this.form.get('roleText').markAsUntouched();
          this.form.get('roleText').setErrors(null);
        }
      }
    }
  }

  schoolingChange(): void {

    if (typeof this.selectedSchooling === 'string') {
      this.form.get('schooling').patchValue(undefined);
      this.form.get('schooling').markAsUntouched();
    } else {
      if (this.selectedSchooling !== undefined && this.selectedSchooling !== null) {
        if (this.selectedSchooling.flagOther) {
          this.form.get('course').enable();
        } else {
          this.form.get('course').reset();
          this.form.get('course').disable();
        }
      }
    }
  }

  cityChange(selectedCity: City): void {
    // this.selectedCity = selectedCity;
    this.user.city = selectedCity.label;
  }

  stateChange(): void {

    if (typeof this.selectedState === 'string') {
      this.form.get('uf').patchValue(undefined);
      this.form.get('uf').markAsUntouched();
    } else {
      if (this.selectedState !== undefined && this.selectedState !== null) {

        this.userService.getCity(this.selectedState.id).pipe(
          takeUntil(this.unsubscribe))
          .subscribe(city => {
            this.cities = city;
          });
      }
    }
  }
}
