import { UnitOfMeasurement } from '../../../../shared/entities/unit-of-measurement';
import { SchoolOperation } from './school-operation';
import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';

export class SchoolsOperation extends NavigableComponentData {

    costs: Array<SchoolOperation> = new Array<SchoolOperation>();
    unitsOfMeasurement: Array<UnitOfMeasurement>;

    constructor(init?: Partial<SchoolsOperation>) {
        super();
        Object.assign(this, init);
    }
}
