import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { ChargesAndAdditionalsComponent } from './charges-and-additionals/charges-and-additionals.component';
import { PriceIndicesComponent } from './price-indices/price-indices.component';
import { FullPriceListComponent } from './full-price-list/full-price-list.component';
import { SocialChargesComponent } from './social-charges/social-charges.component';
import { AdditionalsComponent } from './additionals/additionals.component';
import { AdministrativeAreaCostingComponent } from '../quality-conditions/administrative-area-costing/administrative-area-costing.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ChargesAndAdditionalsComponent,
    AdditionalsComponent,
    AdministrativeAreaCostingComponent,
    FullPriceListComponent,
    PriceIndicesComponent,
    SocialChargesComponent
  ],
  exports: [
    AdditionalsComponent,
    AdministrativeAreaCostingComponent,
    SocialChargesComponent
  ]
})
export class ChargesAndAdditionalsModule { }
