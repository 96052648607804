import { ResultSerieEnrollments } from "./serie-enrollments-by-school";


export class ResultStageEnrollments {
    id: number;
    description: string;
    serieEnrollmentsBySchool: Array<ResultSerieEnrollments>;
    totalEnrollmentDay: number;
    totalEnrollmentNigth: number;
    totalEnrollmentFullTime: number;
    percentageFullTime: number;
    totalEnrollmentCurrent: number;
    totalMatriculaPorEtapa: number;

    constructor(init?: Partial<ResultStageEnrollments>) {
        Object.assign(this, init);
    }
}
