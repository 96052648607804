import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { QualityConditionsComponent } from './quality-conditions/quality-conditions.component';
import { TeacherNumberComponent } from './teacher-number/teacher-number.component';
import { StaffNumberComponent } from './staff-number/staff-number.component';
import { WorkJourneyTeacherComponent } from './work-journey-teacher/work-journey-teacher.component';
import { TeacherTrainingComponent } from './teacher-training/teacher-training.component';
import { SchoolsStaffComponent } from './schools-staff/schools-staff.component';
import { SchoolsOperationComponent } from './schools-operation/schools-operation.component';
import { SchoolTransportComponent } from './school-transport/school-transport.component';
import { SchoolFeedingComponent } from './school-feeding/school-feeding.component';
import { SchoolDayPerWeekComponent } from './school-day-per-week/school-day-per-week.component';
import { OfferGoalEnrollmentFullTimeComponent } from './offer-goal-enrollment-full-time/offer-goal-enrollment-full-time.component';
import { NumberStudentClassComponent } from './number-student-class/number-student-class.component';
import { NewRoomBuildingComponent } from './new-room-building/new-room-building.component';
import { InfrastructureSchoolBuildingsComponent } from './infrastructure-school-buildings/infrastructure-school-buildings.component';
import { DailyTeachingLoadComponent } from './daily-teaching-load/daily-teaching-load.component';
import { CareerAndRemunerationTeachersComponent } from './career-and-remuneration-teachers/career-and-remuneration-teachers.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    QualityConditionsComponent,
    TeacherNumberComponent,
    StaffNumberComponent,
    CareerAndRemunerationTeachersComponent,
    DailyTeachingLoadComponent,
    InfrastructureSchoolBuildingsComponent,
    NewRoomBuildingComponent,
    NumberStudentClassComponent,
    OfferGoalEnrollmentFullTimeComponent,
    SchoolDayPerWeekComponent,
    SchoolFeedingComponent,
    SchoolTransportComponent,
    SchoolsOperationComponent,
    SchoolsStaffComponent,
    TeacherTrainingComponent,
    WorkJourneyTeacherComponent
  ],
  exports: [
    CareerAndRemunerationTeachersComponent,
    DailyTeachingLoadComponent,
    InfrastructureSchoolBuildingsComponent,
    NewRoomBuildingComponent,
    NumberStudentClassComponent,
    OfferGoalEnrollmentFullTimeComponent,
    SchoolDayPerWeekComponent,
    SchoolFeedingComponent,
    SchoolTransportComponent,
    SchoolsOperationComponent,
    SchoolsStaffComponent,
    TeacherTrainingComponent,
    WorkJourneyTeacherComponent
  ]
})
export class QualityConditionsModule { }
