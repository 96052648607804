import { TypeTransport } from './type-transport';
import { PriceList } from '../../../../shared/entities/price-list';
import { UnitOfMeasurementEnum } from '../../../../shared/entities/enums/unit-of-measurement.enum';
import { AccountPlanEnum } from '../../../../shared/entities/enums/account-plan.enum';
import { ExpenseTypeEnum } from '../../../../shared/entities/enums/expense-type.enum';
import { PriceIndiceEnum } from '../../../../shared/entities/enums/price-indice.enum';

export class SchoolTransport extends PriceList {
    textId: string;
    descriptionTransport: string;
    numberStudent: number;
    numberUnitConsumer: number;
    unitPrice: number;
    numberSchoolDayYear: number;

    private _sequence: string;

    get sequence(): string {
        return this._sequence;
    }

    set sequence(sequence: string) {
        this._sequence = sequence.indexOf('P') > -1 ? sequence : `P${sequence}`;
    }

    constructor(init?: Partial<SchoolTransport>) {
        super();

        this.unitOfMeasurements = [UnitOfMeasurementEnum.km];
        this.accountPlans = [AccountPlanEnum.Transporte_Escolar];
        this.expenseTypes = [ExpenseTypeEnum.Corrente];
        this.priceIndices = [PriceIndiceEnum.IGP];
        this.multiplier = 200;

        Object.assign(this, init);
    }

    toJSON(): any {
        const json = {};
        Object.keys(this).forEach(key => json[(key[0] !== '_' ? key : key.replace('_', ''))] = this[key]);
        return json;
    }
}
