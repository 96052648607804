import { Routes } from '@angular/router';

import { HasErrorGuard } from '../../shared/guards/has-error/has-error.guard';
import { HasRequiredDataGuard } from '../shared/guards/required-data/has-required-data.guard';
import { CaqReportComponent } from './caq-report/caq-report.component';
import { BudgetForecastReportComponent } from './budget-forecast-report/budget-forecast-report.component';
import { FinancingFundsReportComponent } from './financing-funds-report/financing-funds-report.component';
import { ItemCostExpenseReportComponent } from './item-cost-expense-report/item-cost-expense-report.component';
import { FinancingFederatedEntitiesReportComponent } from './financing-federated-entities-report/financing-federated-entities-report.component';
import { CustoPorEscolaComponent } from './custo-por-escola/custo-por-escola.component';
import { AuthUserGuard } from '../../shared/guards/auth/auth-user.guard';

const routes: Routes = [
  { path: 'caqreport', component: CaqReportComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard] },
  { path: 'budgetforecastreport', component: BudgetForecastReportComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard] },
  { path: 'financingfundsreport', component: FinancingFundsReportComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard], canActivate: [AuthUserGuard] },
  {
    path: 'financingfederatedentitiesreport',
    component: FinancingFederatedEntitiesReportComponent,
    canDeactivate: [HasErrorGuard, HasRequiredDataGuard],
  },
  {
    path: 'custoporescola',
    component: CustoPorEscolaComponent,
    canDeactivate: [HasErrorGuard, HasRequiredDataGuard],
  },
  { path: 'itemcostexpensereport', component: ItemCostExpenseReportComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard] }
];

export const ResultsChildrenRoutes: Routes = routes;
