import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, mergeMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { SessionService } from '../../../../shared/services/session/session.service';
import { HttpService } from '../../../../shared/services/http/http.service';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { FinancingsFederatedsEntitiesReport } from './../entities/financings_federateds-entities-report';
import { EnrollmentBySchool } from 'app/simulator/access-and-offer/enrollment-by-stage-series-by-school/entities/enrollment-by-school';
import { SelectLocation } from 'app/simulator/select-location/entities/select-location';
import { SimulatorService } from 'app/simulator/simulator/services/simulator.service';
import { HttpHeaders } from '@angular/common/http';
import { TipoDependencia } from 'app/shared/entities/enums/tipo-dependencia';
import { DependenciaAdministrativa } from 'app/shared/entities/enums/dependencia-administrativa';

@Injectable({
  providedIn: 'root'
})
export class FinancingFederatedEntitiesReportService {

  constructor(
    private utilitiesService: UtilitiesService,
    private httpService: HttpService,
    private sessionService: SessionService,
  ) { }

  getFinancingFederatedEntitiesReport(editar: string): Observable<FinancingsFederatedsEntitiesReport> {
    let data: FinancingsFederatedsEntitiesReport = new FinancingsFederatedsEntitiesReport();
    data.editar = editar;
    const pqrData: Array<any> = this.utilitiesService.getPqrLocalUFG();
    const tipoDependencia = this.utilitiesService.getTipoDependenciaUFG();
    const tipoRecorte = this.utilitiesService.getTipoRecorte();
    const tipoDependenciaEstadoOuMunicipio = this.utilitiesService.getTipoDependenciaEstadoOuMunicipio();
    const requestOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const editMode: boolean = this.sessionService.getItem<boolean>(SimulatorService.simulationEditModeSessionKey);



    if (editMode) {
      // quando estiver no modo edição usará o metodo POST
      const resultForEnrollmentSchool: EnrollmentBySchool = this.sessionService.getItem<EnrollmentBySchool>(Functionality.enrollmentByStageAndSeriesBySchool.key);
      resultForEnrollmentSchool.diagnosticosMatriculaPorEscola.forEach(objeto => {
        // Remover a propriedade 'version' de cada objeto
        delete objeto.version;

        objeto.etapasEnsino.forEach(etapas => {
          // Remover a propriedade 'percentualMatriculasIntegral' de cada objeto
          delete etapas.percentualMatriculasIntegral;
        });
      });

      const resultadoJson = {
        diagnosticoDeMatriculaPorEscola: resultForEnrollmentSchool.diagnosticosMatriculaPorEscola,
        pqr: pqrData
      }
      return this.getResultadosMetodoPost(tipoRecorte, data, resultadoJson, tipoDependencia, tipoDependenciaEstadoOuMunicipio, requestOptions);
    } else {
      // se não estiver no modo edição usará o método GET
      return this.getResultadosMetodoGet(tipoRecorte, data, tipoDependencia, tipoDependenciaEstadoOuMunicipio, requestOptions);
    }
  }

  getResultadosMetodoGet(tipoRecorte: string, data: FinancingsFederatedsEntitiesReport, tipoDependencia: string, tipoDependenciaEstadoOuMunicipio: string, requestOptions: { headers: HttpHeaders; }): Observable<FinancingsFederatedsEntitiesReport> {
    return this.httpService.getApiEndpointUFG().pipe(
      switchMap(apiEndpoint => {

        return this.httpService.get<any>(`${apiEndpoint}/simulador/memoria/${tipoDependencia}`).pipe(
          mergeMap(memoriaCalulo => {
            let urlEscolas = apiEndpoint + '/informacoes-educacionais/numero-escolas/' + tipoDependenciaEstadoOuMunicipio + '?filtro-prioridade=true'
            let urlDimensao = apiEndpoint + '/planejamento-orcamentario/variacao-dimensao-oferta/' + tipoDependencia
            let urlOrcamento = apiEndpoint + '/planejamento-orcamentario/diferenca-orcamento-realizado-projetado/' + tipoDependenciaEstadoOuMunicipio
            if (tipoRecorte === TipoDependencia.Nacional) {
              urlOrcamento = apiEndpoint + '/planejamento-orcamentario/diferenca-orcamento-realizado-projetado'
              urlDimensao = apiEndpoint + '/planejamento-orcamentario/variacao-dimensao-oferta'
              urlEscolas = apiEndpoint + '/informacoes-educacionais/numero-escolas/' + tipoRecorte + '?filtro-prioridade=true'
            }

            data.totalMatriculas = memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.total;

            return this.httpService.get<any>(urlEscolas).pipe(
              mergeMap(escolas => {

                return this.httpService.get<any>(urlDimensao).pipe(
                  mergeMap(relarioDimensaoOferta => {

                    return this.httpService.get<any>(urlOrcamento).pipe(
                      map(planejamentoOrcamentario => {

                        const localidadeSelecionada = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);

                        let numeroEscolas: number = 0;

                        const dadosEscolasMatriculasOrdenados = _.sortBy(escolas.content, ['idDependenciaAdministrativa']);

                        //console.log("dadosEscolasMatriculasOrdenados:", dadosEscolasMatriculasOrdenados);

                        for (const escola of dadosEscolasMatriculasOrdenados) {
                          if (escola.idDependenciaAdministrativa === DependenciaAdministrativa.Municipal && tipoRecorte === TipoDependencia.Municipal) {
                            numeroEscolas = numeroEscolas + escola.numeroEscolas;
                          }

                          if ((escola.idDependenciaAdministrativa === DependenciaAdministrativa.Estadual || escola.idDependenciaAdministrativa === DependenciaAdministrativa.Municipal) && tipoRecorte === TipoDependencia.Estadual) {
                            numeroEscolas = numeroEscolas + escola.numeroEscolas;
                          }

                          if ((escola.idDependenciaAdministrativa === DependenciaAdministrativa.Estadual || escola.idDependenciaAdministrativa === DependenciaAdministrativa.Municipal) && tipoRecorte === TipoDependencia.Nacional) {
                            numeroEscolas = numeroEscolas + escola.numeroEscolas;
                          }
                        }

                        data.numeroEscolas = numeroEscolas;

                        const matriculasCreche: number = memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasCrecheUrbanaParcial
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasCrecheUrbanaIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasCrecheRuralParcial
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasCrecheRuralIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasCrecheUrbanaNoturna
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasCrecheRuralNoturna;

                        const matriculasCrecheIntegral: number = memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasCrecheUrbanaIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasCrecheRuralIntegral;

                        const matriculasPreescola: number = memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasPreEscolaUrbanaParcial
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasPreEscolaUrbanaIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasPreEscolaRuralParcial
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasPreEscolaRuralIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasPreEscolaUrbanaNoturna
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasPreEscolaRuralNoturna;

                        const matriculasPrescolaIntegral: number = memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasPreEscolaUrbanaIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasPreEscolaRuralIntegral;

                        const matriculasEnsFundamentalAnosIniciais: number = memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosIniciaisUrbanaParcial
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosIniciaisUrbanaIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosIniciaisRuralParcial
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosIniciaisRuralIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosIniciaisUrbanaNoturna
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosIniciaisRuralNoturna;

                        const matriculasEnsFundamentalAnosIniciaisIntegral: number = memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosIniciaisUrbanaIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosIniciaisRuralIntegral;

                        const matriculasEnsFundamentalAnosFinais: number = memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosFinaisUrbanaParcial
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosFinaisUrbanaIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosFinaisRuralParcial
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosFinaisRuralIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosFinaisUrbanaNoturna
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosFinaisRuralNoturna;

                        const matriculasEnsFundamentalAnosFinaisIntegral: number = memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosFinaisUrbanaIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosFinaisRuralIntegral;

                        const matriculasEnsinoMedio: number = memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsMedioUrbanaParcial
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsMedioUrbanaIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsMedioRuralParcial
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsMedioRuralIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsMedioUrbanaNoturna
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsMedioRuralNoturna;

                        const matriculasEnsinoMedioIntegral: number = memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsMedioUrbanaIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsMedioRuralIntegral;

                        const matriculasEJA: number = memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEjaUrbanaParcial
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEjaUrbanaIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEjaRuralParcial
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEjaRuralIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEjaUrbanaNoturna
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEjaRuralNoturna;

                        const matriculasEJAIntegral: number = memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEjaUrbanaIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEjaRuralIntegral;

                        const matriculasEscolaRural: number = memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.crecheRuralParcial
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.crecheRuralIntegral
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.crecheRuralNoturno
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.preEscolaRuralParcial
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.preEscolaRuralIntegral
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.preEscolaRuralNoturno
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.ensFunAnosIniciaisRuralParcial
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.ensFunAnosIniciaisRuralIntegral
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.ensFunAnosIniciaisRuralNoturno
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.ensFunAnosFinaisRuralParcial
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.ensFunAnosFinaisRuralIntegral
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.ensFunAnosFinaisRuralNoturno
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.ensMedioRuralParcial
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.ensMedioRuralIntegral
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.ensMedioRuralNoturno
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.ejaRuralParcial
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.ejaRuralNoturno;

                        data.matriculasCreche = matriculasCreche;
                        data.matriculasPreescola = matriculasPreescola;
                        data.matriculasEnsFundamentalAnosIniciais = matriculasEnsFundamentalAnosIniciais;
                        data.matriculasEnsFundamentalAnosFinais = matriculasEnsFundamentalAnosFinais;
                        data.matriculasEnsinoMedio = matriculasEnsinoMedio;
                        data.matriculasEJA = matriculasEJA;
                        data.matriculasEmEscolasRurais = matriculasEscolaRural;
                        data.totalMatriculas = memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.totalMatriculas;

                        let perentualMatriculasTempoIntegralCreche: number;
                        let perentualMatriculasTempoIntegralPreescola: number;
                        let perentualMatriculasTempoIntegralEnsFundamentalAnosIniciais: number;
                        let perentualMatriculasTempoIntegralEnsFundamentalAnosFinais: number;
                        let perentualMatriculasTempoIntegralEnsinoMedio: number;
                        let perentualMatriculasTempoIntegralEJA: number;

                        if (matriculasCreche > 0) {
                          perentualMatriculasTempoIntegralCreche = (matriculasCrecheIntegral / matriculasCreche);
                        } else {
                          perentualMatriculasTempoIntegralCreche = 0;
                        }

                        if (matriculasPreescola > 0) {
                          perentualMatriculasTempoIntegralPreescola = (matriculasPrescolaIntegral / matriculasPreescola);
                        } else {
                          perentualMatriculasTempoIntegralPreescola = 0;
                        }

                        if (matriculasEnsFundamentalAnosIniciais > 0) {
                          perentualMatriculasTempoIntegralEnsFundamentalAnosIniciais = (matriculasEnsFundamentalAnosIniciaisIntegral / matriculasEnsFundamentalAnosIniciais);
                        } else {
                          perentualMatriculasTempoIntegralEnsFundamentalAnosIniciais = 0;
                        }

                        if (matriculasEnsFundamentalAnosFinais > 0) {
                          perentualMatriculasTempoIntegralEnsFundamentalAnosFinais = (matriculasEnsFundamentalAnosFinaisIntegral / matriculasEnsFundamentalAnosFinais);
                        } else {
                          perentualMatriculasTempoIntegralEnsFundamentalAnosFinais = 0;
                        }

                        if (matriculasEnsinoMedio > 0) {
                          perentualMatriculasTempoIntegralEnsinoMedio = (matriculasEnsinoMedioIntegral / matriculasEnsinoMedio);
                        } else {
                          perentualMatriculasTempoIntegralEnsinoMedio = 0;
                        }

                        if (matriculasEJA > 0) {
                          perentualMatriculasTempoIntegralEJA = (matriculasEJAIntegral / matriculasEJA);
                        } else {
                          perentualMatriculasTempoIntegralEJA = 0;
                        }

                        data.percentualMatriculasTempoIntegralCreche = perentualMatriculasTempoIntegralCreche * 100;
                        data.percentualMatriculasTempoIntegralPreescola = perentualMatriculasTempoIntegralPreescola * 100;
                        data.percentualMatriculasTempoIntegralEnsFundamentalAnosIniciais = perentualMatriculasTempoIntegralEnsFundamentalAnosIniciais * 100;
                        data.percentualMatriculasTempoIntegralEnsFundamentalAnosFinais = perentualMatriculasTempoIntegralEnsFundamentalAnosFinais * 100;
                        data.percentualMatriculasTempoIntegralEnsinoMedio = perentualMatriculasTempoIntegralEnsinoMedio * 100;
                        data.percentualMatriculasTempoIntegralEJA = perentualMatriculasTempoIntegralEJA * 100;
                        data.percentualMatriculasEmEscolasRurais = matriculasEscolaRural > 0 ? ((matriculasEscolaRural * 100) / data.totalMatriculas) : 0;

                        data.numeroTotalDocentesProjetado = memoriaCalulo.resultadoEtapa10CalculoDemandaPorDocentes.total;
                        data.numeroTotalFuncionariosProjetado = memoriaCalulo.resultadoEtapa18ProjecaoNumeroFuncionarios.total;

                        data.totalTurmasExistentes = relarioDimensaoOferta.content.numeroTurmas;
                        data.totalTurmasNecessarias = memoriaCalulo.resultadoEtapa4CalculoNumeroTurmas.total

                        if (tipoRecorte === TipoDependencia.Municipal) {
                          data.despesaCorrenteNecessaria = memoriaCalulo.resultadoEtapa27TotalDespesasCorrentes.despesasGovernoMunicipal;
                          data.receitaPotencialMinimaVinculadaEducacaoBasica = planejamentoOrcamentario.content.receitaPotencialMunicipal;
                          data.despesaCorrenteRealizada = planejamentoOrcamentario.content.despesaRealizadaMunicipal;
                          data.dependenciaAdministrativa = "Municipal";

                          data.codigo = localidadeSelecionada.selectedCity.value;
                          data.uf = localidadeSelecionada.selectedState.label;
                          data.enteFederativo = localidadeSelecionada.selectedCity.label;

                        }
                        if (tipoRecorte === TipoDependencia.Estadual) {
                          data.despesaCorrenteNecessaria = memoriaCalulo.resultadoEtapa27TotalDespesasCorrentes.despesasGovernoEstadual + memoriaCalulo.resultadoEtapa27TotalDespesasCorrentes.despesasGovernoMunicipal;
                          data.receitaPotencialMinimaVinculadaEducacaoBasica = planejamentoOrcamentario.content.receitaPotencialEstadual + planejamentoOrcamentario.content.receitaPotencialMunicipal;
                          data.despesaCorrenteRealizada = planejamentoOrcamentario.content.despesaRealizadaEstadual + planejamentoOrcamentario.content.despesaRealizadaMunicipal;
                          data.dependenciaAdministrativa = "Estadual e Municipal";

                          data.codigo = localidadeSelecionada.selectedState.value;
                          data.uf = "BR";
                          data.enteFederativo = localidadeSelecionada.selectedState.label;
                        }
                        if (tipoRecorte === TipoDependencia.Nacional) {
                          data.despesaCorrenteNecessaria = memoriaCalulo.resultadoEtapa27TotalDespesasCorrentes.totalDespesasCorrentes;
                          data.receitaPotencialMinimaVinculadaEducacaoBasica = planejamentoOrcamentario.content.receitaPotencialMunicipal + planejamentoOrcamentario.content.receitaPotencialEstadual;
                          data.despesaCorrenteRealizada = planejamentoOrcamentario.content.despesaRealizadaMunicipal + planejamentoOrcamentario.content.despesaRealizadaEstadual;
                          data.dependenciaAdministrativa = "Estadual e Municipal";
                          data.uf = "BR";
                          data.enteFederativo = "BRASIL";
                          data.codigo = "";
                        }

                        data.valorAlunoCAQAno = data.despesaCorrenteNecessaria / data.totalMatriculas;
                        data.valorAlunoCAQMes = data.valorAlunoCAQAno / 12;

                        if (memoriaCalulo.complementacaoDto) {
                          data.valorComplementacao = memoriaCalulo.complementacaoDto.complementacaoNivelMunicipio + memoriaCalulo.complementacaoDto.complementacaoNivelEstado;

                          let totalReceitaPotencial: number = 0;
                          if (memoriaCalulo.complementacaoDto.complementacaoNivelMunicipio > 0) {
                            totalReceitaPotencial += planejamentoOrcamentario.content.receitaPotencialMunicipal;
                          }
                          if (memoriaCalulo.complementacaoDto.complementacaoNivelEstado > 0) {
                            totalReceitaPotencial += planejamentoOrcamentario.content.receitaPotencialEstadual;
                          }
                          data.percentualComplementacao = totalReceitaPotencial > 0 ? (data.valorComplementacao / totalReceitaPotencial) * 100 : 0;
                        }

                        return data;
                      }));
                  }));
              }));
          }));
      }));
  }

  getResultadosMetodoPost(tipoRecorte: string, data: FinancingsFederatedsEntitiesReport, resultadoJson: { diagnosticoDeMatriculaPorEscola: import("app/simulator/access-and-offer/enrollment-by-stage-series-by-school/entities/diagnostico-de-matricula-por-escola").DiagnosticoDeMatriculaPorEscola[]; pqr: any[]; }, tipoDependencia: string, tipoDependenciaEstadoOuMunicipio: string, requestOptions: { headers: HttpHeaders; }): Observable<FinancingsFederatedsEntitiesReport> {
    return this.httpService.getApiEndpointUFG().pipe(
      switchMap(apiEndpoint => {

        return this.httpService.post<any>(`${apiEndpoint}/simulador/memoria/${tipoDependencia}`, resultadoJson, requestOptions).pipe(
          mergeMap(memoriaCalulo => {

            let urlEscolas = apiEndpoint + '/informacoes-educacionais/numero-escolas/' + tipoDependenciaEstadoOuMunicipio + '?filtro-prioridade=true'
            let urlDimensao = apiEndpoint + '/planejamento-orcamentario/variacao-dimensao-oferta/' + tipoDependencia
            let urlOrcamento = apiEndpoint + '/planejamento-orcamentario/diferenca-orcamento-realizado-projetado/' + tipoDependenciaEstadoOuMunicipio
            if (tipoRecorte === TipoDependencia.Nacional) {
              urlOrcamento = apiEndpoint + '/planejamento-orcamentario/diferenca-orcamento-realizado-projetado'
              urlDimensao = apiEndpoint + '/planejamento-orcamentario/variacao-dimensao-oferta'
              urlEscolas = apiEndpoint + '/informacoes-educacionais/numero-escolas/' + tipoRecorte + '?filtro-prioridade=true'
            }

            data.totalMatriculas = memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.total;

            return this.httpService.get<any>(urlEscolas).pipe(
              mergeMap(escolas => {

                return this.httpService.get<any>(urlDimensao).pipe(
                  mergeMap(relarioDimensaoOferta => {

                    return this.httpService.get<any>(urlOrcamento).pipe(
                      map(planejamentoOrcamentario => {

                        const localidadeSelecionada = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);

                        let numeroEscolas: number = 0;

                        const dadosEscolasMatriculasOrdenados = _.sortBy(escolas.content, ['idDependenciaAdministrativa']);

                        for (const escola of dadosEscolasMatriculasOrdenados) {
                          if (escola.idDependenciaAdministrativa === DependenciaAdministrativa.Municipal && tipoRecorte === TipoDependencia.Municipal) {
                            numeroEscolas = numeroEscolas + escola.numeroEscolas;
                          }

                          if ((escola.idDependenciaAdministrativa === DependenciaAdministrativa.Estadual || escola.idDependenciaAdministrativa === DependenciaAdministrativa.Municipal) && tipoRecorte === TipoDependencia.Estadual) {
                            numeroEscolas = numeroEscolas + escola.numeroEscolas;
                          }

                          if ((escola.idDependenciaAdministrativa === DependenciaAdministrativa.Estadual || escola.idDependenciaAdministrativa === DependenciaAdministrativa.Municipal) && tipoRecorte === TipoDependencia.Nacional) {
                            numeroEscolas = numeroEscolas + escola.numeroEscolas;
                          }
                        }

                        data.numeroEscolas = numeroEscolas;

                        const matriculasCreche: number = memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasCrecheUrbanaParcial
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasCrecheUrbanaIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasCrecheRuralParcial
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasCrecheRuralIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasCrecheUrbanaNoturna
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasCrecheRuralNoturna;

                        const matriculasPreescola: number = memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasPreEscolaUrbanaParcial
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasPreEscolaUrbanaIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasPreEscolaRuralParcial
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasPreEscolaRuralIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasPreEscolaUrbanaNoturna
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasPreEscolaRuralNoturna;

                        const matriculasEnsFundamentalAnosIniciais: number = memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosIniciaisUrbanaParcial
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosIniciaisUrbanaIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosIniciaisRuralParcial
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosIniciaisRuralIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosIniciaisUrbanaNoturna
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosIniciaisRuralNoturna;

                        const matriculasEnsFundamentalAnosFinais: number = memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosFinaisUrbanaParcial
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosFinaisUrbanaIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosFinaisRuralParcial
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosFinaisRuralIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosFinaisUrbanaNoturna
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosFinaisRuralNoturna;

                        const matriculasEnsinoMedio: number = memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsMedioUrbanaParcial
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsMedioUrbanaIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsMedioRuralParcial
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsMedioRuralIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsMedioUrbanaNoturna
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsMedioRuralNoturna;

                        const matriculasEJA: number = memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEjaUrbanaParcial
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEjaUrbanaIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEjaRuralParcial
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEjaRuralIntegral
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEjaUrbanaNoturna
                          + memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEjaRuralNoturna;

                        const matriculasEscolaRural: number = memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.crecheRuralParcial
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.crecheRuralIntegral
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.crecheRuralNoturno
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.preEscolaRuralParcial
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.preEscolaRuralIntegral
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.preEscolaRuralNoturno
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.ensFunAnosIniciaisRuralParcial
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.ensFunAnosIniciaisRuralIntegral
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.ensFunAnosIniciaisRuralNoturno
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.ensFunAnosFinaisRuralParcial
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.ensFunAnosFinaisRuralIntegral
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.ensFunAnosFinaisRuralNoturno
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.ensMedioRuralParcial
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.ensMedioRuralIntegral
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.ensMedioRuralNoturno
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.ejaRuralParcial
                          + memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.ejaRuralNoturno;

                        data.matriculasCreche = matriculasCreche;
                        data.matriculasPreescola = matriculasPreescola;
                        data.matriculasEnsFundamentalAnosIniciais = matriculasEnsFundamentalAnosIniciais;
                        data.matriculasEnsFundamentalAnosFinais = matriculasEnsFundamentalAnosFinais;
                        data.matriculasEnsinoMedio = matriculasEnsinoMedio;
                        data.matriculasEJA = matriculasEJA;
                        data.matriculasEmEscolasRurais = matriculasEscolaRural;
                        data.totalMatriculas = memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.totalMatriculas;

                        const perentualMatriculasTempoIntegralCreche: number = memoriaCalulo.resultadoEtapa2PorcentagemDeMatriculasEmTempoIntegral.creche;
                        const perentualMatriculasTempoIntegralPreescola: number = memoriaCalulo.resultadoEtapa2PorcentagemDeMatriculasEmTempoIntegral.preEscola;
                        const perentualMatriculasTempoIntegralEnsFundamentalAnosIniciais: number = memoriaCalulo.resultadoEtapa2PorcentagemDeMatriculasEmTempoIntegral.ensFunAnosIniciais;
                        const perentualMatriculasTempoIntegralEnsFundamentalAnosFinais: number = memoriaCalulo.resultadoEtapa2PorcentagemDeMatriculasEmTempoIntegral.ensFunAnosFinais;
                        const perentualMatriculasTempoIntegralEnsinoMedio: number = memoriaCalulo.resultadoEtapa2PorcentagemDeMatriculasEmTempoIntegral.ensMedio;
                        const perentualMatriculasTempoIntegralEJA: number = memoriaCalulo.resultadoEtapa2PorcentagemDeMatriculasEmTempoIntegral.eja;

                        data.percentualMatriculasTempoIntegralCreche = perentualMatriculasTempoIntegralCreche;
                        data.percentualMatriculasTempoIntegralPreescola = perentualMatriculasTempoIntegralPreescola;
                        data.percentualMatriculasTempoIntegralEnsFundamentalAnosIniciais = perentualMatriculasTempoIntegralEnsFundamentalAnosIniciais;
                        data.percentualMatriculasTempoIntegralEnsFundamentalAnosFinais = perentualMatriculasTempoIntegralEnsFundamentalAnosFinais;
                        data.percentualMatriculasTempoIntegralEnsinoMedio = perentualMatriculasTempoIntegralEnsinoMedio;
                        data.percentualMatriculasTempoIntegralEJA = perentualMatriculasTempoIntegralEJA;
                        data.percentualMatriculasEmEscolasRurais = matriculasEscolaRural > 0 ? (matriculasEscolaRural / data.totalMatriculas) * 100 : 0;

                        data.numeroTotalDocentesProjetado = memoriaCalulo.resultadoEtapa10CalculoDemandaPorDocentes.total;
                        data.numeroTotalFuncionariosProjetado = memoriaCalulo.resultadoEtapa18ProjecaoNumeroFuncionarios.total;

                        data.totalTurmasExistentes = relarioDimensaoOferta.content.numeroTurmas;
                        data.totalTurmasNecessarias = memoriaCalulo.resultadoEtapa4CalculoNumeroTurmas.total;

                        if (tipoRecorte === TipoDependencia.Municipal) {
                          data.despesaCorrenteNecessaria = memoriaCalulo.resultadoEtapa27TotalDespesasCorrentes.despesasGovernoMunicipal;
                          data.receitaPotencialMinimaVinculadaEducacaoBasica = planejamentoOrcamentario.content.receitaPotencialMunicipal;
                          data.despesaCorrenteRealizada = planejamentoOrcamentario.content.despesaRealizadaMunicipal;
                          data.dependenciaAdministrativa = "Municipal";

                          data.codigo = localidadeSelecionada.selectedCity.value;
                          data.uf = localidadeSelecionada.selectedState.label;
                          data.enteFederativo = localidadeSelecionada.selectedCity.label;

                        }
                        if (tipoRecorte === TipoDependencia.Estadual) {
                          data.despesaCorrenteNecessaria = memoriaCalulo.resultadoEtapa27TotalDespesasCorrentes.despesasGovernoEstadual + memoriaCalulo.resultadoEtapa27TotalDespesasCorrentes.despesasGovernoMunicipal;
                          data.receitaPotencialMinimaVinculadaEducacaoBasica = planejamentoOrcamentario.content.receitaPotencialEstadual + planejamentoOrcamentario.content.receitaPotencialMunicipal;
                          data.despesaCorrenteRealizada = planejamentoOrcamentario.content.despesaRealizadaEstadual + planejamentoOrcamentario.content.despesaRealizadaMunicipal;
                          data.dependenciaAdministrativa = "Estadual e Municipal";

                          data.codigo = localidadeSelecionada.selectedState.value;
                          data.uf = "BR";
                          data.enteFederativo = localidadeSelecionada.selectedState.label;
                        }
                        if (tipoRecorte === TipoDependencia.Nacional) {
                          data.despesaCorrenteNecessaria = memoriaCalulo.resultadoEtapa27TotalDespesasCorrentes.totalDespesasCorrentes;
                          data.receitaPotencialMinimaVinculadaEducacaoBasica = planejamentoOrcamentario.content.receitaPotencialMunicipal + planejamentoOrcamentario.content.receitaPotencialEstadual;
                          data.despesaCorrenteRealizada = planejamentoOrcamentario.content.despesaRealizadaMunicipal + planejamentoOrcamentario.content.despesaRealizadaEstadual;
                          data.dependenciaAdministrativa = "Estadual e Municipal";
                          data.uf = "BR";
                          data.enteFederativo = "BRASIL";
                          data.codigo = "";
                        }

                        data.valorAlunoCAQAno = data.despesaCorrenteNecessaria / data.totalMatriculas;
                        data.valorAlunoCAQMes = data.valorAlunoCAQAno / 12;

                        if (memoriaCalulo.complementacaoDto) {
                          data.valorComplementacao = memoriaCalulo.complementacaoDto.complementacaoNivelMunicipio + memoriaCalulo.complementacaoDto.complementacaoNivelEstado;
                          data.percentualComplementacao = (memoriaCalulo.complementacaoDto.complementacaoNivelMunicipio * 100) / planejamentoOrcamentario.content.receitaPotencialMunicipal;
                        }


                        return data;
                      }));
                  }));
              }));
          }));
      }));
  }

  downloadCsv(): void {
    const tipoRecorte = this.utilitiesService.getTipoRecorte();
    const tipoDependenciaEstadoOuMunicipio = this.utilitiesService.getTipoDependenciaEstadoOuMunicipio();
    let caqUrlUFG: string;

    if (tipoRecorte === TipoDependencia.Municipal) {
      caqUrlUFG = this.httpService.apiEndpointFabriacUFG + `/financiamento-entes-federados` + tipoDependenciaEstadoOuMunicipio + `/analitico`;
    } else if (tipoRecorte === TipoDependencia.Estadual) {
      caqUrlUFG = this.httpService.apiEndpointFabriacUFG + `/financiamento-entes-federados` + tipoDependenciaEstadoOuMunicipio + `/analitico`;
    } else if (tipoRecorte === TipoDependencia.Nacional) {
      caqUrlUFG = this.httpService.apiEndpointFabriacUFG + `/financiamento-entes-federados/nacional/analitico`;
    }

    window.open(caqUrlUFG);

  }
  isSimulationCity(): boolean {

    const resultForSelectLocation: SelectLocation = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);
    if (resultForSelectLocation.selectedCity) {
      return true;
    } else {
      return false;
    }
  }
}
