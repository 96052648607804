export class SerieEnrollmentsBySchool {
    id: number;
    description: string;
    totalEnrollmentDay: number;
    totalEnrollmentNight: number;

    constructor(init?: Partial<SerieEnrollmentsBySchool>) {
        Object.assign(this, init);
    }
}
