import { TeacherNumberByYear } from './teacher-number-by-year';

export class TeacherNumberGroupedCalculation {

  id: number;
  stageDescription: string;
  teacherNumberByYear: Array<TeacherNumberByYear>;

  constructor(init?: Partial<TeacherNumberGroupedCalculation>) {
    Object.assign(this, init);
  }

}
