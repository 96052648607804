import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';

import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { SessionService } from '../../../../shared/services/session/session.service';
import { SchoolsFeedings } from '../entities/schools-feedings';
import { NavigableComponentService } from '../../../shared/entities/base/navigable-component-service';
import { UtilitiesService } from './../../../../shared/services/utilities/utilities.service';
import { StageEnum } from './../../../../shared/entities/enums/stage.enum';
import { EnrollmentBySchool } from 'app/simulator/access-and-offer/enrollment-by-stage-series-by-school/entities/enrollment-by-school';

@Injectable({
  providedIn: 'root'
})
export class SchoolFeedingService implements NavigableComponentService {

  constructor(
    private sessionService: SessionService,
    private utilitiesService: UtilitiesService
  ) { }

  getData(): Observable<SchoolsFeedings> {
    return of(this.sessionService.getItem<SchoolsFeedings>(Functionality.schoolFeeding.pqrKey));
  }

  setTotalStudent(schoolsFeedings: SchoolsFeedings): void {
    schoolsFeedings.year = _.first(this.utilitiesService.getSimulationYears(1));
    const resultForEnrollmentSchool: EnrollmentBySchool = this.sessionService.getItem<EnrollmentBySchool>(Functionality.enrollmentByStageAndSeriesBySchool.key);

    let resultTotalIntegral: number = 0;
    let resultTotalParcial: number = 0;

    for (let i = 0; i < resultForEnrollmentSchool.enrollmentByStageSeriesBySchool.length; i++) {
      for (let j = 0; j < resultForEnrollmentSchool.enrollmentByStageSeriesBySchool[i].enrollmentBySchoolLocation.length; j++) {
        for (let k = 0; k < resultForEnrollmentSchool.enrollmentByStageSeriesBySchool[j].enrollmentBySchoolLocation[j].stagesEnrollmentsByScholl.length; k++) {

          const totalEnrollmentDaySchool = resultForEnrollmentSchool.enrollmentByStageSeriesBySchool[i].enrollmentBySchoolLocation[j].stagesEnrollmentsByScholl[k].totalEnrollmentDay;
          const totalEnrollmentNightSchool = resultForEnrollmentSchool.enrollmentByStageSeriesBySchool[i].enrollmentBySchoolLocation[j].stagesEnrollmentsByScholl[k].totalEnrollmentNight;
          const integralPercentageSchool = resultForEnrollmentSchool.enrollmentByStageSeriesBySchool[i].enrollmentBySchoolLocation[j].stagesEnrollmentsByScholl[k].percentageIntegral;
          if (resultForEnrollmentSchool.enrollmentByStageSeriesBySchool[i].enrollmentBySchoolLocation[j].stagesEnrollmentsByScholl[k].id !== StageEnum.eja) {

            const integralEnrollmentSchool: number = (totalEnrollmentDaySchool * (integralPercentageSchool / 100));
            const parcialEnrollmentSchool: number = (totalEnrollmentDaySchool - integralEnrollmentSchool) + totalEnrollmentNightSchool;

            resultTotalIntegral += integralEnrollmentSchool;
            resultTotalParcial += parcialEnrollmentSchool;
          } else {
            resultTotalParcial += totalEnrollmentDaySchool + totalEnrollmentNightSchool;
          }
        }
      }
    }

    schoolsFeedings.feedings[0].totalEnrollment = parseFloat(resultTotalParcial.toFixed(0));
    schoolsFeedings.feedings[1].totalEnrollment = parseFloat(resultTotalIntegral.toFixed(0));
  }

}
