import { Enrollment } from './enrollment';
import { OutOfSchoolPopulation } from '../../../shared/services/out-of-school-population/entities/out-of-school-population';

export class SerieEnrollments {
    id: number;
    description: string;
    outOfSchoolPopulations: Array<OutOfSchoolPopulation> = new Array<OutOfSchoolPopulation>();
    currentOffer: number = 0;
    enrollments: Array<Enrollment> = new Array<Enrollment>();

    constructor(init?: Partial<SerieEnrollments>) {
        Object.assign(this, init);
    }
}
