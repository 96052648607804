import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { FullPriceList } from './entities/full-price-list';
import { FullPriceListService } from './services/full-price-list.service';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { SimulatorService } from '../../simulator/services/simulator.service';

@Component({
  selector: 'app-full-price-list',
  templateUrl: './full-price-list.component.html',
  styleUrls: ['./full-price-list.component.scss']
})
export class FullPriceListComponent extends BaseNavigableComponent<FullPriceList> {

  data: FullPriceList = new FullPriceList();
  functionality: FunctionalityInfo = Functionality.fullPriceList;
  inconsistencies: Array<Inconsistency>;

  constructor(private fullPriceListService: FullPriceListService, private simulatorService: SimulatorService, private injector: Injector) {
    super(injector);
  }

  processData(): Observable<FullPriceList> {
    return this.fullPriceListService.getData();
  }

  getNextStepRoute(): Array<string> {
    return this.simulatorService.getResultSimulationRoute();
  }

}
