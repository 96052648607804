import { Component, Injector, Type } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { Functionality } from '../../../shared/entities/functionality/functionality';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { FormationLevel } from '../../../shared/entities/formation-level';
import { SchoolsStaff } from './entities/schools-staff';
import { SchoolsStaffService } from './services/schools-staff.service';
import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { PqrComponentData } from '../../shared/entities/base/pqr-component-data';
import { NavigableComponentData } from '../../shared/entities/base/navigable-component-data';
import { SourceInformation } from '../../../shared/components/source-information/entities/source-information';

@Component({
  selector: 'app-schools-staff',
  templateUrl: './schools-staff.component.html',
  styleUrls: ['./schools-staff.component.scss']
})
export class SchoolsStaffComponent extends BaseNavigableComponent<SchoolsStaff> implements PqrComponentData {

  data: SchoolsStaff = new SchoolsStaff();
  functionality: FunctionalityInfo = Functionality.schoolsStaff;
  inconsistencies: Array<Inconsistency>;
  pqrAdminMode: boolean;
  pqrMode: boolean;
  pqrModalMode: boolean;
  componentType: Type<PqrComponentData> = SchoolsStaffComponent;
  referenceDateMask: any = this.utilitiesService.getReferenceDateMask();

  constructor(private schoolsStaffService: SchoolsStaffService, private injector: Injector) {
    super(injector);
  }

  processData(): Observable<SchoolsStaff> {
    return this.schoolsStaffService.getData();
  }

  getPqrData(): NavigableComponentData {
    return this.data;
  }

  setPqrData(pqrData: any) {
    this.data = pqrData;
  }

  formationLevelEquals(FormationLevelA: FormationLevel, FormationLevelB: FormationLevel): boolean {
    if (FormationLevelA && FormationLevelB) {
      return FormationLevelA.id === FormationLevelB.id;
    }

    return false;
  }

  newSchoolsStaff(): void {
    const newStaff = this.schoolsStaffService.getNewSchoolsStaff(this.data.staffs.length, this.data.locations);
    this.data.staffs.push(newStaff);
  }

  deleteSchoolsStaff(sequence: string): void {
    this.schoolsStaffService.removeSchoolsStaff(this.data.staffs, sequence);
  }

  processValidation(): void {
    super.processValidation();

    if (_.uniq(this.data.staffs.map(d => d.denomination)).length !== this.data.staffs.length) {
      this.inconsistencies.push(new Inconsistency({
        message: `Existem denominações duplicadas.`
      }));
    }

    let functionDescription: String = '';

    for (const staff of this.data.staffs) {

      if (staff.denomination === undefined || staff.denomination === '') {
        functionDescription = staff.sequence.toString();
        this.inconsistencies.push(new Inconsistency({
          message: `A descrição da função ${staff.sequence} deve ser informada.`
        }));
      } else {
        functionDescription = staff.denomination;
      }

      let locationSelected: boolean = false;

      for (const location of staff.employeeLocation) {
        if (location.value) {
          locationSelected = true;
        }
      }

      if (!locationSelected) {
        this.inconsistencies.push(new Inconsistency({
          message: `A localização não foi informada para a função ${functionDescription}.`
        }));
      }

      if ((staff.professionalByEnrollment === undefined || staff.professionalByEnrollment === 0) &&
        (staff.professionalBySchool === undefined || staff.professionalBySchool === 0)) {
        this.inconsistencies.push(new Inconsistency({
          message: `Um dos campos 'Quantidade de Matrícula por profissional' ou 'Quantidade de profissional por escola' da função ${functionDescription} deve ser informado.`
        }));
      } else {
        if (staff.professionalByEnrollment > 0 && staff.professionalBySchool > 0) {
          this.inconsistencies.push(new Inconsistency({
            message: `Apenas um dos campos 'Quantidade de Matrícula por profissional' ou 'Quantidade de profissional por escola'
              da função ${functionDescription} deve ser informado com valor maior que 0.`
          }));
        }
      }

      /*if (isNaN(staff.minBySchool) || staff.minBySchool === null || staff.minBySchool === undefined || staff.minBySchool <= 0) {
        this.inconsistencies.push(new Inconsistency({
          message: `O número mínimo/escola da função ${functionDescription} deve ser informado com um valor maior do que 0.`
        }));
      }*/

      if (staff.maxBySchool !== undefined && staff.maxBySchool !== null) {
        if (staff.minBySchool > staff.maxBySchool) {
          this.inconsistencies.push(new Inconsistency({
            message: `O número mínimo/escola da função ${functionDescription} deve ser menor ou igual ao número máximo/escola.`
          }));
        }
      }

      if (staff.formationLevel) {
        if (staff.formationLevel.id === undefined) {
          this.inconsistencies.push(new Inconsistency({
            message: `O nível de formação da função ${functionDescription} não é válido. Selecione um valor.`
          }));
        }
      } else {
        this.inconsistencies.push(new Inconsistency({
          message: `O nível de formação da função ${functionDescription} não é válido. Selecione um valor.`
        }));
      }

      if (staff.grossMonthlyRemuneration === undefined || staff.grossMonthlyRemuneration === null) {
        this.inconsistencies.push(new Inconsistency({
          message: `A remuneração bruta mensal da função ${functionDescription} deve ser informada.`
        }));
      }

      if (!this.utilitiesService.isReferenceDateValid(staff.referenceDate)) {
        this.inconsistencies.push(new Inconsistency({
          message: `A data de referência da função ${functionDescription} não é valida.`
        }));
      }

    }
  }

}
