import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { CareerLevelByYear } from './../calculate-teacher-number/entities/career-level-by-year';
import { TeacherNumberByStageCalc } from './../calculate-teacher-number/entities/teacher-number-by-stage-calc';
import { TeacherNumberByLocationCalc } from './../calculate-teacher-number/entities/teacher-number-by-location-calc';
import { TeacherNumberCalc } from './../calculate-teacher-number/entities/teacher-number-calc';
import { UtilitiesService } from './../../../../shared/services/utilities/utilities.service';
import { CareerLevel } from '../calculate-teacher-number/entities/career-level';
import { SessionService } from './../../../../shared/services/session/session.service';
import { CareerAndRemunerationTeachers } from './../../../quality-conditions/career-and-remuneration-teachers/entities/career-and-remuneration-teachers';
import { StageAndSeries } from './../../../../shared/entities/stage-and-series';
import { Location } from './../../../../shared/entities/location';
import { TeacherFormationLevel } from './../../../quality-conditions/career-and-remuneration-teachers/entities/teacher-formation-level';
import { Stage } from 'app/shared/entities/stage';
import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { PercentageTeacherCareer } from 'app/simulator/results/financing-federated-entities-report/entities/percentage-teacher-career';
import { FormationLevelEnum } from 'app/shared/entities/enums/formation-level.enum';

@Injectable({
  providedIn: 'root'
})
export class CreateTeacherNumberCalcService {

  constructor(private utilitiesService: UtilitiesService,
    private sessionService: SessionService
  ) { }

  getTeacherNumberCalculated(datas: any): Array<TeacherNumberCalc> {

    const teachersNumber: Array<TeacherNumberCalc> = new Array<TeacherNumberCalc>();
    const teachersNumberByLocations: Array<TeacherNumberByLocationCalc> = new Array<TeacherNumberByLocationCalc>();

    for (let i = 0; i < datas.length; i++) {

      teachersNumberByLocations.push(new TeacherNumberByLocationCalc({
        id: datas[i].location_id,
        description: datas[i].location_name,
        teachersNumberByStagesCalc: this.getTeacherNumberByStage(datas[i].education_level)
      }));
    }

    teachersNumber.push(new TeacherNumberCalc({ teachersNumberByLocationsCalc: teachersNumberByLocations }));

    return teachersNumber;
  }

  getEmptyTeacherNumberCalculated(locations: Array<Location>, stages: Array<StageAndSeries>): Array<TeacherNumberCalc> {

    const teachersNumber: Array<TeacherNumberCalc> = new Array<TeacherNumberCalc>();
    const teachersNumberByLocations: Array<TeacherNumberByLocationCalc> = new Array<TeacherNumberByLocationCalc>();
    const resultForCareerAndRemunerationTeachers: CareerAndRemunerationTeachers = this.sessionService.getItem<CareerAndRemunerationTeachers>(Functionality.careerAndRemunerationTeachers.key);

    for (let i = 0; i < locations.length; i++) {

      teachersNumberByLocations.push(new TeacherNumberByLocationCalc({
        id: locations[i].id,
        description: locations[i].description,
        teachersNumberByStagesCalc: this.getEmptyTeacherNumberByStage(resultForCareerAndRemunerationTeachers.teacherFormationLevels.levels, stages)
      }));
    }

    teachersNumber.push(new TeacherNumberCalc({ teachersNumberByLocationsCalc: teachersNumberByLocations }));

    return teachersNumber;
  }

  getPercentageTeacherCareer(datas: any): Array<PercentageTeacherCareer> {
    const percentagesTeachersCareers: Array<PercentageTeacherCareer> = new Array<PercentageTeacherCareer>();
    for (let i = 0; i < datas.percentage_teacher_career.length; i++) {
      if (datas.percentage_teacher_career[i].formation_level_id !== FormationLevelEnum.Medio) {
        percentagesTeachersCareers.push(new PercentageTeacherCareer({
          formationLevelId: datas.percentage_teacher_career[i].formation_level_id,
          percentage: datas.percentage_teacher_career[i].percentage
        }));
      }
    }
    return percentagesTeachersCareers;
  }

  private getTeacherNumberByStage(
    educationsLevels: Array<any>
  ): Array<TeacherNumberByStageCalc> {

    const teachersNumberByStages: Array<TeacherNumberByStageCalc> = new Array<TeacherNumberByStageCalc>();

    for (let i = 0; i < educationsLevels.length; i++) {

      const data = educationsLevels[i];

      const teacherNumberByStageCalc: TeacherNumberByStageCalc = new TeacherNumberByStageCalc({
        id: data.education_level_short_id,
        description: data.education_level_short_name,
        careerLevelsByYear: this.getCareerLevelByYear(data)
      });

      teachersNumberByStages.push(teacherNumberByStageCalc);
    }
    return teachersNumberByStages;
  }

  private getCareerLevelByYear(teacherNumberStage: any): Array<CareerLevelByYear> {

    const careersLevelsByYear: Array<CareerLevelByYear> = new Array<CareerLevelByYear>();
    const simulationsYears: Array<number> = this.utilitiesService.getSimulationYears();

    for (let i = 0; i < simulationsYears.length; i++) {
      const year = simulationsYears[i];
      careersLevelsByYear.push(new CareerLevelByYear({
        year: year,
        teacherNumberTotal: teacherNumberStage.teacherNumber.total_teacher,
        teacherNumberShiftPartial: teacherNumberStage.teacherNumber.total_teacher_partial,
        teacherNumberShiftIntegral: teacherNumberStage.teacherNumber.total_teacher_full_period,
        careerLevels: this.getCareerLevels(teacherNumberStage.teacherNumber.careerLevels)
      }));
    }
    return careersLevelsByYear;
  }

  private getCareerLevels(careerLevelsStage: Array<any>): Array<CareerLevel> {

    const careerLevels: Array<CareerLevel> = new Array<CareerLevel>();

    for (const teacherFormationLevel of careerLevelsStage) {

      careerLevels.push(new CareerLevel({
        sequence: teacherFormationLevel.sequence,
        denomination: teacherFormationLevel.denomination,
        quantity: teacherFormationLevel.total_teacher_career,
        totalTeacherFullPeriodCareer: teacherFormationLevel.total_teacher_full_period_career,
        totalTeacherPartialCareer: teacherFormationLevel.total_teacher_partial_career
      }));
    }
    return careerLevels;
  }

  private getEmptyTeacherNumberByStage(
    levels: Array<TeacherFormationLevel>, stages: Array<Stage>
  ): Array<TeacherNumberByStageCalc> {

    const teachersNumberByStages: Array<TeacherNumberByStageCalc> = new Array<TeacherNumberByStageCalc>();

    for (let i = 0; i < stages.length; i++) {

      const teacherNumberByStageCalc: TeacherNumberByStageCalc = new TeacherNumberByStageCalc({
        id: stages[i].id,
        description: stages[i].description,
        careerLevelsByYear: this.getEmptyCareerLevelByYear(levels)
      });

      teachersNumberByStages.push(teacherNumberByStageCalc);
    }
    return teachersNumberByStages;
  }

  private getEmptyCareerLevelByYear(levels: Array<TeacherFormationLevel>): Array<CareerLevelByYear> {

    const careersLevelsByYear: Array<CareerLevelByYear> = new Array<CareerLevelByYear>();
    const simulationsYears: Array<number> = this.utilitiesService.getSimulationYears();

    for (let i = 0; i < simulationsYears.length; i++) {
      const year = simulationsYears[i];
      careersLevelsByYear.push(new CareerLevelByYear({
        year: year,
        teacherNumberTotal: 0,
        teacherNumberShiftPartial: 0,
        teacherNumberShiftIntegral: 0,
        careerLevels: this.getEmptyCareerLevels(levels)
      }));
    }
    return careersLevelsByYear;
  }

  private getEmptyCareerLevels(levels: Array<TeacherFormationLevel>): Array<CareerLevel> {

    const careerLevels: Array<CareerLevel> = new Array<CareerLevel>();

    for (let i = 0; i < levels.length; i++) {

      careerLevels.push(new CareerLevel({
        sequence: levels[i].sequence,
        denomination: levels[i].denomination,
        quantity: 0,
        totalTeacherFullPeriodCareer: 0,
        totalTeacherPartialCareer: 0
      }));
    }
    return careerLevels;
  }

}
