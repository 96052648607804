import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { Enrollment } from '../../../access-and-offer/enrollment-projection/entities/enrollment';
import { StageEnrollments } from '../../../access-and-offer/enrollment-projection/entities/stage-enrollments';
import { CostDriveByYear } from './entities/cost-drive-by-year';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { EnrollmentProjection } from '../../../access-and-offer/enrollment-projection/entities/enrollment-projection';
import { CostDriveByStage } from './entities/cost-drive-by-stage';
import { SessionService } from '../../../../shared/services/session/session.service';
import { CostDrive } from './entities/cost-drive';
import { EnrollmentProjectionByLocation } from '../../../access-and-offer/enrollment-projection/entities/enrollment-projection-by-location';
import { CostDriveByLocation } from './entities/cost-drive-by-location';
import { OfferGoalEnrollmentFullTime } from '../../../quality-conditions/offer-goal-enrollment-full-time/entities/offer-goal-enrollment-full-time';
import { FullTime } from '../../../quality-conditions/offer-goal-enrollment-full-time/entities/full-time';

@Injectable({
  providedIn: 'root'
})
export class CalculateCostDriveService {

  private indiceEnrollmentCity: number;

  constructor(
    private utilitiesService: UtilitiesService,
    private sessionService: SessionService) { }

  // @tempoDeExecucao()
  calculateCostDrive(enrollmentProjection: EnrollmentProjection = undefined): Observable<Array<CostDrive>> {

    const costDrives: Array<CostDrive> = new Array<CostDrive>();
    let resultForEnrollmentProjection: EnrollmentProjection = new EnrollmentProjection();
    const resultsForOfferGoalEnrollmentFullTime: OfferGoalEnrollmentFullTime = this.sessionService.getItem<OfferGoalEnrollmentFullTime>(Functionality.offerGoalEnrollmentFullTime.key);

    if (!enrollmentProjection) {
      resultForEnrollmentProjection = this.sessionService.getItem<EnrollmentProjection>(Functionality.enrollmentProjectionByLocation.key);
    } else {
      resultForEnrollmentProjection = enrollmentProjection;
    }

    const enrollmentsTotal: Array<Enrollment> = this.getEnrollmentTotal(resultForEnrollmentProjection);

    const costDrivesByLocations: Array<CostDriveByLocation> = new Array<CostDriveByLocation>();

    for (let i = 0; i < resultForEnrollmentProjection.enrollmentsProjectionsByLocations.length; i++) {

      const enrollmentProjectionByLocation = resultForEnrollmentProjection.enrollmentsProjectionsByLocations[i];
      this.indiceEnrollmentCity = 1;

      costDrivesByLocations.push(new CostDriveByLocation({
        id: enrollmentProjectionByLocation.id,
        description: enrollmentProjectionByLocation.description,
        costDrivesByStages: this.getCostDriveByStage(enrollmentProjectionByLocation, resultsForOfferGoalEnrollmentFullTime, enrollmentsTotal)
      }));
    }

    costDrives.push(new CostDrive({
      costDrivesByLocations: costDrivesByLocations
    }));

    return of(costDrives);

  }

  private getCostDriveByStage(
    enrollmentsProjectionByLocation: EnrollmentProjectionByLocation,
    resultsForOfferGoalEnrollmentFullTime: OfferGoalEnrollmentFullTime,
    enrollmentsTotal: Array<Enrollment>): Array<CostDriveByStage> {

    const costDrivesByStages: Array<CostDriveByStage> = new Array<CostDriveByStage>();

    for (let i = 0; i < enrollmentsProjectionByLocation.stagesEnrollments.length; i++) {

      const enrollmentProjectionByStage: StageEnrollments = enrollmentsProjectionByLocation.stagesEnrollments[i];

      let offerGoalEnrollmentFullTime: FullTime = new FullTime({
        id: enrollmentProjectionByStage.id,
        stageDescription: enrollmentProjectionByStage.description,
        offerGoal: 0
      });

      if (enrollmentProjectionByStage.integralPercentage !== undefined) {
        offerGoalEnrollmentFullTime.offerGoal = enrollmentProjectionByStage.integralPercentage;
      } else {
        for (let j = 0; j < resultsForOfferGoalEnrollmentFullTime.fullTime.length; j++) {
          if (resultsForOfferGoalEnrollmentFullTime.fullTime[j].id === enrollmentProjectionByStage.id) {
            offerGoalEnrollmentFullTime = resultsForOfferGoalEnrollmentFullTime.fullTime[j];
          }
        }
      }

      costDrivesByStages.push(new CostDriveByStage({
        id: enrollmentProjectionByStage.id,
        description: enrollmentProjectionByStage.description,
        costDrivesByYears: this.getCostDriveByYear(enrollmentProjectionByStage.totalEnrollments, offerGoalEnrollmentFullTime, enrollmentsProjectionByLocation.totalsEnrollmentProjection)
      }));
    }
    return costDrivesByStages;
  }

  private getCostDriveByYear(
    enrollmentsByStage: Array<Enrollment>,
    offerGoalEnrollmentFullTime: FullTime,
    enrollmentsTotalByLocation: Array<Enrollment>): Array<CostDriveByYear> {

    const costDrivesByYears: Array<CostDriveByYear> = new Array<CostDriveByYear>();

    for (let i = 0; i < enrollmentsByStage.length; i++) {

      const enrollment: Enrollment = enrollmentsByStage[i];

      let totalEnrollmentYear: number = 0;
      for (let j = 0; j < enrollmentsTotalByLocation.length; j++) {
        if (enrollmentsTotalByLocation[j].year === enrollment.year) {
          totalEnrollmentYear = enrollmentsTotalByLocation[j].quantity;
          break;
        }
      }

      // const enrollmentIntegralYear: number = ((enrollment.quantity - enrollment.quantityNocturnal) * this.indiceEnrollmentCity) * (offerGoalEnrollmentFullTime.offerGoal / 100);
      const enrollmentIntegralYear: number = (enrollment.quantity * this.indiceEnrollmentCity) * (offerGoalEnrollmentFullTime.offerGoal / 100);
      const enrollmentPartialYear: number = (enrollment.quantity * this.indiceEnrollmentCity) - enrollmentIntegralYear;

      costDrivesByYears.push(new CostDriveByYear({
        year: enrollment.year,
        value_shift_integral: totalEnrollmentYear > 0 ? (enrollmentIntegralYear / totalEnrollmentYear) : 0,
        value_shift_partial: totalEnrollmentYear > 0 ? (enrollmentPartialYear / totalEnrollmentYear) : 0
      }));
    }

    return costDrivesByYears;
  }

  private getEnrollmentTotal(resultForEnrollmentProjection: EnrollmentProjection): Array<Enrollment> {

    const simulationYears: Array<number> = this.utilitiesService.getSimulationYears();
    const enrollmentsTotal: Array<Enrollment> = simulationYears.map(year => new Enrollment({ year: year, quantity: 0 }));

    for (let i = 0; i < resultForEnrollmentProjection.enrollmentsProjectionsByLocations.length; i++) {

      const enrollmentTotalLocations = resultForEnrollmentProjection.enrollmentsProjectionsByLocations[i].totalsEnrollmentProjection;

      for (let j = 0; j < enrollmentTotalLocations.length; j++) {
        enrollmentsTotal[j].quantity += enrollmentTotalLocations[j].quantity;
      }
    }
    return enrollmentsTotal;
  }
}
