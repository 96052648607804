import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { SessionService } from '../../../../shared/services/session/session.service';
import { HttpService } from '../../../../shared/services/http/http.service';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { EnrollmentBySchool } from 'app/simulator/access-and-offer/enrollment-by-stage-series-by-school/entities/enrollment-by-school';
import { SelectLocation } from 'app/simulator/select-location/entities/select-location';
import { SimulatorService } from 'app/simulator/simulator/services/simulator.service';
import { HttpHeaders } from '@angular/common/http';
import { TipoDependencia } from 'app/shared/entities/enums/tipo-dependencia';
import { CustoPorEscola } from '../entities/custo-por-escola';

@Injectable({
  providedIn: 'root'
})
export class CustoPorEscolaService {

  constructor(
    private utilitiesService: UtilitiesService,
    private httpService: HttpService,
    private sessionService: SessionService,
  ) { }

  downloadCsvPorEscola(): void {

    const tipoRecorte = this.utilitiesService.getTipoRecorte();
    const tipoDependenciaEstadoOuMunicipio = this.utilitiesService.getTipoDependenciaEstadoOuMunicipio();

    let url: string;
    if (tipoRecorte === TipoDependencia.Municipal || tipoRecorte === TipoDependencia.Estadual) {
      url = this.httpService.apiEndpointFabriacUFG + '/custo-escola-publica-em-condicao-de-qualidade/' + tipoDependenciaEstadoOuMunicipio
    } else if (tipoRecorte === TipoDependencia.Nacional) {
      url = this.httpService.apiEndpointFabriacUFG + '/custo-escola-publica-em-condicao-de-qualidade/nacional'
    }
    window.open(url);

  }

  getCustoPorEscola(): Observable<CustoPorEscola> {
    /*
    let data: CustoPorEscola = new CustoPorEscola();
    const pqrData: Array<any> = this.utilitiesService.getPqrLocalUFG();
    const tipoRecorte = this.utilitiesService.getTipoRecorte();
    const tipoDependenciaEstadoOuMunicipio = this.utilitiesService.getTipoDependenciaEstadoOuMunicipio();
    const requestOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const editMode: boolean = this.sessionService.getItem<boolean>(SimulatorService.simulationEditModeSessionKey);


    
        if (editMode) {
          // quando estiver no modo edição usará o metodo POST
          const resultForEnrollmentSchool: EnrollmentBySchool = this.sessionService.getItem<EnrollmentBySchool>(Functionality.enrollmentByStageAndSeriesBySchool.key);
          resultForEnrollmentSchool.diagnosticosMatriculaPorEscola.forEach(objeto => {
            // Remover a propriedade 'version' de cada objeto
            delete objeto.version;
    
            objeto.etapasEnsino.forEach(etapas => {
              // Remover a propriedade 'percentualMatriculasIntegral' de cada objeto
              delete etapas.percentualMatriculasIntegral;
            });
          });
    
          const resultadoJson = {
            diagnosticoDeMatriculaPorEscola: resultForEnrollmentSchool.diagnosticosMatriculaPorEscola,
            pqr: pqrData
          }
          return this.getResultadosMetodoPost(tipoRecorte, data, resultadoJson, tipoDependenciaEstadoOuMunicipio, requestOptions);
        } else {
          // se não estiver no modo edição usará o método GET
          return this.getResultadosMetodoGet(tipoRecorte, data, tipoDependenciaEstadoOuMunicipio, requestOptions);
        }
          */
    return null;
  }
  /*
    getResultadosMetodoGet(tipoRecorte: string, data: CustoPorEscola, tipoDependenciaEstadoOuMunicipio: string, requestOptions: { headers: HttpHeaders; }): Observable<CustoPorEscola> {
      return this.httpService.getApiEndpointUFG().pipe(
        switchMap(apiEndpoint => {
  
          let url = apiEndpoint;
          if (tipoRecorte === TipoDependencia.Municipal || tipoRecorte === TipoDependencia.Estadual) {
            url = url + '/custo-escola-publica-em-condicao-de-qualidade/' + tipoDependenciaEstadoOuMunicipio
          } else if (tipoRecorte === TipoDependencia.Nacional) {
            url = url + '/custo-escola-publica-em-condicao-de-qualidade/nacional'
          }
  
          console.log(url)
  
          return this.httpService.get<any>(url).pipe(
            map(relatorioCustoPorEscola => {
              data.relatorioCustoPorEscola = relatorioCustoPorEscola;
              return data;
            }));
        }));
    }
  
    getResultadosMetodoPost(tipoRecorte: string, data: CustoPorEscola, resultadoJson: { diagnosticoDeMatriculaPorEscola: import("app/simulator/access-and-offer/enrollment-by-stage-series-by-school/entities/diagnostico-de-matricula-por-escola").DiagnosticoDeMatriculaPorEscola[]; pqr: any[]; }, tipoDependenciaEstadoOuMunicipio: string, requestOptions: { headers: HttpHeaders; }): Observable<CustoPorEscola> {
      return this.httpService.getApiEndpointUFG().pipe(
        switchMap(apiEndpoint => {
  
          let url = apiEndpoint;
          if (tipoRecorte === TipoDependencia.Municipal || tipoRecorte === TipoDependencia.Estadual) {
            url = url + '/custo-escola-publica-em-condicao-de-qualidade/' + tipoDependenciaEstadoOuMunicipio
          } else if (tipoRecorte === TipoDependencia.Nacional) {
            url = url + '/custo-escola-publica-em-condicao-de-qualidade/nacional'
          }
  
          return this.httpService.post<any>(url, resultadoJson, requestOptions).pipe(
            map(relatorioCustoPorEscola => {
              data.relatorioCustoPorEscola = relatorioCustoPorEscola;
              return data;
            }));
        }));
    }
  */
  isSimulationCity(): boolean {

    const resultForSelectLocation: SelectLocation = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);
    if (resultForSelectLocation.selectedCity) {
      return true;
    }
    return false;
  }
}
