import { Routes } from '@angular/router';

import { InconsistencyGuard } from '../shared/guards/inconsistency/inconsistency.guard';
import { HasRequiredDataGuard } from '../shared/guards/required-data/has-required-data.guard';
import { HasErrorGuard } from '../../shared/guards/has-error/has-error.guard';
import { ViewEnrollmentByStageSeriesComponent } from './view-enrollment-by-stage-series/view-enrollment-by-stage-series.component';
import { EnrollmentByStageSeriesComponent } from './enrollment-by-stage-series/enrollment-by-stage-series.component';
import { EnrollmentProjectionComponent } from './enrollment-projection/enrollment-projection.component';
import { CollaborationSchemeComponent } from './collaboration-scheme/collaboration-scheme.component';
import { EnrollmentByStageSeriesBySchoolComponent } from './enrollment-by-stage-series-by-school/enrollment-by-stage-series-by-school.component';
import { ResultEnrollmentProjectionComponent } from './result-enrollment-projection/result-enrollment-projection.component';

const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: 'viewenrollmentbystageandseries' },
    { path: 'viewenrollmentbystageandseries', component: ViewEnrollmentByStageSeriesComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] },
    { path: 'enrollmentbystageandseries', component: EnrollmentByStageSeriesComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] },
    { path: 'enrollmentbystageseriesbyschool', component: EnrollmentByStageSeriesBySchoolComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] },
    { path: 'resultenrollmentprojection', component: ResultEnrollmentProjectionComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] },
    { path: 'enrollmentprojection', component: EnrollmentProjectionComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] },
    { path: 'collaborationscheme', component: CollaborationSchemeComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] }
];

export const AccessAndOfferChildrenRoutes: Routes = routes;
