import { Component, Injector, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';

import { Inconsistency } from 'app/shared/components/inconsistency/entities/inconsistency';
import { BaseNavigableComponent } from 'app/shared/entities/base/base-navigable-component';
import { Functionality } from 'app/shared/entities/functionality/functionality';
import { FunctionalityInfo } from 'app/shared/entities/functionality/functionality-info';
import { EnrollmentByStageSeriesBySchoolService } from './services/enrollment-by-stage-series-by-school.service';
import { EnrollmentBySchool } from './entities/enrollment-by-school';
import { StageEnrollmentsBySchool } from './entities/stage-enrollments-by-school';
import { MatriculaPorEtapaEnsino } from './entities/matricula-por-etapa-ensino';

@Component({
  selector: 'app-enrollment-by-stage-series-by-school',
  templateUrl: './enrollment-by-stage-series-by-school.component.html',
  styleUrls: ['./enrollment-by-stage-series-by-school.component.scss']
})

export class EnrollmentByStageSeriesBySchoolComponent extends BaseNavigableComponent<EnrollmentBySchool> implements OnInit {

  data: EnrollmentBySchool = new EnrollmentBySchool();
  functionality: FunctionalityInfo = Functionality.enrollmentByStageAndSeriesBySchool;
  inconsistencies: Array<Inconsistency>;
  pqrMode: boolean;
  pqrModalMode: boolean;
  private calculateTotalsSubject: Subject<StageEnrollmentsBySchool> = new Subject<StageEnrollmentsBySchool>();
  private calculateTotalsObserver: Observable<StageEnrollmentsBySchool> = this.calculateTotalsSubject.asObservable();

  constructor(private enrollmentByStageSeriesBySchoolService: EnrollmentByStageSeriesBySchoolService, private injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  processData(): Observable<EnrollmentBySchool> {
    return this.enrollmentByStageSeriesBySchoolService.getData();
  }

  calculateTotal(stageEnrollmentsBySchool: StageEnrollmentsBySchool): void {
    this.calculateTotalsSubject.next(stageEnrollmentsBySchool);
  }

  processSpecificBehaviors(): void {
    super.processSpecificBehaviors();

    this.calculateTotalsObserver.pipe(
      takeUntil(this.unsubscribe),
      debounceTime(0))
      .subscribe(stageEnrollmentsBySchool => this.enrollmentByStageSeriesBySchoolService.calculateTotal(stageEnrollmentsBySchool));
  }

  processValidation(): void {
    super.processValidation();

    for (let i = 0; i < this.data.diagnosticosMatriculaPorEscola.length; i++) {
      for (const etapaEnsino of this.data.diagnosticosMatriculaPorEscola[i].etapasEnsino) {
        if (etapaEnsino.percentualMatriculasIntegral > 100) {
          let escola = this.data.diagnosticosMatriculaPorEscola[i].nomeInstituicao;
          this.inconsistencies.push(new Inconsistency({
            message: `O percentual de matrículas de tempo integral da escola ${escola} da Etapa/Modalidade ${etapaEnsino.descricao} não pode ser maior que 100%.`
          }));
        }
      }
    }
  }

  numeroMatriculasIntegralChange(stage: MatriculaPorEtapaEnsino): void {
    const totalMatriculasDiurno = stage.totalMatriculasDiurno > 0 ? stage.totalMatriculasDiurno : 0;
    if (stage.percentualMatriculasIntegral > 0) {
      stage.numeroMatriculasIntegral = Math.round((stage.percentualMatriculasIntegral / 100) * totalMatriculasDiurno);
      stage.numeroMatriculasDiurno = totalMatriculasDiurno - stage.numeroMatriculasIntegral;
    } else {
      stage.numeroMatriculasIntegral = 0;
      stage.numeroMatriculasDiurno = totalMatriculasDiurno;
    }
  }
}
