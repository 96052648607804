import { StageAdmDependencyLocationEnrollments } from './stage-adm-dependency-location-enrollments';
import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';
import { Footnote } from '../../../../shared/components/footnote/entities/footnote';
import { DiagnosticoMatriculaPorEtapa } from './diagnostico-matricula-por-etapa';

export class ViewEnrollmentByStageSeries extends NavigableComponentData {
    stagesAdmDependencyLocationEnrollments: Array<StageAdmDependencyLocationEnrollments>;
    admDependencies: Array<string>;
    locations: Array<string>;
    totals: StageAdmDependencyLocationEnrollments;
    enrollmentCurrentYear: number;
    outOfSchoolPopulationCurrentYear: number;
    sourceInformations: Array<Footnote>;
    enrollmentNote: Footnote;
    notesOutOfSchoolPopulation: Array<Footnote>;

    diagnosticosMatriculaPorEtapa: Array<DiagnosticoMatriculaPorEtapa>;
    diagnosticoMatriculaTotal: DiagnosticoMatriculaPorEtapa;

    constructor(init?: Partial<ViewEnrollmentByStageSeries>) {
        super();
        Object.assign(this, init);
    }
}
