import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs/operators';

import { UtilitiesService } from '../shared/services/utilities/utilities.service';
import { BaseUnsubscribe } from '../shared/entities/base/base-unsubscribe';
import { NotificationService } from '../shared/services/notification/notification.service';
import { UserCredential } from './entities/user-credential';
import { AuthService } from '../shared/services/auth/auth.service';
import { HttpService } from '../shared/services/http/http.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseUnsubscribe implements OnInit {

  userCredential: UserCredential = new UserCredential();
  loginMode: boolean = true;
  cpfMask: any = [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  emailSent: boolean = false;

  private notificationTitle: string = 'Atenção';

  constructor(
    public bsModalRef: BsModalRef,
    private notificationService: NotificationService,
    private authService: AuthService,
    private utilitiesService: UtilitiesService,
    private httpService: HttpService) {
    super();
  }

  ngOnInit(): void {
  }

  login(): void {
    if (this.userCredencialIsValid()) {
      this.authService.login(this.userCredential.email, this.userCredential.password).pipe(
        takeUntil(this.unsubscribe))
        .subscribe(
          () => this.bsModalRef.hide(),
          (error: any) => {
            this.notificationService.showError(error, this.notificationTitle);
          });
    }
  }

  cancel(): void {
    this.authService.logout(false);
    this.bsModalRef.hide();
  }

  rememberPassword(): void {
    if (this.userCredencialIsValid()) {
      const options = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([['origin', 'SimCAQ'], ['email', this.userCredential.email]]));
      this.httpService.getApiEndpoint().pipe(
        takeUntil(this.unsubscribe))
        .subscribe(apiEndpoint => {
          this.httpService.get<any>(`${apiEndpoint}/user/reset/password`, options).pipe(
            takeUntil(this.unsubscribe))
            .subscribe(
              (retorno) => {
                this.notificationService.showInfo(retorno.msg, 'Verifique seu email');
                this.emailSent = true;
              },
              (error: any) => {
                this.notificationService.showError(error.errorBody.msg, this.notificationTitle);
              });
        });
    }
  }

  private userCredencialIsValid(): boolean {
    if (this.loginMode) {
      if (!this.userCredential.email || !this.userCredential.password) {
        this.notificationService.showError('Email e senha devem ser informados', this.notificationTitle);
        return false;
      }
    } else {
      if (!this.userCredential.email) {
        this.notificationService.showError('Email deve ser informado', this.notificationTitle);
        return false;
      }
    }

    if (!this.utilitiesService.emailIsValid(this.userCredential.email)) {
      this.notificationService.showError('Email inválido', this.notificationTitle);
      return false;
    }

    return true;
  }

}
