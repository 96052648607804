import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';
import { MatriculaPorEtapaEnsino } from './matricula-por-etapa-ensino';

export class DiagnosticoDeMatriculaPorEscola extends NavigableComponentData {
    codMec: number;
    codMun: number;
    codUf: number;
    nomeInstituicao: string;
    etapasEnsino: Array<MatriculaPorEtapaEnsino>;

    constructor(init?: Partial<DiagnosticoDeMatriculaPorEscola>) {
        super();
        Object.assign(this, init);
    }
}
