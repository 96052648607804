import { NavigableComponentData } from '../../shared/entities/base/navigable-component-data';

export class SimulationTime extends NavigableComponentData {

    time: number;

    constructor(init?: Partial<SimulationTime>) {
        super();
        Object.assign(this, init);
    }
}
