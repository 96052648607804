export class ExpenseByState {

  stateId: number;
  currentExpenseTotal: number;
  potentialRevenue: number;
  complementation: number;

  constructor(init?: Partial<ExpenseByState>) {
    Object.assign(this, init);
  }

}
