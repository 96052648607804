import { Component, Injector, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { FinancingFundsReportService } from './services/financing-funds-report.service';
import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { ColumnReport } from './entities/column-report';
import { FinancingsFundsReport } from './entities/financings-funds-report';
import { FinancingFundsReport } from './entities/financing-funds-report';
import { ShiftEnum } from './../../../shared/entities/enums/shift-enum';
import { StageEnum } from './../../../shared/entities/enums/stage.enum';
import { environment } from '../../../../environments/environment';

import { MatomoTracker } from 'ngx-matomo';

@Component({
  selector: 'app-financing-funds-report',
  templateUrl: './financing-funds-report.component.html',
  styleUrls: ['./financing-funds-report.component.scss']
})
export class FinancingFundsReportComponent extends BaseNavigableComponent<FinancingsFundsReport> implements OnInit {

  data: FinancingsFundsReport = new FinancingsFundsReport({ financingsFundsReport: new Array<FinancingFundsReport>() });
  functionality: FunctionalityInfo = Functionality.financingFundsReport;
  inconsistencies: Array<Inconsistency>;
  columnsReport: Array<ColumnReport> = new Array<ColumnReport>();
  stageEJA: number = StageEnum.eja;
  shiftIntegral: number = ShiftEnum.Integral;

  constructor(private injector: Injector, private financingFundsReportService: FinancingFundsReportService, private matomoTracker: MatomoTracker) {
    super(injector);
  }

  ngOnInit() {
    this.matomoTracker.setCustomUrl('/financingfundsreport');
    this.matomoTracker.trackPageView();
  }

  processData(): Observable<FinancingsFundsReport> {
    return this.financingFundsReportService.getFinancingsFundsReport();
  }

  processSpecificBehaviors() {
    this.financingFundsReportService.getColumnsReport().pipe(
      takeUntil(this.unsubscribe))
      .subscribe(columnsReport => {
        this.columnsReport = columnsReport;
      });
  }

  dataHasChanged(): boolean {
    return false;
  }

  downloadCsv(): void {
    this.financingFundsReportService.downloadCsv(this.data).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe();
  }

  getNextStepLabel(): string {
    return 'Finalizar';
  }

}
