import { IOption } from 'ng-select';

export class State implements IOption {
    value: string;
    label: string;
    disabled?: boolean;

    constructor(init?: Partial<State>) {
        Object.assign(this, init);
    }
}
