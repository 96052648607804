import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { MatomoTracker } from 'ngx-matomo';

import { Team } from './entities/team';
import { BaseUnsubscribe } from '../shared/entities/base/base-unsubscribe';
import { TeamService } from './services/team.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
  providers: [TeamService]
})
export class TeamComponent extends BaseUnsubscribe implements OnInit {

  teams: Array<Team>;

  constructor(private teamService: TeamService, private matomoTracker: MatomoTracker) {
    super();
  }

  ngOnInit() {
    this.matomoTracker.setCustomUrl('/team');
    this.matomoTracker.trackPageView();

    this.teamService.getTeams().pipe(
      takeUntil(this.unsubscribe))
      .subscribe(teams => this.teams = teams);
  }

}
