import { ClassNumber } from '../../../shared/services/calculate-class-number/entities/class-number';
import { EnrollmentProjection } from '../../../access-and-offer/enrollment-projection/entities/enrollment-projection';
import { TeacherNumberCalc } from './../../../shared/services/calculate-teacher-number/entities/teacher-number-calc';
import { PercentageTeacherCareer } from '../../financing-federated-entities-report/entities/percentage-teacher-career';

export class EnrollmentAndClassAndClassroom {

  state_id: number;
  state_description: string;
  city_id: number;
  city_description: string;
  school_id: string;
  school_description: string;
  adm_dependency_id: number;
  adm_dependency_name: string;
  enrollmentProjection: EnrollmentProjection;
  classNumber: ClassNumber;
  teacherNumber: Array<TeacherNumberCalc>;
  hasEnrollment: boolean;
  percentageTeacherCareer: Array<PercentageTeacherCareer>;

  constructor(init?: Partial<EnrollmentAndClassAndClassroom>) {
    Object.assign(this, init);
  }
}
