import { Component, Injector, Type, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { PqrComponentData } from '../../shared/entities/base/pqr-component-data';
import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { NewRoomBuildingService } from './services/new-room-building.service';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { NewRoomsBuildings } from './entities/new-rooms-buildings';
import { LocationEnum } from '../../../shared/entities/enums/location.enum';
import { DemandsClassroomsEnum } from './entities/enums/demandsClassrooms.enum';
import { NavigableComponentData } from '../../shared/entities/base/navigable-component-data';
import { SelectLocation } from '../../select-location/entities/select-location';

@Component({
  selector: 'app-new-room-building',
  templateUrl: './new-room-building.component.html',
  styleUrls: ['./new-room-building.component.scss']
})
export class NewRoomBuildingComponent extends BaseNavigableComponent<NewRoomsBuildings> implements PqrComponentData, OnInit {

  data: NewRoomsBuildings = new NewRoomsBuildings();
  functionality: FunctionalityInfo = Functionality.newRoomBuilding;
  inconsistencies: Array<Inconsistency>;
  resultForSelectLocation: SelectLocation = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);
  pqrAdminMode: boolean;
  pqrMode: boolean;
  pqrModalMode: boolean;
  locationUrban: number = LocationEnum.urban;
  locationRural: number = LocationEnum.rural;
  selectLocationData: SelectLocation = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);
  disabledData: boolean = true;
  popoverTriggers: string;
  popoverText: string = 'Esses parâmetros só podem ser alterados para simulações em nível estadual ou municipal. ' +
    'Para simulações em nível nacional, são apresentados resultados dos valores municipais agregados internamente pelo simulador.';

  componentType: Type<PqrComponentData> = NewRoomBuildingComponent;
  referenceDateMask: any = this.utilitiesService.getReferenceDateMask();

  constructor(private newRoomBuildingService: NewRoomBuildingService, private injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.disabledData = this.processDisabledData();

    if (this.disabledData) {
      this.popoverTriggers = 'mouseenter:mouseleave';
    }
  }

  processData(): Observable<NewRoomsBuildings> {
    return this.newRoomBuildingService.getData();
  }

  getPqrData(): NavigableComponentData {
    return this.data.newRooms;
  }

  setPqrData(pqrData: any) {

    const demandClassRoomByYear = this.data.newRooms.demandClassRoomByYear;
    const buildings = this.data.newRooms.buildings;
    this.data = pqrData;

    this.data.newRooms.demandClassRoomByYear = demandClassRoomByYear;
    this.newRoomBuildingService.setNewRoomBuilding(buildings, this.data.newRooms.buildings);
  }

  processValidation(): void {
    super.processValidation();

    let totalRoomUrbanInformed: number = 0;
    let totalRoomRuralInformed: number = 0;
    let totalRoomUrban: number = 0;
    let totalRoomRural: number = 0;
    let valideLocationQuantity: boolean = false;

    for (const room of this.data.newRooms.buildings) {
      for (const loc of room.numberDemandLocation) {
        if (!isNaN(loc.value) && loc.value !== undefined && loc.value !== null) {
          if (loc.location.id === LocationEnum.urban) {
            totalRoomUrban += loc.value * room.numberClassroom;
          }

          if (loc.location.id === LocationEnum.rural) {
            totalRoomRural += loc.value * room.numberClassroom;
          }
          if (isNaN(room.dimension) || room.dimension === 0 || room.dimension === undefined) {
            valideLocationQuantity = true;
          }
        }

        if (!this.utilitiesService.isReferenceDateValid(room.referenceDate)) {
          this.inconsistencies.push(new Inconsistency({
            message: `A data de referência da demanda '${room.denomination}' não é valida.`
          }));
        }

      }

      if (valideLocationQuantity) {
        this.inconsistencies.push(new Inconsistency({
          message: `Na demanda "${room.denomination}", quando a quantidade urbana ou rural estiver
          preenchida a dimensão m2 e preço por m2 deverão ser preenchidos.`
        }));
      }

      valideLocationQuantity = false;
    }

    for (const demand of this.data.newRooms.demandClassRoomByYear.demandClassRooms) {
      if (demand.id === DemandsClassroomsEnum.ClassroomDemand) {
        for (const roomLoc of demand.quantityDemandClassRoomLocation) {
          if (roomLoc.location.id === LocationEnum.urban) {
            totalRoomUrbanInformed = roomLoc.value;
          }
          if (roomLoc.location.id === LocationEnum.rural) {
            totalRoomRuralInformed = roomLoc.value;
          }
        }
      }
    }

    if (parseFloat(totalRoomUrban.toFixed(0)) !== totalRoomUrbanInformed) {
      this.inconsistencies.push(new Inconsistency({
        message: `Existe(em) ${totalRoomUrbanInformed} sala(s) nova(s) a ser(em) distribuida(s) no quadro demanda por construção na
        localidade urbana. Já foram distribuidas ${parseFloat(totalRoomUrban.toFixed(0))} sala(s), o valor somado da distribuição deverá ser ${totalRoomUrbanInformed}.`
      }));
    }

    if (parseFloat(totalRoomRural.toFixed(0)) !== totalRoomRuralInformed) {
      this.inconsistencies.push(new Inconsistency({
        message: `Existe(em) ${totalRoomRuralInformed} sala(s) nova(s) a ser(em) distribuida(s) no quadro demanda por construção na
        localidade rural. Já foram distribuidas ${parseFloat(totalRoomRural.toFixed(0))} sala(s), o valor somado da distribuição deverá ser ${totalRoomRuralInformed}.`
      }));
    }
  }

  processDisabledData(pqrAdminMode: boolean = false): boolean {

    if (pqrAdminMode) {
      return false;
    } else {
      if (!(!this.pqrAdminMode && this.pqrMode)) {
        return this.disabledData = !this.selectLocationData || !(this.selectLocationData.selectedCity || this.selectLocationData.selectedState);
      } else {
        return !this.pqrAdminMode && this.pqrMode;
      }
    }
  }
}
