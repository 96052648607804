import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MatomoTracker } from 'ngx-matomo';

import { SimulationType } from '../simulator/simulator/entities/enums/simulation-type.enum';
import { SimulatorService } from '../simulator/simulator/services/simulator.service';
import { AuthService } from '../shared/services/auth/auth.service';
import { BaseUnsubscribe } from '../shared/entities/base/base-unsubscribe';
import { UserInfo } from '../shared/services/auth/entities/user-info';
import { TutorialsComponent } from '../shared/components/modal/tutorials/tutorials.component';
import { Tutorial } from '../shared/components/modal/tutorials/entities/tutorial';
import { ViewEnum } from '../shared/components/modal/tutorials/entities/enums/view.enum';
import { SessionService } from 'app/shared/services/session/session.service';
import { AlertComponent } from "../shared/components/modal/alert/alert.component";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseUnsubscribe implements OnInit {

  userInfo: UserInfo = new UserInfo();

  readonly caq: SimulationType = SimulationType.caq;
  readonly planning: SimulationType = SimulationType.planning;
  readonly planningByStateSphereAdm: SimulationType = SimulationType.planningByStateSphereAdm;
  readonly planningByCitySphereAdm: SimulationType = SimulationType.planningByCitySphereAdm;
  readonly financingFederatedEntitiesByStateSphereAdm: SimulationType = SimulationType.financingFederatedEntitiesByStateSphereAdm;
  readonly financingFederatedEntitiesByCitySphereAdm: SimulationType = SimulationType.financingFederatedEntitiesByCitySphereAdm;
  readonly financingFederatedEntitiesGroupByCityOrState: SimulationType = SimulationType.financingFederatedEntitiesGroupByCityOrState;

  nomeHost: string = "";

  constructor(private simulatorService: SimulatorService, private authService: AuthService,
    private bsModalService: BsModalService, private matomoTracker: MatomoTracker, private sessionService: SessionService) {
    super();
  }

  ngOnInit(): void {
    //console.log('url',window.location.hostname)
    this.nomeHost = window.location.hostname.toLowerCase()
    this.sessionService.clear();
    this.matomoTracker.setCustomUrl('/home');
    this.matomoTracker.trackPageView();


    this.verifyUserInfo();

    this.authService.userLoggedObserver.pipe(
      takeUntil(this.unsubscribe))
      .subscribe(userIsLoggedIn => {
        if (userIsLoggedIn) {
          this.verifyUserInfo();
        } else {
          this.userInfo = new UserInfo();
        }
      });

    this.showNotificacao();
  }

  startSimulation(simulationType: SimulationType): void {
    if (simulationType === SimulationType.caq) {
      this.matomoTracker.trackEvent('simulation', 'click', 'Simulacao CAQ');
    } else {
      this.matomoTracker.trackEvent('simulation', 'click', 'Simulacao Orçamento educacional');
    }
    this.simulatorService.startSimulation(simulationType);
  }

  openVideoTutorial(): void {
    const tutorial: Tutorial = new Tutorial({ videoSource: 'https://www.youtube.com/embed/wWhQob6Efhw' });
    this.bsModalService.show(TutorialsComponent, { ignoreBackdropClick: true, class: 'modal-lg modal-tutorial', initialState: { currentView: ViewEnum.VideoTutorialPlatform, tutorial } });
  }

  showNotificacao(): void {
    var modal = this.bsModalService.show(AlertComponent, { class: 'modal-novidades' });

    modal.content.title = "Novidades";

    modal.content.body = `
    <p>Versão <span class="destaque">4.0</span> do Simulador de Custo-Aluno Qualidade com valores do CAQ <span class="destaque">2024</span>.</p>
    <p>Agora disponível em <a href="https://simcaq.ufg.br">simcaq.ufg.br</a></p>
    <p>Clique <a href="novidades">aqui</a> para saber o que há de novo nesta versão!</p>
    `;
    modal.content.closeButtonText = "Fechar";

  }

  private verifyUserInfo(): void {
    this.authService.getUserInfo().pipe(
      takeUntil(this.unsubscribe))
      .subscribe(userInfo => this.userInfo = userInfo);
  }

}
