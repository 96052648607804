import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';

export class TeacherTraining extends NavigableComponentData {

  teacherTrainingValue: number;
  referenceDateTeacher: string;
  employeeTrainingValue: number;
  referenceDateEmployee: string;
  totalNumberTeacher: number;
  totalNumberEmployee: number;
  year: number;

  constructor(init?: Partial<TeacherTraining>) {
    super();
    Object.assign(this, init);
  }

}
