import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';
import { DiagnosticoDeMatriculaPorEscola } from './diagnostico-de-matricula-por-escola';
import { EnrollmentByStageSeriesBySchool } from './enrollment-by-stage-series-by-school';

export class EnrollmentBySchool extends NavigableComponentData {

    enrollmentByStageSeriesBySchool: Array<EnrollmentByStageSeriesBySchool> = new Array<EnrollmentByStageSeriesBySchool>();
    diagnosticosMatriculaPorEscola: Array<DiagnosticoDeMatriculaPorEscola> = new Array<DiagnosticoDeMatriculaPorEscola>();
    constructor(init?: Partial<EnrollmentBySchool>) {
        super();
        Object.assign(this, init);
    }
}
