import { Stage } from './stage';
import { Serie } from './serie';

export class StageAndSeries extends Stage {
    series: Array<Serie> = new Array<Serie>();

    constructor(init?: Partial<StageAndSeries>) {
        super();
        Object.assign(this, init);
    }
}
