import { TeacherNumberByLocationCalc } from './teacher-number-by-location-calc';

export class TeacherNumberCalc {

  city_id: string;
  cityDescription: string;
  teachersNumberByLocationsCalc: Array<TeacherNumberByLocationCalc>;

  constructor(init?: Partial<TeacherNumberCalc>) {
    Object.assign(this, init);
  }

}
