import { CustomError } from '../custom-error';

export class FunctionalityInfo {

    static readonly simulationNamespace: string = 'simulation';
    static readonly pqrNamespace: string = 'pqr';

    name: string;
    subtitle: string;
    route: Array<string>;
    previousStep: Array<string>;
    nextStep: Array<string>;
    requiredData: Array<FunctionalityInfo>;
    hasPqr: boolean;
    disabled: boolean;
    mainStep: boolean;

    private _key: string;

    get key(): string {
        return this._key;
    }

    set key(key: string) {
        this._key = `${FunctionalityInfo.simulationNamespace}.${key}`;
    }

    get pqrKey(): string {
        if (this.hasPqr) {
            return `${FunctionalityInfo.pqrNamespace}.${this.key}`.replace(`${FunctionalityInfo.simulationNamespace}.`, '');
        }

        throw new CustomError({ message: 'Functionality does not have PQR' });
    }

    constructor(init?: Partial<FunctionalityInfo>) {
        Object.assign(this, init);
    }
}
