import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseNavigableComponent } from 'app/shared/entities/base/base-navigable-component';
import { Functionality } from './../../../shared/entities/functionality/functionality';
import { Inconsistency } from './../../../shared/components/inconsistency/entities/inconsistency';
import { FunctionalityInfo } from './../../../shared/entities/functionality/functionality-info';
import { FinancingFederatedEntitiesReportService } from './services/financing-federated-entities-report.service';
import { FinancingsFederatedsEntitiesReport } from './entities/financings_federateds-entities-report';
import { FunctionalityRoute } from '../../../shared/entities/functionality/functionality-route';
import { SimulationType } from '../../simulator/entities/enums/simulation-type.enum';
import { UtilitiesService } from '../../../shared/services/utilities/utilities.service';
import { Footnote } from 'app/shared/components/footnote/entities/footnote';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-financing-federated-entities-report',
  templateUrl: './financing-federated-entities-report.component.html',
  styleUrls: ['./financing-federated-entities-report.component.scss']
})

export class FinancingFederatedEntitiesReportComponent extends BaseNavigableComponent<FinancingsFederatedsEntitiesReport> {

  data: FinancingsFederatedsEntitiesReport = new FinancingsFederatedsEntitiesReport();
  functionality: FunctionalityInfo = Functionality.financingFederatedEntitiesReport;
  inconsistencies: Array<Inconsistency>;
  simulationTypeGroupByCityOrState: boolean = this.sessionService.getItem<SimulationType>(UtilitiesService.simulationTypeSessionKey) ===
    SimulationType.financingFederatedEntitiesGroupByCityOrState ? true : false;
  editSimulation: boolean = this.simulationLevel();

  constructor(
    private injector: Injector,
    private route: ActivatedRoute,
    private financingFederatedEntitiesReportService: FinancingFederatedEntitiesReportService
  ) {
    super(injector);
  }

  processData(): Observable<FinancingsFederatedsEntitiesReport> {
    let editar: string;
    this.route.queryParams.subscribe(params => {
      editar = params['editar'];
      // Aqui você pode fazer o que desejar com o parâmetro, como atribuir a uma variável de classe ou realizar alguma lógica com ele.
    });
    return this.financingFederatedEntitiesReportService.getFinancingFederatedEntitiesReport(editar);
  }

  downloadCsv(): void {
    this.financingFederatedEntitiesReportService.downloadCsv();
  }

  simulationLevel(): boolean {
    return this.financingFederatedEntitiesReportService.isSimulationCity();
  }


  getFinanciangNotes(): Array<Footnote> {
    const financiangNotes: Array<Footnote> = new Array<Footnote>();
    financiangNotes.push(new Footnote({
      indice: 1,
      note: 'Receita Potencial mínima vinculada para a Educação Básica (RPEb) = [RLI x 25% - receitas destinadas ao Fundeb + ' +
        'receitas recebidas do Fundeb + Royalties de Petróleo e Gás + Salário Educação (cota estadual/municipal) + complementações da União ' +
        'ao Fundeb]. A RLI é a “receita resultante de impostos, compreendida a proveniente de transferências”, base de cálculo da vinculação ' +
        'constitucional de recursos para a educação (Art. 212 da CF). ' +
        'A RPEb é calculada a partir dos dados da Receita STN 2021 para VAAT 2023 e do Arquivo Programas Universais FNDE 2021 para VAAT 2023;'
    }));

    financiangNotes.push(new Footnote({
      indice: 2,
      note: 'Resultados das Despesas liquidadas com Educação Básica = [Despesa na Função Educação] - [Despesa na sub-função "Ensino Profissional"] ' +
        '- [Despesa na sub-função "Ensino Superior"] calculada a partir do Relatório Resumido da Execução Orçamentária (RREO) / Anexo 02 - ' +
        'Demonstrativo da Execução das Despesas por Função/Subfunção;'
    }));

    financiangNotes.push(new Footnote({
      indice: 3,
      note: 'Despesa calculada pelo Simulador a partir do PQR;'
    }));

    financiangNotes.push(new Footnote({
      indice: 4,
      note: 'Valor do Custo-aluno-ano dividido por 12;'
    }));

    financiangNotes.push(new Footnote({
      indice: 5,
      note: 'Resultado da divisão entre a projeção da despesa corrente necessária e o total de matrículas;'
    }));

    financiangNotes.push(new Footnote({
      indice: 6,
      note: 'Calculada a partir da diferença entre a Projeção da despesa corrente necessária e a Receita Potencial Mínima Vinculada;'
    }));

    financiangNotes.push(new Footnote({
      indice: 7,
      note: 'Complementação % = [Projeção da despesa corrente necessária / pela Receita Potencial Mínima Vinculada] - 1.'
    }));

    return financiangNotes;
  }
}
