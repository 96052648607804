import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'app/shared/services/auth/auth.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { SimulationType } from 'app/simulator/simulator/entities/enums/simulation-type.enum';
import { SimulatorService } from 'app/simulator/simulator/services/simulator.service';
import { MatomoTracker } from 'ngx-matomo';
import { BaseUnsubscribe } from 'app/shared/entities/base/base-unsubscribe';
import { UserInfo } from 'app/shared/services/auth/entities/user-info';

@Component({
  selector: 'app-custo-por-escola',
  templateUrl: './custo-por-escola.component.html',
  styleUrls: ['./custo-por-escola.component.scss']
})

export class CustoPorEscolaComponent extends BaseUnsubscribe implements OnInit {

  userInfo: UserInfo = new UserInfo();

  readonly caq: SimulationType = SimulationType.caq;
  readonly planning: SimulationType = SimulationType.planning;
  readonly planningByStateSphereAdm: SimulationType = SimulationType.planningByStateSphereAdm;
  readonly planningByCitySphereAdm: SimulationType = SimulationType.planningByCitySphereAdm;
  readonly financingFederatedEntitiesByStateSphereAdm: SimulationType = SimulationType.financingFederatedEntitiesByStateSphereAdm;
  readonly financingFederatedEntitiesByCitySphereAdm: SimulationType = SimulationType.financingFederatedEntitiesByCitySphereAdm;
  readonly financingFederatedEntitiesGroupByCityOrState: SimulationType = SimulationType.financingFederatedEntitiesGroupByCityOrState;

  nomeHost: string = "";

  constructor(private simulatorService: SimulatorService, private authService: AuthService,
    private matomoTracker: MatomoTracker, private sessionService: SessionService) {
    super();
  }



  ngOnInit() {
    this.matomoTracker.setCustomUrl('/custo-por-escola');
    this.matomoTracker.trackPageView();
    this.sessionService.clear();
    this.verifyUserInfo();

    this.authService.userLoggedObserver.pipe(
      takeUntil(this.unsubscribe))
      .subscribe(userIsLoggedIn => {
        if (userIsLoggedIn) {
          this.verifyUserInfo();
        } else {
          this.userInfo = new UserInfo();
        }
      });
  }

  startSimulation(simulationType: SimulationType): void {
    if (simulationType === SimulationType.caq) {
      this.matomoTracker.trackEvent('simulation', 'click', 'Simulacao CAQ');
    } else {
      this.matomoTracker.trackEvent('simulation', 'click', 'Simulacao Orçamento educacional');
    }

    this.simulatorService.startSimulation(simulationType);
  }

  private verifyUserInfo(): void {
    this.authService.getUserInfo().pipe(
      takeUntil(this.unsubscribe))
      .subscribe(userInfo => this.userInfo = userInfo);
  }
}
