import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { NavigableComponentService } from '../../../shared/entities/base/navigable-component-service';
import { HttpService } from '../../../../shared/services/http/http.service';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { Projection } from '../entities/projection';
import { Enrollment } from '../entities/enrollment';
import { LocationEnum } from '../../../../shared/entities/enums/location.enum';
import { Period } from '../../../../shared/entities/enums/period.enum';
import { EnrollmentByStageSeries } from '../entities/enrollment-by-stage-series';
import { StageEnrollments } from '../entities/stage-enrollments';
import { SerieEnrollments } from '../entities/serie-enrollments';
import { CurrentYearService } from '../../../shared/services/current-year/current-year.service';
import { StageEnum } from '../../../../shared/entities/enums/stage.enum';
import { SerieEnum } from '../../../../shared/entities/enums/serie.enum';
import { Footnote } from '../../../../shared/components/footnote/entities/footnote';
import { SourceInformationEnum } from '../../../../shared/entities/enums/source-information.enum';
import { OutOfSchoolPopulationService } from '../../../shared/services/out-of-school-population/out-of-school-population.service';

@Injectable({
  providedIn: 'root'
})
export class EnrollmentByStageSeriesService implements NavigableComponentService {

  private stagesAndSeriesWithoutNocturnal: Array<number> = [
    StageEnum.creche,
    SerieEnum.crecheMenorDeUmAno,
    SerieEnum.crecheUmAno,
    SerieEnum.crecheDoisAnos,
    SerieEnum.crecheTresAnos,
    StageEnum.preEscola,
    SerieEnum.preEscolaQuatroAnos,
    SerieEnum.preEscolaCincoAnos
  ];

  constructor(
    private utilitiesService: UtilitiesService,
    private httpService: HttpService,
    private currentYearService: CurrentYearService,
    private outOfSchoolPopulationService: OutOfSchoolPopulationService) { }

  getData(): Observable<EnrollmentByStageSeries> {
    const requestOptions: any = this.getRequestOptions();
    const offerYear: number = this.currentYearService.getEnrollmentCurrentYear();
    const outOfSchoolPopulationCurrentYear: number = this.currentYearService.getOutOfSchoolPopulationCurrentYear();
    const enrollmentByStageSeries: EnrollmentByStageSeries = new EnrollmentByStageSeries({
      projections: this.getProjections(),
      offerYear: offerYear,
      outOfSchoolPopulationCurrentYear: outOfSchoolPopulationCurrentYear,
      years: this.utilitiesService.getSimulationYears()
    });

    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/enrollment_projection`, requestOptions).pipe(
          map(enrollmentProjections => {
            // Process enrollment projections.
            enrollmentProjections = this.getProcessedEnrollmentProjection(enrollmentProjections);

            for (const projection of enrollmentByStageSeries.projections) {
              let stageEnrollments: StageEnrollments;
              projection.stagesEnrollments = new Array<StageEnrollments>();

              for (const enrollmentProjection of enrollmentProjections) {
                const currentOffer: number = this.getCurrentOffer(projection, enrollmentProjection);

                // Enrollments for stage and series of 'Creche' and 'Pré-Escola' on period nocturnal is zero (0).
                const enrollments: number =
                  this.stagesAndSeriesWithoutNocturnal.some(x => x === enrollmentProjection.education_level_school_year_id) && projection.periods.some(x => x === Period.nocturnal)
                    ? 0
                    : currentOffer;

                // When 'ID' is less or equal 10 then is an 'stage (education level)' else is an 'serie (school year)'.
                if (enrollmentProjection.education_level_school_year_id <= 10) {
                  stageEnrollments = new StageEnrollments({
                    id: enrollmentProjection.education_level_school_year_id,
                    description: enrollmentProjection.education_level_school_year_name,
                    totalCurrentOffers: currentOffer,
                    totalEnrollments: enrollmentByStageSeries.years.map(year => new Enrollment({ year: year, quantity: enrollments })),
                    seriesEnrollments: new Array<SerieEnrollments>()
                  });

                  projection.stagesEnrollments.push(stageEnrollments);
                } else {
                  const serieEnrollments: SerieEnrollments = new SerieEnrollments({
                    id: enrollmentProjection.education_level_school_year_id,
                    description: enrollmentProjection.education_level_school_year_name,
                    currentOffer: currentOffer,
                    enrollments: enrollmentByStageSeries.years.map(year => new Enrollment({ year: year, quantity: enrollments }))
                  });

                  stageEnrollments.seriesEnrollments.push(serieEnrollments);
                }
              }

              this.calculateProjectionTotalCurrentOffers(projection);
            }

            return enrollmentByStageSeries;
          }));
      }));
  }

  setOutOfSchoolPopulation(enrollmentByStageSeries: EnrollmentByStageSeries): Observable<void> {
    return this.outOfSchoolPopulationService.getOutOfSchoolPopulations().pipe(
      map(outOfSchoolPopulations => {
        if (outOfSchoolPopulations && outOfSchoolPopulations.length > 0) {
          for (const projection of enrollmentByStageSeries.projections) {
            projection.totals.totalOutOfSchoolPopulation = 0;
            projection.notesOutOfSchoolPopulation = new Array<Footnote>();

            for (const stage of projection.stagesEnrollments) {
              let totalStageOutOfSchoolPopulation: number = 0;

              for (const serie of stage.seriesEnrollments) {
                serie.outOfSchoolPopulations = this.outOfSchoolPopulationService.getOutOfSchoolPopulationBasedOnSerie(serie.id);

                if (serie.outOfSchoolPopulations) {
                  for (const outOfSchoolPopulationBySerie of serie.outOfSchoolPopulations) {
                    const outOfSchoolPopulation: any = _.first(outOfSchoolPopulations.filter(x => x.pfe_id === outOfSchoolPopulationBySerie.id));

                    if (outOfSchoolPopulation) {
                      outOfSchoolPopulationBySerie.value = outOfSchoolPopulation.total;
                      outOfSchoolPopulationBySerie.noteText = outOfSchoolPopulation.pfe_name;

                      totalStageOutOfSchoolPopulation += outOfSchoolPopulation.total;
                    }

                    if (outOfSchoolPopulationBySerie.noteIndice) {
                      projection.notesOutOfSchoolPopulation.push(new Footnote({ indice: outOfSchoolPopulationBySerie.noteIndice, note: outOfSchoolPopulationBySerie.noteText }));
                    }
                  }
                }
              }

              stage.totalOutOfSchoolPopulation = totalStageOutOfSchoolPopulation;
              projection.totals.totalOutOfSchoolPopulation += stage.totalOutOfSchoolPopulation;
            }
          }
        } else {
          for (const projection of enrollmentByStageSeries.projections) {
            for (let i = 0; i < projection.sourceInformations.length; i++) {
              projection.sourceInformations[i].indice = i + 1;
            }
            projection.currentOfferNote.indice = projection.sourceInformations.length + 1;

            if (projection.locationNote) {
              projection.locationNote.indice = projection.currentOfferNote.indice + (projection.locationNote.indice - 10);  // 1 day, 2 night
            }
          }
        }
      })
    );
  }

  calculateTotals(projection: Projection): void {
    for (const stageEnrollments of projection.stagesEnrollments) {
      this.calculateStageTotalEnrollments(stageEnrollments);
    }

    this.calculateProjectionTotalsAndRelativeProjection(projection);
  }

  private getProjections(): Array<Projection> {
    const totalDescription: string = 'TOTAL';
    const relativeProjectionDescription: string = 'Projeção relativa aos valores atuais';
    const outOfSchoolPopulationSourceInformation: Footnote = new Footnote({
      indice: 8,
      sourceInformation: SourceInformationEnum.outOfSchoolPopulation,
      remarks: 'Resultados disponíveis somente para Brasil, estados e municípios das capitais'
    });

    const projectionSourceInformation: Footnote = new Footnote({ indice: 9, sourceInformation: SourceInformationEnum.enrollment });

    const currentOfferNote = new Footnote({
      indice: 10,
      note: 'Na projeção considera somente as matrículas das escolas públicas. Não são contabilizadas as matrículas de atividade complementar e/ou atendimento educacional especializado (AEE). '
    });

    const locationNoteDay = new Footnote({
      indice: 11,
      note: 'São consideradas turmas diurnas as informadas como turno matutino, vespertino, tempo integral e EaD.'
    });

    const locationNoteNight = new Footnote({
      indice: 12,
      note: 'São consideradas turmas noturnas aquelas cujas atividades se iniciam a partir das 17 horas. Não são consideradas as matrículas de ' +
        'Educação Infantil (Creche e Pré-escola) em turmas noturnas.'
    });

    return new Array<Projection>(
      new Projection({
        location: LocationEnum.urban,
        periods: new Array<Period>(Period.matutinal, Period.vespertine),
        totals: new StageEnrollments({ description: totalDescription, totalEnrollments: new Array<Enrollment>() }),
        relativeProjection: new StageEnrollments({ description: relativeProjectionDescription, totalEnrollments: new Array<Enrollment>() }),
        sourceInformations: new Array<Footnote>(outOfSchoolPopulationSourceInformation, projectionSourceInformation),
        currentOfferNote: currentOfferNote,
        locationNote: locationNoteDay
      }),
      new Projection({
        location: LocationEnum.urban,
        periods: [Period.nocturnal],
        totals: new StageEnrollments({ description: totalDescription, totalEnrollments: new Array<Enrollment>() }),
        relativeProjection: new StageEnrollments({ description: relativeProjectionDescription, totalEnrollments: new Array<Enrollment>() }),
        sourceInformations: new Array<Footnote>(outOfSchoolPopulationSourceInformation, projectionSourceInformation),
        currentOfferNote: currentOfferNote,
        locationNote: locationNoteNight
      }),
      new Projection({
        location: LocationEnum.rural,
        periods: new Array<Period>(Period.matutinal, Period.vespertine),
        totals: new StageEnrollments({ description: totalDescription, totalEnrollments: new Array<Enrollment>() }),
        relativeProjection: new StageEnrollments({ description: relativeProjectionDescription, totalEnrollments: new Array<Enrollment>() }),
        sourceInformations: new Array<Footnote>(outOfSchoolPopulationSourceInformation, projectionSourceInformation),
        currentOfferNote: currentOfferNote,
        locationNote: locationNoteDay
      }),
      new Projection({
        location: LocationEnum.rural,
        periods: [Period.nocturnal],
        totals: new StageEnrollments({ description: totalDescription, totalEnrollments: new Array<Enrollment>() }),
        relativeProjection: new StageEnrollments({ description: relativeProjectionDescription, totalEnrollments: new Array<Enrollment>() }),
        sourceInformations: new Array<Footnote>(outOfSchoolPopulationSourceInformation, projectionSourceInformation),
        currentOfferNote: currentOfferNote,
        locationNote: locationNoteNight
      })
    );
  }

  private getCurrentOffer(projection: Projection, enrollmentProjection: any): number {
    let location: string;
    let period: string;

    switch (projection.location) {
      case LocationEnum.urban:
        location = 'urban';
        break;

      case LocationEnum.rural:
        location = 'rural';
        break;
    }

    switch (_.first(projection.periods)) {
      case Period.matutinal:
      case Period.vespertine:
        period = 'day';
        break;

      case Period.nocturnal:
        period = 'night';
        break;
    }

    return enrollmentProjection[`${location}_${period}_total`];
  }

  private getProcessedEnrollmentProjection(enrollmentProjections: Array<any>): Array<any> {
    // Sort by hierarchical stage and your series.
    const enrollmentProjectionsResult = _.sortBy(enrollmentProjections, [enrollmentProjection => `${enrollmentProjection.education_level_school_year_id}`]);
    return enrollmentProjectionsResult;
  }

  private calculateStageTotalEnrollments(stageEnrollments: StageEnrollments): void {
    stageEnrollments.totalEnrollments = new Array<Enrollment>();

    const simulationYears: Array<number> = (_.first(stageEnrollments.seriesEnrollments) as SerieEnrollments).enrollments.map(enrollment => enrollment.year);

    for (const simulationYear of simulationYears) {
      const seriesEnrollmentsOfYear: Array<Enrollment> =
        [].concat(...stageEnrollments.seriesEnrollments.map(serieEnrollments => serieEnrollments.enrollments.filter(enrollment => enrollment.year === simulationYear))) as Array<Enrollment>;

      stageEnrollments.totalEnrollments.push(seriesEnrollmentsOfYear.reduce((previous, current) => {
        if (isNaN(previous.quantity)) {
          previous.quantity = 0;
        }

        if (isNaN(current.quantity)) {
          current.quantity = 0;
        }

        return new Enrollment({ year: simulationYear, quantity: previous.quantity + current.quantity });
      }));
    }
  }

  private calculateProjectionTotalCurrentOffers(projection: Projection): void {
    const totalCurrentOfferEnrollments = projection.stagesEnrollments.reduce((previous, current) => new StageEnrollments({
      totalCurrentOffers: previous.totalCurrentOffers + current.totalCurrentOffers
    }));

    projection.totals.totalCurrentOffers = totalCurrentOfferEnrollments.totalCurrentOffers;

    this.calculateProjectionTotalsAndRelativeProjection(projection);
  }

  private calculateProjectionTotalsAndRelativeProjection(projection: Projection): void {
    projection.totals.totalEnrollments = new Array<Enrollment>();
    projection.relativeProjection.totalEnrollments = new Array<Enrollment>();

    const simulationYears: Array<number> = (_.first(projection.stagesEnrollments) as StageEnrollments).totalEnrollments.map(totalEnrollment => totalEnrollment.year);

    for (const simulationYear of simulationYears) {
      const stagesEnrollmentsOfYear: Array<Enrollment> =
        [].concat(...projection.stagesEnrollments.map(stage => stage.totalEnrollments.filter(totalEnrollment => totalEnrollment.year === simulationYear))) as Array<Enrollment>;

      const totalEnrollmentsOfYear = stagesEnrollmentsOfYear.reduce((previous, current) => new Enrollment({ year: simulationYear, quantity: previous.quantity + current.quantity }));

      // Total.
      projection.totals.totalEnrollments.push(totalEnrollmentsOfYear);

      // Relative projection.
      projection.relativeProjection.totalEnrollments.push(new Enrollment({
        year: totalEnrollmentsOfYear.year,
        quantity: totalEnrollmentsOfYear.quantity > 0 && projection.totals.totalCurrentOffers > 0
          ? ((totalEnrollmentsOfYear.quantity - projection.totals.totalCurrentOffers) / projection.totals.totalCurrentOffers) * 100
          : 0
      }));
    }
  }

  private getRequestOptions(): any {
    const enrollmentCurrentYear: number = this.currentYearService.getEnrollmentCurrentYear();
    const filters: Array<string> = this.utilitiesService.getYearAndSelectLocationFilters(enrollmentCurrentYear);

    filters.push(this.utilitiesService.getAdmDependencyFilter());
    filters.push('period_not:[99]');

    const searchParams: Map<string, string> = new Map<string, string>([
      ['filter', filters.join(',')]
    ]);

    return this.httpService.getRequestOptionsWithSearchParams(searchParams);
  }
}
