import { Observable, Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  title: string = 'Atenção!';
  richTitle: string;
  body: string;
  hideCloseButtonX: boolean;
  yesButtonText: string = 'Sim';
  noButtonText: string = 'Não';
  confirmed: Observable<boolean>;

  private confirmedMessenger: Subject<boolean> = new Subject<boolean>();

  constructor(public bsModalRef: BsModalRef) {
    this.confirmed = this.confirmedMessenger.asObservable();
  }

  ngOnInit() {
  }

  yes(): void {
    this.confirmedMessenger.next(true);
    this.bsModalRef.hide();
  }

  no(): void {
    this.confirmedMessenger.next(false);
    this.bsModalRef.hide();
  }

  hide(): void {
    this.confirmedMessenger.next(false);
    this.bsModalRef.hide();
  }

}
