import { Location } from '../../../../shared/entities/location';

export class StudentTeacherClass {

  id: number;
  location: Location;
  numberStudentClass: number;
  numberTeacherClass: number;
  diagnostic: number;

  constructor(init?: Partial<StudentTeacherClass>) {
    Object.assign(this, init);
  }

}
