import { HigherDemandClassroom } from './higher-demand-classroom';
import { ClassNumberByLocation } from './class-number-by-location';
import { Shift } from './shift';
import { City } from './city';

export class ClassNumber {

  classesNumberByLocations: Array<ClassNumberByLocation>;
  higherDemandClassroom: HigherDemandClassroom;

  constructor(init?: Partial<ClassNumber>) {
    Object.assign(this, init);
  }

}
