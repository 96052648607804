import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import { BaseUnsubscribe } from '../shared/entities/base/base-unsubscribe';
import { ConfirmComponent } from '../shared/components/modal/confirm/confirm.component';
import { AuthService } from '../shared/services/auth/auth.service';
import { LoginService } from '../login/services/login.service';
import { UserInfo } from '../shared/services/auth/entities/user-info';
import { Simulation } from '../simulations/simulations/entities/simulation';
import { SimulationsService } from '../simulations/simulations/services/simulations.service';
import { NotificationService } from '../shared/services/notification/notification.service';
import { FunctionalityRoute } from '../shared/entities/functionality/functionality-route';
import { Functionality } from '../shared/entities/functionality/functionality';
import { SimulationType } from '../simulator/simulator/entities/enums/simulation-type.enum';
import { SimulatorService } from '../simulator/simulator/services/simulator.service';
import { UtilitiesService } from '../shared/services/utilities/utilities.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends BaseUnsubscribe implements OnInit {

  @Input() isInverseColor: boolean;

  readonly financing: SimulationType = SimulationType.financing;
  readonly financingText: string = UtilitiesService.financingText;

  isSimulator: boolean;
  userInfo: UserInfo = new UserInfo();
  dropdownIsOpen: boolean;
  currentSimulation: Simulation;

  @ViewChild('simulationName', { static: false }) simulationNameInputElement: ElementRef;

  private bsModalRef: BsModalRef;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private authService: AuthService,
    private bsModalService: BsModalService,
    private simulationsService: SimulationsService,
    private notificationService: NotificationService,
    private simulatorService: SimulatorService) {
    super();
  }

  ngOnInit() {
    this.listenEvents();
    this.setProfileData();
  }

  openLogin(): void {
    this.loginService.openLogin();
  }

  logout(): void {
    this.getConfirmStream('Tem certeza que deseja sair?').pipe(
      takeUntil(this.unsubscribe))
      .subscribe(confirmed => {
        if (confirmed) {
          this.authService.logout();
        }
      });
  }

  saveSimulation(): void {
    if (!this.currentSimulation.name) {
      this.notificationService.showError('O nome da simulação deve ser informado', 'Atenção');
      this.simulationNameInputElement.nativeElement.focus();
    } else {
      this.simulationsService.saveCurrentSimulation(this.currentSimulation.name).pipe(
        takeUntil(this.unsubscribe))
        .subscribe(() => this.simulationNameInputElement.nativeElement.blur());
    }
  }

  resetSimulation(): void {
    this.getConfirmStream('Ao reiniciar a simulação todos os dados serão perdidos. Deseja continuar?').pipe(
      takeUntil(this.unsubscribe))
      .subscribe(confirmed => {
        if (confirmed) {
          this.simulationsService.resetSimulation().pipe(
            takeUntil(this.unsubscribe))
            .subscribe(() => {
              if (this.router.url.indexOf('selectlocation') === -1) {
                this.router.navigate(Functionality.selectLocation.route, { queryParamsHandling: 'merge' });
              } else {
                this.router.navigate(FunctionalityRoute.simulations, { queryParamsHandling: 'merge' })
                  .then(() => this.router.navigate(Functionality.selectLocation.route, { queryParamsHandling: 'merge' }));
              }
            });
        }
      });
  }

  discardSimulation(): void {
    this.getConfirmStream('Ao descartar a simulação os dados que não foram salvos serão perdidos. Deseja continuar?').pipe(
      takeUntil(this.unsubscribe))
      .subscribe(confirmed => {
        if (confirmed) {
          this.simulationsService.discardSimulation().pipe(
            takeUntil(this.unsubscribe))
            .subscribe(() => this.router.navigate(FunctionalityRoute.simulations, { queryParamsHandling: 'merge' }));
        }
      });
  }

  startSimulation(simulationType: SimulationType): void {
    this.simulatorService.startSimulation(simulationType);
  }

  private listenEvents(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.unsubscribe))
      .subscribe((event: NavigationEnd) => {
        // Salvar simulação ficará desabilitado por enquanto.
        this.isSimulator = false; // event.url.indexOf('/simulator') > -1;

        if (this.currentSimulation) {
          if (!this.currentSimulation.id && this.currentSimulation.name) {
            this.clearCurrentSimulation();
          } else {
            const currentSessionSimulation: Simulation = this.simulationsService.getCurrentSimulation();

            if (currentSessionSimulation && (currentSessionSimulation.id !== this.currentSimulation.id || currentSessionSimulation.name !== this.currentSimulation.name)) {
              this.currentSimulation = currentSessionSimulation;
            } else if (!currentSessionSimulation) {
              this.clearCurrentSimulation();
            }
          }
        }
      });

    this.authService.userLoggedObserver.pipe(
      takeUntil(this.unsubscribe))
      .subscribe(userIsLoggedIn => {
        if (userIsLoggedIn) {
          this.setProfileData();
        } else {
          this.userInfo = new UserInfo();
          this.clearCurrentSimulation();
          this.simulationsService.discardSimulation(false).pipe(
            takeUntil(this.unsubscribe))
            .subscribe();
        }
      });
  }

  private setProfileData(): void {
    this.authService.getUserInfo().pipe(
      takeUntil(this.unsubscribe))
      .subscribe(userInfo => {
        this.userInfo = userInfo;
        this.currentSimulation = this.simulationsService.getCurrentSimulation();

        if (!this.currentSimulation) {
          this.clearCurrentSimulation();
        }
      });
  }

  private clearCurrentSimulation(): void {
    this.currentSimulation = new Simulation();
  }

  private getConfirmStream(body: string): Observable<boolean> {
    this.bsModalRef = this.bsModalService.show(ConfirmComponent);
    this.bsModalRef.content.body = body;

    return this.bsModalRef.content.confirmed;
  }

}
