import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  paragraphs: Array<string> = new Array<string>(
    'Versão 4.0 - sujeita a aprimoramentos e correções decorrentes de pesquisas educacionais e computacionais.',
    'Os conteúdos e resultados do simulador são de exclusiva e inteira responsabilidade dos autores. Não exprime, necessariamente, o ponto de vista do Ministério de Educação (MEC).'
  );

  fullText: string = this.paragraphs.join(' ');

  constructor() { }

  ngOnInit() {
  }

}
