export class FuncionariosPorFuncao {

  nomeFuncao: string;
  quantidade: number;

  constructor(init?: Partial<FuncionariosPorFuncao>) {
    Object.assign(this, init);
  }

}
