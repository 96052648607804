export class School {

  id: string;
  description: string;
  cityId: string;
  classroomNumber: number;
  locationId: number;
  enrollmentQuantity: number;

  constructor(init?: Partial<School>) {
    Object.assign(this, init);
  }

}
