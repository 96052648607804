import { CostSharingByYear } from './cost-sharing-by-year';

export class CostSharingBySubitem {

  id: string;
  description: string;
  isEducationAssistant: boolean;
  costsSharingByYears: Array<CostSharingByYear>;

  constructor(init?: Partial<CostSharingBySubitem>) {
    Object.assign(this, init);
  }

}
