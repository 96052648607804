import { ItemCostExpenseReportByShift } from './item-cost-expense-report-by-shift';

export class ItemCostExpenseReportByLocation {

  id: number;
  description: string;
  itemsCostExpenseReportByShifts: Array<ItemCostExpenseReportByShift>;

  constructor(init?: Partial<ItemCostExpenseReportByLocation>) {
    Object.assign(this, init);
  }

}
