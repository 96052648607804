import { CostDriveByStage } from './cost-drive-by-stage';

export class CostDriveByLocation {

  id: number;
  description: string;
  costDrivesByStages: Array<CostDriveByStage>;

  constructor(init?: Partial<CostDriveByLocation>) {
    Object.assign(this, init);
  }

}
