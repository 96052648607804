import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpService } from '../../shared/services/http/http.service';
import { Team } from '../entities/team';
import { Member } from '../entities/member';

@Injectable()
export class TeamService {

  constructor(private httpService: HttpService) { }

  getTeams(): Observable<Array<Team>> {
    return this.httpService.get<Array<any>>('assets/data/team.data.json').pipe(
      map(teamData => {
        const teams: Array<Team> = teamData.map(team => new Team({
          title: team.title,
          members: team.members.map(member => new Member({ name: member.name, info: member.info }))
        }));

        return teams;
      }));
  }

}
