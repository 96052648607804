import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { SessionService } from '../../../../shared/services/session/session.service';
import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { SchoolUnitLocation } from '../entities/school-unit-location';
import { InfrastructureSchoolBuildings } from '../entities/infrastructure-school-buildings';
import { HttpService } from '../../../../shared/services/http/http.service';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { LocationEnum } from '../../../../shared/entities/enums/location.enum';
import { InfrastructureSchoolsBuildings } from '../entities/infrastructure-schools-buildings';
import { UnitOfMeasurementEnum } from '../../../../shared/entities/enums/unit-of-measurement.enum';
import { SchoolQuantityExisting } from '../entities/school-quantity-existing';
import { InfrastructureSchools } from '../entities/infraestructure-schools';
import { State } from '../../../../shared/entities/state';
import { CurrentYearService } from '../../../shared/services/current-year/current-year.service';
import { NavigableComponentService } from '../../../shared/entities/base/navigable-component-service';
import { StagesSpecificities } from '../entities/stages-specificities';
import { SourceInformationEnum } from './../../../../shared/entities/enums/source-information.enum';
import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { CurrentYearMonthService } from '../../../shared/services/current-year-month/current-year-month.service';

@Injectable({
  providedIn: 'root'
})
export class InfrastructureSchoolBuildingsService implements NavigableComponentService {

  constructor(
    private httpService: HttpService,
    private utilitiesService: UtilitiesService,
    private sessionService: SessionService,
    private currentYearService: CurrentYearService,
    private currentYearMonthService: CurrentYearMonthService
  ) { }

  getData(pqrMode: boolean = false): Observable<InfrastructureSchoolsBuildings> {
    const infrastructureSchoolsBuildings: InfrastructureSchoolsBuildings = new InfrastructureSchoolsBuildings();

    if (!pqrMode) {
      const schoolCurrentYear: number = this.currentYearService.getSchoolCurrentYear();
      const infrastructureCurrentYear: number = this.currentYearService.getInfrastructureCurrentYear();
      const filtersLocation: Array<string> = this.utilitiesService.getSelectLocationFilter();
      const schoolsQuantityExisting: Array<SchoolQuantityExisting> = new Array<SchoolQuantityExisting>();
      const observables: Array<Observable<Array<any>>> = new Array<Observable<Array<any>>>();

      infrastructureSchoolsBuildings.sourceInformation = new Array<Footnote>();
      infrastructureSchoolsBuildings.sourceInformation.push(new Footnote({ indice: 1, sourceInformation: SourceInformationEnum.school }));
      infrastructureSchoolsBuildings.sourceInformation.push(new Footnote({ indice: 2, sourceInformation: SourceInformationEnum.infrastructure }));

      infrastructureSchoolsBuildings.sourceNote = new Footnote({
        indice: 3,
        note: 'A contagem do número de estabelecimentos considera apenas aqueles informados como ‘em atividade’ no ano do Censo, que ' +
          'funcionavam em prédio escolar e que tinham pelo menos uma matrícula de Ensino Regular, Educação de Jovens e Adultos (EJA) e/ou Educação Profissional.'
      });

      let filtersYear: Array<string>;
      let filters: Array<string>;
      let options: any;
      let options2: any;
      let options3: any;

      filtersYear = new Array<string>(
        `min_year:"${schoolCurrentYear}"`,
        `max_year:"${schoolCurrentYear}"`
      );

      filtersYear.push(this.utilitiesService.getAdmDependencyFilter());

      filters = new Array<string>();
      filters = filters.concat(filtersYear);
      filters = filters.concat(filtersLocation);
      filters = filters.concat('school_building:true');

      options = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([
        ['filter', filters.join(',')]
      ]));

      observables.push(this.httpService.getApiEndpoint().pipe(
        switchMap(apiEndpoint => {
          return this.httpService.get<Array<any>>(`${apiEndpoint}/school/count`, options).pipe(
            map(schools => {
              return new Array<SchoolQuantityExisting>(
                {
                  id: 1,
                  description: 'Escolas públicas que funcionam em prédios escolares',
                  quantity: schools.length > 0 ? _.first(schools).total : 0
                });
            }));
        })));

      // filters = new Array<string>();
      // filters = filters.concat(filtersYear);
      // filters = filters.concat(filtersLocation);

      options2 = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([
        ['dims', 'location'],
        ['filter', filters.join(',')]
      ]));

      observables.push(this.httpService.getApiEndpoint().pipe(
        switchMap(apiEndpoint => {
          return this.httpService.get<Array<any>>(`${apiEndpoint}/school/count`, options2).pipe(
            map(locations => {
              const schoolsQuantityExistingLocation: Array<SchoolQuantityExisting> = new Array<SchoolQuantityExisting>();
              let descriptionLocation: string;
              let idItemLocation: number;

              for (const location of locations) {
                location.location_id === LocationEnum.urban ? descriptionLocation = 'urbanas' : descriptionLocation = 'rurais';
                location.location_id === LocationEnum.urban ? idItemLocation = 2 : idItemLocation = 3;
                schoolsQuantityExistingLocation.push(
                  {
                    id: idItemLocation,
                    description: `Escolas públicas ${descriptionLocation} que funcionam em prédios escolares`,
                    quantity: location.total
                  }
                );
              }

              return schoolsQuantityExistingLocation;
            }));
        })));

      // **************** Escolas públicas que não funcionam em prédios escolares
      filters = new Array<string>();
      filters = filters.concat(filtersYear);
      filters = filters.concat(filtersLocation);
      filters = filters.concat('school_building:false');

      options3 = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([
        ['filter', filters.join(',')]
      ]));

      observables.push(this.httpService.getApiEndpoint().pipe(
        switchMap(apiEndpoint => {
          return this.httpService.get<Array<any>>(`${apiEndpoint}/school/count`, options3).pipe(
            map(schools => {
              const schoolQuantityExisting = new SchoolQuantityExisting();
              const schoolsQuantity = new Array<SchoolQuantityExisting>();

              schoolQuantityExisting.id = 4;
              schoolQuantityExisting.description = 'Escolas públicas que não funcionam em prédios escolares';
              schoolQuantityExisting.quantity = schools.length > 0 ? _.first(schools).total : 0;
              schoolsQuantityExisting.push(schoolQuantityExisting);

              return schoolsQuantity;
            }));
        })));
      // ****************

      observables.push(this.utilitiesService.getUnitOfMeasurement([UnitOfMeasurementEnum.alunoMes, UnitOfMeasurementEnum.m2]).pipe(
        map(unitsOfMeasurement => infrastructureSchoolsBuildings.unitsOfMeasurement = unitsOfMeasurement)));

      observables.push(this.utilitiesService.getLocations().pipe(
        map(location => infrastructureSchoolsBuildings.locations = location)));

      observables.push(this.utilitiesService.getStages().pipe(
        map(stages => infrastructureSchoolsBuildings.stages = stages.filter(s => s.id !== 6))));

      return forkJoin(observables).pipe(
        mergeMap(schoolsQuantity => {
          for (const schools of schoolsQuantity) {
            for (const item of schools) {
              if (item.quantity) {
                schoolsQuantityExisting.push(item);
              }
            }
          }

          infrastructureSchoolsBuildings.currentYear = schoolCurrentYear;
          infrastructureSchoolsBuildings.schoolQuantityExisting = _.sortBy(schoolsQuantityExisting, ['id']);
          infrastructureSchoolsBuildings.infrastructureSchools = this.getInfrastructureSchoolBuildingsItems();

          filtersYear = new Array<string>(
            `min_year:"${infrastructureCurrentYear}"`,
            `max_year:"${infrastructureCurrentYear}"`
          );

          filtersYear.push(this.utilitiesService.getAdmDependencyFilter());

          filters = new Array<string>();
          filters = filters.concat(filtersYear);
          filters = filters.concat(filtersLocation);

          options = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([
            ['dims', 'location'],
            ['filter', filters.join(',')]
          ]));

          return this.httpService.getApiEndpoint().pipe(
            mergeMap(apiEndpoint => {
              return this.httpService.get<Array<any>>(`${apiEndpoint}/school_infrastructure`, options).pipe(
                mergeMap(infraestruturesfromroute => {
                  return this.utilitiesService.getCub().pipe(
                    map(priceCub => {
                      const infraFormRoute: Array<any> = _.head(infraestruturesfromroute);

                      for (const infra of infrastructureSchoolsBuildings.infrastructureSchools.infrastructureBuildings) {
                        let partial: number = 0;
                        let total: number = 0;

                        if (infraFormRoute.hasOwnProperty(infra.textId)) {

                          for (const infraRoute of infraFormRoute[infra.textId]) {

                            partial += infraRoute.partial;
                            total += infraRoute.total;

                            //// _.find(infra.schoolUnitLocation, l => l.location.id === infraRoute.location_id).value = true;
                          }
                        }

                        infra.numberRequired = total;
                        infra.numberAdequate = partial;
                      }
                      this.setUnitPrice(infrastructureSchoolsBuildings.infrastructureSchools, priceCub);
                      return infrastructureSchoolsBuildings;
                    }));
                }));
            }));
        }));
    } else {
      infrastructureSchoolsBuildings.infrastructureSchools = this.getInfrastructureSchoolBuildingsItems();
      return of(infrastructureSchoolsBuildings);
    }
  }

  setUnitPrice(infrastructureSchools: InfrastructureSchools, priceCub: Array<any>): void {
    const yearsMonth = this.currentYearMonthService.getCubCurrentYearMonth();
    for (let i = 0; i < infrastructureSchools.infrastructureBuildings.length; i++) {
      infrastructureSchools.infrastructureBuildings[i].unitPrice = _.first(priceCub).preco;
      infrastructureSchools.infrastructureBuildings[i].referenceDate = _.first(yearsMonth).month + '/' + _.first(yearsMonth).year;
    }
  }

  getInfrastructureSchoolsBuildingsAllStates(states: Array<State>): Observable<Array<InfrastructureSchoolsBuildings>> {

    const infrastructuresSchoolsBuildings: Array<InfrastructureSchoolsBuildings> = new Array<InfrastructureSchoolsBuildings>();
    const infrastructureCurrentYear: number = this.currentYearService.getInfrastructureCurrentYear();

    let filtersYear: Array<string>;
    let filters: Array<string>;
    let options: any;

    filtersYear = new Array<string>(
      `min_year:"${infrastructureCurrentYear}"`,
      `max_year:"${infrastructureCurrentYear}"`
    );

    filtersYear.push(this.utilitiesService.getAdmDependencyFilter());

    filters = new Array<string>();
    filters = filters.concat(filtersYear);

    options = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([
      ['dims', 'state,location'],
      ['filter', filters.join(',')]
    ]));

    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/school_infrastructure`, options).pipe(
          map(infraestruturesfromroute => {
            const infraFormRoute: Array<any> = _.head(infraestruturesfromroute);

            for (let i = 0; i < states.length; i++) {

              const infrastructureSchoolsBuildings: InfrastructureSchoolsBuildings = new InfrastructureSchoolsBuildings({
                state: new State({ id: states[i].id, description: states[i].description }),
                infrastructureSchools: this.getInfrastructureSchoolBuildingsItems()
              });

              const infrastructureSchool = infrastructureSchoolsBuildings.infrastructureSchools.infrastructureBuildings;

              for (let j = 0; j < infrastructureSchool.length; j++) {

                const infra = infrastructureSchool[j];

                if (infraFormRoute.hasOwnProperty(infra.textId)) {

                  let partial: number = 0;
                  let total: number = 0;

                  for (const infraRoute of infraFormRoute[infra.textId]) {

                    if (infraRoute.state_id === states[i].id) {

                      partial += infraRoute.partial;
                      total += infraRoute.total;
                    }

                  }

                  infra.numberRequired = total;
                  infra.numberAdequate = partial;

                }
              }

              infrastructuresSchoolsBuildings.push(infrastructureSchoolsBuildings);
            }
            return infrastructuresSchoolsBuildings;
          }));
      }));
  }

  getInfrastructureSchoolsBuildingsByState(stateId: number, infrastructuresSchoolsBuildings: Array<InfrastructureSchoolsBuildings>): InfrastructureSchoolsBuildings {

    for (let i = 0; i < infrastructuresSchoolsBuildings.length; i++) {
      if (stateId === infrastructuresSchoolsBuildings[i].state.id) {
        return infrastructuresSchoolsBuildings[i];
      }
    }

  }

  getNumberAdequacy(infrastructureSchoolBuildings: InfrastructureSchoolBuildings): number {
    return !infrastructureSchoolBuildings.isTitle ? infrastructureSchoolBuildings.numberRequired - infrastructureSchoolBuildings.numberAdequate : 0;
  }

  getAdequatePercentage(infrastructureSchoolBuildings: InfrastructureSchoolBuildings): number {
    let adequatePercentage: number = 0;

    if (infrastructureSchoolBuildings.numberRequired > 0) {
      adequatePercentage = (infrastructureSchoolBuildings.numberAdequate / infrastructureSchoolBuildings.numberRequired) * 100;
    }

    return adequatePercentage;
  }

  private getInfrastructureSchoolBuildingsItems(): InfrastructureSchools {
    const infrastructureSchools: InfrastructureSchools = this.sessionService.getItem<InfrastructureSchools>(Functionality.infrastructureSchoolBuildings.pqrKey);

    infrastructureSchools.infrastructureBuildings =
      infrastructureSchools.infrastructureBuildings
        .map(infraItem => {
          if (!infraItem.isTitle) {
            return new InfrastructureSchoolBuildings({
              id: infraItem.id,
              textId: infraItem.textId,
              description: infraItem.description,
              schoolUnitLocation: infraItem.schoolUnitLocation.map(schoolUnitLocation => new SchoolUnitLocation({
                location: schoolUnitLocation.location, value: schoolUnitLocation.value
              })),
              stagesSpecificities: infraItem.stagesSpecificities.map(stagesSpecificities => new StagesSpecificities({
                stage: stagesSpecificities.stage, value: stagesSpecificities.value
              })),
              numberRequired: infraItem.numberRequired,
              dimension: infraItem.dimension,
              unitPrice: infraItem.unitPrice,
              numberAdequate: infraItem.numberAdequate,
              isTitle: infraItem.isTitle
            });
          }

          return new InfrastructureSchoolBuildings({
            id: infraItem.id,
            description: infraItem.description,
            isTitle: infraItem.isTitle
          });
        });

    return infrastructureSchools;
  }

}
