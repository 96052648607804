export class FinancialData {

  stateId: number;
  cityId: number;
  sphereAdmId: number;
  financialDataId: number;
  year: number;
  value: number;

  constructor(init?: Partial<FinancialData>) {
    Object.assign(this, init);
  }
}
