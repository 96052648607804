import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { TeacherDistributionCareerLevel } from './teacher-distribution-career-level';
import { FormationLevel } from '../../../../shared/entities/formation-level';
import { ProjectionNumberOfTeachers } from './projection-number-of-teachers';
import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';
import { TeacherFormationLevels } from './teacher-formation-levels';

export class CareerAndRemunerationTeachers extends NavigableComponentData {
    teacherFormationLevels: TeacherFormationLevels;
    totals: Array<TeacherDistributionCareerLevel>;
    years: Array<number>;
    formationLevels: Array<FormationLevel>;
    // projectionsNumberOfTeachers: Array<ProjectionNumberOfTeachers>;
    sourceInformation: Footnote;
    sourceNotes: Array<Footnote>;
    currentYear: number;
    // journeyTotal: number;
    // journeyTotalPQR: number;
    constructor(init?: Partial<CareerAndRemunerationTeachers>) {
        super();
        Object.assign(this, init);
    }
}
