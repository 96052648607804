import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { CaqReport } from './entities/caq-report';
import { CaqReportService } from './services/caq-report.service';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { ShiftEnum } from '../../../shared/entities/enums/shift-enum';
import { StageEnum } from '../../../shared/entities/enums/stage.enum';
import { LocationEnum } from 'app/shared/entities/enums/location.enum';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-caq-report',
  templateUrl: './caq-report.component.html',
  styleUrls: ['./caq-report.component.scss'],
})
export class CaqReportComponent extends BaseNavigableComponent<CaqReport> {

  data: CaqReport = new CaqReport();
  functionality: FunctionalityInfo = Functionality.caqReport;
  stageEJA: number = StageEnum.eja;
  shiftIntegral: number = ShiftEnum.Integral;
  inconsistencies: Array<Inconsistency>;
  locationRural: number = LocationEnum.rural;

  currentDate: string = ''
  textToCopy: string = ''

  constructor(private injector: Injector, private caqReportService: CaqReportService) {

    super(injector);

    const today = new Date();
    this.currentDate = formatDate(today, 'dd MMM. yyyy', 'pt-BR');
    this.textToCopy = `SIMULADOR de Custo-Aluno Qualidade: Valores do Custo-Aluno Qualidade (CAQ) para o Brasil, 2024.
    Disponível em: <https://simcaq.ufg.br/resultas/caqreport>. Acesso em: ${this.currentDate}.`;

  }

  processData(): Observable<CaqReport> {
    return this.caqReportService.getCAQ();
  }

  dataHasChanged(): boolean {
    return false;
  }

  downloadCsvPorEscola(): void {
    this.caqReportService.downloadCsvPorEscola();
  }

  downloadCsvTela(): void {
    //console.log(this.data);
    //console.log(this.data.relatorioCaq);
    this.caqReportService.downloadCsvTela(this.data.relatorioCaq);
  }

  getNextStepLabel(): string {
    return 'Finalizar';
  }

  async copyTextToClipboard() {

    try {
      await navigator.clipboard.writeText(this.textToCopy);
    } catch (err) {
      console.error('Falha ao copiar o texto para a área de transferência', err);
    }
  }

}
