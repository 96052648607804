import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { BudgetForecastReportByItem } from './budget-forecast-report-by-item';
import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';
import { EmployeeEstimateByRole } from 'app/simulator/shared/services/calculate-employee-estimate/entities/employee-estimate-by-role';
import { RelatorioDimensaoOferta } from './relatorio-dimensao-oferta';
import { TabelaProjecao } from './tabela-projecao';
import { FuncionariosPorFuncao } from 'app/simulator/quality-conditions/staff-number/entities/funcionarios-por-funcao';

export class BudgetForecastReport extends NavigableComponentData {

  stateId: number;
  stateDescription: string;
  cityId: number;
  cityDescription: string;
  admDependencyId: number;
  years: Array<number>;
  offerYear: number;
  offerYearFinancial: number;
  offersDimensions: Array<BudgetForecastReportByItem>;
  enrollmentsBasicEducation: Array<BudgetForecastReportByItem>;
  expensesBasicEducation: Array<BudgetForecastReportByItem>;
  expenseTotal: BudgetForecastReportByItem;
  expensesFederativeEntity: Array<BudgetForecastReportByItem>;
  expensesByStage: Array<BudgetForecastReportByItem>;
  totalExpenseWithInfrastructure: number;
  totalExpenseWithNewroom: number;
  percentExpenseWithInfrastructure: number;
  percentExpenseWithNewroom: number;
  sourceInformationsExpensesFederativeEntity: Array<Footnote>;
  sourceInformationsOfferDimension: Array<Footnote>;
  sourceInformationsExpensesBasicEducation: Array<Footnote>;
  sourceInformationsExpenseTotal: Array<Footnote>;
  sourceInformationsExpensesByStage: Array<Footnote>;
  sourceInformationPQR: Footnote;
  employeesEstimateByRole: Array<EmployeeEstimateByRole>;
  relatorioDimensaoOferta: RelatorioDimensaoOferta;
  totalMatriculasProjecao: number;
  totalTurmasProjecao: number;
  totalDocentesProjecao: number;
  totalFuncionariosProjecao: number;

  receitaPotencialMunicipal: number;
  receitaPotencialEstadual: number;

  despesaRealizadaMunicipal: number;
  despesaRealizadaEstadual: number;

  despesaCorrenteProjecaoEstadual: number;
  despesaCorrenteProjecaoMunicipal: number;
  totalDespesaCorrenteProjecao: number;

  totalReceitaPotencial: number;
  totalDespesaCorrente: number;

  complementacaoMunicipal: number;
  complementacaoEstadual: number;

  percComplementacaoMunicipal: number;
  percComplementacaoEstadual: number;

  tabelaProjecao: TabelaProjecao;

  totalMatriculaCreche: number;
  totalMatriculaPreEscola: number;
  totalMatriculaEnsFundAnosIniciais: number;
  totalMatriculaEnsFundAnosFinais: number;
  totalMatriculaEnsMedio: number;
  totalMatriculaEja: number;

  totalPorcentagemCreche: number;
  totalPorcentagemPreEscola: number;
  totalPorcentagemEnsFundAnosIniciais: number;
  totalPorcentagemEnsFundAnosFinais: number;
  totalPorcentagemEnsMedio: number;
  totalPorcentagemEja: number;

  despesaCorrenteAnoCreche: number;
  despesaCorrenteAnoPreEscola: number;
  despesaCorrenteAnoEnsFundAnosIniciais: number;
  despesaCorrenteAnoEnsFundAnosFinais: number;
  despesaCorrenteAnoEnsMedio: number;
  despesaCorrenteAnoEja: number;

  funcionariosPorFuncao: Array<FuncionariosPorFuncao>;
  totalFuncionarios: number;

  constructor(init?: Partial<BudgetForecastReport>) {
    super();
    Object.assign(this, init);
  }
}
