import { CareerLevel } from './career-level';

export class CareerLevelByYear {

  year: number;
  teacherNumberTotal: number;
  teacherNumberShiftPartial: number;
  teacherNumberShiftIntegral: number;
  careerLevels: Array<CareerLevel>;

  constructor(init?: Partial<CareerLevelByYear>) {
    Object.assign(this, init);
  }
}
