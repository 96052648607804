import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';
import { StageEnrollmentsBySchool } from './stage-enrollments-by-school';

export class EnrollmentBySchoolLocation extends NavigableComponentData {
    id: number;
    description: string;
    stagesEnrollmentsByScholl: Array<StageEnrollmentsBySchool>;
    totalExistingClassrooms: number;

    constructor(init?: Partial<EnrollmentBySchoolLocation>) {
        super();
        Object.assign(this, init);
    }
}
