import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HasErrorGuard } from '../shared/guards/has-error/has-error.guard';
import { HasRequiredDataGuard } from './shared/guards/required-data/has-required-data.guard';
import { InconsistencyGuard } from './shared/guards/inconsistency/inconsistency.guard';
import { SimulatorComponent } from './simulator/simulator.component';
import { SelectLocationComponent } from './select-location/select-location.component';
import { SimulationTimeComponent } from './simulation-time/simulation-time.component';
import { AccessAndOfferComponent } from './access-and-offer/access-and-offer/access-and-offer.component';
import { AccessAndOfferChildrenRoutes } from './access-and-offer/access-and-offer-children.routes';
import { QualityConditionsComponent } from './quality-conditions/quality-conditions/quality-conditions.component';
import { QualityConditionsChildrenRoutes } from './quality-conditions/quality-conditions-children.routes';
import { ChargesAndAdditionalsComponent } from './charges-and-additionals/charges-and-additionals/charges-and-additionals.component';
import { ChargesAndAdditionalsChildrenRoutes } from './charges-and-additionals/charges-and-additionals-children.routes';
import { ResultsComponent } from './results/results/results.component';
import { ResultsChildrenRoutes } from './results/results-children.routes';
import { ProcessingSimulationComponent } from './shared/components/processing-simulation/processing-simulation.component';
import { SimulationPlanningTypeGuard } from './shared/guards/simulator/simulation-planning-type.guard';
import { SimulationTypeGuard } from './shared/guards/simulator/simulation-type.guard';
import { SimulationEditModeGuard } from './shared/guards/simulator/simulation-edit-mode.guard';
import { SimulationNotEditModeGuard } from './shared/guards/simulator/simulation-not-edit-mode.guard';
import { SimulationNotPerformedGuard } from './shared/guards/simulator/simulation-not-performed.guard';
import { SimulationPlanningFinancingTypeGuard } from './shared/guards/simulator/simulation-planning-financing-type.guard';
import { AuthGuard } from '../shared/guards/auth/auth.guard';

const routes: Routes = [
  {
    path: 'simulator',
    component: SimulatorComponent,
    // canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'selectlocation'
      },
      {
        path: 'processingsimulation',
        component: ProcessingSimulationComponent, canDeactivate: [HasErrorGuard]
      },
      {
        path: 'selectlocation',
        component: SelectLocationComponent,
        canActivate: [SimulationPlanningTypeGuard, SimulationNotEditModeGuard, SimulationNotPerformedGuard],
        canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard]
      },
      {
        path: 'simulationtime',
        component: SimulationTimeComponent,
        canActivate: [SimulationPlanningTypeGuard, SimulationEditModeGuard],
        canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard]
      },
      {
        path: 'accessandoffer',
        component: AccessAndOfferComponent,
        children: [...AccessAndOfferChildrenRoutes],
        canActivate: [SimulationPlanningFinancingTypeGuard, SimulationEditModeGuard]
      },
      {
        path: 'qualityconditions',
        component: QualityConditionsComponent,
        children: [...QualityConditionsChildrenRoutes],
        canActivate: [SimulationTypeGuard, SimulationEditModeGuard]
      },
      {
        path: 'chargesandadditionals',
        component: ChargesAndAdditionalsComponent,
        children: [...ChargesAndAdditionalsChildrenRoutes],
        canActivate: [SimulationTypeGuard, SimulationEditModeGuard]
      },
      {
        path: 'results',
        component: ResultsComponent,
        children: [...ResultsChildrenRoutes],
        canActivate: [SimulationTypeGuard]
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SimulatorRoutingModule { }
