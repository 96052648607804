import { Member } from './member';

export class Team {

    title: string;
    members: Array<Member>;

    constructor(init?: Partial<Team>) {
        Object.assign(this, init);
    }
}
