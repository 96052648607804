import { CostSharingBySubitem } from './cost-sharing-by-subitem';

export class CostSharingByLocation {

  id: number;
  description: string;
  costsSharingBySubitems: Array<CostSharingBySubitem>;

  constructor(init?: Partial<CostSharingByLocation>) {
    Object.assign(this, init);
  }

}
