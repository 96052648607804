import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatomoModule } from 'ngx-matomo';

import { SharedModule } from './shared/shared.module';
import { UserModule } from './user/user.module';
import { SimulationsModule } from './simulations/simulations.module';
import { SimulatorModule } from './simulator/simulator.module';
import { AppHttpInterceptor } from './shared/services/http/interceptors/app-http-interceptor.service';
import { AppErrorHandler } from './shared/services/error-handler/app-error-handler.service';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PublicationsComponent } from './publications/publications.component';
import { TeamComponent } from './team/team.component';
import { ProfileComponent } from './profile/profile.component';
import { AboutComponent } from './about/about.component';
import { QualityComponent } from './quality/quality.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ContactComponent } from './contact/contact.component';
import { CaqComponent } from './caq/caq.component';
import { CaqResultComponent } from './caq-result/caq-result.component';
import { NovidadesComponent } from "./novidades/novidades.component";
import { Rel3Component } from './rel3/rel3.component';
import { CustoPorEscolaComponent } from './custo-por-escola/custo-por-escola.component';
import { FinanciamentoAtualNecessarioComponent } from './financiamento-atual-necessario/financiamento-atual-necessario.component';

registerLocaleData(localePt, 'pt');

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    UserModule,
    SimulationsModule,
    SimulatorModule,
    AppRoutingModule,
    MatomoModule
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    NotFoundComponent,
    PublicationsComponent,
    CaqComponent,
    CaqResultComponent,
    TeamComponent,
    ProfileComponent,
    AboutComponent,
    QualityComponent,
    ResetPasswordComponent,
    ContactComponent,
    NovidadesComponent,
    Rel3Component,
    CustoPorEscolaComponent,
    FinanciamentoAtualNecessarioComponent
  ],
  entryComponents: [
    LoginComponent,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    { provide: ErrorHandler, useClass: AppErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
