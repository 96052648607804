import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { SessionService } from '../../../../shared/services/session/session.service';
import { SimulationType } from '../../../simulator/entities/enums/simulation-type.enum';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';

@Injectable({
  providedIn: 'root'
})
export class SimulationPlanningFinancingTypeGuard implements CanActivate {

  constructor(private sessionService: SessionService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const simulationType: SimulationType = this.sessionService.getItem<SimulationType>(UtilitiesService.simulationTypeSessionKey);

    return simulationType === SimulationType.planning ||
      simulationType === SimulationType.planningByStateSphereAdm ||
      simulationType === SimulationType.planningByCitySphereAdm ||
      simulationType === SimulationType.financing ||
      simulationType === SimulationType.financingFederatedEntitiesByCitySphereAdm ||
      simulationType === SimulationType.financingFederatedEntitiesByStateSphereAdm ||
      simulationType === SimulationType.financingFederatedEntitiesGroupByCityOrState;
  }

}
