export enum SerieEnum {
    crecheMenorDeUmAno = 11,
    crecheUmAno = 12,
    crecheDoisAnos = 13,
    crecheTresAnos = 14,
    preEscolaQuatroAnos = 21,
    preEscolaCincoAnos = 22,
    ensinoFundamentalPrimeiroAno = 31,
    ensinoFundamentalPrimeiraSerieSegundoAno = 32,
    ensinoFundamentalSegundaSerieTerceiroAno = 33,
    ensinoFundamentalTerceiraSerieQuartoAno = 34,
    ensinoFundamentalQuartaSerieQuintoAno = 35,
    ensinoFundamentalQuintaSerieSextoAno = 41,
    ensinoFundamentalSextaSerieSetimoAno = 42,
    ensinoFundamentalSetimaSerieOitavoAno = 43,
    ensinoFundamentalOitavaSerieNonoAno = 44,
    ensinoMedioPrimeiraSerie = 51,
    ensinoMedioSegundaSerie = 52,
    ensinoMedioTerceiraSerie = 53,
    ensinoMedioQuartaSerie = 54,
    ejaAnosIniciaisDoEnsinoFundamental = 61,
    ejaAnosFinaisDoEnsinoFundamental = 62,
    ejaEnsinoMedio = 63
}
