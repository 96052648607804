export enum ItemCostEnum {
  Pessoal = 1,
  Docentes = 2,
  NaoDocentes = 3,
  BensServicos = 4,
  AlimentacaoEscolar = 5,
  AlimentosGasCozinha = 6,
  EncargosSociaisAuxiliarAlimentacao = 7,
  AuxiliarAlimentacao = 8,
  CustosAdministracaoCentral = 9,
  FormacaoDocentes = 10,
  FormacaoNaoDocentes = 11,
  EncargosSociaisAdministracaoCentral = 12,
  AdministracaoSupervisaoRede = 13,
  TotalGeral = 14,
  PessoalEncargosSociais = 15,
  TotalMDE = 16,
  NumeroMatriculas = 17,
  CustoAlunoAno = 18,
  CustoAlunoAnoMDE = 19,
  DespesasMateriaisDidaticos = 20,
  FormacaoContinuadaProfissionais = 21,
  FuncionamentoManutencaoEscolas = 22,
  AdministrativeCosts = 23
}
