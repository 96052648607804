
export class EmployeeEstimateByYear {

  year: number;
  quantity: number;

  constructor(init?: Partial<EmployeeEstimateByYear>) {
    Object.assign(this, init);
  }
}
