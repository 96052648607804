import { Location } from '../../../../shared/entities/location';

export class ColumnReportByLocation {

  id: number;
  description: string;
  columnsReportByShifts: Array<Location>;

  constructor(init?: Partial<ColumnReportByLocation>) {
    Object.assign(this, init);
  }
}
