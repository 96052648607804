import { CapitalExpenseByYear } from './capital-expense-by-year';

export class CapitalExpense {

  capitalExpenseDescription: string;
  capitalExpenseByYears: Array<CapitalExpenseByYear>;
  totalExpenseWithInfrastructure: number;
  totalExpenseWithNewroom: number;

  constructor(init?: Partial<CapitalExpense>) {
    Object.assign(this, init);
  }

}
