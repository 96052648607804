import { Component, DoCheck, Injector, Type } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { PqrComponentData } from '../../shared/entities/base/pqr-component-data';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { FormationLevel } from '../../../shared/entities/formation-level';
import { CareerAndRemunerationTeachers } from './entities/career-and-remuneration-teachers';
import { CareerAndRemunerationTeachersService } from './services/career-and-remuneration-teachers.service';
import { TeacherFormationLevel } from './entities/teacher-formation-level';
import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { TeacherDistributionCareerLevel } from './entities/teacher-distribution-career-level';
import { NavigableComponentData } from '../../shared/entities/base/navigable-component-data';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-career-and-remuneration-teachers',
  templateUrl: './career-and-remuneration-teachers.component.html',
  styleUrls: ['./career-and-remuneration-teachers.component.scss']
})
export class CareerAndRemunerationTeachersComponent extends BaseNavigableComponent<CareerAndRemunerationTeachers> implements PqrComponentData, DoCheck {

  data: CareerAndRemunerationTeachers = new CareerAndRemunerationTeachers();
  functionality: FunctionalityInfo = Functionality.careerAndRemunerationTeachers;
  inconsistencies: Array<Inconsistency>;
  pqrAdminMode: boolean;
  pqrMode: boolean;
  pqrModalMode: boolean;
  componentType: Type<PqrComponentData> = CareerAndRemunerationTeachersComponent;

  referenceDateMask: any = this.utilitiesService.getReferenceDateMask();

  private prevTeacherFormationLevels: Array<TeacherFormationLevel>;

  constructor(private careerAndRemunerationTeachersService: CareerAndRemunerationTeachersService, private injector: Injector) {
    super(injector);
  }

  processData(): Observable<CareerAndRemunerationTeachers> {
    return this.careerAndRemunerationTeachersService.getData(this.pqrMode);
  }

  processSessionData(sessionData: CareerAndRemunerationTeachers): void {
    sessionData.teacherFormationLevels.levels = this.careerAndRemunerationTeachersService.getTeacherFormationLevelsInObject(sessionData.teacherFormationLevels.levels);
    this.data = sessionData;
  }

  processSpecificBehaviors() {

    super.processSpecificBehaviors();

    if (!this.pqrMode) {
      // this.data.projectionsNumberOfTeachers = this.careerAndRemunerationTeachersService.getProjectionOfTheNumberOfTeachers();
      this.careerAndRemunerationTeachersService.populateDiagnostic(this.data).pipe(
        takeUntil(this.unsubscribe))
        .subscribe();
    }
  }

  formationLevelEquals(formationA: FormationLevel, formationB: FormationLevel): boolean {
    if (formationA && formationB) {
      return formationA.id === formationB.id;
    }

    return false;
  }

  ngDoCheck(): void {
    if (!this.pqrMode) {
      if (this.data.teacherFormationLevels && this.data.teacherFormationLevels.levels.length > 0) {
        if (this.data.teacherFormationLevels.levels[0].diagnostic !== undefined) {
          if (!_.isEqual(this.data.teacherFormationLevels.levels, this.prevTeacherFormationLevels)) {
            this.careerAndRemunerationTeachersService.processTotals(this.data);
            this.prevTeacherFormationLevels = _.cloneDeep(this.data.teacherFormationLevels.levels);
          }
        }
      }
    }
  }

  newFormationLevel(): void {
    const newFormationLevel = this.careerAndRemunerationTeachersService.getNewTeacherFormationLevel(this.data.teacherFormationLevels.levels.length);
    this.data.teacherFormationLevels.levels.push(newFormationLevel);
  }

  deleteFormationLevel(sequence: string): void {
    this.careerAndRemunerationTeachersService.removeTeacherFormationLevel(this.data.teacherFormationLevels.levels, sequence);
  }

  getPqrData(): NavigableComponentData {
    return this.data.teacherFormationLevels;
  }

  setPqrData(pqrData: any) {
    this.data = pqrData;
  }
  sumDiagnostic() {
    let total: number = 0;
    if (this.data.teacherFormationLevels !== undefined) {
      total = this.data.teacherFormationLevels.levels.reduce((prevVal, elem) => {
        return elem.diagnostic ? prevVal + elem.diagnostic : prevVal;
      }
        , 0);
    }
    return total;
  }

  sumInitialTeacherPercentage() {
    let total: number = 0;
    if (this.data.teacherFormationLevels !== undefined) {
      total = this.data.teacherFormationLevels.levels.reduce((prevVal, elem) => {
        return elem.initialTeacherPercentage ? prevVal + elem.initialTeacherPercentage : prevVal;
      }
        , 0);
    }
    return total;
  }

  calcPercentageJourneyWithInteraction(sequence: string): void {
    const journeyNoInteraction: number = _.find(this.data.teacherFormationLevels.levels, l => l.sequence === sequence).journeyNoInteraction;
    _.find(this.data.teacherFormationLevels.levels, l => l.sequence === sequence).journeyWithInteraction = 100 - journeyNoInteraction;
  }

  calcPercentageJourneyNoInteraction(sequence: string): void {
    const journeyWithInteraction: number = _.find(this.data.teacherFormationLevels.levels, l => l.sequence === sequence).journeyWithInteraction;
    _.find(this.data.teacherFormationLevels.levels, l => l.sequence === sequence).journeyNoInteraction = 100 - journeyWithInteraction;
  }

  processValidation(): void {
    super.processValidation();

    if (this.data.teacherFormationLevels.levels.length === 0) {
      this.inconsistencies.push(new Inconsistency({
        message: `Nenhum nível / classe foi informado.`
      }));
    } else {
      for (const teacherFormationLevel of this.data.teacherFormationLevels.levels) {
        if (teacherFormationLevel.denomination === null || teacherFormationLevel.denomination === '') {
          this.inconsistencies.push(new Inconsistency({
            message: `A denominação da sequência ${teacherFormationLevel.sequence} não foi informada.`
          }));
        }

        if (teacherFormationLevel.formationLevel) {
          if (teacherFormationLevel.formationLevel.id === undefined) {
            this.inconsistencies.push(new Inconsistency({
              message: `O nível de formação da sequência ${teacherFormationLevel.sequence} não é válido. Selecione um valor.`
            }));
          }
        } else {
          this.inconsistencies.push(new Inconsistency({
            message: `O nível de formação da sequência ${teacherFormationLevel.sequence} não é válido. Selecione um valor.`
          }));
        }

        // if (isNaN(teacherFormationLevel.journeyTotal) ||
        //   teacherFormationLevel.journeyTotal === null ||
        //   teacherFormationLevel.journeyTotal === undefined ||
        //   teacherFormationLevel.journeyTotal < 10 ||
        //   teacherFormationLevel.journeyTotal > 44) {
        //   this.inconsistencies.push(new Inconsistency({
        //     message: `A jornada total da sequencia  ${teacherFormationLevel.sequence} não é válida. Informe um valor de 10 a 44 horas.`
        //   }));
        // }

        if (teacherFormationLevel.grossMonthlyRemuneration === 0 || teacherFormationLevel.grossMonthlyRemuneration === undefined) {
          this.inconsistencies.push(new Inconsistency({
            message: `A remuneração bruta mensal da sequência ${teacherFormationLevel.sequence} deve ser um valor maior que 0.`
          }));
        }

        if (!this.utilitiesService.isReferenceDateValid(teacherFormationLevel.referenceDate)) {
          this.inconsistencies.push(new Inconsistency({
            message: `A data de referência da sequência ${teacherFormationLevel.sequence} não é valida.`
          }));
        }
      }

      if (_.uniq(this.data.teacherFormationLevels.levels.map(d => d.denomination.toLocaleLowerCase())).length !== this.data.teacherFormationLevels.levels.length) {
        this.inconsistencies.push(new Inconsistency({
          message: `Existem denominações duplicadas.`
        }));
      }

      let totalPercentages: Array<TeacherDistributionCareerLevel> = new Array<TeacherDistributionCareerLevel>();

      totalPercentages = this.data.totals.filter(pct => pct.percentage !== 100);

      if (totalPercentages.length > 0) {
        this.inconsistencies.push(new Inconsistency({
          message: `A soma das porcentagens da distribuição do quadro de professores nos níveis da carreira em cada ano do plano não pode ser diferente de 100%.`
        }));
      }
    }
  }
}
