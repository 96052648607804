import { Component, Injector, Type, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { PqrComponentData } from '../../shared/entities/base/pqr-component-data';
import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { InfrastructureSchoolsBuildings } from './entities/infrastructure-schools-buildings';
import { InfrastructureSchoolBuildingsService } from './services/infrastructure-school-buildings.service';
import { NavigableComponentData } from '../../shared/entities/base/navigable-component-data';
import { InfrastructureSchoolBuildings } from './entities/infrastructure-school-buildings';
import { CsvHeader } from '../../../shared/services/csv/entities/csv-header';
import { CsvService } from '../../../shared/services/csv/csv.service';
import { Csv } from '../../../shared/services/csv/entities/csv';
import { SelectLocation } from 'app/simulator/select-location/entities/select-location';

@Component({
  selector: 'app-infrastructure-school-buildings',
  templateUrl: './infrastructure-school-buildings.component.html',
  styleUrls: ['./infrastructure-school-buildings.component.scss']
})

export class InfrastructureSchoolBuildingsComponent extends BaseNavigableComponent<InfrastructureSchoolsBuildings> implements PqrComponentData, OnInit {

  data: InfrastructureSchoolsBuildings = new InfrastructureSchoolsBuildings();
  functionality: FunctionalityInfo = Functionality.infrastructureSchoolBuildings;
  inconsistencies: Array<Inconsistency>;
  pqrAdminMode: boolean;
  pqrMode: boolean;
  pqrModalMode: boolean;
  componentType: Type<PqrComponentData> = InfrastructureSchoolBuildingsComponent;
  selectLocationData: SelectLocation = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);
  disabledData: boolean = true;
  popoverTriggers: string;
  popoverText: string = 'Esses parâmetros só podem ser alterados para simulações em nível estadual ou municipal. ' +
    'Para simulações em nível nacional, são apresentados resultados dos valores estaduais agregados internamente pelo simulador.';
  referenceDateMask: any = this.utilitiesService.getReferenceDateMask();

  constructor(private injector: Injector, private infrastructureSchoolBuildingsService: InfrastructureSchoolBuildingsService, private csvService: CsvService) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.disabledData = this.processDisabledData();

    if (this.disabledData) {
      this.popoverTriggers = 'mouseenter:mouseleave';
    }
  }

  processData(): Observable<InfrastructureSchoolsBuildings> {
    return this.infrastructureSchoolBuildingsService.getData(this.pqrMode);
  }

  getPqrData(): NavigableComponentData {
    return this.data.infrastructureSchools;
  }

  setPqrData(pqrData: any) {
    this.data = pqrData;
  }

  getNumberAdequacy(infrastructureSchoolBuildings: InfrastructureSchoolBuildings): number {
    return this.infrastructureSchoolBuildingsService.getNumberAdequacy(infrastructureSchoolBuildings);
  }

  getAdequatePercentage(infrastructureSchoolBuildings: InfrastructureSchoolBuildings): number {
    return this.infrastructureSchoolBuildingsService.getAdequatePercentage(infrastructureSchoolBuildings);
  }

  processValidation(): void {
    super.processValidation();

    for (const infra of this.data.infrastructureSchools.infrastructureBuildings) {
      if (this.getNumberAdequacy(infra) > 0 &&
        ((infra.dimension === undefined || isNaN(infra.dimension)) ||
          (infra.unitPrice === undefined || isNaN(infra.unitPrice))
        )) {
        this.inconsistencies.push(new Inconsistency({
          message: `Os parâmetros para adequação da dependência escolar '${infra.description}' não foram informados corretamente.`
        }));
      }

      if (!this.utilitiesService.isReferenceDateValid(infra.referenceDate)) {
        this.inconsistencies.push(new Inconsistency({
          message: `A data de referência da sequência '${infra.description}' não é valida.`
        }));
      }

    }
  }

  downloadCsv(): void {
    const header: Array<CsvHeader> = new Array<CsvHeader>();
    const data: Array<any> = new Array<any>();

    header.push(new CsvHeader({ key: 'dependency', label: 'Dependências escolares' }));

    for (const location of this.data.locations) {
      header.push(new CsvHeader({ key: location.description, label: location.description }));
    }

    for (const state of this.data.stages) {
      header.push(new CsvHeader({ key: state.description, label: state.description }));
    }

    header.push(new CsvHeader({ key: 'numberRequired', label: 'Número de escolas' }));
    header.push(new CsvHeader({ key: 'adequatePercentage', label: '% Adequado' }));
    header.push(new CsvHeader({ key: 'numberAdequacy', label: 'Número de adequações necessárias' }));
    header.push(new CsvHeader({ key: 'dimension', label: 'Dimensão m2' }));
    header.push(new CsvHeader({ key: 'unitOfMeasurement', label: 'Unidade de Medida' }));
    header.push(new CsvHeader({ key: 'unitPrice', label: 'Preço por m2 (R$)' }));
    header.push(new CsvHeader({ key: 'referenceDate', label: 'Mês/ano referência' }));

    for (const dependency of this.data.infrastructureSchools.infrastructureBuildings.filter(x => !x.isTitle)) {
      const infrastructureBuildings: any = { dependency: dependency.description };

      for (let i = 0; i < dependency.schoolUnitLocation.length; i++) {
        infrastructureBuildings[this.data.locations[i].description] = dependency.schoolUnitLocation[i].value ? 'X' : '';
      }

      for (let i = 0; i < dependency.stagesSpecificities.length; i++) {
        infrastructureBuildings[this.data.stages[i].description] = dependency.stagesSpecificities[i].value ? 'X' : '';
      }

      infrastructureBuildings.numberRequired = dependency.numberRequired;
      infrastructureBuildings.adequatePercentage = this.utilitiesService.roundNumber(this.getAdequatePercentage(dependency), 1);
      infrastructureBuildings.numberAdequacy = this.getNumberAdequacy(dependency);
      infrastructureBuildings.dimension = dependency.dimension;
      infrastructureBuildings.unitOfMeasurement = dependency.unitOfMeasurement.description;
      infrastructureBuildings.unitPrice = this.utilitiesService.roundNumber(dependency.unitPrice, 2);
      infrastructureBuildings.referenceDate = dependency.referenceDate;

      data.push(infrastructureBuildings);
    }

    const csv: Csv = new Csv({ header: header, data: data, name: 'Infraestrutura dos prédios escolares' });
    this.csvService.download(csv);
  }

  processDisabledData(pqrAdminMode: boolean = false): boolean {

    if (pqrAdminMode) {
      return false;
    } else {
      if (!(!this.pqrAdminMode && this.pqrMode)) {
        return this.disabledData = !this.selectLocationData || !(this.selectLocationData.selectedCity || this.selectLocationData.selectedState);
      } else {
        return !this.pqrAdminMode && this.pqrMode;
      }
    }
  }

}
