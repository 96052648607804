import { Projection } from './projection';
import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';

export class EnrollmentByStageSeries extends NavigableComponentData {
    projections: Array<Projection> = new Array<Projection>();
    years: Array<number>;
    offerYear: number;
    outOfSchoolPopulationCurrentYear: number;

    constructor(init?: Partial<EnrollmentByStageSeries>) {
        super();
        Object.assign(this, init);
    }
}
