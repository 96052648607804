export enum FunctionalityKey {
  selectLocation = 'selectlocation',
  simulationTime = 'simulationtime',
  viewEnrollmentByStageAndSeries = 'viewenrollmentbystageandseries',
  enrollmentByStageAndSeries = 'enrollmentbystageandseries',
  enrollmentProjection = 'enrollmentprojection',
  enrollmentProjectionByLocation = 'enrollmentprojectionbylocation',
  collaborationScheme = 'collaborationscheme',
  schoolDayPerWeek = 'schooldayperweek',
  dailyTeachingLoad = 'dailyteachingload',
  offerGoalEnrollmentFullTime = 'offergoalenrollmentfulltime',
  numberStudentClass = 'numberstudentclass',
  workJourneyTeacher = 'workjourneyteacher',
  careerAndRemunerationTeachers = 'careerandremunerationteachers',
  teacherNumber = 'teachernumber',
  schoolsStaff = 'schoolsstaff',
  staffNumber = 'staffnumber',
  infrastructureSchoolBuildings = 'infrastructureschoolbuildings',
  newRoomBuilding = 'newroombuilding',
  administrativeAreaCosting = 'administrativeareacosting',
  equipmentAndFurniture = 'equipmentandfurniture',
  schoolFeeding = 'schoolfeeding',
  schoolTransport = 'schooltransport',
  schoolsOperation = 'schoolsoperation',
  teacherTraining = 'teachertraining',
  socialCharges = 'socialcharges',
  additionals = 'additionals',
  priceIndices = 'priceindices',
  fullPriceList = 'fullpricelist',
  caqReport = 'caqreport',
  budgetForecastReport = 'budgetforecastreport',
  financingFundsReport = 'financingfundsreport',
  financingFederatedEntitiesReport = 'financingfederatedentitiesreport',
  itemCostExpenseReport = 'itemcostexpensereport',
  higherDemandClassroom = 'higherDemandClassroom',
  calculatedClassNumber = 'calculatedclassnumber',
  calculatedClassroomNumber = 'calculatedclassroomnumber',
  calculatedTeacherNumber = 'calculatedteachernumber',
  calculatedEmployeeEstimate = 'calculatedemployeeestimate',
  calculatedCostDrive = 'calculatedcostdrive',
  enrollmentAndClassAndClassroom = 'enrollmentandclassandclassroom',
  enrollmentByStageAndSeriesBySchool = 'enrollmentbystageseriesbyschool',
  resultEnrollmentProjection = 'resultenrollmentprojection',
  custoPorEscola = 'custoporescola'
}