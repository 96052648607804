import { Component, Injector, Type } from '@angular/core';
import { Observable } from 'rxjs';

import { WorkJourneyTeacherService } from './services/work-journey-teacher.service';
import { PqrComponentData } from '../../shared/entities/base/pqr-component-data';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { WorkJourneyTeacher } from './entities/work-journey-teacher';
import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { NavigableComponentData } from '../../shared/entities/base/navigable-component-data';

@Component({
  selector: 'app-work-journey-teacher',
  templateUrl: './work-journey-teacher.component.html',
  styleUrls: ['./work-journey-teacher.component.scss']
})
export class WorkJourneyTeacherComponent extends BaseNavigableComponent<WorkJourneyTeacher> implements PqrComponentData {

  data: WorkJourneyTeacher = new WorkJourneyTeacher();
  functionality: FunctionalityInfo = Functionality.workJourneyTeacher;
  inconsistencies: Array<Inconsistency>;
  pqrAdminMode: boolean;
  pqrMode: boolean;
  pqrModalMode: boolean;
  componentType: Type<PqrComponentData> = WorkJourneyTeacherComponent;

  constructor(private injector: Injector, private workJourneyTeacherService: WorkJourneyTeacherService) {
    super(injector);
  }

  processData(): Observable<WorkJourneyTeacher> {
    return this.workJourneyTeacherService.getData();
  }

  getPqrData(): NavigableComponentData {
    return this.data;
  }

  setPqrData(pqrData: any) {
    this.data = pqrData;
  }

  calcPercentageJourneyWithInteraction(): void {
    this.data.journeyWithInteraction = 100 - this.data.journeyNoInteraction;
  }

  calcPercentageJourneyNoInteraction(): void {
    this.data.journeyNoInteraction = 100 - this.data.journeyWithInteraction;
  }

  processValidation(): void {
    super.processValidation();

    if (isNaN(this.data.journeyTotal) || this.data.journeyTotal === null || this.data.journeyTotal === undefined || this.data.journeyTotal < 10 || this.data.journeyTotal > 44) {
      this.inconsistencies.push(new Inconsistency({
        message: 'A jornada total não é válida. Informe um valor de 10 a 44 horas.'
      }));
    }

    if (+this.data.journeyNoInteraction + +this.data.journeyWithInteraction !== 100) {
      this.inconsistencies.push(new Inconsistency({
        message: 'A soma dos campos jornada SEM interação com alunos e jornada COM interação com alunos deve ser igual a 100%.'
      }));
    }

  }

}
