import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { EnrollmentProjectionByLocation } from '../../../access-and-offer/enrollment-projection/entities/enrollment-projection-by-location';
import { CollaborationScheme } from '../../../access-and-offer/collaboration-scheme/entities/collaboration-scheme';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { SessionService } from '../../../../shared/services/session/session.service';
import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { EnrollmentProjection } from '../../../access-and-offer/enrollment-projection/entities/enrollment-projection';
import { BudgetForecastReportByItem } from '../entities/budget-forecast-report-by-item';
import { BudgetForecastReportByYear } from '../entities/budget-forecast-report-by-year';
import { BudgetForecastReport } from '../entities/budget-forecast-report';
import { StudentCostByStage } from '../../../shared/services/calculate-student-cost/entities/student-cost-by-stage';
import { ExpenseTypeEnum } from '../../../../shared/entities/enums/expense-type.enum';
import { StudentCost } from '../../../shared/services/calculate-student-cost/entities/student-cost';
import { CapitalExpense } from '../../../shared/services/calculate-capital-expense/entities/capital-expense';
import { HttpService } from '../../../../shared/services/http/http.service';
import { CurrentYearService } from '../../../shared/services/current-year/current-year.service';
import { CalculateClassNumberService } from '../../../shared/services/calculate-class-number/calculate-class-number.service';
import { TeacherNumberCalc } from '../../../shared/services/calculate-teacher-number/entities/teacher-number-calc';
import { ClassNumber } from '../../../shared/services/calculate-class-number/entities/class-number';
import { EmployeeEstimate } from '../../../shared/services/calculate-employee-estimate/entities/employee-estimate';
import { ClassroomExistingByCity } from '../../../quality-conditions/new-room-building/entities/classroom-existing-by-city';
import { ClassroomExistingByLocation } from '../../../quality-conditions/new-room-building/entities/classroom-existing-by-location';
import { DemandClassRoom } from '../../../quality-conditions/new-room-building/entities/demand-class-room';
import { SelectLocation } from '../../../select-location/entities/select-location';
import { CsvService } from '../../../../shared/services/csv/csv.service';
import { CsvHeader } from '../../../../shared/services/csv/entities/csv-header';
import { Csv } from '../../../../shared/services/csv/entities/csv';
import { FinancialDataTypeEnum } from '../../../../shared/entities/enums/financial-data-type.enum';
import { SphereAdmEnum } from '../../../../shared/entities/enums/sphere-adm.enum';
import { FinancialData } from '../entities/financial-data';
import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { CapitalExpenseByYear } from './../../../shared/services/calculate-capital-expense/entities/capital-expense-by-year';
import { DatasCsvEnum } from './../../../../shared/entities/enums/datas-csv.enum';
import { AdmDependencyEnum } from 'app/shared/entities/enums/adm-dependency.enum';
import { CreateProjectionsService } from 'app/simulator/shared/services/create-projections/create-projections.service';
import { CreateTeacherNumberCalcService } from 'app/simulator/shared/services/create-teacher-number-calc/create-teacher-number-calc.service';
import { EnrollmentBySchool } from 'app/simulator/access-and-offer/enrollment-by-stage-series-by-school/entities/enrollment-by-school';
import { EnrollmentAndClassAndClassroom } from '../entities/enrollment-and-class-and-classroom';
import { Stage } from 'app/shared/entities/stage';
import { Location } from 'app/shared/entities/location';
import { SchoolByCity } from '../entities/school_by_city';
import { School } from '../entities/school';
import { StageEnrollments } from 'app/simulator/access-and-offer/enrollment-projection/entities/stage-enrollments';
import { LocationEnum } from 'app/shared/entities/enums/location.enum';
import { ViewEnrollmentByStageSeries } from 'app/simulator/access-and-offer/view-enrollment-by-stage-series/entities/view-enrollment-by-stage-series';
import { SimulationType } from 'app/simulator/simulator/entities/enums/simulation-type.enum';
import { Enrollment } from '../../../access-and-offer/enrollment-projection/entities/enrollment';
import { OfferGoalEnrollmentFullTime } from 'app/simulator/quality-conditions/offer-goal-enrollment-full-time/entities/offer-goal-enrollment-full-time';
import { NumberStudentClass } from 'app/simulator/quality-conditions/number-student-class/entities/number-student-class';
import { SchoolDayPerWeek } from 'app/simulator/quality-conditions/school-day-per-week/entities/school-day-per-week';
import { DailyTeachingLoad } from 'app/simulator/quality-conditions/daily-teaching-load/entities/daily-teaching-load';
import { CareerAndRemunerationTeachers } from 'app/simulator/quality-conditions/career-and-remuneration-teachers/entities/career-and-remuneration-teachers';
import { WorkJourneyTeacher } from 'app/simulator/quality-conditions/work-journey-teacher/entities/work-journey-teacher';
import { ExpenseByCity } from '../entities/expense_by_city';
import { EmployeeEstimateByRole } from 'app/simulator/shared/services/calculate-employee-estimate/entities/employee-estimate-by-role';
import { EmployeeEstimateByYear } from 'app/simulator/shared/services/calculate-employee-estimate/entities/employee-estimate-by-year';
import { SchoolsStaff } from 'app/simulator/quality-conditions/schools-staff/entities/schools-staff';
import { ExpenseByState } from '../entities/expense_by_state';
import { HttpHeaders } from '@angular/common/http';
import { RelatorioDimensaoOferta } from '../entities/relatorio-dimensao-oferta';
import { TipoDependencia } from 'app/shared/entities/enums/tipo-dependencia';
import { FuncionariosPorFuncao } from 'app/simulator/quality-conditions/staff-number/entities/funcionarios-por-funcao';
import { SimulatorService } from 'app/simulator/simulator/services/simulator.service';
import { TipoPlanoRede } from 'app/shared/entities/enums/tipo-plano-rede';

@Injectable({
  providedIn: 'root'
})
export class BudgetForecastReportService {

  constructor(
    private utilitiesService: UtilitiesService,
    private sessionService: SessionService,
    private httpService: HttpService,
    private csvService: CsvService,
    private currentYearService: CurrentYearService,
    private calculateClassNumberService: CalculateClassNumberService,
    private createProjectionsService: CreateProjectionsService,
    private createTeacherNumberCalcService: CreateTeacherNumberCalcService
  ) { }

  getBudgetForecastReport(): Observable<BudgetForecastReport> {
    let data: BudgetForecastReport = new BudgetForecastReport();
    const pqrData: Array<any> = this.utilitiesService.getPqrLocalUFG();
    data.sourceInformationsOfferDimension = this.getSourceInformationsOfferDimension();
    data.sourceInformationsExpensesByStage = this.getSourceInformationsExpensesByStage();
    data.sourceInformationsExpensesFederativeEntity = this.getSourceInformationsExpensesFederativeEntity();
    data.sourceInformationPQR = this.getSourceInformationPQR();

    const tipoDependencia = this.utilitiesService.getTipoDependenciaUFG();
    const tipoRecorte = this.utilitiesService.getTipoRecorte();
    const tipoDependenciaEstadoOuMunicipio = this.utilitiesService.getTipoDependenciaEstadoOuMunicipio();
    const tipoPlanoOuRede = this.utilitiesService.getTipoPlanoOuRede();
    const requestOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const editMode: boolean = this.sessionService.getItem<boolean>(SimulatorService.simulationEditModeSessionKey);

    if (editMode) {
      // quando estiver no modo edição usará o metodo POST
      const resultForEnrollmentSchool: EnrollmentBySchool = this.sessionService.getItem<EnrollmentBySchool>(Functionality.enrollmentByStageAndSeriesBySchool.key);
      resultForEnrollmentSchool.diagnosticosMatriculaPorEscola.forEach(objeto => {
        // Remover a propriedade 'version' de cada objeto
        delete objeto.version;

        objeto.etapasEnsino.forEach(etapas => {
          // Remover a propriedade 'percentualMatriculasIntegral' de cada objeto
          delete etapas.percentualMatriculasIntegral;
        });
      });

      const resultadoJson = {
        diagnosticoDeMatriculaPorEscola: resultForEnrollmentSchool.diagnosticosMatriculaPorEscola,
        pqr: pqrData
      }
      return this.getResultadosMetodoPost(tipoPlanoOuRede, tipoRecorte, data, resultadoJson, tipoDependencia, tipoDependenciaEstadoOuMunicipio, requestOptions);
    } else {
      // se não estiver no modo edição usará o método GET
      return this.getResultadosMetodoGet(tipoPlanoOuRede, tipoRecorte, data, tipoDependencia, tipoDependenciaEstadoOuMunicipio, requestOptions);
    }
  }

  getResultadosMetodoGet(tipoPlanoOuRede: string, tipoRecorte: string, data: BudgetForecastReport, tipoDependencia: string, tipoDependenciaEstadoOuMunicipio: string, requestOptions: { headers: HttpHeaders; }): Observable<BudgetForecastReport> {
    return this.httpService.getApiEndpointUFG().pipe(
      switchMap(apiEndpoint => {

        return this.httpService.get<any>(`${apiEndpoint}/simulador/memoria/${tipoDependencia}`).pipe(
          mergeMap(memoriaCalulo => {

            let urlDimensao = apiEndpoint + '/planejamento-orcamentario/variacao-dimensao-oferta/' + tipoDependencia
            if (tipoRecorte === TipoDependencia.Nacional) {
              urlDimensao = apiEndpoint + '/planejamento-orcamentario/variacao-dimensao-oferta'
            }

            return this.httpService.get<any>(urlDimensao).pipe(
              mergeMap(relarioDimensaoOferta => {
                data.relatorioDimensaoOferta = relarioDimensaoOferta;
                data.relatorioDimensaoOferta = new RelatorioDimensaoOferta({ numeroMatriculas: 0, numeroTurmas: 0, numeroDocentes: 0, numeroFuncionarios: 0 });
                data.relatorioDimensaoOferta.numeroMatriculas = relarioDimensaoOferta.content.numeroMatriculas;
                data.relatorioDimensaoOferta.numeroDocentes = relarioDimensaoOferta.content.numeroDocentes;
                data.relatorioDimensaoOferta.numeroFuncionarios = relarioDimensaoOferta.content.numeroFuncionarios;
                data.relatorioDimensaoOferta.numeroTurmas = relarioDimensaoOferta.content.numeroTurmas;

                data.totalMatriculasProjecao = memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.total;
                data.totalTurmasProjecao = memoriaCalulo.resultadoEtapa4CalculoNumeroTurmas.total;
                data.totalDocentesProjecao = memoriaCalulo.resultadoEtapa10CalculoDemandaPorDocentes.total;
                data.totalFuncionariosProjecao = memoriaCalulo.resultadoEtapa18ProjecaoNumeroFuncionarios.total;

                if (memoriaCalulo.complementacaoDto) {
                  data.complementacaoMunicipal = memoriaCalulo.complementacaoDto.complementacaoNivelMunicipio !== 0 ? memoriaCalulo.complementacaoDto.complementacaoNivelMunicipio : '';
                  data.complementacaoEstadual = memoriaCalulo.complementacaoDto.complementacaoNivelEstado !== 0 ? memoriaCalulo.complementacaoDto.complementacaoNivelEstado : '';
                }

                const funcionariosPorFuncao: Array<FuncionariosPorFuncao> = new Array<FuncionariosPorFuncao>();

                for (let i = 0; i < memoriaCalulo.resultadoEtapa18ProjecaoNumeroFuncionarios.funcionariosPorFuncao.length; i++) {
                  const funcao = memoriaCalulo.resultadoEtapa18ProjecaoNumeroFuncionarios.funcionariosPorFuncao[i];

                  funcionariosPorFuncao.push(new FuncionariosPorFuncao({
                    nomeFuncao: funcao.nomeFuncao,
                    quantidade: funcao.quantidade
                  }));
                }
                data.funcionariosPorFuncao = funcionariosPorFuncao;
                data.totalFuncionarios = memoriaCalulo.resultadoEtapa18ProjecaoNumeroFuncionarios.total;

                let urlOrcamento = apiEndpoint + '/planejamento-orcamentario/diferenca-orcamento-realizado-projetado/' + tipoDependenciaEstadoOuMunicipio
                if (tipoRecorte === TipoDependencia.Nacional) {
                  urlOrcamento = apiEndpoint + '/planejamento-orcamentario/diferenca-orcamento-realizado-projetado'
                }

                return this.httpService.get<any>(urlOrcamento).pipe(
                  map(planejamentoOrcamentario => {
                    //console.log("planejamentoOrcamentario: ", planejamentoOrcamentario);
                    //data.relatorioDimensaoOferta = relarioDimensaoOferta;

                    data.totalReceitaPotencial = 0;
                    data.totalDespesaCorrente = 0;

                    if (tipoRecorte === TipoDependencia.Municipal || tipoRecorte === TipoDependencia.Nacional) {
                      data.receitaPotencialMunicipal = planejamentoOrcamentario.content.receitaPotencialMunicipal;
                      data.despesaRealizadaMunicipal = planejamentoOrcamentario.content.despesaRealizadaMunicipal;
                      data.totalReceitaPotencial = data.totalReceitaPotencial + planejamentoOrcamentario.content.receitaPotencialMunicipal;
                      data.totalDespesaCorrente = data.totalDespesaCorrente + planejamentoOrcamentario.content.despesaRealizadaMunicipal;
                      data.despesaCorrenteProjecaoMunicipal = memoriaCalulo.resultadoEtapa27TotalDespesasCorrentes.despesasGovernoMunicipal;
                      data.totalDespesaCorrenteProjecao = data.despesaCorrenteProjecaoMunicipal;
                    }

                    if (tipoRecorte === TipoDependencia.Estadual || tipoRecorte === TipoDependencia.Nacional) {
                      data.totalReceitaPotencial = 0;
                      data.totalDespesaCorrente = 0;
                      data.receitaPotencialEstadual = planejamentoOrcamentario.content.receitaPotencialEstadual;
                      data.despesaRealizadaEstadual = planejamentoOrcamentario.content.despesaRealizadaEstadual;
                      if (tipoRecorte === TipoDependencia.Estadual && tipoPlanoOuRede === TipoPlanoRede.Rede) {
                        data.totalReceitaPotencial = data.totalReceitaPotencial + planejamentoOrcamentario.content.receitaPotencialEstadual;
                        data.totalDespesaCorrente = data.totalDespesaCorrente + planejamentoOrcamentario.content.despesaRealizadaEstadual;
                      } else {
                        data.receitaPotencialMunicipal = planejamentoOrcamentario.content.receitaPotencialMunicipal;
                        data.despesaRealizadaMunicipal = planejamentoOrcamentario.content.despesaRealizadaMunicipal;
                        data.totalReceitaPotencial = data.totalReceitaPotencial + planejamentoOrcamentario.content.receitaPotencialEstadual + planejamentoOrcamentario.content.receitaPotencialMunicipal;
                        data.totalDespesaCorrente = data.totalDespesaCorrente + planejamentoOrcamentario.content.despesaRealizadaEstadual + planejamentoOrcamentario.content.despesaRealizadaMunicipal;
                        data.despesaCorrenteProjecaoMunicipal = memoriaCalulo.resultadoEtapa27TotalDespesasCorrentes.despesasGovernoMunicipal;
                      }
                      data.totalDespesaCorrenteProjecao = memoriaCalulo.resultadoEtapa27TotalDespesasCorrentes.totalDespesasCorrentes;
                      data.despesaCorrenteProjecaoEstadual = memoriaCalulo.resultadoEtapa27TotalDespesasCorrentes.despesasGovernoEstadual !== 0 ? memoriaCalulo.resultadoEtapa27TotalDespesasCorrentes.despesasGovernoEstadual : "";
                    }

                    if (memoriaCalulo.complementacaoDto) {
                      data.percComplementacaoEstadual = (memoriaCalulo.complementacaoDto.complementacaoNivelEstado * 100) / planejamentoOrcamentario.content.receitaPotencialEstadual;
                      if (tipoRecorte === TipoDependencia.Estadual && tipoPlanoOuRede === TipoPlanoRede.Rede) {
                        //sem complementação, foi solicitado pela gabriel para não mostrar quando for plano da rede municipal  
                      } else {
                        data.percComplementacaoMunicipal = (memoriaCalulo.complementacaoDto.complementacaoNivelMunicipio * 100) / planejamentoOrcamentario.content.receitaPotencialMunicipal;
                      }
                    }

                    data.tabelaProjecao = memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes;
                    //console.log("resultadoEtapa28NumerosMatriculasEDocentes:", memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes)


                    //matricula
                    data.totalMatriculaCreche =
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasCrecheUrbanaParcial +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasCrecheUrbanaIntegral +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasCrecheRuralParcial +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasCrecheRuralIntegral +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasCrecheUrbanaNoturna +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasCrecheRuralNoturna;

                    data.totalPorcentagemCreche = (data.totalMatriculaCreche / data.totalMatriculasProjecao);

                    data.totalMatriculaPreEscola =
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasPreEscolaUrbanaParcial +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasPreEscolaUrbanaIntegral +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasPreEscolaRuralParcial +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasPreEscolaRuralIntegral +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasPreEscolaUrbanaNoturna +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasPreEscolaRuralNoturna;

                    data.totalPorcentagemPreEscola = (data.totalMatriculaPreEscola / data.totalMatriculasProjecao);

                    data.totalMatriculaEnsFundAnosIniciais =
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosIniciaisUrbanaParcial +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosIniciaisUrbanaIntegral +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosIniciaisRuralParcial +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosIniciaisRuralIntegral +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosIniciaisUrbanaNoturna +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosIniciaisRuralNoturna;

                    data.totalPorcentagemEnsFundAnosIniciais = (data.totalMatriculaEnsFundAnosIniciais / data.totalMatriculasProjecao);

                    data.totalMatriculaEnsFundAnosFinais =
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosFinaisUrbanaParcial +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosFinaisUrbanaIntegral +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosFinaisRuralParcial +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosFinaisRuralIntegral +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosFinaisUrbanaNoturna +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosFinaisRuralNoturna;

                    data.totalPorcentagemEnsFundAnosFinais = (data.totalMatriculaEnsFundAnosFinais / data.totalMatriculasProjecao);

                    data.totalMatriculaEnsMedio =
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsMedioUrbanaParcial +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsMedioUrbanaIntegral +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsMedioRuralParcial +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsMedioRuralIntegral +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsMedioUrbanaNoturna +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsMedioRuralNoturna;

                    data.totalPorcentagemEnsMedio = (data.totalMatriculaEnsMedio / data.totalMatriculasProjecao);

                    data.totalMatriculaEja = memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEjaUrbanaParcial +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEjaUrbanaIntegral +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEjaRuralParcial +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEjaRuralIntegral +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEjaUrbanaNoturna +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEjaRuralNoturna;

                    data.totalPorcentagemEja = (data.totalMatriculaEja / data.totalMatriculasProjecao);

                    //despesaCorrente - projeção
                    data.despesaCorrenteAnoCreche =
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoCrecheUrbanaParcial +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoCrecheUrbanaIntegral +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoCrecheRuralParcial +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoCrecheRuralIntegral +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoCrecheUrbanaNoturna +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoCrecheRuralNoturna;

                    data.despesaCorrenteAnoPreEscola =
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoPreEscolaUrbanaParcial +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoPreEscolaUrbanaIntegral +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoPreEscolaRuralParcial +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoPreEscolaRuralIntegral +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoPreEscolaUrbanaNoturna +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoPreEscolaRuralNoturna;

                    data.despesaCorrenteAnoEnsFundAnosIniciais =
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsFunAnosIniciaisUrbanaParcial +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsFunAnosIniciaisUrbanaIntegral +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsFunAnosIniciaisRuralParcial +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsFunAnosIniciaisRuralIntegral +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsFunAnosIniciaisUrbanaNoturna +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsFunAnosIniciaisRuralNoturna;

                    data.despesaCorrenteAnoEnsFundAnosFinais =
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsFunAnosFinaisUrbanaParcial +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsFunAnosFinaisUrbanaIntegral +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsFunAnosFinaisRuralParcial +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsFunAnosFinaisRuralIntegral +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsFunAnosFinaisUrbanaNoturna +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsFunAnosFinaisRuralNoturna;

                    data.despesaCorrenteAnoEnsMedio =
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsMedioUrbanaParcial +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsMedioUrbanaIntegral +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsMedioRuralParcial +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsMedioRuralIntegral +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsMedioUrbanaNoturna +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsMedioRuralNoturna;

                    data.despesaCorrenteAnoEja =
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEJAUrbanaParcial +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEJARuralParcial +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEJAUrbanaNoturna +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEJARuralNoturna;

                    return data;
                  }));

              }));

          }));
      }));
  }

  getResultadosMetodoPost(tipoPlanoOuRede: string, tipoRecorte: string, data: BudgetForecastReport, resultadoJson: { diagnosticoDeMatriculaPorEscola: import("app/simulator/access-and-offer/enrollment-by-stage-series-by-school/entities/diagnostico-de-matricula-por-escola").DiagnosticoDeMatriculaPorEscola[]; pqr: any[]; }, tipoDependencia: string, tipoDependenciaEstadoOuMunicipio: string, requestOptions: { headers: HttpHeaders; }): Observable<BudgetForecastReport> {
    return this.httpService.getApiEndpointUFG().pipe(
      switchMap(apiEndpoint => {

        //POST
        return this.httpService.post<any>(`${apiEndpoint}/simulador/memoria/${tipoDependencia}`, resultadoJson, requestOptions).pipe(
          mergeMap(memoriaCalulo => {

            const urlDimensao = apiEndpoint + '/planejamento-orcamentario/variacao-dimensao-oferta/' + tipoDependencia

            return this.httpService.get<any>(urlDimensao).pipe(
              mergeMap(relarioDimensaoOferta => {
                data.relatorioDimensaoOferta = relarioDimensaoOferta;
                data.relatorioDimensaoOferta = new RelatorioDimensaoOferta({ numeroMatriculas: 0, numeroTurmas: 0, numeroDocentes: 0, numeroFuncionarios: 0 });
                data.relatorioDimensaoOferta.numeroMatriculas = relarioDimensaoOferta.content.numeroMatriculas;
                data.relatorioDimensaoOferta.numeroDocentes = relarioDimensaoOferta.content.numeroDocentes;
                data.relatorioDimensaoOferta.numeroFuncionarios = relarioDimensaoOferta.content.numeroFuncionarios;
                data.relatorioDimensaoOferta.numeroTurmas = relarioDimensaoOferta.content.numeroTurmas;

                data.totalMatriculasProjecao = memoriaCalulo.resultadoEtapa1NumerosDeMatriculas.total;
                data.totalTurmasProjecao = memoriaCalulo.resultadoEtapa4CalculoNumeroTurmas.total;
                data.totalDocentesProjecao = memoriaCalulo.resultadoEtapa10CalculoDemandaPorDocentes.total;
                data.totalFuncionariosProjecao = memoriaCalulo.resultadoEtapa18ProjecaoNumeroFuncionarios.total;

                if (memoriaCalulo.complementacaoDto) {
                  data.complementacaoMunicipal = memoriaCalulo.complementacaoDto.complementacaoNivelMunicipio !== 0 ? memoriaCalulo.complementacaoDto.complementacaoNivelMunicipio : '';
                  data.complementacaoEstadual = memoriaCalulo.complementacaoDto.complementacaoNivelEstado !== 0 ? memoriaCalulo.complementacaoDto.complementacaoNivelEstado : '';
                }

                const funcionariosPorFuncao: Array<FuncionariosPorFuncao> = new Array<FuncionariosPorFuncao>();

                for (let i = 0; i < memoriaCalulo.resultadoEtapa18ProjecaoNumeroFuncionarios.funcionariosPorFuncao.length; i++) {
                  const funcao = memoriaCalulo.resultadoEtapa18ProjecaoNumeroFuncionarios.funcionariosPorFuncao[i];

                  funcionariosPorFuncao.push(new FuncionariosPorFuncao({
                    nomeFuncao: funcao.nomeFuncao,
                    quantidade: funcao.quantidade
                  }));
                }
                data.funcionariosPorFuncao = funcionariosPorFuncao;
                data.totalFuncionarios = memoriaCalulo.resultadoEtapa18ProjecaoNumeroFuncionarios.total;

                //console.log(data);
                //console.log(memoriaCalulo);


                return this.httpService.get<any>(`${apiEndpoint}/planejamento-orcamentario/diferenca-orcamento-realizado-projetado/${tipoDependenciaEstadoOuMunicipio}`).pipe(
                  map(planejamentoOrcamentario => {
                    //console.log("planejamentoOrcamentario: ", planejamentoOrcamentario);
                    //data.relatorioDimensaoOferta = relarioDimensaoOferta;

                    data.totalReceitaPotencial = 0;
                    data.totalDespesaCorrente = 0;

                    if (tipoRecorte === TipoDependencia.Municipal || tipoRecorte === TipoDependencia.Nacional) {
                      data.receitaPotencialMunicipal = planejamentoOrcamentario.content.receitaPotencialMunicipal;
                      data.despesaRealizadaMunicipal = planejamentoOrcamentario.content.despesaRealizadaMunicipal;
                      data.totalReceitaPotencial = data.totalReceitaPotencial + planejamentoOrcamentario.content.receitaPotencialMunicipal;
                      data.totalDespesaCorrente = data.totalDespesaCorrente + planejamentoOrcamentario.content.despesaRealizadaMunicipal;
                      data.despesaCorrenteProjecaoMunicipal = memoriaCalulo.resultadoEtapa27TotalDespesasCorrentes.despesasGovernoMunicipal;
                      data.totalDespesaCorrenteProjecao = data.despesaCorrenteProjecaoMunicipal;
                    }

                    if (tipoRecorte === TipoDependencia.Estadual || tipoRecorte === TipoDependencia.Nacional) {
                      data.totalReceitaPotencial = 0;
                      data.totalDespesaCorrente = 0;
                      data.receitaPotencialEstadual = planejamentoOrcamentario.content.receitaPotencialEstadual;
                      data.despesaRealizadaEstadual = planejamentoOrcamentario.content.despesaRealizadaEstadual;
                      if (tipoRecorte === TipoDependencia.Estadual && tipoPlanoOuRede === TipoPlanoRede.Rede) {
                        data.totalReceitaPotencial = data.totalReceitaPotencial + planejamentoOrcamentario.content.receitaPotencialEstadual;
                        data.totalDespesaCorrente = data.totalDespesaCorrente + planejamentoOrcamentario.content.despesaRealizadaEstadual;
                      } else {
                        data.receitaPotencialMunicipal = planejamentoOrcamentario.content.receitaPotencialMunicipal;
                        data.despesaRealizadaMunicipal = planejamentoOrcamentario.content.despesaRealizadaMunicipal;
                        data.totalReceitaPotencial = data.totalReceitaPotencial + planejamentoOrcamentario.content.receitaPotencialEstadual + planejamentoOrcamentario.content.receitaPotencialMunicipal;
                        data.totalDespesaCorrente = data.totalDespesaCorrente + planejamentoOrcamentario.content.despesaRealizadaEstadual + planejamentoOrcamentario.content.despesaRealizadaMunicipal;
                        data.despesaCorrenteProjecaoMunicipal = memoriaCalulo.resultadoEtapa27TotalDespesasCorrentes.despesasGovernoMunicipal;
                      }
                      data.totalDespesaCorrenteProjecao = memoriaCalulo.resultadoEtapa27TotalDespesasCorrentes.totalDespesasCorrentes;
                      data.despesaCorrenteProjecaoEstadual = memoriaCalulo.resultadoEtapa27TotalDespesasCorrentes.despesasGovernoEstadual !== 0 ? memoriaCalulo.resultadoEtapa27TotalDespesasCorrentes.despesasGovernoEstadual : "";
                    }

                    if (memoriaCalulo.complementacaoDto) {
                      data.percComplementacaoEstadual = (memoriaCalulo.complementacaoDto.complementacaoNivelEstado * 100) / planejamentoOrcamentario.content.receitaPotencialEstadual;
                      if (tipoRecorte === TipoDependencia.Estadual && tipoPlanoOuRede === TipoPlanoRede.Rede) {
                        //sem complementação, foi solicitado pela gabriel para não mostrar quando for plano da rede municipal  
                      } else {
                        data.percComplementacaoMunicipal = (memoriaCalulo.complementacaoDto.complementacaoNivelMunicipio * 100) / planejamentoOrcamentario.content.receitaPotencialMunicipal;
                      }
                    }

                    data.tabelaProjecao = memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes;
                    //console.log("resultadoEtapa28NumerosMatriculasEDocentes:", memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes)


                    //matricula
                    data.totalMatriculaCreche =
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasCrecheUrbanaParcial +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasCrecheUrbanaIntegral +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasCrecheRuralParcial +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasCrecheRuralIntegral +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasCrecheUrbanaNoturna +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasCrecheRuralNoturna;

                    data.totalPorcentagemCreche = (data.totalMatriculaCreche / data.totalMatriculasProjecao);

                    data.totalMatriculaPreEscola =
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasPreEscolaUrbanaParcial +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasPreEscolaUrbanaIntegral +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasPreEscolaRuralParcial +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasPreEscolaRuralIntegral +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasPreEscolaUrbanaNoturna +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasPreEscolaRuralNoturna;

                    data.totalPorcentagemPreEscola = (data.totalMatriculaPreEscola / data.totalMatriculasProjecao);

                    data.totalMatriculaEnsFundAnosIniciais =
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosIniciaisUrbanaParcial +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosIniciaisUrbanaIntegral +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosIniciaisRuralParcial +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosIniciaisRuralIntegral +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosIniciaisUrbanaNoturna +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosIniciaisRuralNoturna;

                    data.totalPorcentagemEnsFundAnosIniciais = (data.totalMatriculaEnsFundAnosIniciais / data.totalMatriculasProjecao);

                    data.totalMatriculaEnsFundAnosFinais =
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosFinaisUrbanaParcial +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosFinaisUrbanaIntegral +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosFinaisRuralParcial +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosFinaisRuralIntegral +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosFinaisUrbanaNoturna +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsFunAnosFinaisRuralNoturna;

                    data.totalPorcentagemEnsFundAnosFinais = (data.totalMatriculaEnsFundAnosFinais / data.totalMatriculasProjecao);

                    data.totalMatriculaEnsMedio =
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsMedioUrbanaParcial +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsMedioUrbanaIntegral +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsMedioRuralParcial +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsMedioRuralIntegral +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsMedioUrbanaNoturna +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEnsMedioRuralNoturna;

                    data.totalPorcentagemEnsMedio = (data.totalMatriculaEnsMedio / data.totalMatriculasProjecao);

                    data.totalMatriculaEja = memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEjaUrbanaParcial +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEjaUrbanaIntegral +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEjaRuralParcial +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEjaRuralIntegral +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEjaUrbanaNoturna +
                      memoriaCalulo.resultadoEtapa28NumerosMatriculasEDocentes.quantidadeMatriculasEjaRuralNoturna;

                    data.totalPorcentagemEja = (data.totalMatriculaEja / data.totalMatriculasProjecao);

                    //despesaCorrente - projeção
                    data.despesaCorrenteAnoCreche =
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoCrecheUrbanaParcial +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoCrecheUrbanaIntegral +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoCrecheRuralParcial +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoCrecheRuralIntegral +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoCrecheUrbanaNoturna +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoCrecheRuralNoturna;

                    data.despesaCorrenteAnoPreEscola =
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoPreEscolaUrbanaParcial +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoPreEscolaUrbanaIntegral +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoPreEscolaRuralParcial +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoPreEscolaRuralIntegral +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoPreEscolaUrbanaNoturna +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoPreEscolaRuralNoturna;

                    data.despesaCorrenteAnoEnsFundAnosIniciais =
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsFunAnosIniciaisUrbanaParcial +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsFunAnosIniciaisUrbanaIntegral +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsFunAnosIniciaisRuralParcial +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsFunAnosIniciaisRuralIntegral +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsFunAnosIniciaisUrbanaNoturna +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsFunAnosIniciaisRuralNoturna;


                    data.despesaCorrenteAnoEnsFundAnosFinais =
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsFunAnosFinaisUrbanaParcial +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsFunAnosFinaisUrbanaIntegral +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsFunAnosFinaisRuralParcial +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsFunAnosFinaisRuralIntegral +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsFunAnosFinaisUrbanaNoturna +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsFunAnosFinaisRuralNoturna;

                    data.despesaCorrenteAnoEnsMedio =
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsMedioUrbanaParcial +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsMedioUrbanaIntegral +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsMedioRuralParcial +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsMedioRuralIntegral +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsMedioUrbanaNoturna +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEnsMedioRuralNoturna;


                    data.despesaCorrenteAnoEja =
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEJAUrbanaParcial +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEJARuralParcial +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEJAUrbanaNoturna +
                      memoriaCalulo.resultadoEtapa31Caq.despesaCorrenteAnoEJARuralNoturna;

                    /*
                                      receitaPotencialEstadual: number;
                                      receitaRealizadaEstadual: number;
                                      receitaPotencialMunicipal: number;
                                      receitaRealizadaMunicipal: number;
                                      despesaCorrenteProjecaoEstadual: number;
                                      despesaCorrenteProjecaoMunicipal: number;
                                      complementacaoMunicipal: number;
                                      complementacaoEstadual: number;
                                      percComplementacaoMunicipal: number;
                                      percComplementacaoEstadual: number;
                  */

                    //console.log(data);

                    return data;
                  }));

              }));

          }));
      }));
  }

  /*
  getBudgetForecastReport(): Observable<BudgetForecastReport> {

    const resultForEnrollmentProjection: EnrollmentProjection = this.sessionService.getItem<EnrollmentProjection>(Functionality.enrollmentProjection.key);
    const resultForCollaborationScheme: CollaborationScheme = this.sessionService.getItem<CollaborationScheme>(Functionality.collaborationScheme.key);
    const filtersLocation: Array<string> = this.utilitiesService.getSelectLocationFilter();
    const filtersFinancialDataType: Array<string> = new Array<string>(`"${FinancialDataTypeEnum.potentialRevenue}"`, `"${FinancialDataTypeEnum.expenseMDE}"`);
    const resultForSelectLocation: SelectLocation = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);
    const financialCurrentYear: number = this.currentYearService.getFinancialCurrentYear();
    const enrollmentCurrentYear: number = this.currentYearService.getEnrollmentCurrentYear();
    const simulationYearInitial: number = _.first(this.utilitiesService.getSimulationYears());
    const budgetForecastReport: BudgetForecastReport = new BudgetForecastReport({
      offersDimensions: new Array<BudgetForecastReportByItem>(),
      enrollmentsBasicEducation: new Array<BudgetForecastReportByItem>(),
      expensesBasicEducation: new Array<BudgetForecastReportByItem>(),
      expenseTotal: new BudgetForecastReportByItem(),
      expensesFederativeEntity: new Array<BudgetForecastReportByItem>(),
      expensesByStage: new Array<BudgetForecastReportByItem>(),
      sourceInformationsExpensesFederativeEntity: new Array<Footnote>(),
      sourceInformationsOfferDimension: new Array<Footnote>(),
      sourceInformationsExpensesBasicEducation: new Array<Footnote>(),
      sourceInformationsExpenseTotal: new Array<Footnote>(),
      sourceInformationsExpensesByStage: new Array<Footnote>()
    });
    const resultForEnrollmentSchool: EnrollmentBySchool = this.sessionService.getItem<EnrollmentBySchool>(Functionality.enrollmentByStageAndSeriesBySchool.key);
    const newRoomsBuildings: NewRoomsBuildings = new NewRoomsBuildings();

    let options: any;

    budgetForecastReport.years = this.utilitiesService.getSimulationYears();
    budgetForecastReport.offerYear = resultForEnrollmentProjection.offerYear;
    const offerYearClass: number = this.currentYearService.getClassCurrentYear();
    const offerYearClassroom: number = this.currentYearService.getClassroomCurrentYear();
    const offerYearTeacher: number = this.currentYearService.getTeacherCurrentYear();
    const offerYearEmployees: number = this.currentYearService.getEmployeeCurrentYear();
    const offerYearFinancial: number = this.currentYearService.getFinancialCurrentYear();
    budgetForecastReport.offerYearFinancial = offerYearFinancial;
    budgetForecastReport.sourceInformationsExpensesFederativeEntity = this.getSourceInformationsExpensesFederativeEntity();
    budgetForecastReport.sourceInformationsOfferDimension = this.getSourceInformationsOfferDimension();
    budgetForecastReport.sourceInformationPQR = this.getSourceInformationPQR();

    let priceCub, demandConstruction, locations, stages,
      classNumberCurrent, classroomNumberCurrent, employeeNumberCurrent, teacherNumberCurrent, auxiliarNumberCurrent, financialsDatas,
      stagesAndSeries, unitsOfMeasurement;

    const observablesCommon: Array<Observable<any>> = new Array<Observable<any>>();
    const observablesCurrentExpense: Array<Observable<any>> = new Array<Observable<any>>();
    const considerQuantityTeacherCareer = false;

    let enrollmentsAndClassesAndClassroomsBySchool: Array<EnrollmentAndClassAndClassroom>;
    let enrollmentsAndClassesAndClassroomsBySchool1: Array<EnrollmentAndClassAndClassroom>;
    let enrollmentsAndClassesAndClassroomsBySchool2: Array<EnrollmentAndClassAndClassroom>;
    let enrollmentsAndClassesAndClassroomsBySchool3: Array<EnrollmentAndClassAndClassroom>;
    let enrollmentsAndClassesAndClassroomsBySchool4: Array<EnrollmentAndClassAndClassroom>;
    let enrollmentsAndClassesAndClassroomsBySchool5: Array<EnrollmentAndClassAndClassroom>;
    const employeeEstimateByRoleTotal: Array<EmployeeEstimateByRole> = this.getEmptyEmployeeEstimateByRole();
    const editMode: boolean = this.sessionService.getItem<boolean>(SimulatorService.simulationEditModeSessionKey);

    options = this.getOptions(offerYearClass, filtersLocation);

    observablesCommon.push(this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/class`, options).pipe(
          map(resultClassNumberCurrent => classNumberCurrent = resultClassNumberCurrent));
      })));

    options = this.getOptions(offerYearClassroom, filtersLocation);

    observablesCommon.push(this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/classroom`, options).pipe(
          map(resultClassroomNumberCurrent => { classroomNumberCurrent = resultClassroomNumberCurrent; }));
      })));

    options = this.getOptions(offerYearEmployees, filtersLocation);

    observablesCommon.push(this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/employees`, options).pipe(
          map(resultEmployeeNumberCurrent => employeeNumberCurrent = resultEmployeeNumberCurrent));
      })));

    options = this.getOptions(offerYearTeacher, filtersLocation);

    observablesCommon.push(this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/teacher`, options).pipe(
          map(resultTeacherNumberCurrent => teacherNumberCurrent = resultTeacherNumberCurrent));
      })));

    observablesCommon.push(this.utilitiesService.getLocations().pipe(
      map(resultLocations => locations = resultLocations)));

    observablesCommon.push(this.utilitiesService.getStages().pipe(
      map(resultStages => stages = resultStages)));

    observablesCommon.push(this.utilitiesService.getStagesAndSeries().pipe(
      map(resultStagesAndSeries => stagesAndSeries = resultStagesAndSeries)));

    observablesCommon.push(this.utilitiesService.getDemandConstruction().pipe(
      map(resultDemandConstruction => demandConstruction = resultDemandConstruction)));

    observablesCommon.push(this.utilitiesService.getCub().pipe(
      map(resultPriceCub => priceCub = resultPriceCub)));

    observablesCommon.push(this.utilitiesService.getUnitOfMeasurement([UnitOfMeasurementEnum.alunoMes, UnitOfMeasurementEnum.m2]).pipe(
      map(resultUnitsOfMeasurement => unitsOfMeasurement = resultUnitsOfMeasurement)));

    observablesCommon.push(this.utilitiesService.getFinancialsDatas(offerYearFinancial, filtersFinancialDataType, filtersLocation, true).pipe(
      map(resultFinancialsDatas => financialsDatas = resultFinancialsDatas)));

    observablesCommon.push(this.getAuxiliarNumber().pipe(
      map(auxiliar => { auxiliarNumberCurrent = auxiliar !== undefined ? auxiliar.total : 0; })));

    if (resultForSelectLocation.selectedCity) {
      enrollmentsAndClassesAndClassroomsBySchool = this.getEnrollmentAndClassAndClassroom(resultForEnrollmentSchool);
    } else if (resultForSelectLocation.selectedState) {
      observablesCommon.push(this.getEnrollmentAndClassAndClassroomStateOrRegion().pipe(
        map(resultEnrollmentAndClassAndClassroom => enrollmentsAndClassesAndClassroomsBySchool = resultEnrollmentAndClassAndClassroom)));
    } else { // Brasil por região
      observablesCommon.push(this.getEnrollmentAndClassAndClassroomStateOrRegion(RegionEnum.norte).pipe(
        map(resultEnrollmentAndClassAndClassroom => enrollmentsAndClassesAndClassroomsBySchool1 = resultEnrollmentAndClassAndClassroom)));

      observablesCommon.push(this.getEnrollmentAndClassAndClassroomStateOrRegion(RegionEnum.nordeste).pipe(
        map(resultEnrollmentAndClassAndClassroom => enrollmentsAndClassesAndClassroomsBySchool2 = resultEnrollmentAndClassAndClassroom)));

      observablesCommon.push(this.getEnrollmentAndClassAndClassroomStateOrRegion(RegionEnum.centroOeste).pipe(
        map(resultEnrollmentAndClassAndClassroom => enrollmentsAndClassesAndClassroomsBySchool3 = resultEnrollmentAndClassAndClassroom)));

      observablesCommon.push(this.getEnrollmentAndClassAndClassroomStateOrRegion(RegionEnum.sudeste).pipe(
        map(resultEnrollmentAndClassAndClassroom => enrollmentsAndClassesAndClassroomsBySchool4 = resultEnrollmentAndClassAndClassroom)));

      observablesCommon.push(this.getEnrollmentAndClassAndClassroomStateOrRegion(RegionEnum.sul).pipe(
        map(resultEnrollmentAndClassAndClassroom => enrollmentsAndClassesAndClassroomsBySchool5 = resultEnrollmentAndClassAndClassroom)));
    }

    return forkJoin(observablesCommon).pipe(
      mergeMap((t) => {

        if (!resultForSelectLocation.selectedCity && !resultForSelectLocation.selectedState) {
          // AGRUPA O RESULTADO EM UM ÚNICO ARRAY
          enrollmentsAndClassesAndClassroomsBySchool = [...enrollmentsAndClassesAndClassroomsBySchool1,
          ...enrollmentsAndClassesAndClassroomsBySchool2,
          ...enrollmentsAndClassesAndClassroomsBySchool3,
          ...enrollmentsAndClassesAndClassroomsBySchool4,
          ...enrollmentsAndClassesAndClassroomsBySchool5];
          // LIMPA OS ARRAYS
          enrollmentsAndClassesAndClassroomsBySchool1 = [];
          enrollmentsAndClassesAndClassroomsBySchool2 = [];
          enrollmentsAndClassesAndClassroomsBySchool3 = [];
          enrollmentsAndClassesAndClassroomsBySchool4 = [];
          enrollmentsAndClassesAndClassroomsBySchool5 = [];
        }

        // const enrollmentsAndClassesAndClassroomsBySchool = this.getEnrollmentAndClassAndClassroom(resultForEnrollmentSchool);
        if (editMode) {
          newRoomsBuildings.newRooms = this.sessionService.getItem<NewRoomsBuildings>(Functionality.newRoomBuilding.key).newRooms;
        } else {
          newRoomsBuildings.newRooms = this.sessionService.getItem<NewRooms>(Functionality.newRoomBuilding.pqrKey);
          this.newRoomBuildingService.setUnitPriceAndReferenceDate(newRoomsBuildings.newRooms, priceCub);
        }
        newRoomsBuildings.locations = locations;

        for (let i = 0; i < enrollmentsAndClassesAndClassroomsBySchool.length; i++) {

          let calculatedClassNumber, calculatedTeacherNumber;
          const enrollmentAndClasseAndClassroomBySchool = enrollmentsAndClassesAndClassroomsBySchool[i];
          const schoolId = enrollmentAndClasseAndClassroomBySchool.school_id;
          const schoolDescription = enrollmentAndClasseAndClassroomBySchool.school_description;
          const stateId = enrollmentAndClasseAndClassroomBySchool.state_id;
          const state_description = enrollmentAndClasseAndClassroomBySchool.state_description;
          const cityId = enrollmentAndClasseAndClassroomBySchool.city_id;
          const city_description = enrollmentAndClasseAndClassroomBySchool.city_description;
          const enrollmentProjection = enrollmentAndClasseAndClassroomBySchool.enrollmentProjection;
          const enrollmentTotal: number = this.getEnrollmentTotalBySchool(enrollmentProjection);
          const enrollmentSchoolCity = this.getEnrollmentSchool(schoolId, enrollmentAndClasseAndClassroomBySchool.enrollmentProjection.enrollmentsProjectionsByLocations);
          const admDependencyId = enrollmentsAndClassesAndClassroomsBySchool[i].adm_dependency_id;
          const budgetForecastReportBySchool: BudgetForecastReport = new BudgetForecastReport({
            stateId: stateId,
            stateDescription: state_description,
            cityId: cityId,
            cityDescription: city_description,
            admDependencyId: admDependencyId,
            expensesByStage: new Array<BudgetForecastReportByItem>(),
            expensesFederativeEntity: new Array<BudgetForecastReportByItem>(),
            offersDimensions: new Array<BudgetForecastReportByItem>(),
            expensesBasicEducation: new Array<BudgetForecastReportByItem>(),
            years: this.utilitiesService.getSimulationYears(),
            offerYear: resultForEnrollmentProjection.offerYear,
            offerYearFinancial: offerYearFinancial,
            sourceInformationsExpensesFederativeEntity: this.getSourceInformationsExpensesFederativeEntity(),
            sourceInformationsOfferDimension: this.getSourceInformationsOfferDimension(),
            sourceInformationPQR: this.getSourceInformationPQR()
          });

          if (enrollmentSchoolCity && enrollmentAndClasseAndClassroomBySchool.hasEnrollment) {
            const percentageTeacherCareer = editMode === true ? undefined : enrollmentAndClasseAndClassroomBySchool.percentageTeacherCareer;

            const calculatedEmployeeEstimate = this.calculateEmployeeEstimateService.calculateEmployeeEstimateByEntity(locations, enrollmentSchoolCity);
            this.setTotalEmployeeByRole(calculatedEmployeeEstimate, employeeEstimateByRoleTotal);

            observablesCurrentExpense.push(this.calculateClassNumberService.calculateClassNumber(locations, stages, enrollmentAndClasseAndClassroomBySchool).pipe(
              mergeMap(resultCalcClassNumber => {
                calculatedClassNumber = resultCalcClassNumber;
                return this.calculateTeacherNumberService.calculateTeacherNumber(calculatedClassNumber, true, percentageTeacherCareer).pipe(
                  mergeMap(resultCalcTeacherNumber => {
                    calculatedTeacherNumber = resultCalcTeacherNumber;
                    return this.calculateCostSharingService.calculateCostSharing(locations, calculatedEmployeeEstimate).pipe(
                      mergeMap(calculateCostSharing => {
                        return this.calculateCostDriveService.calculateCostDrive(enrollmentProjection).pipe(
                          mergeMap(calculateCostDrive => {
                            return this.itemCostService.calculateItemCost(locations, stages, calculatedTeacherNumber, calculatedEmployeeEstimate, calculateCostSharing, calculateCostDrive,
                              enrollmentProjection, false, true, false, true, true, undefined, considerQuantityTeacherCareer,
                              percentageTeacherCareer).pipe(
                                mergeMap(resultItemCost => {
                                  const itemCost = resultItemCost;
                                  this.setBudgetForecastReportBySchool(budgetForecastReportBySchool, stages, locations, itemCost);
                                  const classroomExistingSchool = this.getClassroomExisting(enrollmentProjection);
                                  return this.calculateClassNumberService.calculateClassroomNumber(locations, stages, undefined, classroomExistingSchool,
                                    enrollmentAndClasseAndClassroomBySchool).pipe(
                                      mergeMap(higherDemandClassroom => {
                                        return this.newRoomBuildingService.getDemandClassRoomsItems(locations, demandConstruction, higherDemandClassroom).pipe(
                                          mergeMap(demandClassRoomByYear => {
                                            newRoomsBuildings.newRooms.demandClassRoomByYear = demandClassRoomByYear;
                                            this.newRoomBuildingService.calculateClassroomsDistribution(newRoomsBuildings.newRooms, demandClassRoomByYear, locations);
                                            return this.calculateCapitalExpenseService.calculateCapitalExpense(newRoomsBuildings, undefined, priceCub.preco).pipe(
                                              map(resultCapitalExpense => {
                                                const emptyEnrollmentProjection = this.createProjectionsService.getEmptyEnrollmentProjection(locations, stages);

                                                const enrollment = this.getEnrollmentProjection(enrollmentProjection, emptyEnrollmentProjection);

                                                this.setOfferDimension(budgetForecastReportBySchool, enrollment.enrollmentsProjectionsByLocations, calculatedClassNumber,
                                                  calculatedTeacherNumber, calculatedEmployeeEstimate, classNumberCurrent, classroomNumberCurrent, teacherNumberCurrent,
                                                  employeeNumberCurrent, auxiliarNumberCurrent);

                                                this.setExpensesFederativeEntity(budgetForecastReportBySchool, itemCost, resultForCollaborationScheme, financialsDatas,
                                                  resultForSelectLocation, admDependencyId);

                                                this.setExpensesBasicEducation(budgetForecastReportBySchool, itemCost, resultCapitalExpense);
                                                this.setPercentAndValueOfCapitalExpense(budgetForecastReportBySchool, resultCapitalExpense);

                                                return budgetForecastReportBySchool;
                                              }));
                                          }));
                                      }));
                                }));
                          }));
                      }));
                  }));
              })));
          }
        }

        return forkJoin(observablesCurrentExpense).pipe(
          map(result => {
            const budgetForecastReportTotal = this.getbudgetForecastTotal(result, stages, financialsDatas);
            budgetForecastReportTotal.employeesEstimateByRole = employeeEstimateByRoleTotal;
            return budgetForecastReportTotal;
          }));

        // return budgetForecastReport;
      }));


  }
  */

  getAuxiliarNumber(): Observable<any> {

    let filtersLocation: Array<string> = new Array<string>();
    const teacherCurrentYear: number = this.currentYearService.getTeacherCurrentYear();

    filtersLocation = this.utilitiesService.getSelectLocationFilter();

    let filters: Array<string> = new Array<string>(
      `min_year:"${teacherCurrentYear}"`,
      `max_year:"${teacherCurrentYear}"`
    );

    filters.push(this.utilitiesService.getAdmDependencyFilter());

    filters = filters.concat(filtersLocation);

    const options: any = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([['filter', filters.join(',')]]));

    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/auxiliar`, options).pipe(
          map(diagnostic => _.first(diagnostic)));
      }));
  }

  downloadCsv(budgetForecast: BudgetForecastReport, datas: Number): void {
    const header: Array<CsvHeader> = new Array<CsvHeader>();
    const data: Array<any> = new Array<any>();
    let csv = new Csv();

    const yearLabel: string = '2023';

    // Offer Dimensions
    if (datas === DatasCsvEnum.OfferDimensions) {
      header.push(new CsvHeader({ key: 'offerDimensions', label: 'Dimensão da oferta' }));
      header.push(new CsvHeader({ key: `${budgetForecast.offerYear}`, label: `${budgetForecast.offerYear} (Atual)` }));

      for (const year of budgetForecast.years) {
        header.push(new CsvHeader({ key: `${year}`, label: `${yearLabel}` }));
      }

      header.push(new CsvHeader({ key: 'variation', label: `Variação` }));

      for (const offerDimension of budgetForecast.offersDimensions) {
        const budgetForecastData: any = { offerDimensions: offerDimension.description };
        budgetForecastData[`${budgetForecast.offerYear}`] = offerDimension.currentOffer;

        for (const budgetForecastReportByYear of offerDimension.budgetsForecastReportByYears) {
          budgetForecastData[`${budgetForecastReportByYear.year}`] = budgetForecastReportByYear.value;
        }

        budgetForecastData['variation'] = offerDimension.variation;

        data.push(budgetForecastData);
      }

      csv = new Csv({ header: header, data: data, name: 'Previsão orçamentária - Dimensão da oferta' });
      this.csvService.download(csv);

    } else if (datas === DatasCsvEnum.ExpensesBasicEducation) {
      header.push(new CsvHeader({ key: 'expenseBasicEducation', label: 'Despesa de capital da rede pública' }));

      for (const year of budgetForecast.years) {
        header.push(new CsvHeader({ key: `${year}`, label: `${yearLabel}` }));
      }

      // header.push(new CsvHeader({ key: 'variation', label: 'Variação' }));

      for (const expenseBasicEducation of budgetForecast.expensesBasicEducation) {
        const budgetForecastData: any = { expenseBasicEducation: expenseBasicEducation.description };

        for (const budgetForecastReportByYear of expenseBasicEducation.budgetsForecastReportByYears) {
          budgetForecastData[`${budgetForecastReportByYear.year}`] = this.utilitiesService.roundNumber(budgetForecastReportByYear.value, 0);
        }
        // budgetForecastData['variation'] = expenseBasicEducation.variation;
        data.push(budgetForecastData);
      }

      csv = new Csv({ header: header, data: data, name: 'Previsão orçamentária - Despesas por categoria econômica' });
      this.csvService.download(csv);

    } else if (datas === DatasCsvEnum.ExpensesFederativeEntity) {

      const offerYearFinancial: number = budgetForecast.offerYearFinancial;
      header.push(new CsvHeader({ key: 'expenseFederativeEntity', label: 'Esfera administrativa' }));
      header.push(new CsvHeader({ key: 'potentialRevenue', label: `Potencial de receitas(${offerYearFinancial})` }));
      header.push(new CsvHeader({ key: 'expensesMDE', label: `Despesas com MDE(${offerYearFinancial})` }));

      for (const year of budgetForecast.years) {
        header.push(new CsvHeader({ key: `${year}`, label: `${yearLabel}` }));
      }

      header.push(new CsvHeader({ key: 'complementation', label: `Complementação(R$)` }));
      header.push(new CsvHeader({ key: 'variation', label: 'Complementação(%)' }));

      for (const expenseFederativeEntity of budgetForecast.expensesFederativeEntity) {
        const budgetForecastData: any = {
          expenseFederativeEntity: expenseFederativeEntity.description,
          potentialRevenue: this.utilitiesService.roundNumber(expenseFederativeEntity.potentialRevenue, 0),
          expensesMDE: this.utilitiesService.roundNumber(expenseFederativeEntity.expensesMDE, 0)
        };

        for (const budgetForecastReportByYear of expenseFederativeEntity.budgetsForecastReportByYears) {
          budgetForecastData[`${budgetForecastReportByYear.year}`] = this.utilitiesService.roundNumber(budgetForecastReportByYear.value, 0);
        }

        budgetForecastData['complementation'] = expenseFederativeEntity.complementation;
        budgetForecastData['variation'] = expenseFederativeEntity.variation;

        data.push(budgetForecastData);
      }

      csv = new Csv({ header: header, data: data, name: 'Previsão orçamentária - Despesas por esfera administrativa' });
      this.csvService.download(csv);
    } else if (datas === DatasCsvEnum.ExpenseTotal) {

      header.push(new CsvHeader({ key: 'expenseTotal', label: 'Despesa total' }));

      for (const year of budgetForecast.years) {
        header.push(new CsvHeader({ key: `${year}`, label: `${yearLabel}` }));
      }

      // header.push(new CsvHeader({ key: 'variation', label: 'Variação' }));

      // for (const expenseTotal of budgetForecast.expensesBasicEducation) {
      const budgetForecastData: any = { expenseTotal: 'Despesas correntes + capital' };

      for (const budgetForecastReportByYear of budgetForecast.expenseTotal.budgetsForecastReportByYears) {
        budgetForecastData[`${budgetForecastReportByYear.year}`] = this.utilitiesService.roundNumber(budgetForecastReportByYear.value, 0);
      }

      // budgetForecastData['variation'] = budgetForecast.expenseTotal.variation;

      data.push(budgetForecastData);
      // }

      csv = new Csv({ header: header, data: data, name: 'Previsão orçamentária - Despesas por categoria econômica' });
      this.csvService.download(csv);

    } else if (datas === DatasCsvEnum.ExpenseByStage) {

      header.push(new CsvHeader({ key: 'expensesByStage', label: 'Despesas Correntes por Etapa / Modalidade' }));

      header.push(new CsvHeader({ key: 'enrollment', label: `Projeção das Matrículas` }));

      for (const year of budgetForecast.years) {
        header.push(new CsvHeader({ key: `${year}`, label: `${yearLabel}` }));
      }

      header.push(new CsvHeader({ key: 'variation', label: `Variação` }));

      for (const expense of budgetForecast.expensesByStage) {
        const budgetForecastData: any = { expensesByStage: expense.description, enrollment: expense.totalEnrollment };
        budgetForecastData[`${budgetForecast.offerYear}`] = expense.currentOffer;

        for (const budgetForecastReportByYear of expense.budgetsForecastReportByYears) {
          budgetForecastData[`${budgetForecastReportByYear.year}`] = budgetForecastReportByYear.value;
        }

        budgetForecastData['variation'] = expense.variation;

        data.push(budgetForecastData);
      }
      csv = new Csv({ header: header, data: data, name: 'Previsão orçamentária - Etapa / Modalidade' });
      this.csvService.download(csv);

    }

  }

  getEnrollmentTotalBySchool(enrollmentProjection: EnrollmentProjection): number {

    let enrollmentsTotal: number = 0;

    for (let i = 0; i < enrollmentProjection.enrollmentsProjectionsByLocations.length; i++) {
      const enrollmentProjectionByLocation = enrollmentProjection.enrollmentsProjectionsByLocations[i];
      for (let j = 0; j < enrollmentProjectionByLocation.totalsEnrollmentProjection.length; j++) {
        enrollmentsTotal += enrollmentProjectionByLocation.totalsEnrollmentProjection[j].quantity;
      }

    }
    return enrollmentsTotal;
  }

  isSimulationCity(): boolean {

    const resultForSelectLocation: SelectLocation = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);
    if (resultForSelectLocation.selectedCity) {
      return true;
    }
    return false;
  }

  private getOptions(offerYear: number, filtersLocation: Array<string>): String {

    let filtersYear: Array<string>;
    let filters: Array<string>;

    filtersYear = new Array<string>(
      `min_year:"${offerYear}"`,
      `max_year:"${offerYear}"`
    );

    filtersYear.push(this.utilitiesService.getAdmDependencyFilter());

    filters = new Array<string>();
    filters = filters.concat(filtersYear);
    filters = filters.concat(filtersLocation);
    filters.push('period_not:[99]');

    return this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([
      ['filter', filters.join(',')]
    ]));
  }

  private getEnrollmentSchool(school_id: string, enrollmentProjectionByLocation: Array<EnrollmentProjectionByLocation>): SchoolByCity {

    const schoolsByCity: SchoolByCity = new SchoolByCity({ schools: new Array<School>() });

    for (let i = 0; i < enrollmentProjectionByLocation.length; i++) {
      schoolsByCity.schools.push(new School({
        id: school_id,
        locationId: enrollmentProjectionByLocation[i].id,
        enrollmentQuantity: _.first(enrollmentProjectionByLocation[i].totalsEnrollmentProjection).quantity
      }));
    }
    return schoolsByCity;
  }

  private getClassroomExisting(enrollmentProjection: EnrollmentProjection): ClassroomExistingByCity {

    const classroomsExistingBySchool: Array<ClassroomExistingByCity> = new Array<ClassroomExistingByCity>();
    const classroomExisting: ClassroomExistingByCity = new ClassroomExistingByCity({
      classroomExistingByLocations:
        new Array<ClassroomExistingByLocation>()
    });

    for (let i = 0; i < enrollmentProjection.enrollmentsProjectionsByLocations.length; i++) {
      const enrollmentProjectionByLocation = enrollmentProjection.enrollmentsProjectionsByLocations[i];

      classroomExisting.classroomExistingByLocations.push(new ClassroomExistingByLocation({
        location:
          new Location({ id: enrollmentProjectionByLocation.id, description: enrollmentProjectionByLocation.description }),
        quantityClassroomExisting: enrollmentProjectionByLocation.totalExistingClassrooms
      }));

    }
    return classroomExisting;
  }

  private setOfferDimension(budgetForecastReport: BudgetForecastReport, enrollmentProjection: Array<EnrollmentProjectionByLocation>,
    calculatedClassNumber: ClassNumber, calculatedTeacherNumber: Array<TeacherNumberCalc>, calculatedEmployeeEstimate: Array<EmployeeEstimate>,
    classNumberCurrent: Array<any>, classroomNumberCurrent: Array<any>, teacherNumberCurrent: Array<any>, employeeNumberCurrent: Array<any>, auxiliarNumberCurrent: number): void {
    const budgetsForecastReportByYearsEnrollments: Array<BudgetForecastReportByYear> = new Array<BudgetForecastReportByYear>();
    const budgetsForecastReportByYearsClassesNumber: Array<BudgetForecastReportByYear> = new Array<BudgetForecastReportByYear>();
    const budgetsForecastReportByYearsTeachersNumber: Array<BudgetForecastReportByYear> = new Array<BudgetForecastReportByYear>();
    const budgetsForecastReportByYearsEmployeeEstimate: Array<BudgetForecastReportByYear> = new Array<BudgetForecastReportByYear>();
    const budgetsForecastReportByYearsAuxiliarEstimate: Array<BudgetForecastReportByYear> = new Array<BudgetForecastReportByYear>();
    const budgetsForecastReportByYearsClassroomsDemands: Array<BudgetForecastReportByYear> = new Array<BudgetForecastReportByYear>();
    const stagesEnrollments: Array<StageEnrollments> = new Array<StageEnrollments>();
    let currentOfferEnrollment: number = 0;

    for (let i = 0; i < enrollmentProjection.length; i++) {

      for (let j = 0; j < enrollmentProjection[i].stagesEnrollments.length; j++) {

        const stage = enrollmentProjection[i].stagesEnrollments[j];
        let stageEnrollment = _.find(stagesEnrollments, sE => sE.id === stage.id);

        if (stageEnrollment === undefined) {
          stagesEnrollments.push(new StageEnrollments({
            id: stage.id,
            description: stage.description,
            totalEnrollments: new Array<Enrollment>()
          }));
          stageEnrollment = _.find(stagesEnrollments, sE => sE.id === stage.id);
        }

        currentOfferEnrollment += stage.totalCurrentOffers;

        for (let k = 0; k < stage.totalEnrollments.length; k++) {

          const totalEnrollment = _.find(stageEnrollment.totalEnrollments, tE => tE.year === stage.totalEnrollments[k].year);

          if (totalEnrollment) {
            totalEnrollment.quantity += stage.totalEnrollments[k].quantity;
            totalEnrollment.quantityNocturnal += stage.totalEnrollments[k].quantityNocturnal;
          } else {
            stageEnrollment.totalEnrollments.push(new Enrollment({
              year: stage.totalEnrollments[k].year,
              quantity: stage.totalEnrollments[k].quantity,
              quantityNocturnal: stage.totalEnrollments[k].quantityNocturnal
            }));
          }

          if (!budgetsForecastReportByYearsEnrollments[k]) {
            budgetsForecastReportByYearsEnrollments.push(new BudgetForecastReportByYear({
              year: stage.totalEnrollments[k].year,
              value: stage.totalEnrollments[k].quantity
            }));
          } else {
            budgetsForecastReportByYearsEnrollments[k].value += stage.totalEnrollments[k].quantity;
          }
        }
      }
    }

    for (let i = 0; i < calculatedClassNumber.classesNumberByLocations.length; i++) {
      const classesNumberByStages = calculatedClassNumber.classesNumberByLocations[i].classesNumberByStages;
      for (let j = 0; j < classesNumberByStages.length; j++) {
        const classesNumberByYears = classesNumberByStages[j].classesNumberByYear;
        for (let k = 0; k < classesNumberByYears.length; k++) {
          if (!budgetsForecastReportByYearsClassesNumber[k]) {
            budgetsForecastReportByYearsClassesNumber.push(new BudgetForecastReportByYear({
              year: classesNumberByYears[k].year,
              value: classesNumberByYears[k].classNumberDaytimePartial + classesNumberByYears[k].classNumberIntegral + classesNumberByYears[k].classNumberNocturnal
            }));

            budgetsForecastReportByYearsClassroomsDemands.push(new BudgetForecastReportByYear({
              year: classesNumberByYears[k].year,
              value: classesNumberByYears[k].classroomDemand
            }));
          } else {
            budgetsForecastReportByYearsClassesNumber[k].value += classesNumberByYears[k].classNumberDaytimePartial + classesNumberByYears[k].classNumberIntegral +
              classesNumberByYears[k].classNumberNocturnal;

            budgetsForecastReportByYearsClassroomsDemands[k].value += classesNumberByYears[k].classroomDemand;
          }
        }
      }
    }

    for (let i = 0; i < budgetsForecastReportByYearsClassesNumber.length; i++) {
      if (budgetsForecastReportByYearsClassesNumber[i].value > 0 && budgetsForecastReportByYearsClassesNumber[i].value < 1) {
        budgetsForecastReportByYearsClassesNumber[i].value = 1;
      }
      if (budgetsForecastReportByYearsClassroomsDemands[i].value > 0 && budgetsForecastReportByYearsClassroomsDemands[i].value < 1) {
        budgetsForecastReportByYearsClassroomsDemands[i].value = 1;
      }
    }

    for (let i = 0; i < calculatedTeacherNumber.length; i++) {
      const teachersNumberByLocations = calculatedTeacherNumber[i].teachersNumberByLocationsCalc;
      for (let j = 0; j < teachersNumberByLocations.length; j++) {
        const teachersNumberByStages = teachersNumberByLocations[j].teachersNumberByStagesCalc;
        for (let k = 0; k < teachersNumberByStages.length; k++) {
          const teachersNumberByYears = teachersNumberByStages[k].careerLevelsByYear;
          for (let l = 0; l < teachersNumberByYears.length; l++) {
            if (!budgetsForecastReportByYearsTeachersNumber[l]) {
              budgetsForecastReportByYearsTeachersNumber.push(new BudgetForecastReportByYear({
                year: teachersNumberByYears[l].year,
                value: teachersNumberByYears[l].teacherNumberShiftIntegral + teachersNumberByYears[l].teacherNumberShiftPartial
              }));
            } else {
              budgetsForecastReportByYearsTeachersNumber[l].value += teachersNumberByYears[l].teacherNumberShiftIntegral + teachersNumberByYears[l].teacherNumberShiftPartial;
            }
          }
        }
      }
    }

    for (let i = 0; i < calculatedTeacherNumber.length; i++) {
      const teachersNumberByLocations = calculatedTeacherNumber[i].teachersNumberByLocationsCalc;
      for (let j = 0; j < teachersNumberByLocations.length; j++) {
        const teachersNumberByStages = teachersNumberByLocations[j].teachersNumberByStagesCalc;
        for (let k = 0; k < teachersNumberByStages.length; k++) {
          const teachersNumberByYears = teachersNumberByStages[k].careerLevelsByYear;
          for (let l = 0; l < teachersNumberByYears.length; l++) {
            if (!budgetsForecastReportByYearsAuxiliarEstimate[l]) {
              budgetsForecastReportByYearsAuxiliarEstimate.push(new BudgetForecastReportByYear({
                year: teachersNumberByYears[l].year,
                value: null
              }));
            } else {
              budgetsForecastReportByYearsAuxiliarEstimate[l].value = null;
            }
          }
        }
      }
    }

    for (let i = 0; i < calculatedEmployeeEstimate.length; i++) {
      const employeesEstimateByLocations = calculatedEmployeeEstimate[i].employeesEstimateByLocations;
      for (let j = 0; j < employeesEstimateByLocations.length; j++) {
        const employeesEstimateByRoles = employeesEstimateByLocations[j].employeesEstimateByRoles;
        for (let k = 0; k < employeesEstimateByRoles.length; k++) {
          const employeesEstimateByYears = employeesEstimateByRoles[k].employeesEstimatesByYear;
          for (let l = 0; l < employeesEstimateByYears.length; l++) {
            if (!budgetsForecastReportByYearsEmployeeEstimate[l]) {
              budgetsForecastReportByYearsEmployeeEstimate.push(new BudgetForecastReportByYear({
                year: employeesEstimateByYears[l].year,
                value: employeesEstimateByYears[l].quantity
              }));
            } else {
              budgetsForecastReportByYearsEmployeeEstimate[l].value += employeesEstimateByYears[l].quantity;
            }
          }
        }
      }
    }

    budgetForecastReport.offersDimensions.push(new BudgetForecastReportByItem({
      description: 'Número de matrículas',
      currentOffer: currentOfferEnrollment,
      budgetsForecastReportByYears: budgetsForecastReportByYearsEnrollments,
      stagesEnrollments: stagesEnrollments,
      variation: (budgetsForecastReportByYearsEnrollments[budgetsForecastReportByYearsEnrollments.length - 1].value / currentOfferEnrollment - 1) * 100
    }));

    const classNumber: number = classNumberCurrent.length > 0 ? _.first(classNumberCurrent).total : 0;
    budgetForecastReport.offersDimensions.push(new BudgetForecastReportByItem({
      description: 'Número de turmas',
      currentOffer: classNumber,
      budgetsForecastReportByYears: budgetsForecastReportByYearsClassesNumber,
      variation: classNumber > 1 ? (budgetsForecastReportByYearsClassesNumber[budgetsForecastReportByYearsClassesNumber.length - 1].value / classNumber - 1) * 100 : 0
    }));

    const classroomNumber: number = classroomNumberCurrent.length > 0 ? _.first(classroomNumberCurrent).total : 0;
    budgetForecastReport.offersDimensions.push(new BudgetForecastReportByItem({
      description: 'Número de salas',
      currentOffer: classroomNumber,
      budgetsForecastReportByYears: budgetsForecastReportByYearsClassroomsDemands,
      variation: classroomNumber > 1 ? (budgetsForecastReportByYearsClassroomsDemands[budgetsForecastReportByYearsClassroomsDemands.length - 1].value / classroomNumber - 1) * 100 : 0
    }));

    const teacherNumber: number = teacherNumberCurrent.length > 0 ? _.first(teacherNumberCurrent).total : 0;
    budgetForecastReport.offersDimensions.push(new BudgetForecastReportByItem({
      description: 'Número de professores',
      currentOffer: teacherNumber,
      noteNumber: '(2)',
      budgetsForecastReportByYears: budgetsForecastReportByYearsTeachersNumber,
      variation: teacherNumber > 1 ? (budgetsForecastReportByYearsTeachersNumber[budgetsForecastReportByYearsTeachersNumber.length - 1].value / teacherNumber - 1) * 100 : 0
    }));

    const employeeNumber: number = employeeNumberCurrent.length > 0 ? _.first(employeeNumberCurrent).total : 0;
    budgetForecastReport.offersDimensions.push(new BudgetForecastReportByItem({
      description: 'Número de funcionários',
      currentOffer: employeeNumber,
      budgetsForecastReportByYears: budgetsForecastReportByYearsEmployeeEstimate,
      variation: null
    }));

    /*budgetForecastReport.offersDimensions.push(new BudgetForecastReportByItem({
      description: 'Número de auxiliares',
      currentOffer: auxiliarNumberCurrent,
      budgetsForecastReportByYears: budgetsForecastReportByYearsAuxiliarEstimate,
      variation: null
    }));*/

    // budgetForecastReport.offersDimensions.push(new BudgetForecastReportByItem({
    //  description: 'Número de funcionários',
    //  currentOffer: _.first(employeeNumberCurrent).total,
    //  noteNumber: '(1)',
    //  budgetsForecastReportByYears: budgetsForecastReportByYearsEmployeeEstimate,
    //  variation: (budgetsForecastReportByYearsEmployeeEstimate[budgetsForecastReportByYearsEmployeeEstimate.length - 1].value / _.first(employeeNumberCurrent).total - 1) * 100
    // }));
  }

  private setExpensesBasicEducation(budgetForecastReport: BudgetForecastReport, studentsCost: Array<StudentCost>, capitalExpense: CapitalExpense): void {

    const budgetsForecastReportByYearsCorrenteExpenses: Array<BudgetForecastReportByYear> = new Array<BudgetForecastReportByYear>();
    const budgetsForecastReportByYearsCapitalExpenses: Array<BudgetForecastReportByYear> = new Array<BudgetForecastReportByYear>();
    const budgetsForecastReportByYearsTotal: Array<BudgetForecastReportByYear> = new Array<BudgetForecastReportByYear>();
    const studentsCostByStages: Array<StudentCostByStage> = new Array<StudentCostByStage>();
    const years = this.utilitiesService.getSimulationYears();

    years.map(year => {
      budgetsForecastReportByYearsCorrenteExpenses.push(new BudgetForecastReportByYear({
        year: year,
        value: 0
      }));

      budgetsForecastReportByYearsCapitalExpenses.push(new BudgetForecastReportByYear({
        year: year,
        value: 0
      }));
    });

    for (let i = 0; i < studentsCost.length; i++) {
      const studentCost = studentsCost[i];
      for (let j = 0; j < studentCost.studentsCostByLocations.length; j++) {
        const studentCostByLocation = studentCost.studentsCostByLocations[j];
        for (let k = 0; k < studentCostByLocation.studentsCostByStages.length; k++) {
          studentsCostByStages.push(studentCostByLocation.studentsCostByStages[k]);
        }
      }
    }

    // expensesBasicEducation
    for (let i = 0; i < studentsCostByStages.length; i++) {
      const studentCostByStage = studentsCostByStages[i];

      for (let j = 0; j < studentCostByStage.studentsCostBySubitems.length; j++) {

        const studentCostBySubitem = studentCostByStage.studentsCostBySubitems[j];
        for (let k = 0; k < studentCostBySubitem.studentsCostByYear.length; k++) {
          const studentCostByYear = studentCostBySubitem.studentsCostByYear[k];
          if (studentCostBySubitem.expenseType_id === ExpenseTypeEnum.Corrente) {
            budgetsForecastReportByYearsCorrenteExpenses[k].value += studentCostByYear.costShiftIntegral + studentCostByYear.costShiftPartial;
          }
        }
      }
    }

    for (let i = 0; i < capitalExpense.capitalExpenseByYears.length; i++) {
      budgetsForecastReportByYearsCapitalExpenses[i].value = capitalExpense.capitalExpenseByYears[i].value;
    }

    // total of expensesBasicEducation
    for (let i = 0; i < years.length; i++) {

      const correnteExpenses = budgetsForecastReportByYearsCorrenteExpenses[i] ? budgetsForecastReportByYearsCorrenteExpenses[i].value : 0;
      const capitalExpenses = budgetsForecastReportByYearsCapitalExpenses[i] ? budgetsForecastReportByYearsCapitalExpenses[i].value : 0;
      budgetsForecastReportByYearsTotal.push(new BudgetForecastReportByYear({
        year: years[i],
        value: correnteExpenses + capitalExpenses,
      }));
    }

    // budgetForecastReport.expensesBasicEducation.push(new BudgetForecastReportByItem({
    //   description: 'Despesas correntes (MDE)',
    //   budgetsForecastReportByYears: budgetsForecastReportByYearsCorrenteExpenses
    // }));

    budgetForecastReport.expensesBasicEducation.push(new BudgetForecastReportByItem({
      description: 'Despesas de capital',
      noteNumber: '(8)',
      budgetsForecastReportByYears: budgetsForecastReportByYearsCapitalExpenses,
      variation: (budgetsForecastReportByYearsCapitalExpenses[budgetsForecastReportByYearsCapitalExpenses.length - 1].value / budgetsForecastReportByYearsCapitalExpenses[0].value - 1) * 100
    }));

    // budgetForecastReport.expensesBasicEducation.push(new BudgetForecastReportByItem({
    //  description: 'TOTAL DESPESAS (correntes + capital)',
    //  budgetsForecastReportByYears: budgetsForecastReportByYearsTotal
    // }));

    budgetForecastReport.expenseTotal = new BudgetForecastReportByItem({
      description: 'Despesas correntes + capital',
      budgetsForecastReportByYears: budgetsForecastReportByYearsTotal,
      variation: (budgetsForecastReportByYearsTotal[budgetsForecastReportByYearsTotal.length - 1].value / budgetsForecastReportByYearsTotal[0].value - 1) * 100
    });

    budgetForecastReport.sourceInformationsExpenseTotal = this.getSourceInformationsExpenseTotal();

  }

  private setExpensesFederativeEntity(budgetForecastReport: BudgetForecastReport, studentsCost: Array<StudentCost>, resultForCollaborationScheme: CollaborationScheme,
    financialsDatas: Array<FinancialData>, resultForSelectLocation: SelectLocation, admDependencyId: number): void {

    const budgetsForecastReportByYearsExpensesFederalEntity: Array<BudgetForecastReportByYear> = new Array<BudgetForecastReportByYear>();
    const budgetsForecastReportByYearsExpensesStateEntity: Array<BudgetForecastReportByYear> = new Array<BudgetForecastReportByYear>();
    const budgetsForecastReportByYearsExpensesMunicipalEntity: Array<BudgetForecastReportByYear> = new Array<BudgetForecastReportByYear>();
    const budgetsForecastReportByYearsTotal: Array<BudgetForecastReportByYear> = new Array<BudgetForecastReportByYear>();
    const studentsCostByStages: Array<StudentCostByStage> = new Array<StudentCostByStage>();
    const years = this.utilitiesService.getSimulationYears();

    years.map(year => {
      budgetsForecastReportByYearsExpensesFederalEntity.push(new BudgetForecastReportByYear({
        year: year,
        value: 0,
        percentValue: undefined
      }));

      budgetsForecastReportByYearsExpensesStateEntity.push(new BudgetForecastReportByYear({
        year: year,
        value: 0,
        percentValue: undefined
      }));

      budgetsForecastReportByYearsExpensesMunicipalEntity.push(new BudgetForecastReportByYear({
        year: year,
        value: 0,
        percentValue: undefined
      }));
    });

    for (let i = 0; i < studentsCost.length; i++) {
      const studentCost = studentsCost[i];
      for (let j = 0; j < studentCost.studentsCostByLocations.length; j++) {
        const studentCostByLocation = studentCost.studentsCostByLocations[j];
        for (let k = 0; k < studentCostByLocation.studentsCostByStages.length; k++) {
          studentsCostByStages.push(studentCostByLocation.studentsCostByStages[k]);
        }
      }
    }

    // expensesFederativeEntity

    for (let i = 0; i < studentsCostByStages.length; i++) {

      const studentCostByStage = studentsCostByStages[i];

      for (let j = 0; j < studentCostByStage.studentsCostBySubitems.length; j++) {

        const studentCostBySubitem = studentCostByStage.studentsCostBySubitems[j];

        for (let k = 0; k < studentCostBySubitem.studentsCostByYear.length; k++) {

          const studentCostByYear = studentCostBySubitem.studentsCostByYear[k];
          const costTotal = studentCostByYear.costShiftIntegral + studentCostByYear.costShiftPartial;

          if (admDependencyId === AdmDependencyEnum.Federal) {
            budgetsForecastReportByYearsExpensesFederalEntity[k].value += costTotal;
          } else if (admDependencyId === AdmDependencyEnum.State) {
            budgetsForecastReportByYearsExpensesStateEntity[k].value += costTotal;
          } else if (admDependencyId === AdmDependencyEnum.Municipal) {
            budgetsForecastReportByYearsExpensesMunicipalEntity[k].value += costTotal;
          }
        }
      }
    }

    /*for (let i = 0; i < resultForCollaborationScheme.collaborationSchemeByStage.length; i++) {

      const collaborationSchemeByStage = resultForCollaborationScheme.collaborationSchemeByStage[i];

      for (let j = 0; j < studentsCostByStages.length; j++) {

        const studentCostByStage = studentsCostByStages[j];

        if (collaborationSchemeByStage.id === studentsCostByStages[j].id) {

          for (let k = 0; k < studentCostByStage.studentsCostBySubitems.length; k++) {

            const studentCostBySubitem = studentCostByStage.studentsCostBySubitems[k];

            for (let l = 0; l < studentCostBySubitem.studentsCostByYear.length; l++) {

              const studentCostByYear = studentCostBySubitem.studentsCostByYear[l];
              const costTotal = studentCostByYear.costShiftIntegral + studentCostByYear.costShiftPartial;

              budgetsForecastReportByYearsExpensesFederalEntity[l].value += costTotal * (collaborationSchemeByStage.federalPercentage / 100);
              budgetsForecastReportByYearsExpensesStateEntity[l].value += costTotal * (collaborationSchemeByStage.statePercentage / 100);
              budgetsForecastReportByYearsExpensesMunicipalEntity[l].value += costTotal * (collaborationSchemeByStage.municipalPercentage / 100);
            }
          }
        }
      }
    }*/

    // total of expensesFederativeEntity
    for (let i = 0; i < years.length; i++) {
      budgetsForecastReportByYearsTotal.push(new BudgetForecastReportByYear({
        year: years[i],
        value: budgetsForecastReportByYearsExpensesFederalEntity[i].value + budgetsForecastReportByYearsExpensesStateEntity[i].value + budgetsForecastReportByYearsExpensesMunicipalEntity[i].value,
      }));
    }

    const potentialRevenueState: number = resultForSelectLocation.selectedCity ? undefined :
      this.getFinancialDatasByFederativeEntity(SphereAdmEnum.state, FinancialDataTypeEnum.potentialRevenue, financialsDatas);
    const potentialRevenueMunicipal: number = this.getFinancialDatasByFederativeEntity(SphereAdmEnum.municipal, FinancialDataTypeEnum.potentialRevenue, financialsDatas);
    const expensesMDEState: number = resultForSelectLocation.selectedCity ? undefined :
      this.getFinancialDatasByFederativeEntity(SphereAdmEnum.state, FinancialDataTypeEnum.expenseMDE, financialsDatas);
    const expensesMDEMunicipal: number = this.getFinancialDatasByFederativeEntity(SphereAdmEnum.municipal, FinancialDataTypeEnum.expenseMDE, financialsDatas);

    /*budgetForecastReport.expensesFederativeEntity.push(new BudgetForecastReportByItem({
      idItem: AdmDependencyEnum.Federal,
      description: 'Governo Federal',
      budgetsForecastReportByYears: budgetsForecastReportByYearsExpensesFederalEntity
    }));*/

    budgetForecastReport.expensesFederativeEntity.push(new BudgetForecastReportByItem({
      idItem: AdmDependencyEnum.State,
      description: 'Governo Estadual',
      potentialRevenue: potentialRevenueState,
      expensesMDE: expensesMDEState,
      budgetsForecastReportByYears: budgetsForecastReportByYearsExpensesStateEntity,
      variation: (budgetsForecastReportByYearsExpensesStateEntity[budgetsForecastReportByYearsExpensesStateEntity.length - 1].value / potentialRevenueState - 1) * 100
    }));

    budgetForecastReport.expensesFederativeEntity.push(new BudgetForecastReportByItem({
      idItem: AdmDependencyEnum.Municipal,
      description: 'Governo Municipal',
      potentialRevenue: potentialRevenueMunicipal,
      expensesMDE: expensesMDEMunicipal,
      budgetsForecastReportByYears: budgetsForecastReportByYearsExpensesMunicipalEntity,
      variation: (budgetsForecastReportByYearsExpensesMunicipalEntity[budgetsForecastReportByYearsExpensesMunicipalEntity.length - 1].value / potentialRevenueMunicipal - 1) * 100
    }));

    for (let i = 0; i < budgetForecastReport.expensesFederativeEntity.length; i++) {
      const federativeEntity = budgetForecastReport.expensesFederativeEntity[i];

      for (let j = 0; j < federativeEntity.budgetsForecastReportByYears.length; j++) {
        federativeEntity.budgetsForecastReportByYears[j].percentValue = ((federativeEntity.budgetsForecastReportByYears[j].value / budgetsForecastReportByYearsTotal[j].value)
          * 100);
      }
    }

    let totalPotencialRevenue = 0;
    if (potentialRevenueState) {
      totalPotencialRevenue += potentialRevenueState;
    }

    if (potentialRevenueMunicipal) {
      totalPotencialRevenue += potentialRevenueMunicipal;
    }

    let totalExpensesMDE = 0;
    if (expensesMDEState) {
      totalExpensesMDE += expensesMDEState;
    }
    if (expensesMDEMunicipal) {
      totalExpensesMDE += expensesMDEMunicipal;
    }

    budgetForecastReport.expensesFederativeEntity.push(new BudgetForecastReportByItem({
      description: 'TOTAL',
      potentialRevenue: totalPotencialRevenue,
      expensesMDE: totalExpensesMDE,
      budgetsForecastReportByYears: budgetsForecastReportByYearsTotal
    }));

  }

  private setPercentAndValueOfCapitalExpense(budgetForecastReport: BudgetForecastReport, capitalExpense: CapitalExpense): void {

    const totalExpense: number = capitalExpense.totalExpenseWithInfrastructure + capitalExpense.totalExpenseWithNewroom;

    budgetForecastReport.totalExpenseWithInfrastructure = capitalExpense.totalExpenseWithInfrastructure;
    budgetForecastReport.totalExpenseWithNewroom = capitalExpense.totalExpenseWithNewroom;
    budgetForecastReport.percentExpenseWithInfrastructure = totalExpense > 0 ? (capitalExpense.totalExpenseWithInfrastructure / totalExpense) * 100 : 0;
    budgetForecastReport.percentExpenseWithNewroom = totalExpense > 0 ? (capitalExpense.totalExpenseWithNewroom / totalExpense) * 100 : 0;
    budgetForecastReport.sourceInformationsExpensesBasicEducation = this.getSourceInformationsExpensesBasicEducation(budgetForecastReport);
  }

  private setOfferDimensionEnrollment(budgetForecastReportByItemSchool: Array<BudgetForecastReportByItem>, budgetForecastReportByItem: Array<BudgetForecastReportByItem>): void {

    const resultForviewenrollmentbystageandseries = this.sessionService.getItem<ViewEnrollmentByStageSeries>(Functionality.viewEnrollmentByStageAndSeries.key);
    const simulationType: SimulationType = this.sessionService.getItem<SimulationType>(UtilitiesService.simulationTypeSessionKey);
    let totalEnrollmentCurrentOffer: number = 0;
    const totalEnrollmentCurrentOfferMunicipal: number = _.find(resultForviewenrollmentbystageandseries.totals.totalEnrollmentsByAdmDependency, rV => rV.id === AdmDependencyEnum.Municipal).quantity;
    const totalEnrollmentCurrentOfferState: number = _.find(resultForviewenrollmentbystageandseries.totals.totalEnrollmentsByAdmDependency, rV => rV.id === AdmDependencyEnum.State).quantity;
    const totalEnrollmentCurrentOfferFederal: number = _.find(resultForviewenrollmentbystageandseries.totals.totalEnrollmentsByAdmDependency, rV => rV.id === AdmDependencyEnum.Federal).quantity;

    if (simulationType === SimulationType.planningByCitySphereAdm) {
      totalEnrollmentCurrentOffer = totalEnrollmentCurrentOfferMunicipal;
    } else if (simulationType === SimulationType.planningByStateSphereAdm) {
      totalEnrollmentCurrentOffer = totalEnrollmentCurrentOfferState;
    } else {
      totalEnrollmentCurrentOffer = totalEnrollmentCurrentOfferMunicipal + totalEnrollmentCurrentOfferState; /* + totalEnrollmentCurrentOfferFederal;*/
    }

    for (let i = 0; i < budgetForecastReportByItemSchool.length; i++) {
      const itemDimension = _.find(budgetForecastReportByItem, d => d.description === budgetForecastReportByItemSchool[i].description);
      if (itemDimension.description === 'Número de matrículas') {
        itemDimension.currentOffer = totalEnrollmentCurrentOffer;

        for (let j = 0; j < budgetForecastReportByItemSchool[i].stagesEnrollments.length; j++) {
          const stageEnrollment = _.find(itemDimension.stagesEnrollments, sE => sE.id === budgetForecastReportByItemSchool[i].stagesEnrollments[j].id);
          if (stageEnrollment === undefined) {
            itemDimension.stagesEnrollments.push(budgetForecastReportByItemSchool[i].stagesEnrollments[j]);
          } else {
            for (let k = 0; k < stageEnrollment.totalEnrollments.length; k++) {
              const totalEnrollmentSchool = _.find(budgetForecastReportByItemSchool[i].stagesEnrollments[j].totalEnrollments, tE => tE.year === stageEnrollment.totalEnrollments[k].year);
              stageEnrollment.totalEnrollments[k].quantity += totalEnrollmentSchool.quantity;
              stageEnrollment.totalEnrollments[k].quantityNocturnal += totalEnrollmentSchool.quantityNocturnal;
            }
          }
        }
      }
      for (let j = 0; j < budgetForecastReportByItemSchool[i].budgetsForecastReportByYears.length; j++) {
        const itemSchool = budgetForecastReportByItemSchool[i].budgetsForecastReportByYears[j];
        for (let k = 0; k < itemDimension.budgetsForecastReportByYears.length; k++) {
          if (itemDimension.budgetsForecastReportByYears[k].year === itemSchool.year) {
            itemDimension.budgetsForecastReportByYears[k].value += itemSchool.value;
          }
        }
      }
    }
  }

  private setExpensesByStageTotal(budgetForecastReportByItemSchool: Array<BudgetForecastReportByItem>, budgetForecastReportByItem: Array<BudgetForecastReportByItem>,
    itemoffersDimensions: Array<BudgetForecastReportByItem>): void {

    let totalEnrollment: number = 0;

    for (let i = 0; i < budgetForecastReportByItemSchool.length; i++) {
      const item = _.find(budgetForecastReportByItem, d => (d.idItem === budgetForecastReportByItemSchool[i].idItem || d.description === budgetForecastReportByItemSchool[i].description));
      if (item) {
        // item.totalEnrollment = 0;
        for (let j = 0; j < budgetForecastReportByItemSchool[i].budgetsForecastReportByYears.length; j++) {
          const itemSchool = budgetForecastReportByItemSchool[i].budgetsForecastReportByYears[j];
          for (let k = 0; k < item.budgetsForecastReportByYears.length; k++) {
            if (item.budgetsForecastReportByYears[k].year === itemSchool.year) {
              item.budgetsForecastReportByYears[k].value += itemSchool.value;
            }
          }
        }

        const enrollment = _.find(itemoffersDimensions, iE => iE.description === 'Número de matrículas');
        const enrollmentStage = _.find(enrollment.stagesEnrollments, e => e.id === item.idItem);
        if (enrollmentStage) {
          for (let j = 0; j < enrollmentStage.totalEnrollments.length; j++) {
            item.totalEnrollment = item.totalEnrollment === undefined ? enrollmentStage.totalEnrollments[j].quantity : item.totalEnrollment + enrollmentStage.totalEnrollments[j].quantity;
            totalEnrollment += item.totalEnrollment;
          }
        }

        if (item.description === 'TOTAL') {
          item.totalEnrollment = totalEnrollment;
        }
      }
    }
  }

  private setExpensesFederativeEntityTotal(budgetForecastReportByItemSchool: Array<BudgetForecastReportByItem>, budgetForecastReportByItem: Array<BudgetForecastReportByItem>): void {

    for (let i = 0; i < budgetForecastReportByItemSchool.length; i++) {
      const item = _.find(budgetForecastReportByItem, d => (d.description === budgetForecastReportByItemSchool[i].description));
      if (item) {
        for (let j = 0; j < budgetForecastReportByItemSchool[i].budgetsForecastReportByYears.length; j++) {
          const itemSchool = budgetForecastReportByItemSchool[i].budgetsForecastReportByYears[j];
          for (let k = 0; k < item.budgetsForecastReportByYears.length; k++) {
            if (item.budgetsForecastReportByYears[k].year === itemSchool.year) {
              item.budgetsForecastReportByYears[k].value += itemSchool.value;
            }
          }
        }
      }
    }

    const itemTotal = _.find(budgetForecastReportByItem, d => d.description === 'TOTAL');
    for (let i = 0; i < budgetForecastReportByItem.length; i++) {
      const item = budgetForecastReportByItem[i];
      if (item.description !== 'TOTAL') {
        for (let j = 0; j < item.budgetsForecastReportByYears.length; j++) {
          const itemYear = item.budgetsForecastReportByYears[j];
          const itemTotalYear = _.find(itemTotal.budgetsForecastReportByYears, iT => iT.year === itemYear.year);
          itemYear.percentValue = (itemYear.value / itemTotalYear.value) * 100;
        }
      }
    }
  }

  private setCapitalExpensesTotal(capitalExpensesTotal: number, budgetForecastReportByItem: Array<BudgetForecastReportByItem>): void {

    const simulationsYear: Array<number> = this.utilitiesService.getSimulationYears();

    budgetForecastReportByItem.push(new BudgetForecastReportByItem(
      new BudgetForecastReportByItem({
        idItem: ExpenseTypeEnum.Capital,
        description: 'Despesas de capital',
        noteNumber: '(8)',
        budgetsForecastReportByYears: simulationsYear.map(yearSimulation =>
          new BudgetForecastReportByYear({ year: yearSimulation, value: capitalExpensesTotal }))
      })));
  }

  private setCapitalCurrentExpensesTotal(budgetForecastReport: BudgetForecastReport): void {

    const simulationsYear: Array<number> = this.utilitiesService.getSimulationYears();

    budgetForecastReport.expenseTotal = new BudgetForecastReportByItem({
      description: 'Despesas correntes + capital',
      noteNumber: '(6)',
      budgetsForecastReportByYears: simulationsYear.map(yearSimulation =>
        new BudgetForecastReportByYear({ year: yearSimulation, value: 0 }))
    });

    const itemExpensesFederativeEntity = _.find(budgetForecastReport.expensesFederativeEntity, eFe => eFe.description === 'TOTAL');
    const itemExpensesBasicEducation = _.find(budgetForecastReport.expensesBasicEducation, eFe => eFe.idItem === ExpenseTypeEnum.Capital);

    for (let i = 0; i < budgetForecastReport.expenseTotal.budgetsForecastReportByYears.length; i++) {
      for (let j = 0; j < itemExpensesFederativeEntity.budgetsForecastReportByYears.length; j++) {
        if (budgetForecastReport.expenseTotal.budgetsForecastReportByYears[i].year === itemExpensesFederativeEntity.budgetsForecastReportByYears[j].year) {
          budgetForecastReport.expenseTotal.budgetsForecastReportByYears[i].value += itemExpensesFederativeEntity.budgetsForecastReportByYears[j].value;
          break;
        }
      }
      for (let j = 0; j < itemExpensesBasicEducation.budgetsForecastReportByYears.length; j++) {
        if (budgetForecastReport.expenseTotal.budgetsForecastReportByYears[i].year === itemExpensesBasicEducation.budgetsForecastReportByYears[j].year) {
          budgetForecastReport.expenseTotal.budgetsForecastReportByYears[i].value += itemExpensesBasicEducation.budgetsForecastReportByYears[j].value;
        }
      }
    }
  }

  private setVariationPercentOfferDimension(budgetForecastReportByItem: Array<BudgetForecastReportByItem>): void {

    for (let i = 0; i < budgetForecastReportByItem.length; i++) {
      const itemDimension = budgetForecastReportByItem[i];
      if (itemDimension.description !== 'Número de auxiliares') {
        for (let j = 0; j < itemDimension.budgetsForecastReportByYears.length; j++) {
          itemDimension.variation = itemDimension.currentOffer > 1 ? (itemDimension.budgetsForecastReportByYears[j].value / itemDimension.currentOffer - 1) * 100 : 0;
        }
      }
    }
  }

  private setVariationPercentExpensesFederativeEntity(budgetForecastReportByItem: Array<BudgetForecastReportByItem>): void {

    for (let i = 0; i < budgetForecastReportByItem.length; i++) {
      const itemDimension = budgetForecastReportByItem[i];
      if (itemDimension.idItem !== AdmDependencyEnum.Federal) {
        for (let j = 0; j < itemDimension.budgetsForecastReportByYears.length; j++) {
          itemDimension.variation = (itemDimension.budgetsForecastReportByYears[j].value / itemDimension.potentialRevenue - 1) * 100;
        }
      }
    }
  }

  private setVariationPercentExpensesByStage(budgetForecastReportByItem: Array<BudgetForecastReportByItem>): void {

    const itemTotal = _.find(budgetForecastReportByItem, i => i.description === 'TOTAL');
    if (itemTotal) {
      for (let i = 0; i < budgetForecastReportByItem.length; i++) {
        const itemDimension = budgetForecastReportByItem[i];
        if (itemDimension.description !== 'TOTAL') {
          for (let j = 0; j < itemDimension.budgetsForecastReportByYears.length; j++) {
            const result = itemDimension.budgetsForecastReportByYears[j].value / itemTotal.budgetsForecastReportByYears[j].value;
            itemDimension.variation = result > 0 ? result * 100 : undefined;
          }
        }
      }
    }
  }

  private setPercentCapitalExpensesTotal(budgetForecastReport: BudgetForecastReport): void {

    const totalCapitalExpense = budgetForecastReport.totalExpenseWithInfrastructure + budgetForecastReport.totalExpenseWithNewroom;
    if (totalCapitalExpense > 0) {
      budgetForecastReport.percentExpenseWithInfrastructure = (budgetForecastReport.totalExpenseWithInfrastructure / totalCapitalExpense) * 100;
      budgetForecastReport.percentExpenseWithNewroom = (budgetForecastReport.totalExpenseWithNewroom / totalCapitalExpense) * 100;
    }
  }

  private setBudgetForecastReportBySchool(budgetForecastReportBySchool: BudgetForecastReport, stages: Array<Stage>, locations: Array<Location>, itemCost: Array<StudentCost>) {
    const totalExpensesByYear: Array<BudgetForecastReportByYear> = new Array<BudgetForecastReportByYear>();
    for (let s = 0; s < stages.length; s++) {
      budgetForecastReportBySchool.expensesByStage.push(new BudgetForecastReportByItem(
        {
          idItem: stages[s].id,
          description: stages[s].description,
          budgetsForecastReportByYears: new Array,
          variation: 0
        })
      );
      for (let l = 0; l < locations.length; l++) {
        for (let y = 0; y < budgetForecastReportBySchool.years.length; y++) {
          let totalExpenseByYear: number = 0;
          const expenseByYear: BudgetForecastReportByYear = new BudgetForecastReportByYear({ value: 0, year: budgetForecastReportBySchool.years[y] });
          const studentCostByLocation = _.find(itemCost[0].studentsCostByLocations, sCl => sCl.id === locations[l].id);
          if (studentCostByLocation.studentsCostByStages.length > 0) {
            for (let cs = 0; cs < itemCost[0].studentsCostByLocations[l].studentsCostByStages[s].studentsCostBySubitems.length; cs++) {

              for (let cst = 0; cst < itemCost[0].studentsCostByLocations[l].studentsCostByStages[s].studentsCostBySubitems[cs].studentsCostByYear.length; cst++) {
                if (itemCost[0].studentsCostByLocations[l].studentsCostByStages[s].studentsCostBySubitems[cs].studentsCostByYear[cst].year
                  === budgetForecastReportBySchool.years[y]) {
                  totalExpenseByYear += itemCost[0].studentsCostByLocations[l].studentsCostByStages[s].studentsCostBySubitems[cs].studentsCostByYear[cst].costTotal;

                  if (budgetForecastReportBySchool.expensesByStage[s].budgetsForecastReportByYears[y] === undefined) {
                    expenseByYear.value += itemCost[0].studentsCostByLocations[l].studentsCostByStages[s].studentsCostBySubitems[cs].studentsCostByYear[cst].costTotal;
                    budgetForecastReportBySchool.expensesByStage[s].budgetsForecastReportByYears.push(expenseByYear);
                  } else {
                    budgetForecastReportBySchool.expensesByStage[s].budgetsForecastReportByYears[y].value +=
                      itemCost[0].studentsCostByLocations[l].studentsCostByStages[s].studentsCostBySubitems[cs].studentsCostByYear[cst].costTotal;
                  }
                }
              }
            }
            if (totalExpensesByYear[y] === undefined) {
              totalExpensesByYear.push(new BudgetForecastReportByYear({
                year: budgetForecastReportBySchool.years[y],
                value: totalExpenseByYear
              }));
            } else {
              totalExpensesByYear[y].value += totalExpenseByYear;
            }
          }
        }
      }

    }
    budgetForecastReportBySchool.expensesByStage.push(new BudgetForecastReportByItem({
      description: 'TOTAL',
      budgetsForecastReportByYears: totalExpensesByYear
    }));
    for (let s = 0; s < stages.length; s++) {
      budgetForecastReportBySchool.expensesByStage[s].variation =
        (budgetForecastReportBySchool.expensesByStage[s].budgetsForecastReportByYears[budgetForecastReportBySchool.expensesByStage[s].
          budgetsForecastReportByYears.length - 1].value / totalExpensesByYear[budgetForecastReportBySchool.expensesByStage[s].
            budgetsForecastReportByYears.length - 1].value) * 100;
    }
    budgetForecastReportBySchool.sourceInformationsExpensesByStage = this.getSourceInformationsExpensesByStage();
  }

  private setTotalEmployeeByRole(calculatedEmployeeEstimate: Array<EmployeeEstimate>, employeesEstimateByRolesTotal: Array<EmployeeEstimateByRole>) {

    const employeeEstimateByRolesLinhaTotal = _.find(employeesEstimateByRolesTotal, y => y.description === 'TOTAL');

    for (let i = 0; i < calculatedEmployeeEstimate.length; i++) {
      const employeesEstimateByLocations = calculatedEmployeeEstimate[i].employeesEstimateByLocations;
      for (let j = 0; j < employeesEstimateByLocations.length; j++) {
        const employeesEstimateByRoles = employeesEstimateByLocations[j].employeesEstimateByRoles;

        for (let k = 0; k < employeesEstimateByRoles.length; k++) {

          const resultEmployeeEstimateByRolesTotal = _.find(employeesEstimateByRolesTotal, y => y.id === employeesEstimateByRoles[k].id);

          const employeesEstimateByYears = employeesEstimateByRoles[k].employeesEstimatesByYear;
          for (let l = 0; l < employeesEstimateByYears.length; l++) {
            const resultEmployeesEstimateByRolesByYearsTotal = _.find(resultEmployeeEstimateByRolesTotal.employeesEstimatesByYear, eY => eY.year === employeesEstimateByYears[l].year);
            if (resultEmployeesEstimateByRolesByYearsTotal !== undefined && employeesEstimateByYears[l] !== undefined) {
              resultEmployeesEstimateByRolesByYearsTotal.quantity += employeesEstimateByYears[l].quantity;
              employeeEstimateByRolesLinhaTotal.employeesEstimatesByYear[l].quantity += employeesEstimateByYears[l].quantity;
            }
          }
        }
      }
    }
  }

  private getEnrollmentAndClassAndClassroom(enrollmentBySchool: EnrollmentBySchool): Array<EnrollmentAndClassAndClassroom> {

    const enrollmentAndClassAndClassrooms: Array<EnrollmentAndClassAndClassroom> = new Array<EnrollmentAndClassAndClassroom>();

    for (let i = 0; i < enrollmentBySchool.enrollmentByStageSeriesBySchool.length; i++) {

      const enrollmentAndClassAndClassroom: EnrollmentAndClassAndClassroom = new EnrollmentAndClassAndClassroom({
        school_id: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].school_id,
        school_description: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].school_name,
        city_id: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].city_id,
        city_description: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].city_name,
        adm_dependency_id: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].adm_dependency_id,
        adm_dependency_name: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].adm_dependency_name,
        enrollmentProjection: this.createProjectionsService.getEnrollmentProjectionBySchool(enrollmentBySchool.enrollmentByStageSeriesBySchool[i]),
        hasEnrollment: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].hasEnrollment,
        percentageTeacherCareer: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].percentageTeacherCareer
      });

      enrollmentAndClassAndClassrooms.push(enrollmentAndClassAndClassroom);
    }

    return enrollmentAndClassAndClassrooms;
  }

  private getTotalEnrollment(enrollmentsTotal: Array<Enrollment>): Array<BudgetForecastReportByYear> {

    const BudgetsForecastReportByYears: Array<BudgetForecastReportByYear> = new Array<BudgetForecastReportByYear>();

    for (let i = 0; i < enrollmentsTotal.length; i++) {

      BudgetsForecastReportByYears.push(new BudgetForecastReportByYear({
        year: enrollmentsTotal[i].year,
        value: enrollmentsTotal[i].quantity
      }));

    }

    return BudgetsForecastReportByYears;
  }

  private getClassroomExisting_old(demandClassRoom: DemandClassRoom): Array<ClassroomExistingByCity> {

    const classroomsExistingByCities: Array<ClassroomExistingByCity> = new Array<ClassroomExistingByCity>();
    const classroomsExistingByLocations: Array<ClassroomExistingByLocation> = new Array<ClassroomExistingByLocation>();

    for (let i = 0; i < demandClassRoom.quantityDemandClassRoomLocation.length; i++) {

      const quantityDemandClassRoom = demandClassRoom.quantityDemandClassRoomLocation[i];

      classroomsExistingByLocations.push(new ClassroomExistingByLocation({
        location: quantityDemandClassRoom.location,
        quantityClassroomExisting: quantityDemandClassRoom.value
      }));
    }

    classroomsExistingByCities.push(new ClassroomExistingByCity({
      classroomExistingByLocations: classroomsExistingByLocations
    }));

    return classroomsExistingByCities;
  }

  private getFinancialDatasByFederativeEntity(sphereAdm: number, financialDataType: number, financialsDatas: Array<FinancialData>): number {

    let value: number = 0;
    for (let i = 0; i < financialsDatas.length; i++) {
      if (sphereAdm === financialsDatas[i].sphereAdmId && financialDataType === financialsDatas[i].financialDataId) {
        value += financialsDatas[i].value;
      }
    }
    return value;
  }

  private getSourceInformationsExpensesFederativeEntity(): Array<Footnote> {

    const sourcesInformations: Array<Footnote> = new Array<Footnote>();

    sourcesInformations.push(new Footnote({
      indice: 4,

      note: 'Receita Potencial mínima vinculada para a Educação Básica’ (RPEb) = [RLI x 25% - receitas destinadas ao Fundeb + receitas recebidas do Fundeb + complementações ' +
        'da União ao Fundeb + Salário Educação (cota estadual/municipal) + Receitas de Programas do FNDE (PNATE + PDDE + PNLD) + Royalties de Petróleo e Gás]. A RLI é a “receita ' +
        'resultante de impostos, compreendida a proveniente de transferências”, base de cálculo da vinculação constitucional de recursos para a educação (Art. 212 da CF). ' +
        'Os recursos do PNAE não foram considerados porque as despesas com Alimentação Escolar não estão sendo consideradas na projeção de despesas do SimCAQ. RPEb é calculada ' +
        'com base nos dados das Receitas de 2022 consideradas para cálculo do para VAAT 2024 fornecidas pelo FNDE e dados da Complementação VAAT do Fundeb 2022 disponíveis do ' +
        'portal de Transferências Constitucionais do STN.'
    }));

    sourcesInformations.push(new Footnote({
      indice: 5,
      note: 'Resultado das Despesas liquidadas com Educação Básica = [Despesa na Função Educação] - [Despesa na sub-função ‘Ensino Profissional’] - [Despesa na sub-função ‘Ensino Superior’] ' +
        'calculada a partir do Relatório Resumido da Execução Orçamentária (RREO) / Anexo 02 - Demonstrativo da Execução das Despesas por Função/Subfunção.'
    }));

    sourcesInformations.push(new Footnote({
      indice: 6,
      note: 'Complementação (R$) 2022-2024 - Calculada a partir da diferença entre a Projeção da despesa corrente necessária e a receita potencial mínima vinculada em cada ente federativo.'
    }));

    sourcesInformations.push(new Footnote({
      indice: 7,
      note: 'Complementação % = [Projeção da despesa corrente necessária / pela Receita Potencial Mínima Vinculada] - 1.'
    }));

    return sourcesInformations;
  }

  private getSourceInformationsOfferDimension(): Array<Footnote> {

    // const sourceInformation: Footnote = new Footnote({
    //   indice: 1,
    //   note: 'Somatório do número de funcionários que atuam fora da sala de aula nas escolas públicas de Educação Básica (estaduais e municipais). ' +
    //     'Para cada escola, é aplicada a seguinte fórmula: Número total de funcionários da escola (-) Número de profissionais que atuam em sala de aula. ' +
    //     'Valores calculados a partir dos microdados do Censo Escolar/INEP'
    // });
    const sourceInformations: Array<Footnote> = new Array<Footnote>();
    sourceInformations.push(new Footnote({
      indice: 1,
      note: 'A simulação considera as etapas da educação básica e a modalidade de educação de jovens e adultos (EJA) prioritárias para a rede pública estadual e/ou municipal. ' +
        'Na rede estadual, são contabilizadas as matrículas de ensino fundamental - anos iniciais, ensino fundamental - anos finais, ensino médio e EJA de ensino fundamental e médio. ' +
        'Na rede municipal, são incluídas as matrículas de creche, pré-escola, ensino fundamental - anos iniciais, ensino fundamental - anos finais e EJA de ensino fundamental.'
    }));

    sourceInformations.push(new Footnote({
      indice: 2,
      note: 'O resultado da projeção do número de professores necessários depende do padrão de jornada de trabalho semanal adotado. Inicialmente, ' +
        'o simulador faz projeções considerando uma jornada de 40 horas semanais. Se necessário, edite o resultado e altere esse parâmetro de modo a expressar a ' +
        'jornada dos docentes da rede em análise.'
    }));

    sourceInformations.push(new Footnote({
      indice: 3,
      note: 'Variação de valores entre o diagnóstico e o projetado.'
    }));
    return sourceInformations;
  }

  private getEmptyCapitalExpense(): CapitalExpense {

    const simulationsYear: Array<number> = this.utilitiesService.getSimulationYears();

    return new CapitalExpense({
      capitalExpenseDescription: 'Despesas de capital',
      capitalExpenseByYears: simulationsYear.map(yearSimulation =>
        new CapitalExpenseByYear({ year: yearSimulation, value: 0 })),
      totalExpenseWithInfrastructure: 0,
      totalExpenseWithNewroom: 0
    });

  }

  private getEmptyOfferDimension(budgetForecastReport: Array<BudgetForecastReport>): Array<BudgetForecastReportByItem> {
    const simulationsYear: Array<number> = this.utilitiesService.getSimulationYears();
    const budgetForecastReportByItems: Array<BudgetForecastReportByItem> = new Array<BudgetForecastReportByItem>();

    for (let i = 0; budgetForecastReport.length; i++) {
      for (let j = 0; j < budgetForecastReport[i].offersDimensions.length; j++) {
        const descriptionDimension = budgetForecastReport[i].offersDimensions[j].description;
        const currentOfferDimension = budgetForecastReport[i].offersDimensions[j].currentOffer;
        const valueByYear: number = descriptionDimension === 'Número de auxiliares' ? undefined : 0;
        const noteNumber: string = budgetForecastReport[i].offersDimensions[j].noteNumber;
        budgetForecastReportByItems.push(new BudgetForecastReportByItem({
          description: descriptionDimension,
          currentOffer: descriptionDimension !== 'Número de matrículas' ? currentOfferDimension : 0,
          stagesEnrollments: new Array<StageEnrollments>(),
          noteNumber: noteNumber,
          budgetsForecastReportByYears: simulationsYear.map(yearSimulation =>
            new BudgetForecastReportByYear({ year: yearSimulation, value: valueByYear })),
          variation: undefined
        }));
      }
      break;
    }
    return budgetForecastReportByItems;
  }

  private getEmptyExpensesByStage(stages: Array<Stage>): Array<BudgetForecastReportByItem> {

    const simulationsYear: Array<number> = this.utilitiesService.getSimulationYears();
    const budgetForecastReportByItems: Array<BudgetForecastReportByItem> = new Array<BudgetForecastReportByItem>();

    for (let i = 0; i < stages.length; i++) {

      budgetForecastReportByItems.push(new BudgetForecastReportByItem({
        idItem: stages[i].id,
        description: stages[i].description,
        budgetsForecastReportByYears: simulationsYear.map(yearSimulation =>
          new BudgetForecastReportByYear({ year: yearSimulation, value: 0 })),
        variation: undefined
      }));
    }

    budgetForecastReportByItems.push(new BudgetForecastReportByItem({
      description: 'TOTAL',
      budgetsForecastReportByYears: simulationsYear.map(yearSimulation =>
        new BudgetForecastReportByYear({ year: yearSimulation, value: 0 })),
      variation: undefined
    }));

    return budgetForecastReportByItems;
  }

  private getEmptyExpensesFederativeEntity(budgetForecastReport: Array<BudgetForecastReport>, financialsDatas: Array<FinancialData>): Array<BudgetForecastReportByItem> {

    const simulationsYear: Array<number> = this.utilitiesService.getSimulationYears();
    const budgetForecastReportByItems: Array<BudgetForecastReportByItem> = new Array<BudgetForecastReportByItem>();
    const referenceBudgetForecastReportItens = _.first(budgetForecastReport).expensesFederativeEntity;
    const simulationType: SimulationType = this.sessionService.getItem<SimulationType>(UtilitiesService.simulationTypeSessionKey);
    let admDependency: number;
    let potentialRevenueState, potentialRevenueMunicipal, expensesMDEState, expensesMDEMunicipal: number;

    if (simulationType === SimulationType.planningByStateSphereAdm) {
      admDependency = AdmDependencyEnum.State;
    } else if (simulationType === SimulationType.planningByCitySphereAdm) {
      admDependency = AdmDependencyEnum.Municipal;
    }

    potentialRevenueState = admDependency !== AdmDependencyEnum.Municipal ? this.getFinancialDatasByFederativeEntity(SphereAdmEnum.state, FinancialDataTypeEnum.potentialRevenue, financialsDatas) :
      undefined;
    expensesMDEState = admDependency !== AdmDependencyEnum.Municipal ? this.getFinancialDatasByFederativeEntity(SphereAdmEnum.state, FinancialDataTypeEnum.expenseMDE, financialsDatas) : undefined;

    potentialRevenueMunicipal = admDependency !== AdmDependencyEnum.State ? this.getFinancialDatasByFederativeEntity(SphereAdmEnum.municipal, FinancialDataTypeEnum.potentialRevenue, financialsDatas) :
      undefined;

    expensesMDEMunicipal = admDependency !== AdmDependencyEnum.State ? this.getFinancialDatasByFederativeEntity(SphereAdmEnum.municipal, FinancialDataTypeEnum.expenseMDE, financialsDatas) : undefined;

    /* budgetForecastReportByItems.push(new BudgetForecastReportByItem({
       description: 'Governo Federal',
       budgetsForecastReportByYears: simulationsYear.map(yearSimulation =>
         new BudgetForecastReportByYear({ year: yearSimulation, value: 0, percentValue: undefined }))
     }));*/

    budgetForecastReportByItems.push(new BudgetForecastReportByItem({
      description: 'Governo Estadual',
      potentialRevenue: potentialRevenueState,
      expensesMDE: expensesMDEState,
      budgetsForecastReportByYears: simulationsYear.map(yearSimulation =>
        new BudgetForecastReportByYear({ year: yearSimulation, value: 0, percentValue: undefined })),
    }));

    budgetForecastReportByItems.push(new BudgetForecastReportByItem({
      description: 'Governo Municipal',
      potentialRevenue: potentialRevenueMunicipal,
      expensesMDE: expensesMDEMunicipal,
      budgetsForecastReportByYears: simulationsYear.map(yearSimulation =>
        new BudgetForecastReportByYear({ year: yearSimulation, value: 0, percentValue: undefined })),
    }));

    let totalPotentialRevenue, totalExpensesMDE: number;
    if (admDependency !== AdmDependencyEnum.State && admDependency !== AdmDependencyEnum.Municipal) {
      totalPotentialRevenue = potentialRevenueState + potentialRevenueMunicipal;
      totalExpensesMDE = expensesMDEState + expensesMDEMunicipal;
    } else if (admDependency === AdmDependencyEnum.State) {
      totalPotentialRevenue = potentialRevenueState;
      totalExpensesMDE = expensesMDEState;
    } else if (admDependency === AdmDependencyEnum.Municipal) {
      totalPotentialRevenue = potentialRevenueMunicipal;
      totalExpensesMDE = expensesMDEMunicipal;
    }

    budgetForecastReportByItems.push(new BudgetForecastReportByItem({
      description: 'TOTAL',
      potentialRevenue: totalPotentialRevenue,
      expensesMDE: totalExpensesMDE,
      budgetsForecastReportByYears: simulationsYear.map(yearSimulation =>
        new BudgetForecastReportByYear({ year: yearSimulation, value: 0, percentValue: undefined }))
    }));

    return budgetForecastReportByItems;
  }

  private getEnrollmentAndClassAndClassroomByState(state_id: number, enrollmentsAndClassesAndClassrooms: Array<EnrollmentAndClassAndClassroom>): EnrollmentAndClassAndClassroom {

    for (let i = 0; i < enrollmentsAndClassesAndClassrooms.length; i++) {
      if (enrollmentsAndClassesAndClassrooms[i].state_id === state_id) {
        return enrollmentsAndClassesAndClassrooms[i];
      }
    }
  }

  private getPriceCubByState(state_id: number, priceCubStates: Array<any>): number {

    for (let i = 0; i < priceCubStates.length; i++) {
      if (priceCubStates[i].cod_uf === state_id) {
        return priceCubStates[i].preco;
      }
    }
  }

  private getSourceInformationsExpensesBasicEducation(budgetForecastReport: BudgetForecastReport): Array<Footnote> {

    const sourceInformations: Array<Footnote> = new Array<Footnote>();

    sourceInformations.push(new Footnote({
      indice: 8,
      remarks: 'As despesas de capital foram compostas por: (a) Ampliação da infraestrutura em escolas ' +
        'existentes (novas dependências/espaços): ' + budgetForecastReport.percentExpenseWithInfrastructure.toFixed(2) + '% ' +
        '(R$ ' + budgetForecastReport.totalExpenseWithInfrastructure.toLocaleString('pt-br', { maximumFractionDigits: 0 }) + '); e Construção de novos prédios escolares e salas de aula: ' +
        budgetForecastReport.percentExpenseWithNewroom.toFixed(2) + '% (R$ ' + budgetForecastReport.totalExpenseWithNewroom.toLocaleString('pt-br', { maximumFractionDigits: 0 }) + ').'
    }));

    // sourceInformations.push(new Footnote({
    //   indice: 7,
    //   note: 'Variação de valores entre o primeiro e o último ano da despesa projetada'
    // }));

    return sourceInformations;
  }

  private getSourceInformationsExpenseTotal(): Array<Footnote> {

    const sourceInformations: Array<Footnote> = new Array<Footnote>();

    // sourceInformations.push(new Footnote({
    //   indice: 8,
    //   note: 'Variação de valores entre o primeiro e o último ano da despesa projetada'
    // }));

    return sourceInformations;
  }

  private getSourceInformationsExpensesByStage(): Array<Footnote> {

    const sourceInformations: Array<Footnote> = new Array<Footnote>();

    sourceInformations.push(new Footnote({
      indice: 8,
      note: 'Porcentagem do orçamento por etapa'
    }));

    return sourceInformations;
  }

  private getSourceInformationPQR(): Footnote {

    const footNote: Footnote = new Footnote({
      note: 'A proposta de valores do PQR foi realizada pela equipe do projeto. Desse modo, não são decisões do governo federal, ' +
        'dos governos estaduais ou municipais. Por consequência, os resultados do SimCAQ decorrem dos parâmetros de qualidade propostos ' +
        'não vinculam responsabilidade de repasses de recursos financeiros por parte dos entes federativos.'
    });
    return footNote;
  }

  private getbudgetForecastTotal(budgetForecastReport: Array<BudgetForecastReport>, stages: Array<Stage>, financialsDatas: Array<FinancialData>): BudgetForecastReport {

    const capitalExpenseTotal: CapitalExpense = this.getEmptyCapitalExpense();

    const budgetForecastReportTotal: BudgetForecastReport = new BudgetForecastReport({
      years: this.utilitiesService.getSimulationYears(),
      offerYear: _.first(budgetForecastReport).offerYear,
      offerYearFinancial: _.first(budgetForecastReport).offerYearFinancial,
      offersDimensions: this.getEmptyOfferDimension(budgetForecastReport),
      expensesBasicEducation: new Array<BudgetForecastReportByItem>(),
      enrollmentsBasicEducation: new Array<BudgetForecastReportByItem>(),
      expenseTotal: new BudgetForecastReportByItem(),
      expensesFederativeEntity: this.getEmptyExpensesFederativeEntity(budgetForecastReport, financialsDatas),
      expensesByStage: this.getEmptyExpensesByStage(stages),
      totalExpenseWithInfrastructure: _.first(budgetForecastReport).totalExpenseWithInfrastructure,
      totalExpenseWithNewroom: 0,
      sourceInformationsExpensesFederativeEntity: this.getSourceInformationsExpensesFederativeEntity(),
      sourceInformationsOfferDimension: this.getSourceInformationsOfferDimension(),
      sourceInformationsExpensesBasicEducation: new Array<Footnote>(),
      sourceInformationsExpenseTotal: new Array<Footnote>(),
      sourceInformationsExpensesByStage: this.getSourceInformationsExpensesByStage()
    });

    for (let i = 0; i < budgetForecastReport.length; i++) {
      budgetForecastReportTotal.totalExpenseWithNewroom += budgetForecastReport[i].totalExpenseWithNewroom;
      this.setOfferDimensionEnrollment(budgetForecastReport[i].offersDimensions, budgetForecastReportTotal.offersDimensions);
      this.setExpensesByStageTotal(budgetForecastReport[i].expensesByStage, budgetForecastReportTotal.expensesByStage, budgetForecastReport[i].offersDimensions);
      this.setExpensesFederativeEntityTotal(budgetForecastReport[i].expensesFederativeEntity, budgetForecastReportTotal.expensesFederativeEntity);
    }

    this.setExpensesTotalByCity(budgetForecastReport, financialsDatas, budgetForecastReportTotal);

    this.setVariationPercentOfferDimension(budgetForecastReportTotal.offersDimensions);
    // this.setVariationPercentExpensesFederativeEntity(budgetForecastReportTotal.expensesFederativeEntity);
    this.setVariationPercentExpensesByStage(budgetForecastReportTotal.expensesByStage);

    const capitalExpensesTotal = budgetForecastReportTotal.totalExpenseWithInfrastructure + budgetForecastReportTotal.totalExpenseWithNewroom;
    this.setCapitalExpensesTotal(capitalExpensesTotal, budgetForecastReportTotal.expensesBasicEducation);
    this.setCapitalCurrentExpensesTotal(budgetForecastReportTotal);

    this.setPercentCapitalExpensesTotal(budgetForecastReportTotal);

    const footNotes = this.getSourceInformationsExpensesBasicEducation(budgetForecastReportTotal);
    budgetForecastReportTotal.sourceInformationsExpensesBasicEducation = footNotes;

    return budgetForecastReportTotal;
  }

  private getEnrollmentAndClassAndClassroomStateOrRegion(filterRegion: number = undefined): Observable<Array<EnrollmentAndClassAndClassroom>> {

    const resultForOffergoalenrollmentfulltime: OfferGoalEnrollmentFullTime = this.sessionService.getItem<OfferGoalEnrollmentFullTime>(Functionality.offerGoalEnrollmentFullTime.pqrKey);
    const resultForNumberstudentclass: NumberStudentClass = this.sessionService.getItem<NumberStudentClass>(Functionality.numberStudentClass.key);
    const enrollmentAndClassAndClassrooms: Array<EnrollmentAndClassAndClassroom> = new Array<EnrollmentAndClassAndClassroom>();
    const resultForSchoolDayPerWeek: SchoolDayPerWeek = this.sessionService.getItem<SchoolDayPerWeek>(Functionality.schoolDayPerWeek.key);
    const resultForDailyTeachingLoad: DailyTeachingLoad = this.sessionService.getItem<DailyTeachingLoad>(Functionality.dailyTeachingLoad.key);
    const resultForCareerAndRemunerationTeachers: CareerAndRemunerationTeachers = this.sessionService.getItem<CareerAndRemunerationTeachers>(Functionality.careerAndRemunerationTeachers.pqrKey);
    const resultForWorkJourneyTeacher: WorkJourneyTeacher = this.sessionService.getItem<WorkJourneyTeacher>(Functionality.workJourneyTeacher.pqrKey);

    return this.utilitiesService.getClassAndClassroomsAndEnrollment(resultForNumberstudentclass, resultForOffergoalenrollmentfulltime, resultForSchoolDayPerWeek, resultForDailyTeachingLoad,
      resultForCareerAndRemunerationTeachers, resultForWorkJourneyTeacher, false, true, filterRegion).pipe(
        map(classAndClassroomNumberAndEnrollment => {

          for (let i = 0; i < classAndClassroomNumberAndEnrollment.length; i++) {

            const classAndClassroomNumberAndEnrollmentByLocations = classAndClassroomNumberAndEnrollment[i].locations;

            const enrollmentAndClassAndClassroom: EnrollmentAndClassAndClassroom = new EnrollmentAndClassAndClassroom({
              state_id: classAndClassroomNumberAndEnrollment[i].state_id,
              state_description: classAndClassroomNumberAndEnrollment[i].state_name,
              city_id: classAndClassroomNumberAndEnrollment[i].city_id,
              city_description: classAndClassroomNumberAndEnrollment[i].city_name,
              school_id: classAndClassroomNumberAndEnrollment[i].school_id,
              school_description: classAndClassroomNumberAndEnrollment[i].school_name,
              adm_dependency_id: classAndClassroomNumberAndEnrollment[i].adm_dependency_id,
              adm_dependency_name: classAndClassroomNumberAndEnrollment[i].adm_dependency_name,
              enrollmentProjection: this.createProjectionsService.getEnrollmentProjection(classAndClassroomNumberAndEnrollment[i]),
              classNumber: this.calculateClassNumberService.getClassNumberCalculated(classAndClassroomNumberAndEnrollmentByLocations),
              teacherNumber: this.createTeacherNumberCalcService.getTeacherNumberCalculated(classAndClassroomNumberAndEnrollmentByLocations),
              hasEnrollment: this.existEnrollment(classAndClassroomNumberAndEnrollmentByLocations),
              percentageTeacherCareer: this.createTeacherNumberCalcService.getPercentageTeacherCareer(classAndClassroomNumberAndEnrollment[i])
            });

            enrollmentAndClassAndClassrooms.push(enrollmentAndClassAndClassroom);
          }
          // console.log(enrollmentAndClassAndClassrooms);
          return enrollmentAndClassAndClassrooms;
        }));
  }

  private getEnrollmentProjection(enrollmentProjection: EnrollmentProjection, emptyEnrollmentProjection: EnrollmentProjection): EnrollmentProjection {

    let emptyLocation;

    for (let i = 0; i < emptyEnrollmentProjection.enrollmentsProjectionsByLocations.length; i++) {
      if (emptyEnrollmentProjection.enrollmentsProjectionsByLocations[i].id === LocationEnum.urban) {
        emptyLocation = emptyEnrollmentProjection.enrollmentsProjectionsByLocations[i];
        emptyLocation.description = 'Urbana e Rural';
      } else {
        emptyEnrollmentProjection.enrollmentsProjectionsByLocations.splice(i, 1);
      }
    }

    for (let i = 0; i < enrollmentProjection.enrollmentsProjectionsByLocations.length; i++) {

      const enrollmentProjectionsByLocation = enrollmentProjection.enrollmentsProjectionsByLocations[i];
      for (let j = 0; j < enrollmentProjectionsByLocation.stagesEnrollments.length; j++) {
        const stageEnrollment = enrollmentProjectionsByLocation.stagesEnrollments[j];
        const stageEnrollmentAux: StageEnrollments = _.find(emptyLocation.stagesEnrollments, sE => sE.id === stageEnrollment.id);

        for (let k = 0; k < stageEnrollment.totalEnrollments.length; k++) {

          const totalEnrollment = stageEnrollment.totalEnrollments[k];
          for (let l = 0; l < stageEnrollmentAux.totalEnrollments.length; l++) {

            if (totalEnrollment.year === stageEnrollmentAux.totalEnrollments[l].year) {
              stageEnrollmentAux.totalEnrollments[l].quantity += totalEnrollment.quantity;
              stageEnrollmentAux.totalEnrollments[l].quantityNocturnal += totalEnrollment.quantityNocturnal;

              const totalEnrollmentProjection = _.find(emptyLocation.totalsEnrollmentProjection, sE => sE.year === totalEnrollment.year);
              totalEnrollmentProjection.quantity += totalEnrollment.quantity;
              totalEnrollmentProjection.quantityNocturnal += totalEnrollment.quantityNocturnal;
            }
          }
        }

      }
    }
    return emptyEnrollmentProjection;
  }

  private existEnrollment(datas: Array<any>): boolean {

    for (let i = 0; i < datas.length; i++) {
      const data = datas[i];

      if (data.education_level.length > 0) {
        return true;
      }
    }
    return false;
  }

  private setExpensesTotalByCity(budgetForecastReport: Array<BudgetForecastReport>, financialsDatas: Array<FinancialData>, budgetForecastReportTotal: BudgetForecastReport): void {

    const expensesByCity: Array<ExpenseByCity> = new Array<ExpenseByCity>();
    const expensesByStage: Array<ExpenseByState> = new Array<ExpenseByState>();
    let city: ExpenseByCity;
    let state: ExpenseByState;
    let total: number = 0;
    let complementationCityTotal: number = 0;
    let complementationStateTotal: number = 0;

    for (let i = 0; i < budgetForecastReport.length; i++) {
      if (budgetForecastReport[i].admDependencyId === AdmDependencyEnum.Municipal) {
        city = _.find(expensesByCity, eX => (eX.cityId === budgetForecastReport[i].cityId));

        if (!city) {
          city = new ExpenseByCity({
            cityId: budgetForecastReport[i].cityId,
            currentExpenseTotal: 0,
            potentialRevenue: this.getFinancialDatasByCity(SphereAdmEnum.municipal, FinancialDataTypeEnum.potentialRevenue, financialsDatas, budgetForecastReport[i].cityId)
          });
          expensesByCity.push(city);
        }
        for (let j = 0; j < budgetForecastReport[i].expensesByStage.length; j++) {
          const itemStage = budgetForecastReport[i].expensesByStage[j];
          if (itemStage.description !== 'TOTAL') {
            for (let k = 0; k < itemStage.budgetsForecastReportByYears.length; k++) {
              city.currentExpenseTotal += itemStage.budgetsForecastReportByYears[k].value;
              city.complementation = city.currentExpenseTotal - city.potentialRevenue > 0 ? city.currentExpenseTotal - city.potentialRevenue : 0;
              total += itemStage.budgetsForecastReportByYears[k].value;
            }
          }
        }
      } else if (budgetForecastReport[i].admDependencyId === AdmDependencyEnum.State) {
        state = _.find(expensesByStage, eXs => (eXs.stateId === budgetForecastReport[i].stateId));

        if (!state) {
          state = new ExpenseByState({
            stateId: budgetForecastReport[i].stateId,
            currentExpenseTotal: 0,
            potentialRevenue: this.getFinancialDatasByState(SphereAdmEnum.state, FinancialDataTypeEnum.potentialRevenue, financialsDatas, budgetForecastReport[i].stateId)
          });
          expensesByStage.push(state);
        }
        for (let j = 0; j < budgetForecastReport[i].expensesByStage.length; j++) {
          const itemStage = budgetForecastReport[i].expensesByStage[j];
          if (itemStage.description !== 'TOTAL') {
            for (let k = 0; k < itemStage.budgetsForecastReportByYears.length; k++) {
              state.currentExpenseTotal += itemStage.budgetsForecastReportByYears[k].value;
              state.complementation = state.currentExpenseTotal - state.potentialRevenue > 0 ? state.currentExpenseTotal - state.potentialRevenue : 0;
              total += itemStage.budgetsForecastReportByYears[k].value;
            }
          }
        }
      }
    }

    for (let i = 0; i < expensesByCity.length; i++) {
      complementationCityTotal += expensesByCity[i].complementation;
    }

    for (let i = 0; i < expensesByStage.length; i++) {
      complementationStateTotal += expensesByStage[i].complementation;
    }

    for (let i = 0; i < budgetForecastReportTotal.expensesFederativeEntity.length; i++) {
      if (budgetForecastReportTotal.expensesFederativeEntity[i].description === 'Governo Municipal') {
        budgetForecastReportTotal.expensesFederativeEntity[i].complementation = complementationCityTotal > 0 ? complementationCityTotal : undefined;
        budgetForecastReportTotal.expensesFederativeEntity[i].variation = (complementationCityTotal / budgetForecastReportTotal.expensesFederativeEntity[i].potentialRevenue) * 100;
      } else if (budgetForecastReportTotal.expensesFederativeEntity[i].description === 'Governo Estadual') {
        const budgetsForecastReportByYears = budgetForecastReportTotal.expensesFederativeEntity[i].budgetsForecastReportByYears;
        const complementationState = budgetForecastReportTotal.expensesFederativeEntity[i].potentialRevenue > 0 ?
          budgetsForecastReportByYears[budgetsForecastReportByYears.length - 1].value - budgetForecastReportTotal.expensesFederativeEntity[i].potentialRevenue : undefined;
        /*budgetForecastReportTotal.expensesFederativeEntity[i].complementation = complementationState > 0 ? complementationState : undefined;
        budgetForecastReportTotal.expensesFederativeEntity[i].variation = (complementationState / budgetForecastReportTotal.expensesFederativeEntity[i].potentialRevenue) * 100;*/
        budgetForecastReportTotal.expensesFederativeEntity[i].complementation = complementationStateTotal > 0 ? complementationStateTotal : undefined;
        budgetForecastReportTotal.expensesFederativeEntity[i].variation = (complementationStateTotal / budgetForecastReportTotal.expensesFederativeEntity[i].potentialRevenue) * 100;
      }
    }
  }

  private getFinancialDatasByCity(sphereAdm: number, financialDataType: number, financialsDatas: Array<FinancialData>, cityId: number): number {

    for (let i = 0; i < financialsDatas.length; i++) {
      if (sphereAdm === financialsDatas[i].sphereAdmId && financialDataType === financialsDatas[i].financialDataId && cityId === financialsDatas[i].cityId) {
        return financialsDatas[i].value;
      }
    }
    return 0;
  }

  private getFinancialDatasByState(sphereAdm: number, financialDataType: number, financialsDatas: Array<FinancialData>, stateId: number): number {

    for (let i = 0; i < financialsDatas.length; i++) {
      if (sphereAdm === financialsDatas[i].sphereAdmId && financialDataType === financialsDatas[i].financialDataId && stateId === financialsDatas[i].stateId) {
        return financialsDatas[i].value;
      }
    }
    return 0;
  }

  private getEmptyEmployeeEstimateByRole(): Array<EmployeeEstimateByRole> {
    const employeesEstimateByRole = new Array<EmployeeEstimateByRole>();
    const schoolsStaffs = this.sessionService.getItem<SchoolsStaff>(Functionality.schoolsStaff.key);
    const simulationYears = this.utilitiesService.getSimulationYears();

    for (let i = 0; i < schoolsStaffs.staffs.length; i++) {
      employeesEstimateByRole.push(
        new EmployeeEstimateByRole({
          id: schoolsStaffs.staffs[i].sequence,
          description: schoolsStaffs.staffs[i].denomination,
          isEducationAssistant: schoolsStaffs.staffs[i].isEducationAssistant,
          employeesEstimatesByYear: simulationYears.map(years => new EmployeeEstimateByYear({ year: years, quantity: 0 }))
        })
      );
    }

    employeesEstimateByRole.push(
      new EmployeeEstimateByRole({
        description: 'TOTAL',
        employeesEstimatesByYear: simulationYears.map(years => new EmployeeEstimateByYear({ year: years, quantity: 0 }))
      })
    );

    return employeesEstimateByRole;
  }

}
