import { CostDriveByYear } from './cost-drive-by-year';

export class CostDriveByStage {

  id: number;
  description: string;
  costDrivesByYears: Array<CostDriveByYear>;

  constructor(init?: Partial<CostDriveByStage>) {
    Object.assign(this, init);
  }

}
