import { StudentCostByYear } from './student-cost-by-year';
import { StudentCostBySubitem } from './student-cost-by-subitem';

export class StudentCostByStage {

  id: number;
  description: string;
  studentsCostBySubitems: Array<StudentCostBySubitem>;
  studentsCostByYearTotal: Array<StudentCostByYear>;

  constructor(init?: Partial<StudentCostByStage>) {
    Object.assign(this, init);
  }

}
