import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';

import { ExpenseByYear } from '../../financing-funds-report/entities/expense-by-year';
import { ExpenseByState } from '../../financing-funds-report/entities/expense-by-state';
import { ExpenseByItem } from '../../financing-funds-report/entities/expense-by-item';
import { Expense } from '../../financing-funds-report/entities/expense';
import { EnrollmentProjectionByLocation } from '../../../access-and-offer/enrollment-projection/entities/enrollment-projection-by-location';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { SessionService } from '../../../../shared/services/session/session.service';
import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { EnrollmentProjection } from '../../../access-and-offer/enrollment-projection/entities/enrollment-projection';
import { StudentCostByStage } from '../../../shared/services/calculate-student-cost/entities/student-cost-by-stage';
import { ExpenseTypeEnum } from '../../../../shared/entities/enums/expense-type.enum';
import { StudentCost } from '../../../shared/services/calculate-student-cost/entities/student-cost';
import { CapitalExpense } from '../../../shared/services/calculate-capital-expense/entities/capital-expense';

@Injectable({
  providedIn: 'root'
})
export class BudgetForecastService {

  constructor(private utilitiesService: UtilitiesService, private sessionService: SessionService) { }

  getExpense(studentsCost: Array<StudentCost>, capitalExpense: CapitalExpense = undefined): Observable<ExpenseByState> {

    const resultForEnrollmentProjection: EnrollmentProjection = this.sessionService.getItem<EnrollmentProjection>(Functionality.enrollmentProjection.key);
    const expense: Expense = new Expense({
      expensesBasicEducation: new Array<ExpenseByItem>()
    });
    const enrollmentProjection: EnrollmentProjectionByLocation = _.head(resultForEnrollmentProjection.enrollmentsProjectionsByLocations);
    const expenseByState = new ExpenseByState();
    const years = this.utilitiesService.getSimulationYears(1);

    expense.years = this.utilitiesService.getSimulationYears();
    expense.offerYear = resultForEnrollmentProjection.offerYear;

    this.getExpensesBasicEducation(expense, studentsCost, true);

    for (let i = 0; i < expense.expensesBasicEducation.length; i++) {
      for (let j = 0; j < years.length; j++) {
        if (expense.expensesBasicEducation[i].description === 'Despesas correntes') {
          expenseByState.current = expense.expensesBasicEducation[i].expensesByYears[j].value;
        }
        if (capitalExpense) {
          if (expense.expensesBasicEducation[i].description === 'Despesas de capital') {
            expenseByState.capital = capitalExpense.capitalExpenseByYears[j].value;
          }
        }
      }
    }

    expenseByState.total = expenseByState.capital + expenseByState.current;

    return of(expenseByState);
  }

  private getExpensesBasicEducation(budgetForecast: Expense, studentsCost: Array<StudentCost>, isFinancingFundsReport: boolean = false): void {

    const budgetsForecastReportByYearsCorrenteExpenses: Array<ExpenseByYear> = new Array<ExpenseByYear>();
    const budgetsForecastReportByYearsCapitalExpenses: Array<ExpenseByYear> = new Array<ExpenseByYear>();
    const budgetsForecastReportByYearsTotal: Array<ExpenseByYear> = new Array<ExpenseByYear>();
    const studentsCostByStages: Array<StudentCostByStage> = new Array<StudentCostByStage>();
    const years = this.utilitiesService.getSimulationYears();

    years.map(year => {
      budgetsForecastReportByYearsCorrenteExpenses.push(new ExpenseByYear({
        year: year,
        value: 0
      }));

      budgetsForecastReportByYearsCapitalExpenses.push(new ExpenseByYear({
        year: year,
        value: 0
      }));
    });

    for (let i = 0; i < studentsCost.length; i++) {
      const studentCost = studentsCost[i];
      for (let j = 0; j < studentCost.studentsCostByLocations.length; j++) {
        const studentCostByLocation = studentCost.studentsCostByLocations[j];
        for (let k = 0; k < studentCostByLocation.studentsCostByStages.length; k++) {
          studentsCostByStages.push(studentCostByLocation.studentsCostByStages[k]);
        }
      }
    }

    // expensesBasicEducation
    for (let i = 0; i < studentsCostByStages.length; i++) {
      const studentCostByStage = studentsCostByStages[i];

      for (let j = 0; j < studentCostByStage.studentsCostBySubitems.length; j++) {

        const studentCostBySubitem = studentCostByStage.studentsCostBySubitems[j];
        for (let k = 0; k < studentCostBySubitem.studentsCostByYear.length; k++) {
          const studentCostByYear = studentCostBySubitem.studentsCostByYear[k];
          if (studentCostBySubitem.expenseType_id === ExpenseTypeEnum.Corrente) {
            budgetsForecastReportByYearsCorrenteExpenses[k].value += studentCostByYear.costShiftIntegral + studentCostByYear.costShiftPartial;
          } else if (studentCostBySubitem.expenseType_id === ExpenseTypeEnum.Capital) {
            budgetsForecastReportByYearsCapitalExpenses[k].value += studentCostByYear.costShiftIntegral + studentCostByYear.costShiftPartial;
          }
        }
      }
    }

    // total of expensesBasicEducation
    for (let i = 0; i < years.length; i++) {

      const correnteExpenses = budgetsForecastReportByYearsCorrenteExpenses[i] ? budgetsForecastReportByYearsCorrenteExpenses[i].value : 0;
      const capitalExpenses = budgetsForecastReportByYearsCapitalExpenses[i] ? budgetsForecastReportByYearsCapitalExpenses[i].value : 0;
      budgetsForecastReportByYearsTotal.push(new ExpenseByYear({
        year: years[i],
        value: correnteExpenses + capitalExpenses
      }));
    }

    budgetForecast.expensesBasicEducation.push(new ExpenseByItem({
      description: 'Despesas correntes',
      expensesByYears: budgetsForecastReportByYearsCorrenteExpenses
    }));

    budgetForecast.expensesBasicEducation.push(new ExpenseByItem({
      description: 'Despesas de capital',
      expensesByYears: budgetsForecastReportByYearsCapitalExpenses
    }));

    budgetForecast.expensesBasicEducation.push(new ExpenseByItem({
      description: 'TOTAL',
      expensesByYears: budgetsForecastReportByYearsTotal
    }));
  }
}
