import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MatomoTracker } from 'ngx-matomo';

import { BaseUnsubscribe } from '../../shared/entities/base/base-unsubscribe';
import { Functionality } from '../../shared/entities/functionality/functionality';
import { SimulatorService } from './services/simulator.service';
import { SimulationType } from './entities/enums/simulation-type.enum';
import { SessionService } from '../../shared/services/session/session.service';
import { UtilitiesService } from '../../shared/services/utilities/utilities.service';
import { TutorialsComponent } from '../../shared/components/modal/tutorials/tutorials.component';

@Component({
  selector: 'app-simulator',
  templateUrl: './simulator.component.html',
  styleUrls: ['./simulator.component.scss']
})
export class SimulatorComponent extends BaseUnsubscribe implements OnInit {

  readonly caq: SimulationType = SimulationType.caq;
  readonly planning: SimulationType = SimulationType.planning;
  readonly planningByStateSphereAdm: SimulationType = SimulationType.planningByStateSphereAdm;
  readonly planningByCitySphereAdm: SimulationType = SimulationType.planningByCitySphereAdm;

  readonly financing: SimulationType = SimulationType.financing;
  readonly financingFederatedEntitiesByCitySphereAdm: SimulationType = SimulationType.financingFederatedEntitiesByCitySphereAdm;
  readonly financingFederatedEntitiesByStateSphereAdm: SimulationType = SimulationType.financingFederatedEntitiesByStateSphereAdm;
  readonly financingFederatedEntitiesGroupByCityOrState: SimulationType = SimulationType.financingFederatedEntitiesGroupByCityOrState;

  initializing: boolean = false;
  simulationType: SimulationType;
  simulationPerformed: boolean = false;
  simulationEditMode: boolean = false;
  selectLocationName: string = Functionality.selectLocation.name;
  simulationTimeName: string = Functionality.simulationTime.name;
  accessAndOfferName: string = Functionality.accessandoffer.name;
  qualityconditionsName: string = Functionality.qualityconditions.name;
  chargesAndAdditionalsName: string = Functionality.chargesandadditionals.name;
  resultsName: string = Functionality.results.name;

  constructor(private simulatorService: SimulatorService, private sessionService: SessionService, private bsModalService: BsModalService, private matomoTracker: MatomoTracker) {
    super();

    this.simulatorService.simulationTypeObserver.pipe(
      takeUntil(this.unsubscribe))
      .subscribe(simulationType => {
        this.resetFlags();
        this.simulationType = simulationType;
      });

    this.simulatorService.simulationPerformedObserver.pipe(
      takeUntil(this.unsubscribe))
      .subscribe(performed => {
        this.simulationPerformed = performed;
        this.sessionService.setItem(SimulatorService.simulationPerformedSessionKey, performed);
      });

    this.simulatorService.simulationEditModeObserver.pipe(
      takeUntil(this.unsubscribe))
      .subscribe(editMode => {
        this.simulationEditMode = editMode;
        this.sessionService.setItem(SimulatorService.simulationEditModeSessionKey, editMode);

        if (editMode) {
          this.resultsName = 'Novo resultado';
        }
      });
  }

  ngOnInit(): void {
    this.matomoTracker.setCustomUrl('/simulator');
    this.matomoTracker.trackPageView();

    const simulationTypeFromSession: SimulationType = this.sessionService.getItem<SimulationType>(UtilitiesService.simulationTypeSessionKey);
    const simulationPerformedromSession: boolean = this.sessionService.getItem<boolean>(SimulatorService.simulationPerformedSessionKey);
    const simulationEditModeFromSession: boolean = this.sessionService.getItem<boolean>(SimulatorService.simulationEditModeSessionKey);

    this.initializing = true;
    this.simulatorService.notifySimulationTypeObservers(simulationTypeFromSession);
    this.simulatorService.notifySimulationPerformedObservers(simulationPerformedromSession || false);
    this.simulatorService.notifySimulationEditModeObservers(simulationEditModeFromSession || false);
    this.initializing = false;
  }

  meetsConditionSelectLocation(): boolean {
    return (
      this.simulationType === SimulationType.planning ||
      this.simulationType === SimulationType.planningByStateSphereAdm ||
      this.simulationType === SimulationType.planningByCitySphereAdm ||
      this.simulationType === SimulationType.financing ||
      this.simulationType === SimulationType.financingFederatedEntitiesByCitySphereAdm ||
      this.simulationType === SimulationType.financingFederatedEntitiesByStateSphereAdm ||
      this.simulationType === SimulationType.financingFederatedEntitiesGroupByCityOrState
    ) &&
      !this.simulationEditMode && !this.simulationPerformed;
  }

  meetsConditionSimulationTime(): boolean {
    /*return (
      this.simulationType === SimulationType.planning ||
      this.simulationType === SimulationType.planningByStateSphereAdm ||
      this.simulationType === SimulationType.planningByCitySphereAdm
    ) &&
      this.simulationEditMode;*/
    return false;
  }

  meetsConditionsAccessAndOffer(): boolean {
    return (this.simulationType === SimulationType.planning ||
      this.simulationType === SimulationType.planningByStateSphereAdm ||
      this.simulationType === SimulationType.planningByCitySphereAdm ||
      this.simulationType === SimulationType.financing ||
      this.simulationType === SimulationType.financingFederatedEntitiesByCitySphereAdm ||
      this.simulationType === SimulationType.financingFederatedEntitiesByStateSphereAdm ||
      this.simulationType === SimulationType.financingFederatedEntitiesGroupByCityOrState
    ) &&
      this.simulationEditMode;
  }

  openTutorial(): void {
    this.bsModalService.show(TutorialsComponent, { ignoreBackdropClick: true, class: 'modal-lg modal-tutorial', initialState: { simulationType: this.simulationType, skipTutorialJustHide: true } });
  }

  private resetFlags(): void {
    if (!this.initializing) {
      this.simulationType = undefined;
      this.simulationPerformed = false;
      this.simulationEditMode = false;
      this.resultsName = Functionality.results.name;
    }
  }
}
