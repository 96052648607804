import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';
import { ItemCostExpenseReportByYear } from './item-cost-expense-report-by-year';
import { ItemCostExpenseReportByStage } from './item-cost-expense-report-by-stage';

export class ItemCostExpenseReport extends NavigableComponentData {

  id: number;
  description: string;
  itemNumber: number;
  header: boolean;
  mde: boolean;
  totalGeneral: boolean;
  groupTotalGeneral: boolean;
  totalMDE: boolean;
  custoAlunoMDE: boolean;
  foodsAndCookingGas: boolean;
  numeroMatriculas: boolean;
  custoAlunoAno: boolean;
  parent_id: number;
  itemsCostExpenseReportByStages: Array<ItemCostExpenseReportByStage>;
  itemCostExpenseTotal: ItemCostExpenseReportByYear;

  constructor(init?: Partial<ItemCostExpenseReport>) {
    super();
    Object.assign(this, init);
  }

}
