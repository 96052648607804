export class MatriculaPorEtapaEnsino {
    codEtapaEnsino: number;
    descricao: string;
    numeroMatriculasDiurno: number;
    numeroMatriculasNoturno: number;
    numeroMatriculasIntegral: number;
    percentualMatriculasIntegral: number;
    totalMatriculasDiurno: number;

    constructor(init?: Partial<MatriculaPorEtapaEnsino>) {
        Object.assign(this, init);
    }
}
