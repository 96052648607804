import { CostSharingByLocation } from './cost-sharing-by-location';

export class CostSharing {

  city_id: string;
  cityDescription: string;
  costsSharingByLocations: Array<CostSharingByLocation>;

  constructor(init?: Partial<CostSharing>) {
    Object.assign(this, init);
  }

}
