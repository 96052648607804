import { Component, DoCheck, Injector, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { IOption } from 'ng-select';
import { PageScrollService, PageScrollOptions } from 'ngx-page-scroll-core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

import { Functionality } from '../../shared/entities/functionality/functionality';
import { Inconsistency } from '../../shared/components/inconsistency/entities/inconsistency';
import { FunctionalityInfo } from '../../shared/entities/functionality/functionality-info';
import { SelectLocationService } from './services/select-location.service';
import { SelectedLocationInfo } from './entities/selected-location-info';
import { SelectLocation } from './entities/select-location';
import { State } from './entities/state';
import { City } from './entities/city';
import { BaseNavigableComponent } from '../../shared/entities/base/base-navigable-component';
import { Footnote } from '../../shared/components/footnote/entities/footnote';
import { SimulatorService } from '../simulator/services/simulator.service';
import { FunctionalityRoute } from '../../shared/entities/functionality/functionality-route';
import { SimulationType } from '../simulator/entities/enums/simulation-type.enum';
import { UtilitiesService } from 'app/shared/services/utilities/utilities.service';
import { SourceInformationEnum } from 'app/shared/entities/enums/source-information.enum';

type Note = {
  index: number
  description: string
};

@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.scss']
})

export class SelectLocationComponent extends BaseNavigableComponent<SelectLocation> implements OnInit, DoCheck {

  readonly planning: number = SimulationType.planning;
  readonly planningByStateSphereAdm: number = SimulationType.planningByStateSphereAdm;
  readonly planningByCitySphereAdm: number = SimulationType.planningByCitySphereAdm;
  readonly planningState: number = SimulationType.planningState;
  readonly planningCity: number = SimulationType.planningCity;
  readonly planningNational: number = SimulationType.planningNational;
  readonly financingFederatedEntitiesByCitySphereAdm: number = SimulationType.financingFederatedEntitiesByCitySphereAdm;
  readonly financingFederatedEntitiesByStateSphereAdm: number = SimulationType.financingFederatedEntitiesByStateSphereAdm;
  readonly financingFederatedEntitiesGroupByCityOrState: number = SimulationType.financingFederatedEntitiesGroupByCityOrState;
  readonly financingNationalFederatedEntities: number = SimulationType.financingNational;
  readonly financingNationalFederatedSchools: number = SimulationType.financingNationalSchool;

  disableRadioCountry: boolean = true;
  disableRadioState: boolean = true;
  disableRadioCity: boolean = false;
  disableRadioPlanning: boolean = true;
  hideRadioStateEducationNetWorkPlan: boolean = false;
  disableRadioFinancingState: boolean = true;
  disableRadioFinancingCity: boolean = true;
  hideRadioPlanning: boolean = true;
  hideRadioFinancing: boolean = true;
  hideRadioPlanningByState: boolean = true;
  hideRadioPlanningNational: boolean = true;
  hideRadioPlanningState: boolean = true;

  simulationTypeValue: string = '';

  data: SelectLocation = new SelectLocation();
  functionality: FunctionalityInfo = Functionality.selectLocation;
  inconsistencies: Array<Inconsistency>;
  seletedLocationInfo: SelectedLocationInfo = new SelectedLocationInfo();
  brazil: Array<IOption> = new Array<IOption>({ value: '0', label: 'BRASIL' });
  states: Array<State>;
  cities: Array<City>;
  selectedStateId: string;
  option1Name: string;
  option2Name: string;
  option3Name: string;

  // NOTAS
  notas: Array<Note> = new Array<Note>();
  //

  private prevSelectedState: State;

  constructor(private selectLocationService: SelectLocationService, private injector: Injector, private pageScrollService: PageScrollService, @Inject(DOCUMENT) private document: any) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();

    this.data.selectedSimulationType = this.sessionService.getItem(UtilitiesService.simulationTypeSessionKey);

    if (this.data.selectedSimulationType === SimulationType.financingFederatedEntitiesByCitySphereAdm) {
      //// this.simulationTypeValue = '7';
      this.simulationTypesChange(this.financingNationalFederatedSchools, '7');
      this.selectLocationService.notifySelectedSimulationTyleObservers(this.data.selectedSimulationType);
    } else if (this.data.selectedSimulationType === SimulationType.financingFederatedEntitiesGroupByCityOrState) {
      this.simulationTypesChange(this.financingFederatedEntitiesGroupByCityOrState, '');
    } else {
      //// this.simulationTypeValue = '4';
      this.simulationTypesChange(this.planning, '8');
    }
    this.controlRadioButtonState();
    // this.selectLocationService.notifySelectedSimulationTyleObservers(this.data.selectedSimulationType);

    const scrollOptions: PageScrollOptions = {
      document: this.document,
      scrollTarget: '.panel-footer',
      scrollOffset: -1000,
      duration: 0
    };

    this.pageScrollService.scroll(scrollOptions);

    setTimeout(() => {
      scrollOptions.scrollTarget = '.simulator';
      scrollOptions.scrollOffset = 1000;
      scrollOptions.duration = 500;
      this.pageScrollService.scroll(scrollOptions);
    }, 750);

    this.selectLocationService.getEstados().pipe(
      takeUntil(this.unsubscribe))
      .subscribe(states => {
        this.states = states;
      });

    /*this.selectLocationService.getCidades(this.data.selectedState).pipe(
      takeUntil(this.unsubscribe))
      .subscribe(cities => {
        this.cities = cities;
      });*/

    this.setLocationInfo();

    //// this.simulationTypesChange(this.planning, '4');
  }

  ngDoCheck(): void {
    if (!_.isEqual(this.prevSelectedState, this.data.selectedState)) {
      this.stateChange(null, this.prevSelectedState ? false : true);
      this.prevSelectedState = this.data.selectedState;
    }
  }

  stateChange(selectedState: State, setSelectedCity: boolean): void {

    this.clearCity(false);
    this.cities = undefined;

    if (this.data.selectedSimulationType === undefined) {
      this.data.selectedSimulationType = this.sessionService.getItem(UtilitiesService.simulationTypeSessionKey);
    }

    if (selectedState) {
      this.data.selectedState = selectedState;
    }

    if (this.data.selectedState) {
      this.selectedStateId = this.data.selectedState.value;
      this.selectLocationService.notifySelectedSimulationTyleObservers(this.planningState);

      //// this.disableRadioCountry = true;
      //// this.disableRadioState = false;
      //// this.disableRadioCity = true;

      this.selectLocationService.getCidades(this.data.selectedState).pipe(
        takeUntil(this.unsubscribe))
        .subscribe(cities => {
          this.cities = cities;

          if (setSelectedCity) {
            if (this.data.selectedCity) {
              this.data.selectedCityId = this.data.selectedCity.value;
            }
          } else {
            this.clearCity(false);
          }

          this.selectLocationService.notifySelectedLocationObservers(this.data);
        });

      if (this.data.selectedSimulationType === SimulationType.planning ||
        this.data.selectedSimulationType === SimulationType.planningByCitySphereAdm ||
        this.data.selectedSimulationType === SimulationType.planningByStateSphereAdm) {

        this.simulationTypesChange(this.planning, '2');

      } else if (this.data.selectedSimulationType === SimulationType.financingFederatedEntitiesGroupByCityOrState) {

        this.simulationTypesChange(this.financingFederatedEntitiesGroupByCityOrState, '');

      } else if (this.data.selectedSimulationType === SimulationType.financingNationalSchool ||
        this.data.selectedSimulationType === SimulationType.financingFederatedEntitiesByCitySphereAdm ||
        this.data.selectedSimulationType === SimulationType.financingFederatedEntitiesByStateSphereAdm) {

        if (this.data.selectedCity) {
          this.simulationTypesChange(this.financingFederatedEntitiesByCitySphereAdm, ''); //// 5,2
        } else {
          this.simulationTypesChange(this.financingFederatedEntitiesByStateSphereAdm, ''); //// 5,1
        }

      } else {

        this.controlRadioButtonState();
      }

    } else {
      this.clearCity(false);
      this.cities = undefined;
      this.clearState();
      this.selectLocationService.notifySelectedLocationObservers(null);
      this.selectLocationService.notifySelectedSimulationTyleObservers(this.planningNational);

      //// this.disableRadioCountry = false;
      //// this.disableRadioState = true;
      //// this.disableRadioCity = true;

      if (this.data.selectedSimulationType === SimulationType.planning ||
        this.data.selectedSimulationType === SimulationType.planningByCitySphereAdm ||
        this.data.selectedSimulationType === SimulationType.planningByStateSphereAdm) {
        this.simulationTypesChange(this.planning, '1');
      } else if (this.data.selectedSimulationType === SimulationType.financingFederatedEntitiesGroupByCityOrState) {
        this.simulationTypesChange(this.financingFederatedEntitiesGroupByCityOrState, '');
      } else if (this.data.selectedSimulationType === SimulationType.financingNationalSchool ||
        this.data.selectedSimulationType === SimulationType.financingFederatedEntitiesByCitySphereAdm ||
        this.data.selectedSimulationType === SimulationType.financingFederatedEntitiesByStateSphereAdm) {
        this.simulationTypesChange(this.financingFederatedEntitiesByCitySphereAdm, '');
      } else {
        this.controlRadioButtonState();
      }

    }

    this.setLocationInfo();
    // this.processSpecificBehaviors();
  }

  cityChange(selectedCity: City): void {

    if (this.data.selectedSimulationType === undefined) {
      this.data.selectedSimulationType = this.sessionService.getItem(UtilitiesService.simulationTypeSessionKey);
    }

    this.data.selectedCity = selectedCity;
    this.selectLocationService.notifySelectedLocationObservers(this.data);
    this.setLocationInfo();
    // this.processSpecificBehaviors();

    //// this.disableRadioCountry = true;
    //// this.disableRadioState = true;
    //// this.disableRadioCity = false;
    if (this.data.selectedSimulationType === SimulationType.planning || this.data.selectedSimulationType === SimulationType.planningNational ||
      this.data.selectedSimulationType === SimulationType.planningByCitySphereAdm ||
      this.data.selectedSimulationType === SimulationType.planningByStateSphereAdm) {

      this.simulationTypesChange(this.planning, '4');
      // this.selectLocationService.notifySelectedSimulationTyleObservers(this.planningCity);

    } else if (this.data.selectedSimulationType === SimulationType.financingFederatedEntitiesGroupByCityOrState) {
      this.simulationTypesChange(this.financingFederatedEntitiesGroupByCityOrState, '');

    } else {
      this.simulationTypesChange(this.financingFederatedEntitiesByCitySphereAdm, '7');
      //// foçando para o rel2
      /*this.simulationTypesChange(this.planning, '4');*/
      this.controlRadioButtonState();
    }

  }

  simulationTypesChange(type: number, value: string) {
    this.sessionService.setItem(UtilitiesService.simulationTypeSessionKey, Number(type));
    this.data.selectedSimulationType = type;
    this.simulationTypeValue = value;

    if (type === SimulationType.planning || type === SimulationType.planningByStateSphereAdm || type === SimulationType.planningByCitySphereAdm) {
      if (this.data.selectedCity !== undefined) {
        if (Number(value) !== SimulationType.planningByCitySphereAdm) {
          this.selectLocationService.notifySelectedSimulationTyleObservers(this.planningCity);
        } else {
          this.selectLocationService.notifySelectedSimulationTyleObservers(type);
        }
      } else if (this.data.selectedState !== undefined) {
        if (Number(value) !== SimulationType.planningByStateSphereAdm) {
          this.selectLocationService.notifySelectedSimulationTyleObservers(this.planningState);
        } else {
          this.selectLocationService.notifySelectedSimulationTyleObservers(type);
        }
      } else {
        this.selectLocationService.notifySelectedSimulationTyleObservers(this.planningNational);
      }
    } else if (type === SimulationType.financingFederatedEntitiesGroupByCityOrState) {
      if (this.data.selectedCity !== undefined) {
        this.selectLocationService.notifySelectedSimulationTyleObservers(this.financingFederatedEntitiesByCitySphereAdm);
      } else if (this.data.selectedState !== undefined) {
        this.selectLocationService.notifySelectedSimulationTyleObservers(this.financingFederatedEntitiesByStateSphereAdm);
      } else {
        this.selectLocationService.notifySelectedSimulationTyleObservers(this.financingNationalFederatedEntities);
      }
    } else {
      // rel4 - por escola
      // this.selectLocationService.notifySelectedSimulationTyleObservers(this.data.selectedSimulationType);
      if (type === SimulationType.financingNationalSchool) {
        this.selectLocationService.notifySelectedSimulationTyleObservers(this.financingFederatedEntitiesByStateSphereAdm);
      } else if (this.data.selectedCity !== undefined) {
        this.selectLocationService.notifySelectedSimulationTyleObservers(this.financingFederatedEntitiesByCitySphereAdm);
      } else if (this.data.selectedState !== undefined) {
        this.selectLocationService.notifySelectedSimulationTyleObservers(this.financingFederatedEntitiesByStateSphereAdm);
      } else {
        this.selectLocationService.notifySelectedSimulationTyleObservers(this.financingNationalFederatedSchools);
      }
    }

    this.processSpecificBehaviors();
    this.controlRadioButtonState();
  }

  getSimulationType(): Number {
    let type: Number;

    if (this.data.selectedSimulationType === undefined) {
      type = this.sessionService.getItem(UtilitiesService.simulationTypeSessionKey);
    } else {
      type = this.data.selectedSimulationType;
    }
    return type;
  }

  controlRadioButtonState() {

    const type = this.getSimulationType();
    //this.notas.push({ index: 10, description: type.toString() });
    //console.log("type...:" + type);

    if (type === SimulationType.planning || type === SimulationType.planningNational || type === SimulationType.planningByCitySphereAdm || type === SimulationType.planningByStateSphereAdm) {

      if (this.data.selectedCity === undefined && this.data.selectedState === undefined) {
        this.hideRadioPlanning = false;
        this.disableRadioPlanning = false;
        this.hideRadioStateEducationNetWorkPlan = true;
        this.hideRadioFinancing = true;
        this.hideRadioPlanningByState = true;
        this.hideRadioPlanningNational = false;
        this.hideRadioPlanningState = true;
        this.option3Name = 'Plano nacional de educação';
        this.simulationTypeValue = '8';
        this.notas.length = 0;
        this.notas.push({
          index: 8, description: 'No Plano nacional de educação, a simulação considera, conjuntamente, ' +
            'os dados das etapas da educação básica com ofertas prioritárias na rede pública estadual e municipal.'
        });

      } else if (this.data.selectedCity === undefined && this.data.selectedState !== undefined) {
        this.hideRadioStateEducationNetWorkPlan = false;
        this.hideRadioPlanning = true;
        this.disableRadioPlanning = false;
        this.hideRadioFinancing = true;
        this.hideRadioPlanningNational = true;
        this.hideRadioPlanningState = false;
        this.simulationTypeValue = '4';
        this.option1Name = 'Plano estadual de educação';
        this.option2Name = 'Plano da rede estadual de educação';
        this.notas.length = 0;
        this.notas.push({
          index: 9, description: 'No Plano estadual de educação, a simulação considera, conjuntamente, os dados etapas da educação básica ' +
            'com ofertas prioritárias da rede pública estadual e municipal.'
        });
        this.notas.push({
          index: 10, description: 'No Plano da rede estadual de educação, são considerados exclusivamente os dados das ' +
            'etapas da educação básica com oferta prioritária da rede pública estadual.'
        });

      } else if (this.data.selectedCity !== undefined) {
        this.hideRadioStateEducationNetWorkPlan = false;
        this.hideRadioPlanning = false;
        this.disableRadioPlanning = false;
        this.hideRadioFinancing = true;
        this.hideRadioPlanningNational = true;
        this.hideRadioPlanningState = true;
        this.simulationTypeValue = '4';
        this.option1Name = 'Plano municipal de educação';
        this.option2Name = 'Plano da rede municipal de educação';
        this.notas.length = 0;
        this.notas.push({
          index: 9, description: 'No Plano municipal de educação, a simulação considera, conjuntamente, os dados das etapas ' +
            'da educação básica com ofertas prioritárias da rede pública estadual e municipal.'
        });
        this.notas.push({
          index: 10, description: 'No Plano da rede municipal de educação, são considerados exclusivamente os dados das ' +
            'etapas da educação básica com oferta prioritária da rede pública municipal.'
        });
        if (this.data.selectedCity.value === '5300108') {
          this.notas.pop();
          this.notas.push({ index: undefined, description: 'Brasília só permite consulta no nível do plano municipal, pois tem ao mesmo tempo funções de estado e município. ' });
          this.hideRadioStateEducationNetWorkPlan = true;
        }
        if (this.data.selectedCity.value === '2605459') {
          this.notas.pop();
          this.notas.push({ index: undefined, description: 'Fernando de Noronha só permite consulta no nível do plano municipal, pois é considerado um distrito estadual de Pernambuco' });
          this.hideRadioStateEducationNetWorkPlan = true;
        }
      }

      /*if (this.data.selectedCity !== undefined) {
        this.hideRadioPlanning = false;
        this.disableRadioPlanning = false;
        this.hideRadioFinancing = true;

      } else {
        this.hideRadioPlanning = false;
        this.disableRadioPlanning = true;
        this.hideRadioFinancing = true;
      }*/
      // this.disableRadioPlanning = false;

    } else if (type === SimulationType.financingFederatedEntitiesByCitySphereAdm ||
      type === SimulationType.financingFederatedEntitiesByStateSphereAdm) {
      this.hideRadioFinancing = false;
      this.hideRadioPlanning = true;
      this.disableRadioPlanning = true;
      if (this.data.selectedCity === undefined) {

        this.disableRadioFinancingCity = true;
        this.disableRadioFinancingState = false;
        //// this.simulationTypeValue = '6';

      } else {
        this.disableRadioFinancingState = true;
        this.disableRadioFinancingCity = false;
      }
    } else if (type === SimulationType.financingFederatedEntitiesGroupByCityOrState) {

      if (this.data.selectedCity === undefined && this.data.selectedState === undefined) {

        this.notas.length = 0;
        this.notas.push({ index: undefined, description: 'A simulação apresenta dados das escolas da rede pública estadual e da rede municipal.' });

      } else if (this.data.selectedCity === undefined && this.data.selectedState !== undefined) {

        this.notas.length = 0;
        this.notas.push({ index: undefined, description: 'A simulação apresenta dados das escolas da rede pública estadual e todas as redes municipais do estado.' });

      } else if (this.data.selectedCity !== undefined) {

        this.notas.length = 0;
        this.notas.push({ index: undefined, description: 'A simulação apresenta dados das escolas da rede pública municipal.' });

      }
    } else if (type === SimulationType.financingNationalSchool) {
      if (this.data.selectedCity === undefined && this.data.selectedState === undefined) {
        this.simulationTypesChange(SimulationType.financingFederatedEntitiesByStateSphereAdm, '6')
        this.notas.length = 0;
        this.notas.push({ index: undefined, description: 'A simulação apresenta dados das escolas da rede pública estadual e da rede municipal.' });

      } else if (this.data.selectedCity === undefined && this.data.selectedState !== undefined) {
        this.notas.length = 0;
        this.notas.push({ index: undefined, description: 'A simulação apresenta dados das escolas da rede pública estadual.' });

      } else if (this.data.selectedCity !== undefined) {

        this.notas.length = 0;
        this.notas.push({ index: undefined, description: 'A simulação apresenta dados das escolas da rede pública municipal.' });
      }

    } else {
      this.hideRadioPlanning = true;
      this.hideRadioFinancing = true;
    }

  }

  clearState(): void {
    this.data.selectedState = undefined;
    this.selectedStateId = undefined;
    this.selectLocationService.notifySelectedLocationObservers(null);

    if (this.data.selectedSimulationType === SimulationType.planning ||
      this.data.selectedSimulationType === SimulationType.planningByCitySphereAdm ||
      this.data.selectedSimulationType === SimulationType.planningByStateSphereAdm) {
      this.simulationTypesChange(this.planning, '1');
    } else {
      this.controlRadioButtonState();
    }
  }

  clearCity(processInformations: boolean): void {

    this.disableRadioCountry = true;
    this.disableRadioState = false;
    //// this.simulationTypesChange(this.planning, '2');
    this.disableRadioCity = true;

    this.data.selectedCity = undefined;
    this.data.selectedCityId = undefined;
    this.selectLocationService.notifySelectedLocationObservers(this.data.selectedState ? this.data : null);

    if (processInformations) {
      this.processSpecificBehaviors();
    }

    if (this.data.selectedSimulationType === SimulationType.planning ||
      this.data.selectedSimulationType === SimulationType.planningByCitySphereAdm ||
      this.data.selectedSimulationType === SimulationType.planningByStateSphereAdm) {
      this.simulationTypesChange(this.planning, '2');
    } else {
      this.controlRadioButtonState();
    }

  }

  getSimulationTypeNotes(): Array<Footnote> {

    const simulationTypeNote: Array<Footnote> = new Array<Footnote>();

    if (this.notas.length > 0) {

      this.notas.map((n) => {
        simulationTypeNote.push(new Footnote({ indice: n.index, note: n.description }));
      });

    } else {

      simulationTypeNote.push(new Footnote({
        indice: 8, //// this.data.selectedState || this.data.selectedCity ? 9 : 8,
        note: this.data.selectedCity ? 'Nas opções plano municipal de Educação, a simulação considera, conjuntamente, os dados da rede pública estadual e municipal;'
          : 'Nas opções plano Estadual de Educação, a simulação considera, conjuntamente, os dados da rede pública estadual e municipal;'
      }));

      simulationTypeNote.push(new Footnote({
        indice: 9, //// this.data.selectedState || this.data.selectedCity ? 10 : 9,
        note: this.data.selectedCity ? 'O plano da rede municipal considera exclusivamente os dados da rede pública municipal'
          : 'O plano da rede estadual considera exclusivamente os dados da rede pública estadual.'
      }));

    }

    return simulationTypeNote;
  }

  getSociodemographicNotes(): Array<Footnote> {
    const sourceInformations: Array<Footnote> = new Array<Footnote>();

    if (this.seletedLocationInfo.sociodemographic) {
      this.seletedLocationInfo.sociodemographic.map(sociodemographic => {
        if (sociodemographic.footnote.note) {     // Returns only info notes
          sourceInformations.push(sociodemographic.footnote);
        }
      });
    }

    if (this.seletedLocationInfo.pibPerCapitaByLevel) {
      sourceInformations.push(this.seletedLocationInfo.pibPerCapitaByLevel.footnote);
    }

    return sourceInformations;
  }

  getSociodemographicSourceInformations(): Array<Footnote> {
    const sourceInformations: Array<Footnote> = new Array<Footnote>();

    sourceInformations.push(new Footnote({ sourceInformation: SourceInformationEnum.population, indice: 1 }));
    sourceInformations.push(new Footnote({ sourceInformation: SourceInformationEnum.pibPerCapita, indice: 2 }));
    sourceInformations.push(new Footnote({ sourceInformation: SourceInformationEnum.idhm, indice: 3 }));

    /*if (this.seletedLocationInfo.sociodemographic) {
      this.seletedLocationInfo.sociodemographic.map(sociodemographic => {
        if (sociodemographic.footnote.sourceInformation) {    // Returns only source notes
          sourceInformations.push(sociodemographic.footnote);
        }
      });
    }*/

    // if (this.seletedLocationInfo.idhmByLevel) {
    //   sourceInformations.push(this.seletedLocationInfo.idhmByLevel.footnote);
    // }

    return sourceInformations;
  }

  setLocationInfo(): void {
    this.selectLocationService.getSelectedLocationInfo(this.data).pipe(
      takeUntil(this.unsubscribe))
      .subscribe(seletedLocationInfo => this.seletedLocationInfo = seletedLocationInfo);
  }

  processData(): Observable<SelectLocation> {
    return this.selectLocationService.getData();
  }

  processSpecificBehaviors(): void {
    super.processSpecificBehaviors();

    /* this.selectLocationService.getSelectedLocationInfo(this.data).pipe(
       takeUntil(this.unsubscribe))
       .subscribe(seletedLocationInfo => this.seletedLocationInfo = seletedLocationInfo);*/
  }

  dataHasChanged(): boolean {
    return this.previousData && (!_.isEqual(this.data.selectedState, this.previousData.selectedState) || !_.isEqual(this.data.selectedCity, this.previousData.selectedCity));
  }

  getNextStepLabel(): string {
    return 'Simular';
  }

  getNextStepRoute(): Array<string> {
    const simulationEditMode: boolean = this.sessionService.getItem<boolean>(SimulatorService.simulationEditModeSessionKey);

    if (simulationEditMode) {
      return this.functionality.nextStep;
    } else {
      return FunctionalityRoute.processingSimulation;
    }
  }

  processValidation(): void {
    super.processValidation();

    /*if (this.data.selectedCity === undefined) {
      this.inconsistencies.push(new Inconsistency({
        message: 'Nenhum Município foi informado.'
      }));
    }*/

    // // if (this.data.selectedSimulationType === SimulationType.planning ||
    // //   this.data.selectedSimulationType === SimulationType.planningByCitySphereAdm ||
    // //   this.data.selectedSimulationType === SimulationType.planningByStateSphereAdm) {

    // //     if ( this.data.selectedCity === undefined) {
    // //       this.inconsistencies.push(new Inconsistency({
    // //         message: 'Nenhum Município foi informado.'
    // //       }));
    // //   }

    // // } else {

    // //   if (this.simulationTypeValue !== '6' && this.simulationTypeValue !== '7') {
    // //     this.inconsistencies.push(new Inconsistency({
    // //       message: 'Escolha uma das redes: Estadual ou Municipal.'
    // //     }));
    // //   }
    // // }

  }

}
