import { DescriptionValue } from '../entities/description-value';

export class DadosSocioDemograficos {

    quantidadePopulacao: number;
    pibPercapita: number;
    valorIdhm: number;
    pibPercapitaPorQuintil: Array<DescriptionValue>;
    vlrQuintil: number;
    nivelIdhm: string;

    constructor(init?: Partial<DadosSocioDemograficos>) {
        Object.assign(this, init);
    }
}
