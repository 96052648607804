import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { BaseUnsubscribe } from '../../shared/entities/base/base-unsubscribe';
import { AuthService } from '../../shared/services/auth/auth.service';
import { PqrService } from './services/pqr.service';
import { PqrComponentData } from '../shared/entities/base/pqr-component-data';
import { SchoolDayPerWeekComponent } from '../quality-conditions/school-day-per-week/school-day-per-week.component';
import { DailyTeachingLoadComponent } from '../quality-conditions/daily-teaching-load/daily-teaching-load.component';
import { OfferGoalEnrollmentFullTimeComponent } from '../quality-conditions/offer-goal-enrollment-full-time/offer-goal-enrollment-full-time.component';
import { NumberStudentClassComponent } from '../quality-conditions/number-student-class/number-student-class.component';
import { WorkJourneyTeacherComponent } from '../quality-conditions/work-journey-teacher/work-journey-teacher.component';
import { CareerAndRemunerationTeachersComponent } from '../quality-conditions/career-and-remuneration-teachers/career-and-remuneration-teachers.component';
import { InfrastructureSchoolBuildingsComponent } from '../quality-conditions/infrastructure-school-buildings/infrastructure-school-buildings.component';
import { NewRoomBuildingComponent } from '../quality-conditions/new-room-building/new-room-building.component';
import { SchoolsStaffComponent } from '../quality-conditions/schools-staff/schools-staff.component';
import { SchoolFeedingComponent } from '../quality-conditions/school-feeding/school-feeding.component';
import { SchoolsOperationComponent } from '../quality-conditions/schools-operation/schools-operation.component';
import { TeacherTrainingComponent } from '../quality-conditions/teacher-training/teacher-training.component';
import { SocialChargesComponent } from '../charges-and-additionals/social-charges/social-charges.component';
import { AdministrativeAreaCostingComponent } from '../quality-conditions/administrative-area-costing/administrative-area-costing.component';
import { AdditionalsComponent } from '../charges-and-additionals/additionals/additionals.component';

@Component({
  selector: 'app-pqr',
  templateUrl: './pqr.component.html',
  styleUrls: ['./pqr.component.scss']
})
export class PqrComponent extends BaseUnsubscribe implements OnInit, AfterViewInit {

  @ViewChild(SchoolDayPerWeekComponent, { static: true }) schoolDayPerWeekComponent: SchoolDayPerWeekComponent;
  @ViewChild(DailyTeachingLoadComponent, { static: true }) dailyTeachingLoadComponent: DailyTeachingLoadComponent;
  @ViewChild(OfferGoalEnrollmentFullTimeComponent, { static: true }) offerGoalEnrollmentFullTimeComponent: OfferGoalEnrollmentFullTimeComponent;
  @ViewChild(NumberStudentClassComponent, { static: true }) numberStudentClassComponent: NumberStudentClassComponent;
  @ViewChild(WorkJourneyTeacherComponent, { static: true }) workJourneyTeacherComponent: WorkJourneyTeacherComponent;
  @ViewChild(CareerAndRemunerationTeachersComponent, { static: true }) careerAndRemunerationTeachersComponent: CareerAndRemunerationTeachersComponent;
  @ViewChild(AdditionalsComponent, { static: true }) additionalsComponent: AdditionalsComponent;
  @ViewChild(SchoolsStaffComponent, { static: true }) schoolsStaffComponent: SchoolsStaffComponent;
  @ViewChild(TeacherTrainingComponent, { static: true }) teacherTrainingComponent: TeacherTrainingComponent;
  @ViewChild(SchoolsOperationComponent, { static: true }) schoolsOperationComponent: SchoolsOperationComponent;
  @ViewChild(SocialChargesComponent, { static: true }) socialChargesComponent: SocialChargesComponent;
  @ViewChild(AdministrativeAreaCostingComponent, { static: true }) administrativeAreaCostingComponent: AdministrativeAreaCostingComponent;
  @ViewChild(InfrastructureSchoolBuildingsComponent, { static: true }) infrastructureSchoolBuildingsComponent: InfrastructureSchoolBuildingsComponent;
  @ViewChild(NewRoomBuildingComponent, { static: true }) newRoomBuildingComponent: NewRoomBuildingComponent;
  @ViewChild(SchoolFeedingComponent, { static: true }) schoolFeedingComponent: SchoolFeedingComponent;

  components: Array<PqrComponentData> = new Array<PqrComponentData>();
  pqrAdminMode: boolean;
  accordionIsOpen: boolean;

  private sessionMode: boolean;

  constructor(private pqrService: PqrService, private authService: AuthService, private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.route.queryParamMap.pipe(
      takeUntil(this.unsubscribe))
      .subscribe(params => {
        const sessionModeParamValue: string = params.get('pqrsession');
        this.sessionMode = sessionModeParamValue && sessionModeParamValue.toLowerCase() === 'true';
      });
  }

  ngAfterViewInit(): void {
    this.components.push(
      this.schoolDayPerWeekComponent,
      this.dailyTeachingLoadComponent,
      this.offerGoalEnrollmentFullTimeComponent,
      this.numberStudentClassComponent,
      this.workJourneyTeacherComponent,
      this.careerAndRemunerationTeachersComponent,
      this.additionalsComponent,
      this.schoolsStaffComponent,
      this.teacherTrainingComponent,
      this.schoolsOperationComponent,
      this.socialChargesComponent,
      this.administrativeAreaCostingComponent,
      this.infrastructureSchoolBuildingsComponent,
      this.newRoomBuildingComponent,
      this.schoolFeedingComponent
    );

    if (this.sessionMode) {
      setTimeout(() => this.loadComponents());
    } else {
      this.pqrService.loadPqr().subscribe(() => setTimeout(() => this.loadComponents()));
    }
  }

  save(): void {
    const pqrData: any = {};

    for (const component of this.components) {
      pqrData[component.functionality.pqrKey] = component.getPqrData();
    }

    this.pqrService.savePqr(pqrData).pipe(
      takeUntil(this.unsubscribe))
      .subscribe();
  }

  private loadComponents(): void {
    this.authService.getUserInfo().pipe(
      takeUntil(this.unsubscribe))
      .subscribe(userInfo => {
        this.pqrAdminMode = userInfo.admin;

        for (const component of this.components) {
          this.loadComponentData(component, userInfo.admin);
        }
      });
  }

  private loadComponentData(component: PqrComponentData, userIsAdmin: boolean): void {
    this.pqrService.getPqrData(component.componentType).pipe(
      takeUntil(this.unsubscribe))
      .subscribe(pqrData => {
        component.pqrAdminMode = userIsAdmin;
        component.pqrMode = true;
        component.setPqrData(pqrData);
      });
  }

}
