import { Component, Injector, Type } from '@angular/core';
import { Observable } from 'rxjs';

import { PqrComponentData } from '../../shared/entities/base/pqr-component-data';
import { SocialChargesService } from './services/social-charges.service';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { SocialCharges } from './entities/social-charges';
import { NavigableComponentData } from '../../shared/entities/base/navigable-component-data';

@Component({
  selector: 'app-social-charges',
  templateUrl: './social-charges.component.html',
  styleUrls: ['./social-charges.component.scss']
})
export class SocialChargesComponent extends BaseNavigableComponent<SocialCharges> implements PqrComponentData {

  data: SocialCharges = new SocialCharges();
  functionality: FunctionalityInfo = Functionality.socialCharges;
  inconsistencies: Array<Inconsistency>;
  pqrAdminMode: boolean;
  pqrMode: boolean;
  pqrModalMode: boolean;
  componentType: Type<PqrComponentData> = SocialChargesComponent;

  constructor(private socialChargesService: SocialChargesService, private injector: Injector) {
    super(injector);
  }

  processData(): Observable<SocialCharges> {
    return this.socialChargesService.getData();
  }

  getPqrData(): NavigableComponentData {
    return this.data;
  }

  setPqrData(pqrData: any) {
    this.data = pqrData;
  }

  processValidation(): void {
    super.processValidation();

    if (isNaN(this.data.socialChargesPercentage) || this.data.socialChargesPercentage === undefined || this.data.socialChargesPercentage === null
      || this.data.socialChargesPercentage < 0 || this.data.socialChargesPercentage > 100) {
      this.inconsistencies.push(new Inconsistency({
        message: 'O valor do percentual o item encargos sociais não é válido. Informe um valor de 0% a 100%.'
      }));
    }
  }
}
