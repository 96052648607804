import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { SessionService } from '../../../../shared/services/session/session.service';
import { SchoolDayPerWeek } from '../entities/school-day-per-week';
import { NavigableComponentService } from '../../../shared/entities/base/navigable-component-service';

@Injectable({
  providedIn: 'root'
})
export class SchoolDayPerWeekService implements NavigableComponentService {

  constructor(private sessionService: SessionService) { }

  getData(): Observable<SchoolDayPerWeek> {
    return of(this.sessionService.getItem<SchoolDayPerWeek>(Functionality.schoolDayPerWeek.pqrKey));
  }

}
