import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { SessionService } from '../../../../shared/services/session/session.service';
import { AdministrativeAreaCosting } from '../entities/administrative-area-costing';
import { NavigableComponentService } from '../../../shared/entities/base/navigable-component-service';
import { AdministrativeItemCosting } from '../entities/administrative-item-costing';

@Injectable({
  providedIn: 'root'
})
export class AdministrativeAreaCostingService implements NavigableComponentService {

  constructor(private sessionService: SessionService) { }

  getData(): Observable<AdministrativeAreaCosting> {
    return of(this.sessionService.getItem<AdministrativeAreaCosting>(Functionality.administrativeAreaCosting.pqrKey));
  }

  getNewAdministrativeItemCosting(currentSequence: number): AdministrativeItemCosting {
    return new AdministrativeItemCosting(
      {
        sequence: (currentSequence + 1),
        denomination: undefined,
        percentage: undefined
      }
    );
  }

  setTotalPercentage(data: AdministrativeAreaCosting) {

    let totalPercentage: number = 0;
    for (let i = 0; i < data.administrativesItensCosting.length; i++) {
      if (data.administrativesItensCosting[i].percentage) {
        totalPercentage += data.administrativesItensCosting[i].percentage;
      }
    }
    data.totalPercentage = totalPercentage;
  }

  removeAdministrativeItemCosting(administrativesItensCostings: Array<AdministrativeItemCosting>, sequence: number): void {
    let levelRemoved: boolean = false;
    let sequenceCount: number = administrativesItensCostings.length - 1;

    for (let i = administrativesItensCostings.length - 1; i >= 0; i--) {
      const administrativeItemCosting = administrativesItensCostings[i];

      if (!levelRemoved && administrativeItemCosting.sequence === sequence) {
        administrativesItensCostings.splice(i, 1);
        levelRemoved = true;
      } else {
        administrativeItemCosting.sequence = sequenceCount;
        sequenceCount--;
      }
    }
  }
}
