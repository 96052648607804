export class School {

  id: string;
  classroomNumber: number;
  locationId: number;
  enrollmentQuantity: number;
  classNumber: number;

  constructor(init?: Partial<School>) {
    Object.assign(this, init);
  }

}
