import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { NavigableComponentService } from '../../../shared/entities/base/navigable-component-service';
import { CalculateTeacherNumberService } from '../../../shared/services/calculate-teacher-number/calculate-teacher-number.service';
import { CalculateClassNumberService } from '../../../shared/services/calculate-class-number/calculate-class-number.service';
import { HttpService } from '../../../../shared/services/http/http.service';
import { TeacherNumber } from '../entities/teacher-number';
import { TeacherNumberByStage } from '../entities/teacher-number-by-stage';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { TeacherNumberByYear } from '../entities/teacher-number-by-year';
import { TeacherNumberByStageCalc } from '../../../shared/services/calculate-teacher-number/entities/teacher-number-by-stage-calc';
import { CareerLevelByYear } from '../../../shared/services/calculate-teacher-number/entities/career-level-by-year';
import { TeacherNumberGroupedCalculation } from '../entities/teacher-number-grouped-calculation';
import { TeacherNumberCalc } from '../../../shared/services/calculate-teacher-number/entities/teacher-number-calc';
import { Stage } from '../../../../shared/entities/stage';
import { CurrentYearService } from '../../../shared/services/current-year/current-year.service';
import { EnrollmentProjectionByLocationService } from '../../../access-and-offer/enrollment-projection/services/enrollment-projection-by-location.service';
import { SourceInformationEnum } from './../../../../shared/entities/enums/source-information.enum';
import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { SessionService } from 'app/shared/services/session/session.service';
import { EnrollmentBySchool } from 'app/simulator/access-and-offer/enrollment-by-stage-series-by-school/entities/enrollment-by-school';
import { Functionality } from 'app/shared/entities/functionality/functionality';
import { EnrollmentAndClassAndClassroom } from 'app/simulator/results/caq-report/entities/enrollment-and-class-and-classroom';
import { CreateProjectionsService } from 'app/simulator/shared/services/create-projections/create-projections.service';
import { FunctionalityKey } from 'app/shared/entities/functionality/functionality-key.enum';
import { StorageType } from 'app/shared/services/session/entities/enums/storage-type.enum';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { TipoDependencia } from 'app/shared/entities/enums/tipo-dependencia';

@Injectable({
  providedIn: 'root'
})
export class TeacherNumberService implements NavigableComponentService {

  constructor(
    private httpService: HttpService,
    private sessionService: SessionService,
    private utilitiesService: UtilitiesService,
    private currentYearService: CurrentYearService,
    private calculateClassNumberService: CalculateClassNumberService,
    private calculateTeacherNumberService: CalculateTeacherNumberService,
    private enrollmentProjectionByLocationService: EnrollmentProjectionByLocationService,
    private createProjectionsService: CreateProjectionsService
  ) { }

  getData(): Observable<TeacherNumber> {
    const resultForEnrollmentSchool: EnrollmentBySchool = this.sessionService.getItem<EnrollmentBySchool>(Functionality.enrollmentByStageAndSeriesBySchool.key);

    const pqrData: Array<any> = this.utilitiesService.getPqrLocalUFG();

    resultForEnrollmentSchool.diagnosticosMatriculaPorEscola.forEach(objeto => {
      // Remover a propriedade 'version' de cada objeto
      delete objeto.version;

      objeto.etapasEnsino.forEach(etapas => {
        // Remover a propriedade 'percentualMatriculasIntegral' de cada objeto
        delete etapas.percentualMatriculasIntegral;
      });
    });

    let resultadoJson = {
      diagnosticoDeMatriculaPorEscola: resultForEnrollmentSchool.diagnosticosMatriculaPorEscola,
      pqr: pqrData
    }

    const tipoDependencia = this.utilitiesService.getTipoDependenciaUFG();
    const requestOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const tipoRecorte = this.utilitiesService.getTipoRecorte();

    //NOTAS
    /*projecaoProfessor.sourceInformation = new Array<Footnote>();
    projecaoProfessor.sourceInformation.push(new Footnote({ indice: 1, sourceInformation: SourceInformationEnum.teacher }));
    projecaoProfessor.sourceInformation.push(new Footnote({ indice: 2, sourceInformation: SourceInformationEnum.teacher }));
    projecaoProfessor.noteInfo.push(new Footnote({
      indice: 2,
      note: 'A contagem do número de professores considera os profissionais informados no Censo Escolar nas funções de ‘Docente’ e ' +
        'de ‘Docente Titular’ - coordenador de tutoria (de módulo ou disciplina) – EaD. São considerados apenas os professores que ' +
        'atuam em turmas cujo tipo de atendimento não seja de Atividade Complementar ou de Atendimento Educacional Especializado (AEE).'
    }));
    */

    if (tipoRecorte === TipoDependencia.Municipal) {
      return this.getProfessorResultadosPost(resultadoJson, tipoDependencia, requestOptions);
    }

    if (tipoRecorte !== TipoDependencia.Municipal) {
      return this.getProfessorResultadosGet(tipoDependencia, requestOptions);
    }

  }

  getProfessorResultadosGet(tipoDependencia: string, requestOptions: { headers: HttpHeaders; }): Observable<TeacherNumber> {
    return this.httpService.getApiEndpointUFG().pipe(
      mergeMap(apiEndpoint => {
        const projecaoProfessor: TeacherNumber = new TeacherNumber({ sourceInformation: new Array<Footnote>(), noteInfo: new Array<Footnote>() });

        projecaoProfessor.sourceInformation.push(new Footnote({ indice: 1, sourceInformation: SourceInformationEnum.teacher }));

        projecaoProfessor.noteInfo.push(new Footnote({
          indice: 1,
          note: 'A contagem do número de professores considera os profissionais informados no Censo Escolar nas funções de ‘Docente’ e de ‘Docente Titular’ - ' +
            'coordenador de tutoria (de módulo ou disciplina) – EaD. São considerados apenas os professores que atuam em turmas cujo tipo de atendimento não seja ' +
            'de Atividade Complementar ou de Atendimento Educacional Especializado (AEE). Os docentes são contados uma única vez em cada Município, portanto, o total ' +
            'não representa a soma das etapas de ensino, pois o mesmo docente pode atuar em mais de uma unidade de agregação.'
        }));

        //POST
        // return this.httpService.post<any>(`${apiEndpoint}/simulador/memoria/${tipoDependencia}`, resultadoJson, requestOptions).pipe(
        return this.httpService.get<any>(`${apiEndpoint}/simulador/memoria/${tipoDependencia}`).pipe(
          mergeMap(projecaoDocentesPorEtapa => {

            return this.httpService.get<any>(`${apiEndpoint}/quantitativo-docentes/${tipoDependencia}`).pipe(
              map(diagnosticoDocentes => {
                projecaoProfessor.totalProfessores = diagnosticoDocentes.content.quantidade;
                projecaoProfessor.professoresPorEtapa = projecaoDocentesPorEtapa.resultadoEtapa10CalculoDemandaPorDocentes;
                return projecaoProfessor;
              }));
          }));
      }));
  }

  getProfessorResultadosPost(resultadoJson: { diagnosticoDeMatriculaPorEscola: import("app/simulator/access-and-offer/enrollment-by-stage-series-by-school/entities/diagnostico-de-matricula-por-escola").DiagnosticoDeMatriculaPorEscola[]; pqr: any[]; }, tipoDependencia: string, requestOptions: { headers: HttpHeaders; }): Observable<TeacherNumber> {
    return this.httpService.getApiEndpointUFG().pipe(
      mergeMap(apiEndpoint => {
        const projecaoProfessor: TeacherNumber = new TeacherNumber({ sourceInformation: new Array<Footnote>(), noteInfo: new Array<Footnote>() });

        projecaoProfessor.sourceInformation.push(new Footnote({ indice: 1, sourceInformation: SourceInformationEnum.teacher }));

        projecaoProfessor.noteInfo.push(new Footnote({
          indice: 2,
          note: 'A contagem do número de professores considera os profissionais informados no Censo Escolar nas funções de ‘Docente’ e de ‘Docente Titular’ - ' +
            'coordenador de tutoria (de módulo ou disciplina) – EaD. São considerados apenas os professores que atuam em turmas cujo tipo de atendimento não seja ' +
            'de Atividade Complementar ou de Atendimento Educacional Especializado (AEE). Os docentes são contados uma única vez em cada Município, portanto, o total ' +
            'não representa a soma das etapas de ensino, pois o mesmo docente pode atuar em mais de uma unidade de agregação.'
        }));
        //POST
        return this.httpService.post<any>(`${apiEndpoint}/simulador/memoria/${tipoDependencia}`, resultadoJson, requestOptions).pipe(
          // return this.httpService.get<any>(`${apiEndpoint}/simulador/memoria/${tipoDependencia}`).pipe(
          mergeMap(projecaoDocentesPorEtapa => {

            return this.httpService.get<any>(`${apiEndpoint}/quantitativo-docentes/${tipoDependencia}`).pipe(
              map(diagnosticoDocentes => {
                projecaoProfessor.totalProfessores = diagnosticoDocentes.content.quantidade;
                projecaoProfessor.professoresPorEtapa = projecaoDocentesPorEtapa.resultadoEtapa10CalculoDemandaPorDocentes;
                return projecaoProfessor;
              }));
          }));
      }));
  }

  /*observablesCommon.push(this.utilitiesService.getLocations().pipe(
    map(resultLocations => locations = resultLocations)));*/

  //observablesCommon.push(this.utilitiesService.getLocalidades().pipe(
  //map(resultLocations => locations = resultLocations)));

  /*observablesCommon.push(this.utilitiesService.getStages().pipe(
    map(resultStages => stages = resultStages)));*/

  /*observablesCommon.push(this.utilitiesService.getEtapasDeEnsino().pipe(
    map(resultStages => stages = resultStages)));
  
  return forkJoin(observablesCommon).pipe(
    mergeMap(() => {
  
      const enrollmentsAndClassesAndClassroomsBySchool = this.getEnrollmentAndClassAndClassroom(resultForEnrollmentSchool);
  
      for (let i = 0; i < enrollmentsAndClassesAndClassroomsBySchool.length; i++) {
  
        const enrollmentAndClasseAndClassroomBySchool = enrollmentsAndClassesAndClassroomsBySchool[i];
  
        observablesCalculation.push(this.calculateClassNumberService.calculateClassNumber(locations, stages, enrollmentAndClasseAndClassroomBySchool).pipe(
          mergeMap(calculatedClassNumber => {
            return this.calculateTeacherNumberService.calculateTeacherNumber(calculatedClassNumber).pipe(
              map(calculatedTeacherNumber => {
                const teacherNumber: TeacherNumber = new TeacherNumber({ years: this.utilitiesService.getSimulationYears() });
                const teachersNumberGroupedCalculation = this.getTeacherNumberGroupedCalculation(calculatedTeacherNumber, stages);
  
                teacherNumber.resultForTeacherNumber = stages.map(stage => new TeacherNumberByStage({
                  id: stage.id,
                  stageDescription: stage.description
                }));
  
                for (const stage of stages) {
  
                  const teacherNumberGroupCalculationByStage = _.find(teachersNumberGroupedCalculation, c => c.id === stage.id);
                  const teacherNumberByStage = _.find(teacherNumber.resultForTeacherNumber, c => c.id === stage.id);
  
                  if (teacherNumberGroupCalculationByStage) {
                    teacherNumberByStage.teacherNumberByYear = teacherNumberGroupCalculationByStage.teacherNumberByYear.map(
                      teacherNumberGroupedYear => new TeacherNumberByYear({ year: teacherNumberGroupedYear.year, value: teacherNumberGroupedYear.value }));
                  }
                }
                return teacherNumber;
              }));
          })));
      }
      return forkJoin(observablesCalculation).pipe(
        mergeMap(results => {
          const teacherNumber: TeacherNumber = this.getTotalTeacherNumberCalculated(results, stages);
          return this.getDiagnostico(teacherNumber);
        }));
    }));
    }
    */

  private getTotalTeacherNumber(totalStages: number, totalAuxiliar: number, teacherNumberByStage: Array<TeacherNumberByStage>): TeacherNumberByStage {

    return new TeacherNumberByStage(
      {
        stageDescription: 'Total',
        currentNumber: totalStages,
        auxiliarNumber: totalAuxiliar,
        teacherNumberByYear: this.getTotalTeacherNumberByYear(teacherNumberByStage)
      });

  }

  private getDiagnostic(): Observable<any> {

    let filtersLocation: Array<string> = new Array<string>();
    const teacherCurrentYear: number = this.currentYearService.getTeacherCurrentYear();

    filtersLocation = this.utilitiesService.getSelectLocationFilter();

    let filters: Array<string> = new Array<string>(
      `min_year:"${teacherCurrentYear}"`,
      `max_year:"${teacherCurrentYear}"`
    );

    filters.push(this.utilitiesService.getAdmDependencyFilter());

    filters = filters.concat(filtersLocation);

    const options: any = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([['filter', filters.join(',')]]));

    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/auxiliar`, options).pipe(
          map(diagnostic => _.first(diagnostic)));
      }));
  }

  private getTotalTeacherNumberByYear(teacherNumberByStage: Array<TeacherNumberByStage>): Array<TeacherNumberByYear> {
    const simulationYears = this.utilitiesService.getSimulationYears();
    const teacherNumberByYear: Array<TeacherNumberByYear> = new Array<TeacherNumberByYear>();
    let totalValue: number;

    for (let i = 0; i < simulationYears.length; i++) {
      const simulationYear = simulationYears[i];
      totalValue = 0;
      for (let j = 0; j < teacherNumberByStage.length; j++) {
        const item = teacherNumberByStage[j];

        for (let k = 0; k < item.teacherNumberByYear.length; k++) {
          const element = item.teacherNumberByYear[k];
          if (element.year === simulationYear) {
            totalValue += element.value;
            break;
          }
        }
      }

      teacherNumberByYear.push(new TeacherNumberByYear({ year: simulationYear, value: totalValue }));
    }
    return teacherNumberByYear;
  }

  private getTeacherNumberGroupedCalculation(calculatedTeachersNumber: Array<TeacherNumberCalc>, stages: Array<Stage>): Array<TeacherNumberGroupedCalculation> {

    const teachersNumberGroupedCalculation: Array<TeacherNumberGroupedCalculation> = new Array<TeacherNumberGroupedCalculation>();
    const teachersNumberByStagesCalc: Array<TeacherNumberByStageCalc> = new Array<TeacherNumberByStageCalc>();

    for (let i = 0; i < calculatedTeachersNumber.length; i++) {
      const calculatedTeacherNumber = calculatedTeachersNumber[i];
      for (let j = 0; j < calculatedTeacherNumber.teachersNumberByLocationsCalc.length; j++) {
        const teacherNumberByLocationCalc = calculatedTeacherNumber.teachersNumberByLocationsCalc[j];
        for (let k = 0; k < teacherNumberByLocationCalc.teachersNumberByStagesCalc.length; k++) {
          teachersNumberByStagesCalc.push(teacherNumberByLocationCalc.teachersNumberByStagesCalc[k]);
        }
      }
    }

    for (const stage of stages) {

      const teacherNumberGroupedCalculation: TeacherNumberGroupedCalculation = new TeacherNumberGroupedCalculation(
        new TeacherNumberGroupedCalculation({
          id: stage.id,
          stageDescription: stage.description,
          teacherNumberByYear: new Array<TeacherNumberByYear>()
        }));

      const calculatedTeachersNumberByStages: Array<TeacherNumberByStageCalc> = new Array<TeacherNumberByStageCalc>();

      for (let i = 0; i < teachersNumberByStagesCalc.length; i++) {
        if (teachersNumberByStagesCalc[i].id === stage.id) {
          calculatedTeachersNumberByStages.push(teachersNumberByStagesCalc[i]);
        }
      }

      const careersLevelsByYear: Array<CareerLevelByYear> = new Array<CareerLevelByYear>();
      for (let i = 0; i < calculatedTeachersNumberByStages.length; i++) {
        const calculatedTeacherNumberByStage = calculatedTeachersNumberByStages[i];
        for (let j = 0; j < calculatedTeacherNumberByStage.careerLevelsByYear.length; j++) {
          careersLevelsByYear.push(calculatedTeacherNumberByStage.careerLevelsByYear[j]);
        }
      }

      this.utilitiesService.getSimulationYears().map(simulationYear => {

        const careersLevelsYear: Array<CareerLevelByYear> = new Array<CareerLevelByYear>();
        for (let i = 0; i < careersLevelsByYear.length; i++) {
          if (careersLevelsByYear[i].year === simulationYear) {
            careersLevelsYear.push(careersLevelsByYear[i]);
          }
        }

        teacherNumberGroupedCalculation.teacherNumberByYear.push(new TeacherNumberByYear({
          year: simulationYear,
          value: this.getTotal(careersLevelsYear)
        }));

        teachersNumberGroupedCalculation.push(teacherNumberGroupedCalculation);
      });
    }
    return teachersNumberGroupedCalculation;
  }

  private getTotal(carrersLevelsByYear: Array<CareerLevelByYear>): number {

    let totalTeachers: number = 0;
    for (let i = 0; i < carrersLevelsByYear.length; i++) {
      totalTeachers += carrersLevelsByYear[i].teacherNumberTotal;
    }

    return totalTeachers;

  }

  private getEnrollmentAndClassAndClassroom(enrollmentBySchool: EnrollmentBySchool): Array<EnrollmentAndClassAndClassroom> {

    const enrollmentAndClassAndClassrooms: Array<EnrollmentAndClassAndClassroom> = new Array<EnrollmentAndClassAndClassroom>();

    for (let i = 0; i < enrollmentBySchool.enrollmentByStageSeriesBySchool.length; i++) {

      const enrollmentAndClassAndClassroom: EnrollmentAndClassAndClassroom = new EnrollmentAndClassAndClassroom({
        school_id: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].school_id,
        school_description: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].school_name,
        enrollmentProjection: this.createProjectionsService.getEnrollmentProjectionBySchool(enrollmentBySchool.enrollmentByStageSeriesBySchool[i]),
        hasEnrollment: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].hasEnrollment,
        percentageTeacherCareer: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].percentageTeacherCareer
      });

      enrollmentAndClassAndClassrooms.push(enrollmentAndClassAndClassroom);
    }
    return enrollmentAndClassAndClassrooms;
  }

  private getDiagnostics(teacherNumber: TeacherNumber): Observable<TeacherNumber> {

    const filtersLocation: Array<string> = this.utilitiesService.getSelectLocationFilter();
    const offerYear: number = this.currentYearService.getTeacherCurrentYear();

    let filters: Array<string> = new Array<string>(
      `min_year:"${offerYear}"`,
      `max_year:"${offerYear}"`
    );

    filters.push(this.utilitiesService.getAdmDependencyFilter());
    filters = filters.concat(filtersLocation);

    const options = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([
      ['filter', filters.join(',')]
    ]));

    return this.httpService.getApiEndpoint().pipe(
      mergeMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/teacher`, options).pipe(
          mergeMap(teachersNumberByeducationLevel => {
            return this.getDiagnostic().pipe(
              map(auxiliar => {
                let totalAuxiliar: number = 0;
                if (auxiliar) {
                  totalAuxiliar = auxiliar.total;
                }
                teacherNumber.resultForTeacherNumber.push(this.getTotalTeacherNumber(_.first(teachersNumberByeducationLevel).total, totalAuxiliar,
                  teacherNumber.resultForTeacherNumber));
                return teacherNumber;
              }));
          }));
      }));
  }

  private getDiagnostico(teacherNumber: TeacherNumber): Observable<TeacherNumber> {

    const tipoDependencia = this.utilitiesService.getTipoDependenciaUFG();

    return this.httpService.getApiEndpointUFG().pipe(
      mergeMap(endpointUFG => {
        return this.httpService.get<any>(`${endpointUFG}/quantitativo-docentes/${tipoDependencia}`).pipe(
          map(teachersNumberTotal => {
            teacherNumber.resultForTeacherNumber.push(this.getTotalTeacherNumber(teachersNumberTotal.content.quantidade, undefined,
              teacherNumber.resultForTeacherNumber));
            teacherNumber.yearCurrent = teachersNumberTotal.content.ano;
            return teacherNumber;
          }));
      }));
  }
}
