import { Component, Injector, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PqrComponentData } from '../../shared/entities/base/pqr-component-data';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { DailyTeachingLoadService } from './services/daily-teaching-load.service';
import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { DailyTeachingLoad } from './entities/daily-teaching-load';
import { NavigableComponentData } from '../../shared/entities/base/navigable-component-data';
import { DailyTeachingLoadDiagnostic } from './entities/daily-teaching-load-diagnostic';

@Component({
  selector: 'app-daily-teaching-load',
  templateUrl: './daily-teaching-load.component.html',
  styleUrls: ['./daily-teaching-load.component.scss']
})
export class DailyTeachingLoadComponent extends BaseNavigableComponent<DailyTeachingLoad> implements PqrComponentData {

  data: DailyTeachingLoad = new DailyTeachingLoad();
  functionality: FunctionalityInfo = Functionality.dailyTeachingLoad;
  inconsistencies: Array<Inconsistency>;
  pqrAdminMode: boolean;
  pqrMode: boolean;
  pqrModalMode: boolean;
  componentType: Type<PqrComponentData> = DailyTeachingLoadComponent;
  diagnostics: Array<DailyTeachingLoadDiagnostic> = new Array<DailyTeachingLoadDiagnostic>();

  constructor(private dailyTeachingLoadService: DailyTeachingLoadService, private injector: Injector) {
    super(injector);
  }

  processData(): Observable<DailyTeachingLoad> {
    return this.dailyTeachingLoadService.getData();
  }

  processSpecificBehaviors(): void {
    /*if (!this.pqrMode) {
      this.dailyTeachingLoadService.getDiagnostic(this.data).pipe(
        takeUntil(this.unsubscribe))
        .subscribe();
    }*/
  }

  getPqrData(): NavigableComponentData {
    return this.data;
  }

  setPqrData(pqrData: any) {
    this.data = pqrData;
    ////this.processSpecificBehaviors();
  }

  processValidation(): void {
    super.processValidation();

    for (const dailyTeachingLoad of this.data.teachingLoads) {
      for (const shift of dailyTeachingLoad.shifts) {
        if (isNaN(shift.value) || shift.value === null || shift.value === undefined || (shift.value < 3 || shift.value > 12)) {
          this.inconsistencies.push(new Inconsistency({
            message: `Carga horária diária na etapa ${dailyTeachingLoad.descriptionStage} no turno ${shift.description} não é válida. Informe um valor de 3 a 12.`
          }));
        }
      }
    }
  }
}
