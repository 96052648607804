import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AlertComponent implements OnInit {

  title: string = 'Atenção!';
  richTitle: string;
  body: string;
  hideCloseButtonX: boolean;
  closeButtonText: string = 'Ok';
  list: Array<any>;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

}
