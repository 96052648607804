import { SchoolToBeBuiltByYear } from './school-to-be-built-by-year';

export class SchoolToBeBuiltByLocation {

  id: number;
  description: string;
  schoolsToBeBuiltsByYear: Array<SchoolToBeBuiltByYear>;

  constructor(init?: Partial<SchoolToBeBuiltByLocation>) {
    Object.assign(this, init);
  }
}
