import { EmployeeEstimateByRole } from './employee-estimate-by-role';

export class EmployeeEstimateByLocation {

  id: number;
  description: string;
  employeesEstimateByRoles: Array<EmployeeEstimateByRole>;

  constructor(init?: Partial<EmployeeEstimateByLocation>) {
    Object.assign(this, init);
  }

}
