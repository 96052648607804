import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, mergeMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { CsvService } from '../../../../shared/services/csv/csv.service';
import { Csv } from '../../../../shared/services/csv/entities/csv';
import { CsvHeader } from '../../../../shared/services/csv/entities/csv-header';
import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { CaqReport } from './../entities/caq-report';
import { HttpService } from 'app/shared/services/http/http.service';
import { RelatorioCaq } from '../entities/relatorio-caq';

@Injectable({
  providedIn: 'root'
})
export class CaqReportService {

  constructor(
    private utilitiesService: UtilitiesService,
    private csvService: CsvService,
    private httpService: HttpService
  ) { }

  getCAQ(): Observable<CaqReport> {

    let data: CaqReport = new CaqReport();
    data.sourceInformations = this.getSourceInformations();

    return this.httpService.getApiEndpointUFG().pipe(
      mergeMap(endpointUFG => {

        const caqUrlUFG: string = `${endpointUFG}/simulador/caq/nacional`;
        return this.httpService.get<any>(caqUrlUFG).pipe(
          map(relCaq => {
            data.relatorioCaq = relCaq;
            return data
          }));

      }));

  }

  downloadCsvPorEscola(): void {

    let caqUrlUFG: string;
    caqUrlUFG = this.httpService.apiEndpointFabriacUFG + `/simulador/caq/nacional/detalhado/csv`;
    const blob: Blob = new Blob([caqUrlUFG], { type: 'text/csv;charset=utf-8' });
    //saveAs(blob, 'teste');
    window.open(caqUrlUFG);

  }

  downloadCsvTela(caq: RelatorioCaq): void {
    const header: Array<CsvHeader> = new Array<CsvHeader>();
    const data: Array<any> = new Array<any>();
    let csv: Csv = new Csv();

    header.push(new CsvHeader({ key: 'etapa', label: 'Etapa' }));
    header.push(new CsvHeader({ key: 'localidade', label: 'Área da localidade' }));
    header.push(new CsvHeader({ key: 'turno', label: 'Turno' }));
    header.push(new CsvHeader({ key: `mediana`, label: `CAQ Mediana 2023 (R$)` }));
    header.push(new CsvHeader({ key: `fator`, label: `Fator de ponderação` }));
    header.push(new CsvHeader({ key: `matricula`, label: `Número de matrículas - 2023` }));
    header.push(new CsvHeader({ key: `escola`, label: `Número de escolas - 2023` }));

    let caqData: any = {
      etapa: 'Creche',
      localidade: 'Urbana',
      turno: 'Parcial',
      mediana: this.utilitiesService.roundNumber(caq.crecheUrbanaParcial.mediana, 0),
      fator: this.utilitiesService.roundNumber(caq.crecheUrbanaParcial.fatorPonderacao, 2),
      matricula: this.utilitiesService.roundNumber(caq.crecheUrbanaParcial.numeroMatriculas, 0),
      escola: this.utilitiesService.roundNumber(caq.crecheUrbanaParcial.numeroEscolas, 0)
    };
    data.push(caqData);

    caqData = {
      etapa: 'Creche',
      localidade: 'Urbana',
      turno: 'Integral',
      mediana: this.utilitiesService.roundNumber(caq.crecheUrbanaIntegral.mediana, 0),
      fator: this.utilitiesService.roundNumber(caq.crecheUrbanaIntegral.fatorPonderacao, 2),
      matricula: this.utilitiesService.roundNumber(caq.crecheUrbanaIntegral.numeroMatriculas, 0),
      escola: this.utilitiesService.roundNumber(caq.crecheUrbanaIntegral.numeroEscolas, 0)
    };
    data.push(caqData);

    caqData = {
      etapa: 'Creche',
      localidade: 'Rural',
      turno: 'Parcial',
      mediana: this.utilitiesService.roundNumber(caq.crecheRuralParcial.mediana, 0),
      fator: this.utilitiesService.roundNumber(caq.crecheRuralParcial.fatorPonderacao, 2),
      matricula: this.utilitiesService.roundNumber(caq.crecheRuralParcial.numeroMatriculas, 0),
      escola: this.utilitiesService.roundNumber(caq.crecheRuralParcial.numeroEscolas, 0)
    };
    data.push(caqData);

    caqData = {
      etapa: 'Creche',
      localidade: 'Rural',
      turno: 'Integral',
      mediana: this.utilitiesService.roundNumber(caq.crecheRuralIntegral.mediana, 0),
      fator: this.utilitiesService.roundNumber(caq.crecheRuralIntegral.fatorPonderacao, 2),
      matricula: this.utilitiesService.roundNumber(caq.crecheRuralIntegral.numeroMatriculas, 0),
      escola: this.utilitiesService.roundNumber(caq.crecheRuralIntegral.numeroEscolas, 0)
    };
    data.push(caqData);

    caqData = {
      etapa: 'Pré-Escola',
      localidade: 'Urbana',
      turno: 'Parcial',
      mediana: this.utilitiesService.roundNumber(caq.preEscolaUrbanaParcial.mediana, 0),
      fator: this.utilitiesService.roundNumber(caq.preEscolaUrbanaParcial.fatorPonderacao, 2),
      matricula: this.utilitiesService.roundNumber(caq.preEscolaUrbanaParcial.numeroMatriculas, 0),
      escola: this.utilitiesService.roundNumber(caq.preEscolaUrbanaParcial.numeroEscolas, 0)
    };
    data.push(caqData);

    caqData = {
      etapa: 'Pré-Escola',
      localidade: 'Urbana',
      turno: 'Integral',
      mediana: this.utilitiesService.roundNumber(caq.preEscolaUrbanaIntegral.mediana, 0),
      fator: this.utilitiesService.roundNumber(caq.preEscolaUrbanaIntegral.fatorPonderacao, 2),
      matricula: this.utilitiesService.roundNumber(caq.preEscolaUrbanaIntegral.numeroMatriculas, 0),
      escola: this.utilitiesService.roundNumber(caq.preEscolaUrbanaIntegral.numeroEscolas, 0)
    };
    data.push(caqData);

    caqData = {
      etapa: 'Pré-Escola',
      localidade: 'Rural',
      turno: 'Parcial',
      mediana: this.utilitiesService.roundNumber(caq.preEscolaRuralParcial.mediana, 0),
      fator: this.utilitiesService.roundNumber(caq.preEscolaRuralParcial.fatorPonderacao, 2),
      matricula: this.utilitiesService.roundNumber(caq.preEscolaRuralParcial.numeroMatriculas, 0),
      escola: this.utilitiesService.roundNumber(caq.preEscolaRuralParcial.numeroEscolas, 0)
    };
    data.push(caqData);

    caqData = {
      etapa: 'Pré-Escola',
      localidade: 'Rural',
      turno: 'Integral',
      mediana: this.utilitiesService.roundNumber(caq.preEscolaRuralIntegral.mediana, 0),
      fator: this.utilitiesService.roundNumber(caq.preEscolaRuralIntegral.fatorPonderacao, 2),
      matricula: this.utilitiesService.roundNumber(caq.preEscolaRuralIntegral.numeroMatriculas, 0),
      escola: this.utilitiesService.roundNumber(caq.preEscolaRuralIntegral.numeroEscolas, 0)
    };
    data.push(caqData);

    caqData = {
      etapa: 'Ensino Fundamental - anos iniciais',
      localidade: 'Urbana',
      turno: 'Parcial',
      mediana: this.utilitiesService.roundNumber(caq.ensFunAnosIniciaisUrbanaParcial.mediana, 0),
      fator: this.utilitiesService.roundNumber(caq.ensFunAnosIniciaisUrbanaParcial.fatorPonderacao, 2),
      matricula: this.utilitiesService.roundNumber(caq.ensFunAnosIniciaisUrbanaParcial.numeroMatriculas, 0),
      escola: this.utilitiesService.roundNumber(caq.ensFunAnosIniciaisUrbanaParcial.numeroEscolas, 0)
    };
    data.push(caqData);

    caqData = {
      etapa: 'Ensino Fundamental - anos iniciais',
      localidade: 'Urbana',
      turno: 'Integral',
      mediana: this.utilitiesService.roundNumber(caq.ensFunAnosIniciaisUrbanaIntegral.mediana, 0),
      fator: this.utilitiesService.roundNumber(caq.ensFunAnosIniciaisUrbanaIntegral.fatorPonderacao, 2),
      matricula: this.utilitiesService.roundNumber(caq.ensFunAnosIniciaisUrbanaIntegral.numeroMatriculas, 0),
      escola: this.utilitiesService.roundNumber(caq.ensFunAnosIniciaisUrbanaIntegral.numeroEscolas, 0)
    };
    data.push(caqData);

    caqData = {
      etapa: 'Ensino Fundamental - anos iniciais',
      localidade: 'Rural',
      turno: 'Parcial',
      mediana: this.utilitiesService.roundNumber(caq.ensFunAnosIniciaisRuralParcial.mediana, 0),
      fator: this.utilitiesService.roundNumber(caq.ensFunAnosIniciaisRuralParcial.fatorPonderacao, 2),
      matricula: this.utilitiesService.roundNumber(caq.ensFunAnosIniciaisRuralParcial.numeroMatriculas, 0),
      escola: this.utilitiesService.roundNumber(caq.ensFunAnosIniciaisRuralParcial.numeroEscolas, 0)
    };
    data.push(caqData);

    caqData = {
      etapa: 'Ensino Fundamental - anos iniciais',
      localidade: 'Rural',
      turno: 'Integral',
      mediana: this.utilitiesService.roundNumber(caq.ensFunAnosIniciaisRuralIntegral.mediana, 0),
      fator: this.utilitiesService.roundNumber(caq.ensFunAnosIniciaisRuralIntegral.fatorPonderacao, 2),
      matricula: this.utilitiesService.roundNumber(caq.ensFunAnosIniciaisRuralIntegral.numeroMatriculas, 0),
      escola: this.utilitiesService.roundNumber(caq.ensFunAnosIniciaisRuralIntegral.numeroEscolas, 0)
    };
    data.push(caqData);

    caqData = {
      etapa: 'Ensino Fundamental - anos finais',
      localidade: 'Urbana',
      turno: 'Parcial',
      mediana: this.utilitiesService.roundNumber(caq.ensFunAnosFinaisUrbanaParcial.mediana, 0),
      fator: this.utilitiesService.roundNumber(caq.ensFunAnosFinaisUrbanaParcial.fatorPonderacao, 2),
      matricula: this.utilitiesService.roundNumber(caq.ensFunAnosFinaisUrbanaParcial.numeroMatriculas, 0),
      escola: this.utilitiesService.roundNumber(caq.ensFunAnosFinaisUrbanaParcial.numeroEscolas, 0)
    };
    data.push(caqData);

    caqData = {
      etapa: 'Ensino Fundamental - anos finais',
      localidade: 'Urbana',
      turno: 'Integral',
      mediana: this.utilitiesService.roundNumber(caq.ensFunAnosFinaisUrbanaIntegral.mediana, 0),
      fator: this.utilitiesService.roundNumber(caq.ensFunAnosFinaisUrbanaIntegral.fatorPonderacao, 2),
      matricula: this.utilitiesService.roundNumber(caq.ensFunAnosFinaisUrbanaIntegral.numeroMatriculas, 0),
      escola: this.utilitiesService.roundNumber(caq.ensFunAnosFinaisUrbanaIntegral.numeroEscolas, 0)
    };
    data.push(caqData);

    caqData = {
      etapa: 'Ensino Fundamental - anos finais',
      localidade: 'Rural',
      turno: 'Parcial',
      mediana: this.utilitiesService.roundNumber(caq.ensFunAnosFinaisRuralParcial.mediana, 0),
      fator: this.utilitiesService.roundNumber(caq.ensFunAnosFinaisRuralParcial.fatorPonderacao, 2),
      matricula: this.utilitiesService.roundNumber(caq.ensFunAnosFinaisRuralParcial.numeroMatriculas, 0),
      escola: this.utilitiesService.roundNumber(caq.ensFunAnosFinaisRuralParcial.numeroEscolas, 0)
    };
    data.push(caqData);

    caqData = {
      etapa: 'Ensino Fundamental - anos finais',
      localidade: 'Rural',
      turno: 'Integral',
      mediana: this.utilitiesService.roundNumber(caq.ensFunAnosFinaisRuralIntegral.mediana, 0),
      fator: this.utilitiesService.roundNumber(caq.ensFunAnosFinaisRuralIntegral.fatorPonderacao, 2),
      matricula: this.utilitiesService.roundNumber(caq.ensFunAnosFinaisRuralIntegral.numeroMatriculas, 0),
      escola: this.utilitiesService.roundNumber(caq.ensFunAnosFinaisRuralIntegral.numeroEscolas, 0)
    };
    data.push(caqData);

    caqData = {
      etapa: 'Ensino Médio',
      localidade: 'Urbana',
      turno: 'Parcial',
      mediana: this.utilitiesService.roundNumber(caq.ensMedioUrbanaParcial.mediana, 0),
      fator: this.utilitiesService.roundNumber(caq.ensMedioUrbanaParcial.fatorPonderacao, 2),
      matricula: this.utilitiesService.roundNumber(caq.ensMedioUrbanaParcial.numeroMatriculas, 0),
      escola: this.utilitiesService.roundNumber(caq.ensMedioUrbanaParcial.numeroEscolas, 0)
    };
    data.push(caqData);

    caqData = {
      etapa: 'Ensino Médio',
      localidade: 'Urbana',
      turno: 'Integral',
      mediana: this.utilitiesService.roundNumber(caq.ensMedioUrbanaIntegral.mediana, 0),
      fator: this.utilitiesService.roundNumber(caq.ensMedioUrbanaIntegral.fatorPonderacao, 2),
      matricula: this.utilitiesService.roundNumber(caq.ensMedioUrbanaIntegral.numeroMatriculas, 0),
      escola: this.utilitiesService.roundNumber(caq.ensMedioUrbanaIntegral.numeroEscolas, 0)
    };
    data.push(caqData);

    caqData = {
      etapa: 'Ensino Médio',
      localidade: 'Rural',
      turno: 'Parcial',
      mediana: this.utilitiesService.roundNumber(caq.ensMedioRuralParcial.mediana, 0),
      fator: this.utilitiesService.roundNumber(caq.ensMedioRuralParcial.fatorPonderacao, 2),
      matricula: this.utilitiesService.roundNumber(caq.ensMedioRuralParcial.numeroMatriculas, 0),
      escola: this.utilitiesService.roundNumber(caq.ensMedioRuralParcial.numeroEscolas, 0)
    };
    data.push(caqData);

    caqData = {
      etapa: 'Ensino Médio',
      localidade: 'Rural',
      turno: 'Integral',
      mediana: this.utilitiesService.roundNumber(caq.ensMedioRuralIntegral.mediana, 0),
      fator: this.utilitiesService.roundNumber(caq.ensMedioRuralIntegral.fatorPonderacao, 2),
      matricula: this.utilitiesService.roundNumber(caq.ensMedioRuralIntegral.numeroMatriculas, 0),
      escola: this.utilitiesService.roundNumber(caq.ensMedioRuralIntegral.numeroEscolas, 0)
    };
    data.push(caqData);


    caqData = {
      etapa: 'EJA',
      localidade: 'Urbana',
      turno: 'Parcial',
      mediana: this.utilitiesService.roundNumber(caq.ejaUrbanaParcial.mediana, 0),
      fator: this.utilitiesService.roundNumber(caq.ejaUrbanaParcial.fatorPonderacao, 2),
      matricula: this.utilitiesService.roundNumber(caq.ejaUrbanaParcial.numeroMatriculas, 0),
      escola: this.utilitiesService.roundNumber(caq.ejaUrbanaParcial.numeroEscolas, 0)
    };
    data.push(caqData);

    caqData = {
      etapa: 'EJA',
      localidade: 'Urbana',
      turno: 'Integral',
      mediana: this.utilitiesService.roundNumber(caq.ejaUrbanaIntegral.mediana, 0),
      fator: this.utilitiesService.roundNumber(caq.ejaUrbanaIntegral.fatorPonderacao, 2),
      matricula: this.utilitiesService.roundNumber(caq.ejaUrbanaIntegral.numeroMatriculas, 0),
      escola: this.utilitiesService.roundNumber(caq.ejaUrbanaIntegral.numeroEscolas, 0)
    };
    data.push(caqData);

    caqData = {
      etapa: 'EJA',
      localidade: 'Rural',
      turno: 'Parcial',
      mediana: this.utilitiesService.roundNumber(caq.ejaRuralParcial.mediana, 0),
      fator: this.utilitiesService.roundNumber(caq.ejaRuralParcial.fatorPonderacao, 2),
      matricula: this.utilitiesService.roundNumber(caq.ejaRuralParcial.numeroMatriculas, 0),
      escola: this.utilitiesService.roundNumber(caq.ejaRuralParcial.numeroEscolas, 0)
    };
    data.push(caqData);

    caqData = {
      etapa: 'EJA',
      localidade: 'Rural',
      turno: 'Integral',
      mediana: this.utilitiesService.roundNumber(caq.ejaRuralIntegral.mediana, 0),
      fator: this.utilitiesService.roundNumber(caq.ejaRuralIntegral.fatorPonderacao, 2),
      matricula: this.utilitiesService.roundNumber(caq.ejaRuralIntegral.numeroMatriculas, 0),
      escola: this.utilitiesService.roundNumber(caq.ejaRuralIntegral.numeroEscolas, 0)
    };
    data.push(caqData);

    caqData = {
      etapa: 'TOTAL',
      localidade: '',
      turno: '',
      mediana: '',
      fator: '',
      matricula: this.utilitiesService.roundNumber(
        caq.crecheUrbanaParcial.numeroMatriculas
        + caq.crecheUrbanaIntegral.numeroMatriculas
        + caq.crecheRuralParcial.numeroMatriculas
        + caq.crecheRuralIntegral.numeroMatriculas
        + caq.preEscolaUrbanaParcial.numeroMatriculas
        + caq.preEscolaUrbanaIntegral.numeroMatriculas
        + caq.preEscolaRuralParcial.numeroMatriculas
        + caq.preEscolaRuralIntegral.numeroMatriculas
        + caq.ensFunAnosIniciaisUrbanaParcial.numeroMatriculas
        + caq.ensFunAnosIniciaisUrbanaIntegral.numeroMatriculas
        + caq.ensFunAnosIniciaisRuralParcial.numeroMatriculas
        + caq.ensFunAnosIniciaisRuralIntegral.numeroMatriculas
        + caq.ensFunAnosFinaisUrbanaParcial.numeroMatriculas
        + caq.ensFunAnosFinaisUrbanaIntegral.numeroMatriculas
        + caq.ensFunAnosFinaisRuralParcial.numeroMatriculas
        + caq.ensFunAnosFinaisRuralIntegral.numeroMatriculas
        + caq.ensMedioUrbanaParcial.numeroMatriculas
        + caq.ensMedioUrbanaIntegral.numeroMatriculas
        + caq.ensMedioRuralParcial.numeroMatriculas
        + caq.ensMedioRuralIntegral.numeroMatriculas
        + caq.ejaUrbanaParcial.numeroMatriculas
        + caq.ejaRuralParcial.numeroMatriculas, 0),
      escola: ''
    };
    data.push(caqData);

    csv = new Csv({ header: header, data: data, name: 'Custo-aluno qualidade' });
    this.csvService.download(csv);

  }

  private getSourceInformations(): Array<Footnote> {

    const footNotes: Array<Footnote> = new Array<Footnote>();
    footNotes.push(new Footnote(
      {
        indice: 1,
        note: 'O CAQ é calculado pela mediana do custo por aluno das escolas que tem pelo menos 3 matrículas em cada categoria.'
      }));
    footNotes.push(new Footnote(
      {
        indice: 2,
        note: 'Fator de ponderação = [valor do CAQ de cada etapa/modalidade, turno e área de localização] / [CAQ dos anos iniciais do ensino fundamental parcial urbano]. Indica as diferenças de custos entre os contextos de oferta ' +
          '(etapas/modalidades, turnos e áreas de localização) na forma de número índice (que permite a análise em termos relativos ou percentuais).'
      }
    ));

    footNotes.push(new Footnote(
      {
        indice: 3,
        note: 'O número de matrículas é calculado a partir dos microdados do Censo Escolar/INEP com base nas etapas e modalidade de educação de jovens e adultos consideradas como prioritárias para as redes estaduais e municipais. Na rede estadual, são contabilizadas as matrículas de ensino fundamental (anos iniciais e finais), ensino médio e Educação de Jovens e Adultos (EJA) de ensino fundamental e médio. ' +
          'Na rede municipal, são incluídas as matrículas de creche, pré-escola, ensino fundamental (anos iniciais e finais) e EJA de ensino fundamental.'
      }
    ));

    footNotes.push(new Footnote(
      {
        indice: 4,
        note: 'Número de escolas estaduais e municipais, em funcionamento, que ofertam matrícula nas etapas/modalidades prioritárias por turno (parcial e integral), calculado a partir dos microdados do Censo Escolar de 2023'
      }
    ));

    return footNotes;
  }


}
