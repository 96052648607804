import { NavigableComponentData } from './../../../shared/entities/base/navigable-component-data';
import { FinancingFederatedEntitiesReport } from './financing_federated-entities-report';

export class FinancingsFederatedsEntitiesReport extends NavigableComponentData {

  financingsFederatedsEntitiesReport: Array<FinancingFederatedEntitiesReport>;
  editar: string;
  uf: string;
  codigo: string;
  enteFederativo: string;
  dependenciaAdministrativa: string;
  numeroEscolas: number;
  matriculasCreche: number;
  matriculasPreescola: number;
  matriculasEnsFundamentalAnosIniciais: number;
  matriculasEnsFundamentalAnosFinais: number;
  matriculasEnsinoMedio: number;
  matriculasEJA: number;
  matriculasEmEscolasRurais: number;
  totalMatriculas: number;
  percentualMatriculasTempoIntegralCreche: number;
  percentualMatriculasTempoIntegralPreescola: number;
  percentualMatriculasTempoIntegralEnsFundamentalAnosIniciais: number;
  percentualMatriculasTempoIntegralEnsFundamentalAnosFinais: number;
  percentualMatriculasTempoIntegralEnsinoMedio: number;
  percentualMatriculasTempoIntegralEJA: number;
  percentualMatriculasEmEscolasRurais: number;
  numeroTotalDocentesProjetado: number;
  numeroTotalFuncionariosProjetado: number;
  totalTurmasExistentes: number;
  totalTurmasNecessarias: number;
  totalSalasExistentes: number;
  totalSalasNecessarias: number;
  receitaPotencialMinimaVinculadaEducacaoBasica: number;
  despesaCorrenteRealizada: number;
  despesaCorrenteNecessaria: number;
  valorAlunoCAQAno: number;
  valorAlunoCAQMes: number;
  valorComplementacao: number;
  percentualComplementacao: number;

  constructor(init?: Partial<FinancingsFederatedsEntitiesReport>) {
    super();
    Object.assign(this, init);
  }
}
