import { Component, OnInit } from '@angular/core';
import { MatomoTracker } from 'ngx-matomo';

@Component({
  selector: 'app-about',
  templateUrl: './novidades.component.html',
  styleUrls: ['./novidades.component.scss']
})

export class NovidadesComponent implements OnInit {

  constructor(private matomoTracker: MatomoTracker) { }

  ngOnInit() {
    this.matomoTracker.setCustomUrl('/novidades');
    this.matomoTracker.trackPageView();
  }

}
