import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { TeacherNumberService } from './service/teacher-number.service';
import { TeacherNumber } from './entities/teacher-number';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';

@Component({
  selector: 'app-teacher-number',
  templateUrl: './teacher-number.component.html',
  styleUrls: ['./teacher-number.component.scss']
})

export class TeacherNumberComponent extends BaseNavigableComponent<TeacherNumber> {

  data: TeacherNumber = new TeacherNumber();
  functionality: FunctionalityInfo = Functionality.teacherNumber;
  inconsistencies: Array<Inconsistency>;

  constructor(private teacherNumberService: TeacherNumberService, private injector: Injector) {
    super(injector);
  }

  processData(): Observable<TeacherNumber> {
    //"teste:", this.teacherNumberService.getData());
    return this.teacherNumberService.getData();
  }

}
