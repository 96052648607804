import { Component, OnInit } from '@angular/core';

import { Functionality } from '../../../shared/entities/functionality/functionality';

@Component({
  selector: 'app-quality-conditions',
  templateUrl: './quality-conditions.component.html',
  styleUrls: ['./quality-conditions.component.scss']
})
export class QualityConditionsComponent implements OnInit {

  qualityAndConditionsName: string = Functionality.qualityconditions.name;

  constructor() { }

  ngOnInit() {
  }

}
