import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

import { Csv } from './entities/csv';
import { UtilitiesService } from '../utilities/utilities.service';

@Injectable({
  providedIn: 'root'
})
export class CsvService {

  constructor(private utilitiesService: UtilitiesService) { }

  download(csv: Csv): void {
    const BOM: string = '\uFEFF';
    const fileName: string = this.getFileName(csv.name);
    const csvData: string = this.getCsvData(csv);
    const fileData: string = `${BOM}${csvData}`;
    const blob: Blob = new Blob([fileData], { type: 'text/csv;charset=utf-8' });

    saveAs(blob, fileName);
  }

  private getFileName(originalName: string): string {
    const csvFileExtension: string = '.csv';
    let fileName: string = originalName;

    if (fileName.indexOf(csvFileExtension) === -1) {
      const selectedLocation: string = this.utilitiesService.getSelectLocationName();
      const simulationYear: string = this.utilitiesService.getLiteralSimulationYear();

      ////fileName += ` - ${selectedLocation} [${simulationYear}]${csvFileExtension}`;
      fileName += ` - ${selectedLocation} [2023]${csvFileExtension}`;
    }

    return fileName;
  }

  private getCsvData(csv: Csv): string {
    const separator: string = ';';
    const breakLine: string = '\n';
    const csvData: Array<string> = new Array<string>();
    let currentRow: Array<string> = new Array<string>();

    // Generate the header row.
    for (const header of csv.header) {
      currentRow.push(`${header.label}`);
    }

    csvData.push(currentRow.join(separator));

    for (const data of csv.data) {
      currentRow = new Array<string>();

      // Generate the data row.
      for (const header of csv.header) {
        let value: string = '';

        if (data[header.key]) {
          value = `${data[header.key]}`;

          if (typeof data[header.key] === 'number') {
            value = value.replace('.', ',');
          }
        }

        currentRow.push(value);
      }

      csvData.push(currentRow.join(separator));
    }

    return csvData.join(breakLine);
  }

}
