import { Location } from '../../../../shared/entities/location';

export class ClassroomExistingByLocation {

  location: Location;
  quantityClassroomExisting: number;

  constructor(init?: Partial<ClassroomExistingByLocation>) {
    Object.assign(this, init);
  }
}
