import { LocationEnrollment } from './location-enrollment';
import { AdmDependencyEnrollment } from './adm-dependency-enrollment';
import { Enrollment } from '../../enrollment-by-stage-series/entities/enrollment';
import { SerieAdmDependencyLocationEnrollments } from './serie-adm-dependency-location-enrollments';

export class StageAdmDependencyLocationEnrollments {
    id: number;
    description: string;
    totalOutOfSchoolPopulation: number;
    totalEnrollments: number;
    seriesAdmDependencyLocationEnrollments: Array<SerieAdmDependencyLocationEnrollments>;
    totalEnrollmentsByAdmDependency: Array<AdmDependencyEnrollment>;
    totalEnrollmentsByLocation: Array<LocationEnrollment>;

    constructor(init?: Partial<StageAdmDependencyLocationEnrollments>) {
        Object.assign(this, init);
    }
}
