export class RelatorioDimensaoOferta {

  numeroMatriculas: number;
  numeroTurmas: number;
  numeroDocentes: number;
  numeroFuncionarios: number;

  constructor(init?: Partial<RelatorioDimensaoOferta>) {
    Object.assign(this, init);
  }

}
