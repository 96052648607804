import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

import { HigherDemandClassroom } from './entities/higher-demand-classroom';
import { ClassNumberByLocation } from './entities/class-number-by-location';
import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { ClassNumber } from './entities/class-number';
import { ClassNumberByYear } from './entities/class-number-by-year';
import { Enrollment } from '../../../access-and-offer/enrollment-projection/entities/enrollment';
import { StageEnrollments } from '../../../access-and-offer/enrollment-projection/entities/stage-enrollments';
import { SessionService } from '../../../../shared/services/session/session.service';
import { ClassNumberByStage } from './entities/class-number-by-stage';
import { Stage } from './entities/stage';
import { LocationEnum } from '../../../../shared/entities/enums/location.enum';
import { Location } from '../../../../shared/entities/location';
import { ClassroomExistingByCity } from '../../../quality-conditions/new-room-building/entities/classroom-existing-by-city';
import { ClassroomExistingByLocation } from '../../../quality-conditions/new-room-building/entities/classroom-existing-by-location';
import { HigherDemandClassroomByLocation } from './entities/higher-demand-classroom-by-location';
import { NumberStudentClass } from '../../../quality-conditions/number-student-class/entities/number-student-class';
import { OfferGoalEnrollmentFullTime } from '../../../quality-conditions/offer-goal-enrollment-full-time/entities/offer-goal-enrollment-full-time';
import { ClassNumberBySerie } from './entities/class-number-by-serie';
import { StageEnum } from '../../../../shared/entities/enums/stage.enum';
import { EnrollmentProjection } from '../../../access-and-offer/enrollment-projection/entities/enrollment-projection';
import { StudentTeacherClass } from '../../../quality-conditions/number-student-class/entities/student-teacher-class';
import { ClassroomSchool } from './entities/enums/classroom-school.enum';
import { SchoolToBeBuiltByYear } from './entities/school-to-be-built-by-year';
import { SchoolToBeBuiltByLocation } from './entities/school-to-be-built-by-location';
import { SelectLocation } from './../../../select-location/entities/select-location';
import { EnrollmentAndClassAndClassroom } from './../../../results/financing-funds-report/entities/enrollment-and-class-and-classroom';
import { StageAndSeries } from './../../../../shared/entities/stage-and-series';
import { WorkJourneyTeacher } from './../../../quality-conditions/work-journey-teacher/entities/work-journey-teacher';
import { CareerAndRemunerationTeachers } from './../../../quality-conditions/career-and-remuneration-teachers/entities/career-and-remuneration-teachers';
import { DailyTeachingLoad } from './../../../quality-conditions/daily-teaching-load/entities/daily-teaching-load';
import { SchoolDayPerWeek } from './../../../quality-conditions/school-day-per-week/entities/school-day-per-week';
import { ClassStageEnum } from 'app/shared/entities/enums/class-stage.enum';
import { ClassReferenceEnum } from 'app/shared/entities/enums/class-reference.enum';
import { EnrollmentByStageEnum } from 'app/shared/entities/enums/enrollment-stage.enum';

@Injectable({
  providedIn: 'root'
})
export class CalculateClassNumberService {

  constructor(
    private utilitiesService: UtilitiesService,
    private sessionService: SessionService
  ) { }

  calculateClassNumber(locations: Array<Location>, stages: Array<Stage>, enrollmentAndClassAndClassroom: EnrollmentAndClassAndClassroom = undefined): Observable<ClassNumber> {

    const resultsEnrollmentAndClassAndClassroom: EnrollmentAndClassAndClassroom = this.sessionService.getItem<EnrollmentAndClassAndClassroom>(Functionality.enrollmentAndClassAndClassroom.key);
    const resultForSelectLocation: SelectLocation = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);

    /*if (resultForSelectLocation.selectedCity) {
      let classNumber: ClassNumber = new ClassNumber();
      classNumber = this.getClassNumber(locations, stages, enrollmentAndClassAndClassroom);
      return of(classNumber);
    } else {
      return of(resultsEnrollmentAndClassAndClassroom.classNumber);
    }*/

    let classNumber: ClassNumber = new ClassNumber();
    classNumber = this.getClassNumber(locations, stages, enrollmentAndClassAndClassroom);
    return of(classNumber);
  }

  calculateClassroomNumber(locations: Array<Location>, stages: Array<Stage>,
    classroomsExistingByCities: Array<ClassroomExistingByCity> = undefined, classroomExistingBySchool: ClassroomExistingByCity = undefined,
    enrollmentAndClassAndClassroom: EnrollmentAndClassAndClassroom = undefined
  ): Observable<HigherDemandClassroom> {

    let classNumber: ClassNumber = new ClassNumber();
    let higherDemandClassroomTotal: HigherDemandClassroom;

    higherDemandClassroomTotal = new HigherDemandClassroom({
      higherDemandsClassroomsByLocations: locations.map(location => new HigherDemandClassroomByLocation({
        location: location,
        demandClassroomExisting: 0,
        demandClassroomRequired: 0,
        demandNewClassroom: 0
      }))
    });

    const classroomExistingByCity = classroomsExistingByCities !== undefined ? _.first(classroomsExistingByCities) : classroomExistingBySchool;
    classNumber = this.getClassNumber(locations, stages, enrollmentAndClassAndClassroom);
    higherDemandClassroomTotal = this.getGreaterDemandClassrooms(locations, classNumber.classesNumberByLocations, classroomExistingByCity);

    return of(higherDemandClassroomTotal);

  }

  getClassNumber(locations: Array<Location>, stages: Array<Stage>, enrollmentAndClassAndClassroom: EnrollmentAndClassAndClassroom = undefined): ClassNumber {

    let classNumber: ClassNumber = new ClassNumber();
    const resultsForNumberStudentClass: NumberStudentClass = this.sessionService.getItem<NumberStudentClass>(Functionality.numberStudentClass.key);
    const resultsForOfferGoalEnrollmentFullTime: OfferGoalEnrollmentFullTime = this.sessionService.getItem<OfferGoalEnrollmentFullTime>(Functionality.offerGoalEnrollmentFullTime.key);
    const classesNumberByLocations: Array<ClassNumberByLocation> = new Array<ClassNumberByLocation>();
    const resultForEnrollmentProjectionsByLocation = enrollmentAndClassAndClassroom !== undefined ? enrollmentAndClassAndClassroom.enrollmentProjection :
      this.sessionService.getItem<EnrollmentProjection>(Functionality.enrollmentProjectionByLocation.key);

    for (let i = 0; i < locations.length; i++) {

      const location: Location = locations[i];
      const enrollmentProjectionByLocation = _.find(resultForEnrollmentProjectionsByLocation.enrollmentsProjectionsByLocations, rEBL => rEBL.id === location.id);

      if (enrollmentProjectionByLocation) {
        classesNumberByLocations.push(new ClassNumberByLocation({
          id: location.id,
          description: location.description,
          classesNumberByStages: this.getClassNumberByStage(enrollmentProjectionByLocation.stagesEnrollments, resultsForNumberStudentClass, resultsForOfferGoalEnrollmentFullTime, location.id)
        }));
      }
    }

    classNumber = new ClassNumber({
      classesNumberByLocations: classesNumberByLocations
    });

    return classNumber;
  }

  getClassNumberCalculated(datas: Array<any>): ClassNumber {

    let classNumber: ClassNumber = new ClassNumber();
    const classesNumberByLocations: Array<ClassNumberByLocation> = new Array<ClassNumberByLocation>();

    for (let i = 0; i < datas.length; i++) {

      const data = datas[i];

      classesNumberByLocations.push(new ClassNumberByLocation({
        id: data.location_id,
        description: data.location_name,
        classesNumberByStages: this.getClassNumberByStageCalculated(data.education_level)
      }));
    }

    classNumber = new ClassNumber({
      classesNumberByLocations: classesNumberByLocations,
      higherDemandClassroom: this.getHigherDemandClassroomCalculated(datas)
    });

    return classNumber;
  }

  getClassroomNumberCalculated(): Observable<HigherDemandClassroom> {

    const resultForOffergoalenrollmentfulltime: OfferGoalEnrollmentFullTime = this.sessionService.getItem<OfferGoalEnrollmentFullTime>(Functionality.offerGoalEnrollmentFullTime.key);
    const resultForNumberstudentclass: NumberStudentClass = this.sessionService.getItem<NumberStudentClass>(Functionality.numberStudentClass.key);
    const higherDemandClassroom: HigherDemandClassroom = new HigherDemandClassroom({ higherDemandsClassroomsByLocations: new Array<HigherDemandClassroomByLocation>() });
    const resultForSchoolDayPerWeek: SchoolDayPerWeek = this.sessionService.getItem<SchoolDayPerWeek>(Functionality.schoolDayPerWeek.key);
    const resultForDailyTeachingLoad: DailyTeachingLoad = this.sessionService.getItem<DailyTeachingLoad>(Functionality.dailyTeachingLoad.key);
    const resultForCareerAndRemunerationTeachers: CareerAndRemunerationTeachers = this.sessionService.getItem<CareerAndRemunerationTeachers>(Functionality.careerAndRemunerationTeachers.key);
    const resultForWorkJourneyTeacher: WorkJourneyTeacher = this.sessionService.getItem<WorkJourneyTeacher>(Functionality.workJourneyTeacher.key);

    let demandClassroomExisting: number;
    let demandClassroomRequired: number;
    let demandNewClassroom: number;

    return this.utilitiesService.getClassAndClassroomsAndEnrollment(resultForNumberstudentclass, resultForOffergoalenrollmentfulltime, resultForSchoolDayPerWeek, resultForDailyTeachingLoad,
      resultForCareerAndRemunerationTeachers, resultForWorkJourneyTeacher).pipe(
        map(classAndClassroomNumberAndEnrollment => {

          for (let i = 0; i < classAndClassroomNumberAndEnrollment.length; i++) {

            const classAndClassroomNumberAndEnrollmentByLocations = classAndClassroomNumberAndEnrollment[i].locations;

            for (let j = 0; j < classAndClassroomNumberAndEnrollmentByLocations.length; j++) {

              demandClassroomRequired = 0;
              demandClassroomExisting = classAndClassroomNumberAndEnrollmentByLocations[j].total_classroom;
              demandNewClassroom = classAndClassroomNumberAndEnrollmentByLocations[j].total_classroom_be_built;
              const classAndClassroomNumberAndEnrollmentByStage = classAndClassroomNumberAndEnrollmentByLocations[j].education_level;

              for (let k = 0; k < classAndClassroomNumberAndEnrollmentByStage.length; k++) {
                demandClassroomRequired += classAndClassroomNumberAndEnrollmentByStage[k].enrollment.total_classrooms_needed;
              }

              higherDemandClassroom.higherDemandsClassroomsByLocations.push(new HigherDemandClassroomByLocation({
                location: new Location({ id: classAndClassroomNumberAndEnrollmentByLocations[j].location_id, description: classAndClassroomNumberAndEnrollmentByLocations[j].location_name }),
                demandClassroomExisting: demandClassroomExisting,
                demandClassroomRequired: demandClassroomRequired,
                demandNewClassroom: demandNewClassroom
              }));

            }
          }
          return higherDemandClassroom;
        }));
  }

  getEmptyClassNumber(locations: Array<Location>, stagesAndSeries: Array<StageAndSeries>): ClassNumber {

    let classNumber: ClassNumber = new ClassNumber();
    const classesNumberByLocations: Array<ClassNumberByLocation> = new Array<ClassNumberByLocation>();

    for (let i = 0; i < locations.length; i++) {
      classesNumberByLocations.push(new ClassNumberByLocation({
        id: locations[i].id,
        description: locations[i].description,
        classesNumberByStages: this.getEmptyClassNumberByStage(stagesAndSeries)
      }));
    }

    classNumber = new ClassNumber({
      classesNumberByLocations: classesNumberByLocations,
      higherDemandClassroom: undefined
    });

    return classNumber;
  }

  getSchoolsToBeBuilt(classesNumberByLocations: Array<ClassNumberByLocation>, classroomExistingByCity: Array<ClassroomExistingByCity>): Array<SchoolToBeBuiltByLocation> {

    const schoolsToBeBuiltByLocation: Array<SchoolToBeBuiltByLocation> = new Array<SchoolToBeBuiltByLocation>();

    for (let i = 0; i < classesNumberByLocations.length; i++) {

      const schoolsToBeBuiltByYear: Array<SchoolToBeBuiltByYear> = new Array<SchoolToBeBuiltByYear>();
      const classNumberByLocation = classesNumberByLocations[i];
      const classroomNumberBySchool = classesNumberByLocations[i].id === LocationEnum.urban ? ClassroomSchool.UrbanClassroom : ClassroomSchool.RuralClassroom;

      for (let j = 0; j < classNumberByLocation.classesNumberByStages.length; j++) {
        const classNumberByStage = classNumberByLocation.classesNumberByStages[j];
        for (let k = 0; k < classNumberByStage.classesNumberByYear.length; k++) {
          const classNumberByYear = classNumberByStage.classesNumberByYear[k];
          const classroomDemand = classNumberByYear.classroomDemand;

          if (schoolsToBeBuiltByYear[k] === undefined) {
            schoolsToBeBuiltByYear.push(new SchoolToBeBuiltByYear({ year: classNumberByYear.year, value: classroomDemand }));
          } else {
            schoolsToBeBuiltByYear[k].value += classroomDemand;
          }
        }
      }

      const classroomExisting = _.first(classroomExistingByCity).classroomExistingByLocations[i].quantityClassroomExisting;

      for (let l = 0; l < schoolsToBeBuiltByYear.length; l++) {
        const classroomToBeBuilt = schoolsToBeBuiltByYear[l].value - classroomExisting;
        schoolsToBeBuiltByYear[l].value = classroomToBeBuilt >= classroomNumberBySchool ? Math.trunc(classroomToBeBuilt / classroomNumberBySchool) : 0;
      }

      schoolsToBeBuiltByLocation.push(new SchoolToBeBuiltByLocation({
        id: classNumberByLocation.id,
        description: classNumberByLocation.description,
        schoolsToBeBuiltsByYear: schoolsToBeBuiltByYear
      }));
    }

    return schoolsToBeBuiltByLocation;
  }

  private getEmptyClassNumberByStage(stagesAndSeries: Array<StageAndSeries>): Array<ClassNumberByStage> {

    const classesNumberByStages: Array<ClassNumberByStage> = new Array<ClassNumberByStage>();
    const simulationsYears: Array<number> = this.utilitiesService.getSimulationYears();

    for (let i = 0; i < stagesAndSeries.length; i++) {

      const stageAndSeries = stagesAndSeries[i];
      const classesNumberByYears: Array<ClassNumberByYear> = new Array<ClassNumberByYear>();
      const classesNumberBySeries: Array<ClassNumberBySerie> = new Array<ClassNumberBySerie>();

      if (stageAndSeries.id === StageEnum.creche) {

        for (let j = 0; j < stageAndSeries.series.length; j++) {
          const classSchool = stageAndSeries.series[j];
          const classesNumberSeriesByYears: Array<ClassNumberByYear> = new Array<ClassNumberByYear>();

          simulationsYears.map(year =>
            classesNumberSeriesByYears.push(new ClassNumberByYear({
              year: year,
              classNumber: 0,
              classNumberIntegral: 0,
              classNumberDaytimePartial: 0,
              classNumberNocturnal: 0,
              classroomDemand: 0
            }))
          );

          classesNumberBySeries.push(new ClassNumberBySerie({
            id: classSchool.id,
            description: classSchool.description,
            classesNumberByYear: classesNumberSeriesByYears
          }));
        }
      }

      simulationsYears.map(year =>
        classesNumberByYears.push(new ClassNumberByYear({
          year: year,
          classNumber: 0,
          classNumberIntegral: 0,
          classNumberDaytimePartial: 0,
          classNumberNocturnal: 0,
          classroomDemand: 0
        })));

      classesNumberByStages.push(new ClassNumberByStage({
        id: stageAndSeries.id,
        description: stageAndSeries.description,
        classNumberBySerie: classesNumberBySeries,
        classesNumberByYear: classesNumberByYears
      }));
    }

    return classesNumberByStages;
  }

  private getClassNumberByStage(
    enrollmentsByStage: Array<StageEnrollments>,
    numberStudentsByClasses: NumberStudentClass,
    offersGoalsEnrollmentFullTime: OfferGoalEnrollmentFullTime,
    location_Id: number
  ): Array<ClassNumberByStage> {

    const classesNumberByStages: Array<ClassNumberByStage> = new Array<ClassNumberByStage>();
    const classesNumberBySerie: Array<ClassNumberBySerie> = new Array<ClassNumberBySerie>();

    for (let i = 0; i < enrollmentsByStage.length; i++) {

      const enrollmentByStage = enrollmentsByStage[i];
      let studentTeacherClass: StudentTeacherClass = new StudentTeacherClass();

      let offerGoalStage: number = 0;

      if (enrollmentByStage.integralPercentage === undefined) {
        for (let j = 0; j < offersGoalsEnrollmentFullTime.fullTime.length; j++) {
          if (offersGoalsEnrollmentFullTime.fullTime[j].id === enrollmentByStage.id) {
            offerGoalStage = offersGoalsEnrollmentFullTime.fullTime[j].offerGoal;
            break;
          }
        }
      } else {
        offerGoalStage = enrollmentByStage.integralPercentage;
      }

      let minimumClassByStage: number = 0;
      let referenceEnrollmentByStage: number;

      if (enrollmentByStage.id === StageEnum.preEscola) {
        minimumClassByStage = ClassStageEnum.minimumClassPre;
        referenceEnrollmentByStage = EnrollmentByStageEnum.minimumEnrollmentPre;
      } else if (enrollmentByStage.id === StageEnum.ensinoFundamentalAnosIniciais) {
        minimumClassByStage = ClassStageEnum.minimumClassEFAI;
        referenceEnrollmentByStage = EnrollmentByStageEnum.minimumEnrollmentEFAI;
      } else if (enrollmentByStage.id === StageEnum.ensinoFundamentalAnosFinais) {
        minimumClassByStage = ClassStageEnum.minimumClassEFAF;
        referenceEnrollmentByStage = EnrollmentByStageEnum.minimumEnrollmentEFAF;
      } else if (enrollmentByStage.id === StageEnum.ensinoMedio) {
        minimumClassByStage = ClassStageEnum.minimumClassEM;
        referenceEnrollmentByStage = EnrollmentByStageEnum.minimumEnrollmentEM;
      } else if (enrollmentByStage.id === StageEnum.eja) {
        minimumClassByStage = ClassStageEnum.minimumClassEJA;
        referenceEnrollmentByStage = EnrollmentByStageEnum.minimumEnrollmentEJA;
      }

      // if (enrollmentByStage.id !== StageEnum.creche) {
      for (let j = 0; j < numberStudentsByClasses.studentClasses.length; j++) {
        if (numberStudentsByClasses.studentClasses[j].id === enrollmentByStage.id) {
          studentTeacherClass = _.find(numberStudentsByClasses.studentClasses[j].studentsTeachersClass, sTC => sTC.location.id === location_Id);
          break;
        }
      }

      classesNumberByStages.push(new ClassNumberByStage({
        id: enrollmentByStage.id,
        description: enrollmentByStage.description,
        classesNumberByYear: this.getClassNumberByYear(enrollmentByStage.totalEnrollments, studentTeacherClass.numberStudentClass, offerGoalStage, minimumClassByStage, referenceEnrollmentByStage)
      }));

      /* } else {
         for (let j = 0; j < enrollmentByStage.seriesEnrollments.length; j++) {
           const enrollmentsBySerie = enrollmentByStage.seriesEnrollments[j];
           const enrollmentsByYear = enrollmentByStage.seriesEnrollments[j].enrollments;

           for (let k = 0; k < numberStudentsByClasses.studentClasses.length; k++) {
             if (numberStudentsByClasses.studentClasses[k].serieId === enrollmentsBySerie.id) {
               studentTeacherClass = _.find(numberStudentsByClasses.studentClasses[k].studentsTeachersClass, sTC => sTC.location.id === location_Id);
               break;
             }
           }

           classesNumberBySerie.push(new ClassNumberBySerie({
             id: enrollmentsBySerie.id,
             description: enrollmentsBySerie.description,
             classesNumberByYear: this.getClassNumberByYear(enrollmentsByYear, studentTeacherClass.numberStudentClass, offerGoalStage)
           }));
         }

         classesNumberByStages.push(new ClassNumberByStage({
           id: enrollmentByStage.id,
           description: enrollmentByStage.description,
           classNumberBySerie: classesNumberBySerie,
           classesNumberByYear: this.getTotalClassNumber(classesNumberBySerie)
         }));
       }*/
    }

    return classesNumberByStages;
  }

  private getClassNumberByYear(
    enrollments: Array<Enrollment>,
    numberStudentByClass: number,
    offerGoalStage: number,
    minimumClassByStage: number,
    referenceEnrollmentByStage: number): Array<ClassNumberByYear> {

    const classesNumberByYears: Array<ClassNumberByYear> = new Array<ClassNumberByYear>();
    const simulationYear = this.utilitiesService.getSimulationYears();

    for (let i = 0; i < simulationYear.length; i++) {

      const shiftIntegralEnrollment = (enrollments[i].quantity * (offerGoalStage / 100));
      const shiftDaytimeEnrollment = enrollments[i].quantity - (shiftIntegralEnrollment + enrollments[i].quantityNocturnal);
      const shiftNocturnalEnrollment = enrollments[i].quantityNocturnal;
      // const shiftIntegralEnrollment = (shiftDaytimeEnrollment * (offerGoalStage / 100));

      // let classNumberIntegral = parseFloat(((shiftDaytimeEnrollment * (offerGoalStage / 100)) / numberStudentByClass).toFixed(2));
      let classNumberIntegral = (shiftIntegralEnrollment / numberStudentByClass);
      let classNumberDaytimePartial = (shiftDaytimeEnrollment / numberStudentByClass);
      // let classNumberDaytimePartial = parseFloat(((shiftDaytimeEnrollment / numberStudentByClass) - classNumberIntegral).toFixed(2));
      let classNumberNocturnal = (shiftNocturnalEnrollment / numberStudentByClass);

      if (classNumberIntegral > 0 && classNumberIntegral < 1) {
        classNumberIntegral = 1;
      } else {
        classNumberIntegral = Math.round(classNumberIntegral);
      }

      if (classNumberDaytimePartial > 0 && classNumberDaytimePartial < 1) {
        classNumberDaytimePartial = 1;
      } else {
        classNumberDaytimePartial = Math.round(classNumberDaytimePartial);
      }

      if (classNumberNocturnal > 0 && classNumberNocturnal < 1) {
        classNumberNocturnal = 1;
      } else {
        classNumberNocturnal = Math.round(classNumberNocturnal);
      }

      if (shiftIntegralEnrollment >= referenceEnrollmentByStage) {
        classNumberIntegral = classNumberIntegral > 0 && classNumberIntegral < minimumClassByStage ? minimumClassByStage : classNumberIntegral;
      }

      if (shiftDaytimeEnrollment >= referenceEnrollmentByStage) {
        classNumberDaytimePartial = classNumberDaytimePartial > 0 && classNumberDaytimePartial < minimumClassByStage ? minimumClassByStage : classNumberDaytimePartial;
      }

      if (shiftNocturnalEnrollment >= referenceEnrollmentByStage) {
        classNumberNocturnal = classNumberNocturnal > 0 && classNumberNocturnal < minimumClassByStage ? minimumClassByStage : classNumberNocturnal;
      }

      const classroomDemandIntegral = classNumberIntegral;
      const classroomDemandDaytimePartial = (classNumberDaytimePartial / 2);
      const classroomDemandNocturnal = classNumberNocturnal;

      let totalClassroomDemand: number = classroomDemandIntegral + classroomDemandDaytimePartial;
      totalClassroomDemand += classroomDemandNocturnal > classroomDemandDaytimePartial ? classroomDemandNocturnal - classroomDemandDaytimePartial : 0;

      classesNumberByYears.push(new ClassNumberByYear({
        year: simulationYear[i],
        classNumber: classNumberIntegral + classNumberDaytimePartial + classNumberNocturnal,
        classNumberIntegral: classNumberIntegral,
        classNumberDaytimePartial: classNumberDaytimePartial,
        classNumberNocturnal: classNumberNocturnal,
        classroomDemand: totalClassroomDemand
      }));

    }
    return classesNumberByYears;
  }

  private getTotalClassNumber(classesNumberBySerie: Array<ClassNumberBySerie>): Array<ClassNumberByYear> {

    const classesNumberByYear: Array<ClassNumberByYear> = new Array<ClassNumberByYear>();

    for (let i = 0; i < classesNumberBySerie.length; i++) {

      const classNumberBySerie = classesNumberBySerie[i];

      for (let j = 0; j < classNumberBySerie.classesNumberByYear.length; j++) {

        const classNumberByYear: ClassNumberByYear = _.find(classesNumberByYear, cNY => cNY.year === classNumberBySerie.classesNumberByYear[j].year);

        if (classNumberByYear === undefined) {
          classesNumberByYear.push(new ClassNumberByYear({
            year: classNumberBySerie.classesNumberByYear[j].year,
            classNumber: classNumberBySerie.classesNumberByYear[j].classNumber,
            classNumberDaytimePartial: classNumberBySerie.classesNumberByYear[j].classNumberDaytimePartial,
            classNumberIntegral: classNumberBySerie.classesNumberByYear[j].classNumberIntegral,
            classNumberNocturnal: classNumberBySerie.classesNumberByYear[j].classNumberNocturnal,
            classroomDemand: classNumberBySerie.classesNumberByYear[j].classroomDemand
          }));
        } else {
          classNumberByYear.classNumber += classNumberBySerie.classesNumberByYear[j].classNumber;
          classNumberByYear.classNumberDaytimePartial += classNumberBySerie.classesNumberByYear[j].classNumberDaytimePartial;
          classNumberByYear.classNumberIntegral += classNumberBySerie.classesNumberByYear[j].classNumberIntegral;
          classNumberByYear.classNumberNocturnal += classNumberBySerie.classesNumberByYear[j].classNumberNocturnal;
          classNumberByYear.classroomDemand += classNumberBySerie.classesNumberByYear[j].classroomDemand;
        }
      }
    }
    return classesNumberByYear;
  }

  private getClassNumberByStageCalculated(educationsLevels: Array<any>): Array<ClassNumberByStage> {

    const classesNumberByStages: Array<ClassNumberByStage> = new Array<ClassNumberByStage>();
    const simulationsYears: Array<number> = this.utilitiesService.getSimulationYears();

    for (let i = 0; i < educationsLevels.length; i++) {

      const data = educationsLevels[i];
      const classesNumberByYears: Array<ClassNumberByYear> = new Array<ClassNumberByYear>();
      const classesNumberBySeries: Array<ClassNumberBySerie> = new Array<ClassNumberBySerie>();

      /*if (data.education_level_short_id === StageEnum.creche) {

        for (let j = 0; j < data.classes_school_year.length; j++) {
          const classSchool = data.classes_school_year[j];
          const classesNumberSeriesByYears: Array<ClassNumberByYear> = new Array<ClassNumberByYear>();

          simulationsYears.map(year =>
            classesNumberSeriesByYears.push(new ClassNumberByYear({
              year: year,
              classNumber: classSchool.full_period_classes + classSchool.day_classes + classSchool.night_classes,
              classNumberIntegral: classSchool.full_period_classes,
              classNumberDaytimePartial: classSchool.day_classes,
              classNumberNocturnal: classSchool.night_classes,
              classroomDemand: classSchool.total_classrooms_needed
            }))
          );

          classesNumberBySeries.push(new ClassNumberBySerie({
            id: classSchool.school_year_id,
            description: classSchool.school_year_name,
            classesNumberByYear: classesNumberSeriesByYears
          }));
        }
      }*/

      simulationsYears.map(year =>
        classesNumberByYears.push(new ClassNumberByYear({
          year: year,
          classNumber: data.enrollment.full_period_classes + data.enrollment.day_classes + data.enrollment.night_classes,
          classNumberIntegral: data.enrollment.full_period_classes,
          classNumberDaytimePartial: data.enrollment.day_classes,
          classNumberNocturnal: data.enrollment.night_classes,
          classroomDemand: data.enrollment.total_classrooms_needed
        })));

      classesNumberByStages.push(new ClassNumberByStage({
        id: data.education_level_short_id,
        description: data.education_level_short_name,
        classNumberBySerie: classesNumberBySeries,
        classesNumberByYear: classesNumberByYears
      }));
    }

    return classesNumberByStages;
  }

  private getHigherDemandClassroomCalculated(datas: Array<any>): HigherDemandClassroom {

    const higherDemandClassroom: HigherDemandClassroom = new HigherDemandClassroom();
    const higherDemandsClassroomsByLocations: Array<HigherDemandClassroomByLocation> = new Array<HigherDemandClassroomByLocation>();

    for (let i = 0; i < datas.length; i++) {

      const data = datas[i];
      let classroomRequired: number = 0;

      for (let j = 0; j < data.education_level.length; j++) {
        classroomRequired += data.education_level[j].enrollment.total_classrooms_needed;
      }

      higherDemandsClassroomsByLocations.push(new HigherDemandClassroomByLocation({
        location: new Location({ id: data.location_id, description: data.location_name }),
        demandClassroomExisting: data.total_classroom,
        demandNewClassroom: data.total_classroom_be_built,
        demandClassroomRequired: classroomRequired
      }));
    }

    higherDemandClassroom.higherDemandsClassroomsByLocations = higherDemandsClassroomsByLocations;

    return higherDemandClassroom;

  }

  private getGreaterDemandClassrooms(locations: Array<Location>, classesNumberByLocations: Array<ClassNumberByLocation>, classroomExistingByCity: ClassroomExistingByCity): HigherDemandClassroom {

    const classesNumberByStagesUrban: Array<ClassNumberByStage> = new Array<ClassNumberByStage>();
    const classesNumberByStagesRural: Array<ClassNumberByStage> = new Array<ClassNumberByStage>();
    const simulationYear = this.utilitiesService.getSimulationYears();

    for (let i = 0; i < classesNumberByLocations.length; i++) {
      for (let j = 0; j < classesNumberByLocations[i].classesNumberByStages.length; j++) {

        if (classesNumberByLocations[i].id === LocationEnum.urban) {
          classesNumberByStagesUrban.push(classesNumberByLocations[i].classesNumberByStages[j]);
        } else if (classesNumberByLocations[i].id === LocationEnum.rural) {
          classesNumberByStagesRural.push(classesNumberByLocations[i].classesNumberByStages[j]);
        }

      }
    }

    let greaterDemandClassroomsUrban: number = 0;
    let greaterDemandClassroomsRural: number = 0;

    for (let i = 0; i < simulationYear.length; i++) {

      let totalDemandClassroomsUrbanByYear: number = 0;
      for (let j = 0; j < classesNumberByStagesUrban.length; j++) {
        for (let k = 0; k < classesNumberByStagesUrban[j].classesNumberByYear.length; k++) {
          if (classesNumberByStagesUrban[j].classesNumberByYear[k].year === simulationYear[i]) {
            totalDemandClassroomsUrbanByYear += classesNumberByStagesUrban[j].classesNumberByYear[k].classroomDemand;
            break;
          }
        }
      }
      greaterDemandClassroomsUrban = greaterDemandClassroomsUrban < totalDemandClassroomsUrbanByYear ? totalDemandClassroomsUrbanByYear : greaterDemandClassroomsUrban;

      let totalDemandClassroomsRuralByYear: number = 0;
      for (let j = 0; j < classesNumberByStagesRural.length; j++) {
        for (let k = 0; k < classesNumberByStagesRural[j].classesNumberByYear.length; k++) {
          if (classesNumberByStagesRural[j].classesNumberByYear[k].year === simulationYear[i]) {
            totalDemandClassroomsRuralByYear += classesNumberByStagesRural[j].classesNumberByYear[k].classroomDemand;
            break;
          }
        }
      }
      greaterDemandClassroomsRural = greaterDemandClassroomsRural < totalDemandClassroomsRuralByYear ? totalDemandClassroomsRuralByYear : greaterDemandClassroomsRural;

    }

    const higherDemandClassroom: HigherDemandClassroom = new HigherDemandClassroom({
      higherDemandsClassroomsByLocations: new Array<HigherDemandClassroomByLocation>()
    });

    for (let i = 0; i < classroomExistingByCity.classroomExistingByLocations.length; i++) {

      const classroomExistingByLocation: ClassroomExistingByLocation = classroomExistingByCity.classroomExistingByLocations[i];
      const demandClassroomRequired: number = classroomExistingByLocation.location.id === LocationEnum.urban ? (greaterDemandClassroomsUrban) : (greaterDemandClassroomsRural);
      const classroomExisting: number = parseFloat(classroomExistingByLocation.quantityClassroomExisting.toString());
      const demandNewClassroom: number = demandClassroomRequired - classroomExisting > 0 ? demandClassroomRequired - classroomExisting : 0;

      higherDemandClassroom.higherDemandsClassroomsByLocations.push(new HigherDemandClassroomByLocation({
        location: classroomExistingByLocation.location,
        demandClassroomExisting: classroomExisting,
        demandClassroomRequired: demandClassroomRequired,
        demandNewClassroom: demandNewClassroom
      }));
    }

    if (higherDemandClassroom.higherDemandsClassroomsByLocations.length < locations.length) {

      for (let i = 0; i < higherDemandClassroom.higherDemandsClassroomsByLocations.length; i++) {

        const higherDemandClassroomsByLocation: HigherDemandClassroomByLocation = higherDemandClassroom.higherDemandsClassroomsByLocations[i];
        if (higherDemandClassroomsByLocation.location.id === LocationEnum.urban) {
          higherDemandClassroom.higherDemandsClassroomsByLocations.push(new HigherDemandClassroomByLocation({
            location: new Location({ id: LocationEnum.rural, description: LocationEnum[LocationEnum.rural] }),
            demandClassroomExisting: 0,
            demandClassroomRequired: (greaterDemandClassroomsRural),
            demandNewClassroom: (greaterDemandClassroomsRural)
          }));
          break;
        } else if (higherDemandClassroomsByLocation.location.id === LocationEnum.rural) {
          higherDemandClassroom.higherDemandsClassroomsByLocations.push(new HigherDemandClassroomByLocation({
            location: new Location({ id: LocationEnum.urban, description: LocationEnum[LocationEnum.urban] }),
            demandClassroomExisting: 0,
            demandClassroomRequired: (greaterDemandClassroomsUrban),
            demandNewClassroom: (greaterDemandClassroomsUrban)
          }));
          break;
        }
      }
    }

    return higherDemandClassroom;
  }
}
