export class AccountPlan {

  id: number;
  description: string;
  order: number;

  constructor(init?: Partial<AccountPlan>) {
    Object.assign(this, init);
  }
}
