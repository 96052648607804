import { City } from './city';
import { State } from './state';

export class User {
  _id: string;
  name: string;
  email: string;
  password: string;
  cpf: string;
  schooling: string;
  course: string;
  segment: string;
  role: string;
  institutionName: string;
  state: string;
  city: string;
  receive_emails: boolean;
  origin: string;
  cep: string;
  nickname: string;
  //// cities: Array<City>;
  //// states: Array<State>;
  constructor(init?: Partial<User>) {
    this.origin = 'SimCAQ';
    Object.assign(this, init);
  }
}
