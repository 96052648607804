import { DemandClassRoomLocation } from './demand-class-room-location';

export class DemandClassRoom {

    id: number;
    description: string;
    quantityDemandClassRoomLocation: Array<DemandClassRoomLocation>;
    sourceNoteIdx: number;

    constructor(init?: Partial<DemandClassRoom>) {
        Object.assign(this, init);
    }
}
