import { Component, OnInit, Input } from '@angular/core';

import { Footnote } from './entities/footnote';
import { SourceInformationService } from '../../../simulator/shared/services/source-information/source-information.service';
import { SourceInformationEnum } from '../../entities/enums/source-information.enum';
import { CurrentYearService } from '../../../simulator/shared/services/current-year/current-year.service';

@Component({
  selector: 'app-footnote',
  templateUrl: './footnote.component.html',
  styleUrls: ['./footnote.component.scss']
})
export class FootnoteComponent implements OnInit {

  @Input() title: string;
  @Input() footnote: Footnote;
  @Input() footnotes: Array<Footnote>;

  private defaultTitle: string = 'Nota';

  constructor(private sourceInformationService: SourceInformationService, private currentYearService: CurrentYearService) { }

  ngOnInit() {
  }

  getNote(footnote: Footnote): string {
    let note: string;

    if (footnote) {
      if (footnote.sourceInformation) {
        let notePrefix: string = 'Elaborado a partir';
        let noteMiddle: string = 'dos';
        let noteSuffix: string;
        let noteYear: number;

        if (!this.title) {
          this.title = this.footnote ? 'Fonte:' : 'Fontes:';
        }

        switch (footnote.sourceInformation) {
          case SourceInformationEnum.enrollment:
            noteSuffix = 'dos microdados do Censo Escolar/INEP'; // this.sourceInformationService.getEnrollmentSourceInformation();
            noteYear = 2023; // this.currentYearService.getEnrollmentCurrentYear();
            break;

          case SourceInformationEnum.school:
            noteSuffix = ' dos microdados do Censo Escolar/INEP'; // this.sourceInformationService.getSchoolSourceInformation();
            noteYear = 2023; // this.currentYearService.getSchoolCurrentYear();
            break;

          case SourceInformationEnum.teacher:
            noteMiddle = '';
            noteSuffix = 'dos microdados do Censo Escolar/INEP'; // this.sourceInformationService.getTeacherSourceInformation();
            noteYear = 2023; // this.currentYearService.getTeacherCurrentYear();
            break;

          case SourceInformationEnum.classroom:
            noteSuffix = this.sourceInformationService.getClassroomSourceInformation();
            noteYear = this.currentYearService.getClassroomCurrentYear();
            break;

          case SourceInformationEnum.population:
            notePrefix = '';
            noteMiddle = '';
            noteSuffix = 'Elaborado a partir das Estimativas da População/IBGE';
            // noteSuffix = this.sourceInformationService.getPopulationSourceInformation();
            noteYear = 2021; // this.currentYearService.getPopulationCurrentYear();
            break;

          case SourceInformationEnum.pibPerCapita:
            notePrefix = '';
            noteMiddle = '';
            noteSuffix = 'Elaborado a partir da base de Produto Interno Bruto dos Municípios e Estimativas da População/IBGE '; // this.sourceInformationService.getPibPerCapitaSourceInformation();
            noteYear = 2021; // this.currentYearService.getPibPerCapitaCurrentYear();
            break;

          case SourceInformationEnum.idhm:
            notePrefix = '';
            noteMiddle = '';
            noteSuffix = 'Elaborado pelo Atlas do Desenvolvimento Humano no Brasil/PNUD ' +
              '(com dados do Censo de 2010) disponibilizados em '; // this.sourceInformationService.getIdhmSourceInformation();
            noteYear = 2013; // this.currentYearService.getIdhmCurrentYear();
            break;

          case SourceInformationEnum.siope:
            noteSuffix = this.sourceInformationService.getSiopeSourceInformation();
            noteYear = this.currentYearService.getSiopeCurrentYear();
            break;

          case SourceInformationEnum.infrastructure:
            noteSuffix = this.sourceInformationService.getInfrastructureSourceInformation();
            noteYear = this.currentYearService.getInfrastructureCurrentYear();
            break;

          case SourceInformationEnum.class:
            noteSuffix = this.sourceInformationService.getClassSourceInformation();
            noteYear = this.currentYearService.getClassCurrentYear();
            break;

          case SourceInformationEnum.cub:
            noteSuffix = this.sourceInformationService.getCubSourceInformation();
            noteYear = this.currentYearService.getCubCurrentYear();
            break;

          case SourceInformationEnum.employees:
            noteSuffix = 'microdados do Censo Escolar/INEP';
            noteYear = 2023;
            // noteSuffix = this.sourceInformationService.getEmployeeSourceInformation();
            // noteYear = this.currentYearService.getEmployeeCurrentYear();
            break;

          case SourceInformationEnum.transport:
            noteSuffix = this.sourceInformationService.getTransportSourceInformation();
            noteYear = this.currentYearService.getTransportCurrentYear();
            break;

          case SourceInformationEnum.financial:
            noteSuffix = this.sourceInformationService.getFinancialSourceInformation();
            noteYear = this.currentYearService.getFinancialCurrentYear();
            break;

          case SourceInformationEnum.dailyChargeAmount:
            noteSuffix = 'dos microdados do Censo Escolar/INEP';
            noteYear = 2023;
            // noteSuffix = this.sourceInformationService.getDailyChargeAmountSourceInformation();
            // noteYear = this.currentYearService.getDailyChargeAmountCurrentYear();
            break;

          case SourceInformationEnum.outOfSchoolPopulation:
            noteMiddle = 'da';
            noteSuffix = this.sourceInformationService.getOutOfSchoolPopulationSourceInformation();
            noteYear = this.currentYearService.getOutOfSchoolPopulationCurrentYear();
            break;

          case SourceInformationEnum.idhmPnud:
            notePrefix = '';
            noteMiddle = '';
            noteSuffix = 'Atlas do Desenvolvimento Humano no Brasil/PNUD (com dados do Censo de 2010)'; // this.sourceInformationService.getIdhmPnudSourceInformation();
            noteYear = 2013; // this.currentYearService.getIdhmPnudCurrentYear();
            break;
        }

        note = `${notePrefix ? `${notePrefix} ` : ''}${noteMiddle ? `${noteMiddle} ` : ''}${noteSuffix ? `${noteSuffix} ` : ''}${noteYear ? noteYear : ''}.`;
      } else {
        note = footnote.note;
      }

      if (!this.title) {
        this.title = this.footnote ? `${this.defaultTitle}:` : `${this.defaultTitle}s:`;
      }
    }

    return note;
  }

}
