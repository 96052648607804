import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PublicationsComponent } from './publications/publications.component';
import { TeamComponent } from './team/team.component';
import { PqrComponent } from './simulator/pqr/pqr.component';
import { QualityComponent } from './quality/quality.component';
import { HasErrorGuard } from './shared/guards/has-error/has-error.guard';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ContactComponent } from './contact/contact.component';
import { CaqComponent } from './caq/caq.component';
import { CaqResultComponent } from './caq-result/caq-result.component';
import { NovidadesComponent } from "./novidades/novidades.component";
import { Rel3Component } from './rel3/rel3.component';
import { CustoPorEscolaComponent } from './custo-por-escola/custo-por-escola.component';
import { FinanciamentoAtualNecessarioComponent } from './financiamento-atual-necessario/financiamento-atual-necessario.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canDeactivate: [HasErrorGuard] },
  { path: 'pqr', component: PqrComponent, canDeactivate: [HasErrorGuard] },
  { path: 'about', component: AboutComponent },
  { path: 'quality', component: QualityComponent },
  { path: 'publications', component: PublicationsComponent },
  { path: 'caq', component: CaqComponent },
  { path: 'custo-por-escola', component: CustoPorEscolaComponent },
  { path: 'financiamento-atual-necessario', component: FinanciamentoAtualNecessarioComponent },
  { path: 'caq-result', component: CaqResultComponent },
  { path: 'team', component: TeamComponent },
  { path: 'rel3', component: Rel3Component },
  { path: 'contact', component: ContactComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  { path: 'notfound', component: NotFoundComponent },
  { path: 'novidades', component: NovidadesComponent },
  { path: '**', pathMatch: 'full', redirectTo: 'notfound' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', enableTracing: false, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
