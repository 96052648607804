import { ClassNumberByYear } from './class-number-by-year';

export class ClassNumberBySerie {

  id: number;
  description: string;
  classesNumberByYear: Array<ClassNumberByYear>;

  constructor(init?: Partial<ClassNumberBySerie>) {
    Object.assign(this, init);
  }
}
