import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';
import { NewRoomBuilding } from './new-room-building';
import { DemandClassRoomByYear } from './demand-class-room-by-year';

export class NewRooms extends NavigableComponentData {

  buildings: Array<NewRoomBuilding> = new Array<NewRoomBuilding>();
  demandClassRoomByYear: DemandClassRoomByYear;

  constructor(init?: Partial<NewRooms>) {
    super();
    Object.assign(this, init);
  }
}
