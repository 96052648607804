import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { UtilitiesService } from '../shared/services/utilities/utilities.service';
import { NotificationService } from '../shared/services/notification/notification.service';
import { BaseUnsubscribe } from '../shared/entities/base/base-unsubscribe';
import { HttpService } from '../shared/services/http/http.service';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from 'app/login/services/login.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends BaseUnsubscribe implements OnInit {

  password: string;
  public passwordReset: boolean = false;
  private notificationTitle: string = 'Atenção';

  constructor(
    private route: ActivatedRoute,
    private loginService: LoginService,
    private notificationService: NotificationService,
    private httpService: HttpService) {
    super();
  }

  ngOnInit() {

  }

  changePassword() {

    const options = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([['password', this.password]]));

    this.httpService.post<any>(`${this.httpService.apiEndpointDadosEducacionaisHomV1}/reset/${this.route.snapshot.paramMap.get('token')}`, options).pipe(
      takeUntil(this.unsubscribe))
      .subscribe(
        (retorno) => {
          this.passwordReset = true;
          this.notificationService.showInfo(retorno.msg, 'Alterado com sucesso!');
        },
        (error: any) => {
          this.notificationService.showError('O token que você tem é inválido ou expirou, gere um novo para recuperar a sua senha.', this.notificationTitle);
        });
  }

  openLogin(): void {
    this.loginService.openLogin();
  }

}
