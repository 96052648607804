import { DescriptionValue } from './description-value';
import { Footnote } from '../../../shared/components/footnote/entities/footnote';

export class Sociodemographic extends DescriptionValue {
    footnote: Footnote;

    constructor(init?: Partial<Sociodemographic>) {
        super(init);
        Object.assign(this, init);
    }
}
