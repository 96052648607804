import { Injectable } from '@angular/core';
import { Observable, empty, forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { SelectLocation } from '../../../select-location/entities/select-location';
import { EmployeeEstimateByYear } from './entities/employee-estimate-by-year';
import { SchoolStaff } from '../../../quality-conditions/schools-staff/entities/school-staff';
import { HttpService } from '../../../../shared/services/http/http.service';
import { EmployeeEstimateByRole } from './entities/employee-estimate-by-role';
import { EmployeeEstimate } from './entities/employee-estimate';
import { SessionService } from '../../../../shared/services/session/session.service';
import { School } from './entities/school';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { SchoolsStaff } from '../../../quality-conditions/schools-staff/entities/schools-staff';
import { EmployeeEstimateByLocation } from './entities/employee-estimate-by-location';
import { Location } from '../../../../shared/entities/location';
import { CurrentYearService } from '../current-year/current-year.service';
import { SchoolByState } from './entities/school_by_state';
import { SchoolToBeBuiltByLocation } from '../calculate-class-number/entities/school-to-be-built-by-location';
import { EnrollmentReferenceEnum } from './enums/enrollment-reference.enum';
import { LocationEnum } from './../../../../shared/entities/enums/location.enum';

@Injectable({
  providedIn: 'root'
})
export class CalculateEmployeeEstimateService {

  constructor(private sessionService: SessionService, private httpService: HttpService, private utilitiesService: UtilitiesService, private currentYearService: CurrentYearService) { }

  // @tempoDeExecucao()
  calculateEmployeeEstimate(locations: Array<Location>, isFinancingFundsReport: boolean = false, stateId: number = 0,
    schoolsToBeBuilt: Array<SchoolToBeBuiltByLocation> = undefined): Observable<Array<EmployeeEstimate>> {

    const employeesEstimates: Array<EmployeeEstimate> = new Array<EmployeeEstimate>();
    const resultForSelectLocation: SelectLocation = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);
    const schoolStaff: SchoolsStaff = this.sessionService.getItem<SchoolsStaff>(Functionality.schoolsStaff.key);
    const simulationYears: Array<number> = this.utilitiesService.getSimulationYears(isFinancingFundsReport ? 1 : 0);
    const enrollmentCurrentYear: number = this.currentYearService.getEnrollmentCurrentYear();
    const enrollmentsObservables: Array<Observable<any>> = new Array<Observable<any>>();

    const filters: Array<string> = new Array<string>(
      `min_year:"${enrollmentCurrentYear}"`,
      `max_year:"${enrollmentCurrentYear}"`
    );

    filters.push(this.utilitiesService.getAdmDependencyFilter());

    if (!isFinancingFundsReport) {
      if (resultForSelectLocation.selectedState) {
        filters.push(`state:"${resultForSelectLocation.selectedState.value}"`);
      }
      if (resultForSelectLocation.selectedCity) {
        filters.push(`city:"${resultForSelectLocation.selectedCity.value}"`);
      }
    } else {
      filters.push(`state:"${stateId}"`);
    }

    filters.push(`period_not:[99]`);

    // total de matrículas por município e escola
    const options = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([
      ['dims', 'school,location'],
      ['filter', filters.join(',')]
    ]));

    enrollmentsObservables.push(this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/enrollment`, options).pipe(
          map(enrollmentsCitySchools => {
            for (let j = 0; j < locations.length; j++) {
              const employeesEstimateByLocations: Array<EmployeeEstimateByLocation> = new Array<EmployeeEstimateByLocation>();
              let employeesEstimateByRoles: Array<EmployeeEstimateByRole> = new Array<EmployeeEstimateByRole>();
              const employeesEstimateByRolesTotal: Array<EmployeeEstimateByRole> = new Array<EmployeeEstimateByRole>();

              for (let y = 0; y < enrollmentsCitySchools.length; y++) {
                const school: School = new School({
                  locationId: enrollmentsCitySchools[y].location_id,
                  enrollmentQuantity: enrollmentsCitySchools[y].total
                });

                if (school.locationId === locations[j].id) {
                  employeesEstimateByRoles = this.getEmployeeEstimateByRole(school.enrollmentQuantity, locations[j].id, schoolStaff, simulationYears);
                  employeesEstimateByRolesTotal.push(...employeesEstimateByRoles);
                }
              }

              employeesEstimateByLocations.push(new EmployeeEstimateByLocation({
                id: locations[j].id,
                description: locations[j].description,
                employeesEstimateByRoles: this.sumEmployeeEstimateByRole(employeesEstimateByRolesTotal, schoolStaff, simulationYears)
              }));

              employeesEstimates.push((new EmployeeEstimate({
                employeesEstimateByLocations: employeesEstimateByLocations
              })));

              if (schoolsToBeBuilt !== undefined) {
                this.addEmployeesForNewSchool(_.first(employeesEstimateByLocations).employeesEstimateByRoles, schoolStaff, schoolsToBeBuilt[j]);
              }
            }

            return empty();
          }));
      })));

    return forkJoin(enrollmentsObservables).pipe(
      map(() => {
        return employeesEstimates;
      }));

  }

  getEnrollmentSchoolState(): Observable<Array<SchoolByState>> {

    const enrollmentCurrentYear: number = this.currentYearService.getEnrollmentCurrentYear();
    const filters: Array<string> = new Array<string>(
      `min_year:"${enrollmentCurrentYear}"`,
      `max_year:"${enrollmentCurrentYear}"`,
      'adm_dependency:["1","2","3"]',
      'period_not:[99]'
    );

    // total de matrículas por município e escola
    const options = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([
      ['dims', 'school,state,location'],
      ['filter', filters.join(',')]
    ]));

    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/enrollment`, options).pipe(
          map(enrollmentsSchools => {

            const schoolsByState: Array<SchoolByState> = new Array<SchoolByState>();
            let schoolByState: SchoolByState;

            for (let i = 0; i < enrollmentsSchools.length; i++) {

              schoolByState = _.find(schoolsByState, sState => sState.id === enrollmentsSchools[i].state_id);

              if (schoolByState === undefined) {
                schoolByState = new SchoolByState({ id: enrollmentsSchools[i].state_id, schools: new Array<School>() });
                schoolsByState.push(schoolByState);
              }

              schoolByState.schools.push(new School({
                id: enrollmentsSchools[i].school_id,
                locationId: enrollmentsSchools[i].location_id,
                enrollmentQuantity: enrollmentsSchools[i].total
              }));
            }

            return schoolsByState;
          }));
      }));
  }

  calculateEmployeeEstimateByEntity(locations: Array<Location>, schoolByState: any, schoolsToBeBuilt: Array<SchoolToBeBuiltByLocation> = undefined): Array<EmployeeEstimate> {

    const employeesEstimates: Array<EmployeeEstimate> = new Array<EmployeeEstimate>();
    const employeeEstimate: EmployeeEstimate = new EmployeeEstimate({ employeesEstimateByLocations: new Array<EmployeeEstimateByLocation>() });
    const schoolStaff: SchoolsStaff = this.sessionService.getItem<SchoolsStaff>(Functionality.schoolsStaff.key);
    const simulationYears: Array<number> = this.utilitiesService.getSimulationYears(1);

    for (let i = 0; i < locations.length; i++) {
      const employeesEstimateByLocations: Array<EmployeeEstimateByLocation> = new Array<EmployeeEstimateByLocation>();
      let employeesEstimateByRoles: Array<EmployeeEstimateByRole> = new Array<EmployeeEstimateByRole>();
      const employeesEstimateByRolesTotal: Array<EmployeeEstimateByRole> = new Array<EmployeeEstimateByRole>();
      const schoolToBeBuilt = _.find(schoolsToBeBuilt, sBlt => sBlt.id === locations[i].id);

      for (let j = 0; j < schoolByState.schools.length; j++) {

        if (schoolByState.schools[j].locationId === locations[i].id) {

          const school: School = new School({
            locationId: schoolByState.schools[j].locationId,
            enrollmentQuantity: schoolByState.schools[j].enrollmentQuantity
          });

          employeesEstimateByRoles = this.getEmployeeEstimateByRole(school.enrollmentQuantity, locations[i].id, schoolStaff, simulationYears);
          employeesEstimateByRolesTotal.push(...employeesEstimateByRoles);
        }
      }

      employeesEstimateByLocations.push(new EmployeeEstimateByLocation({
        id: locations[i].id,
        description: locations[i].description,
        employeesEstimateByRoles: this.sumEmployeeEstimateByRole(employeesEstimateByRolesTotal, schoolStaff, simulationYears)
      }));

      if (schoolToBeBuilt !== undefined) {
        this.addEmployeesForNewSchool(_.first(employeesEstimateByLocations).employeesEstimateByRoles, schoolStaff, schoolToBeBuilt);
      }

      employeeEstimate.employeesEstimateByLocations.push(...employeesEstimateByLocations);
    }

    employeesEstimates.push((employeeEstimate));

    return employeesEstimates;
  }

  private sumEmployeeEstimateByRole(employeesEstimateByRoles: Array<EmployeeEstimateByRole>, schoolStaff: SchoolsStaff, simulationYears: Array<number>): Array<EmployeeEstimateByRole> {

    const employeesEstimateRoles: Array<EmployeeEstimateByRole> = new Array<EmployeeEstimateByRole>();

    for (let i = 0; i < schoolStaff.staffs.length; i++) {
      const staff = schoolStaff.staffs[i];
      const employeeEstimateRole: EmployeeEstimateByRole = new EmployeeEstimateByRole({
        id: staff.sequence,
        description: staff.denomination,
        priceIndice_id: _.head(staff.priceIndices),
        expenseType_id: _.head(staff.expenseTypes),
        grossMonthlyRemuneration: staff.grossMonthlyRemuneration,
        multiplier: staff.multiplier,
        isEducationAssistant: staff.isEducationAssistant,
        employeesEstimatesByYear: simulationYears.map(year => new EmployeeEstimateByYear({ year: year, quantity: 0 }))
      });
      for (let j = 0; j < employeesEstimateByRoles.length; j++) {
        const employeeEstimateByRole = employeesEstimateByRoles[j];
        if (staff.sequence === employeeEstimateByRole.id) {
          for (let k = 0; k < employeeEstimateByRole.employeesEstimatesByYear.length; k++) {
            employeeEstimateRole.employeesEstimatesByYear[k].quantity += employeeEstimateByRole.employeesEstimatesByYear[k].quantity;
          }
        }
      }
      employeesEstimateRoles.push(employeeEstimateRole);
    }

    return employeesEstimateRoles;
  }

  private getEmployeeEstimateByRole(enrollmentTotalBySchool: number, location_id: number, schoolStaff: SchoolsStaff, simulationYears: Array<number>): Array<EmployeeEstimateByRole> {

    const employeesEstimateByRoles: Array<EmployeeEstimateByRole> = new Array<EmployeeEstimateByRole>();

    for (let i = 0; i < schoolStaff.staffs.length; i++) {
      const staff = schoolStaff.staffs[i];

      employeesEstimateByRoles.push(
        new EmployeeEstimateByRole({
          id: staff.sequence,
          description: staff.denomination,
          priceIndice_id: _.head(staff.priceIndices),
          expenseType_id: _.head(staff.expenseTypes),
          grossMonthlyRemuneration: staff.grossMonthlyRemuneration,
          multiplier: staff.multiplier,
          isEducationAssistant: staff.isEducationAssistant,
          employeesEstimatesByYear: this.getEmployeeEstimateByYear(enrollmentTotalBySchool, staff, location_id, simulationYears)
        }));
    }

    return employeesEstimateByRoles;
  }

  private getEmployeeEstimateByYear(enrollmentTotalBySchool: number, schoolstaf: SchoolStaff, location_id: number, simulationYears: Array<number>): Array<EmployeeEstimateByYear> {

    const employeeEstimateByYear: Array<EmployeeEstimateByYear> = new Array<EmployeeEstimateByYear>();
    let employeeNumber: number = 0;
    let existLocationRole: boolean = false;

    for (let i = 0; i < schoolstaf.employeeLocation.length; i++) {
      if (schoolstaf.employeeLocation[i].location.id === location_id) {
        existLocationRole = schoolstaf.employeeLocation[i].value;
      }
    }

    simulationYears.map(simulationYear => {

      if (existLocationRole) {
        if (schoolstaf.professionalBySchool) {
          employeeNumber = schoolstaf.professionalBySchool;
        } else {

          const maxBySchool = schoolstaf.maxBySchool;
          const minBySchool = schoolstaf.minBySchool;
          const minByEnrollmentByFunction = schoolstaf.minEnrollmentByFunction;
          let bolCalculateProfessional = false;

          if (minByEnrollmentByFunction) {
            //// verifica se mínimo de matrícula / Função foi atingido
            if (enrollmentTotalBySchool >= minByEnrollmentByFunction) {
              bolCalculateProfessional = true;
            }
          } else {
            bolCalculateProfessional = true;
          }

          if (bolCalculateProfessional) {

            if (enrollmentTotalBySchool >= minByEnrollmentByFunction) {
              employeeNumber = 1;
            }

            if (enrollmentTotalBySchool >= schoolstaf.professionalByEnrollment) {
              employeeNumber = Math.trunc(enrollmentTotalBySchool / schoolstaf.professionalByEnrollment);
            }

            // employeeNumber = schoolstaf.professionalByEnrollment > 0 ? Math.trunc(enrollmentTotalBySchool / schoolstaf.professionalByEnrollment) : 0;
            if (maxBySchool) {
              if (employeeNumber < minBySchool) {
                employeeNumber = minBySchool;
              } else if (employeeNumber > minBySchool && employeeNumber < maxBySchool) {
                employeeNumber = Math.ceil(employeeNumber);
              } else if (employeeNumber > maxBySchool) {
                employeeNumber = maxBySchool;
              }
            } else {
              if (employeeNumber < minBySchool) {
                employeeNumber = minBySchool;
              } else if (employeeNumber > minBySchool) {
                employeeNumber = Math.ceil(employeeNumber);
              }
            }
          }
        }

        employeeEstimateByYear.push(new EmployeeEstimateByYear({ year: simulationYear, quantity: employeeNumber }));
      }
    });

    return employeeEstimateByYear;
  }

  private addEmployeesForNewSchool(employeesEstimateByRoles: Array<EmployeeEstimateByRole>, schoolStaff: SchoolsStaff, schoolToBeBuiltLocation: SchoolToBeBuiltByLocation): void {

    for (let i = 0; i < schoolStaff.staffs.length; i++) {
      const staff = schoolStaff.staffs[i];
      const existLocationRole: boolean = _.find(staff.employeeLocation, eL => eL.location.id === schoolToBeBuiltLocation.id).value;
      const professionalByEnrollmentDefault = schoolToBeBuiltLocation.id === LocationEnum.urban ? EnrollmentReferenceEnum.enrollmentQuantityUrban : EnrollmentReferenceEnum.enrollmentQuantityRural;

      if (existLocationRole) {
        for (let j = 0; j < employeesEstimateByRoles.length; j++) {
          const employeeEstimateByRole = employeesEstimateByRoles[j];
          if (staff.sequence === employeeEstimateByRole.id) {

            let professionalByEnrollment, totalNumberEmployeeToAdd;
            totalNumberEmployeeToAdd = staff.minBySchool;

            if (staff.professionalByEnrollment) {
              professionalByEnrollment = staff.professionalByEnrollment;

              if (professionalByEnrollment > 0) {
                if (professionalByEnrollmentDefault >= professionalByEnrollment) {
                  totalNumberEmployeeToAdd = Math.trunc(professionalByEnrollmentDefault / staff.professionalByEnrollment);
                }
              }
            }

            for (let k = 0; k < employeeEstimateByRole.employeesEstimatesByYear.length; k++) {
              const schoolToBeBuilt = schoolToBeBuiltLocation.schoolsToBeBuiltsByYear.length > 0 ? schoolToBeBuiltLocation.schoolsToBeBuiltsByYear[k].value : 0;
              employeeEstimateByRole.employeesEstimatesByYear[k].quantity += totalNumberEmployeeToAdd * schoolToBeBuilt;
            }
          }
        }
      }
    }
  }
}
