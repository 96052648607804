import { EmployeeEstimateByYear } from './employee-estimate-by-year';

export class EmployeeEstimateByRole {

  id: string;
  description: string;
  priceIndice_id: number;
  expenseType_id: number;
  expenseType_description: string;
  grossMonthlyRemuneration: number;
  multiplier: number;
  isEducationAssistant: boolean;
  employeesEstimatesByYear: Array<EmployeeEstimateByYear>;

  constructor(init?: Partial<EmployeeEstimateByRole>) {
    Object.assign(this, init);
  }

}
