export class Simulation {
    id: string;
    name: string;
    content: any;
    createdAt: string;
    updatedAt: string;

    constructor(init?: Partial<Simulation>) {
        Object.assign(this, init);
    }
}
