import { SchoolDay } from './school-day';
import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';

export class SchoolDayPerWeek extends NavigableComponentData {

    schoolDaysWeek: Array<SchoolDay> = new Array<SchoolDay>();

    constructor(init?: Partial<SchoolDay>) {
        super();
        Object.assign(this, init);
    }

}
