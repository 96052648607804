import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';

import { TeacherNumberByLocationCalc } from './entities/teacher-number-by-location-calc';
import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { CareerLevelByYear } from './entities/career-level-by-year';
import { WorkJourneyTeacher } from '../../../quality-conditions/work-journey-teacher/entities/work-journey-teacher';
import { CareerAndRemunerationTeachers } from '../../../quality-conditions/career-and-remuneration-teachers/entities/career-and-remuneration-teachers';
import { SessionService } from '../../../../shared/services/session/session.service';
import { TeacherNumberByStageCalc } from './entities/teacher-number-by-stage-calc';
import { CareerLevel } from './entities/career-level';
import { TeacherNumberCalc } from './entities/teacher-number-calc';
import { ShiftTeaching } from './entities/enums/shift-teaching.enum';
import { ClassNumber } from '../calculate-class-number/entities/class-number';
import { ClassNumberByStage } from '../calculate-class-number/entities/class-number-by-stage';
import { DailyTeachingLoad } from '../../../quality-conditions/daily-teaching-load/entities/daily-teaching-load';
import { TeachingLoad } from '../../../quality-conditions/daily-teaching-load/entities/teaching-load';
import { SchoolDayPerWeek } from '../../../quality-conditions/school-day-per-week/entities/school-day-per-week';
import { TeacherNumberBySerieCalc } from './entities/teacher-number-by-serie-calc';
import { ClassNumberByYear } from '../calculate-class-number/entities/class-number-by-year';
import { NumberStudentClass } from '../../../quality-conditions/number-student-class/entities/number-student-class';
import { StageEnum } from '../../../../shared/entities/enums/stage.enum';
import { EnrollmentAndClassAndClassroom } from './../../../results/financing-federated-entities-report/entities/enrollment-and-class-and-classroom';
import { SelectLocation } from './../../../select-location/entities/select-location';
import { PercentageTeacherCareer } from 'app/simulator/access-and-offer/enrollment-by-stage-series-by-school/entities/percentage_teacher_career';

@Injectable({
  providedIn: 'root'
})
export class CalculateTeacherNumberService {

  constructor(
    private sessionService: SessionService
  ) { }

  // @tempoDeExecucao()
  calculateTeacherNumber(calculatedClassNumber: ClassNumber, considerSeriesEspecificStage: boolean = true,
    percentagesTeachersCareer: Array<PercentageTeacherCareer> = undefined): Observable<Array<TeacherNumberCalc>> {

    const teachersNumber: Array<TeacherNumberCalc> = new Array<TeacherNumberCalc>();
    const resultForClassNumber: ClassNumber = calculatedClassNumber;
    const resultForWorkJourneyTeacher: WorkJourneyTeacher = this.sessionService.getItem<WorkJourneyTeacher>(Functionality.workJourneyTeacher.key);
    const resultForCareerAndRemunerationTeachers: CareerAndRemunerationTeachers = this.sessionService.getItem<CareerAndRemunerationTeachers>(Functionality.careerAndRemunerationTeachers.key);
    const resultsForDailyTeachingLoad: DailyTeachingLoad = this.sessionService.getItem<DailyTeachingLoad>(Functionality.dailyTeachingLoad.key);
    const resultsForSchoolDayPerWeek: SchoolDayPerWeek = this.sessionService.getItem<SchoolDayPerWeek>(Functionality.schoolDayPerWeek.key);
    const resultsForNumberStudentClass: NumberStudentClass = this.sessionService.getItem<NumberStudentClass>(Functionality.numberStudentClass.key);
    const teachersNumberByLocations: Array<TeacherNumberByLocationCalc> = new Array<TeacherNumberByLocationCalc>();
    const resultsEnrollmentAndClassAndClassroom: EnrollmentAndClassAndClassroom = this.sessionService.getItem<EnrollmentAndClassAndClassroom>(Functionality.enrollmentAndClassAndClassroom.key);
    const resultForSelectLocation: SelectLocation = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);

    /*if (resultForSelectLocation.selectedCity) {
      for (let j = 0; j < resultForClassNumber.classesNumberByLocations.length; j++) {

        const classNumberByLocation = resultForClassNumber.classesNumberByLocations[j];

        teachersNumberByLocations.push(new TeacherNumberByLocationCalc({
          id: classNumberByLocation.id,
          description: classNumberByLocation.description,
          teachersNumberByStagesCalc: this.getTeacherNumberByStage(classNumberByLocation.classesNumberByStages, resultForWorkJourneyTeacher,
            resultForCareerAndRemunerationTeachers, resultsForDailyTeachingLoad, resultsForSchoolDayPerWeek, resultsForNumberStudentClass, classNumberByLocation.id, considerSeriesEspecificStage,
            percentagesTeachersCareer)
        }));
      }

      teachersNumber.push(new TeacherNumberCalc({
        teachersNumberByLocationsCalc: teachersNumberByLocations
      }));

      return of(teachersNumber);
    } else {
      return of(resultsEnrollmentAndClassAndClassroom.teacherNumber);
    }*/

    for (let j = 0; j < resultForClassNumber.classesNumberByLocations.length; j++) {

      const classNumberByLocation = resultForClassNumber.classesNumberByLocations[j];

      teachersNumberByLocations.push(new TeacherNumberByLocationCalc({
        id: classNumberByLocation.id,
        description: classNumberByLocation.description,
        teachersNumberByStagesCalc: this.getTeacherNumberByStage(classNumberByLocation.classesNumberByStages, resultForWorkJourneyTeacher,
          resultForCareerAndRemunerationTeachers, resultsForDailyTeachingLoad, resultsForSchoolDayPerWeek, resultsForNumberStudentClass, classNumberByLocation.id, considerSeriesEspecificStage,
          percentagesTeachersCareer)
      }));
    }

    teachersNumber.push(new TeacherNumberCalc({
      teachersNumberByLocationsCalc: teachersNumberByLocations
    }));

    return of(teachersNumber);
  }

  private getTeacherNumberByStage(
    classNumberByStages: Array<ClassNumberByStage>,
    resultForWorkJourneyTeacher: WorkJourneyTeacher,
    resultForCareerAndRemunerationTeachers: CareerAndRemunerationTeachers,
    resultsForDailyTeachingLoad: DailyTeachingLoad,
    resultsForSchoolDayPerWeek: SchoolDayPerWeek,
    resultsForNumberStudentClass: NumberStudentClass,
    locationId: number,
    considerSeriesEspecificStage: boolean,
    percentagesTeachersCareer: Array<PercentageTeacherCareer> = undefined
  ): Array<TeacherNumberByStageCalc> {

    const teachersNumberByStages: Array<TeacherNumberByStageCalc> = new Array<TeacherNumberByStageCalc>();
    // const journey: number = (resultForWorkJourneyTeacher.journeyTotal * (resultForWorkJourneyTeacher.journeyWithInteraction / 100));
    let numberTeacherByClass: number = 1;

    for (let i = 0; i < classNumberByStages.length; i++) {

      const classNumberByStage = classNumberByStages[i];

      let teachingLoad: TeachingLoad = new TeachingLoad();
      let schoolDayPerWeek: number;

      const teacherNumberByStage: TeacherNumberByStageCalc = new TeacherNumberByStageCalc({
        id: classNumberByStage.id,
        description: classNumberByStage.description,
        careerLevelsByYear: new Array<CareerLevelByYear>()
      });

      for (let j = 0; j < resultsForDailyTeachingLoad.teachingLoads.length; j++) {
        if (resultsForDailyTeachingLoad.teachingLoads[j].id === classNumberByStage.id) {
          teachingLoad = resultsForDailyTeachingLoad.teachingLoads[j];
          break;
        }
      }

      let dailyTeachingLoadStageDaytimePartial: number = 0;
      let dailyTeachingLoadStageIntegral: number = 0;
      let dailyTeachingLoadStageNocturnal: number = 0;

      for (let j = 0; j < teachingLoad.shifts.length; j++) {

        if (teachingLoad.shifts[j].id === ShiftTeaching.DaytimePartial) {
          dailyTeachingLoadStageDaytimePartial = teachingLoad.shifts[j].value;
        } else if (teachingLoad.shifts[j].id === ShiftTeaching.Integral) {
          dailyTeachingLoadStageIntegral = teachingLoad.shifts[j].value;
        } else if (teachingLoad.shifts[j].id === ShiftTeaching.Nocturnal) {
          dailyTeachingLoadStageNocturnal = teachingLoad.shifts[j].value;
        }
      }

      if (classNumberByStage.id === StageEnum.ensinoFundamentalAnosIniciais || classNumberByStage.id === StageEnum.ensinoFundamentalAnosFinais) {
        dailyTeachingLoadStageNocturnal = dailyTeachingLoadStageDaytimePartial;
      }

      for (let j = 0; j < resultsForSchoolDayPerWeek.schoolDaysWeek.length; j++) {
        if (resultsForSchoolDayPerWeek.schoolDaysWeek[j].id === classNumberByStage.id) {
          schoolDayPerWeek = resultsForSchoolDayPerWeek.schoolDaysWeek[j].value;
          break;
        }
      }

      /*if (classNumberByStage.id === StageEnum.creche && considerSeriesEspecificStage) {

        const teachersNumberBySerie: Array<TeacherNumberBySerieCalc> = new Array<TeacherNumberBySerieCalc>();

        for (let k = 0; k < classNumberByStage.classNumberBySerie.length; k++) {

          const classesNumberBySerie = classNumberByStage.classNumberBySerie[k];
          const teacherNumberBySerie: TeacherNumberBySerieCalc = new TeacherNumberBySerieCalc({
            id: classNumberByStage.id,
            description: classNumberByStage.description,
            careerLevelsByYear: new Array<CareerLevelByYear>()
          });

          for (let j = 0; j < resultsForNumberStudentClass.studentClasses.length; j++) {
            const studentClasses = resultsForNumberStudentClass.studentClasses[j];
            if (studentClasses.serieId === classesNumberBySerie.id) {
              const studentTeacherClass = _.find(studentClasses.studentsTeachersClass, sTC => sTC.location.id === locationId);
              numberTeacherByClass = studentTeacherClass.numberTeacherClass;
            }
          }

          for (let l = 0; l < classesNumberBySerie.classesNumberByYear.length; l++) {

            const classNumberByYear = classesNumberBySerie.classesNumberByYear[l];
            const careerLevelByYear = this.getCareerLevelByYear(classNumberByYear, dailyTeachingLoadStageNocturnal, dailyTeachingLoadStageIntegral,
              dailyTeachingLoadStageDaytimePartial, schoolDayPerWeek, numberTeacherByClass, resultForCareerAndRemunerationTeachers, false, percentagesTeachersCareer);

            teacherNumberBySerie.careerLevelsByYear.push(careerLevelByYear);
          }
          teachersNumberBySerie.push(teacherNumberBySerie);
        }
        teacherNumberByStage.careerLevelsByYear = this.getCareerLevelStage(teachersNumberBySerie);
      } else {*/

      for (let j = 0; j < resultsForNumberStudentClass.studentClasses.length; j++) {
        const studentClasses = resultsForNumberStudentClass.studentClasses[j];
        if (studentClasses.id === classNumberByStage.id) {
          const studentTeacherClass = _.find(studentClasses.studentsTeachersClass, sTC => sTC.location.id === locationId);
          numberTeacherByClass = studentTeacherClass.numberTeacherClass;
          break;
        }
      }

      for (let k = 0; k < classNumberByStage.classesNumberByYear.length; k++) {

        const classNumberByYear = classNumberByStage.classesNumberByYear[k];
        const careerLevelByYear = this.getCareerLevelByYear(classNumberByYear, dailyTeachingLoadStageNocturnal, dailyTeachingLoadStageIntegral,
          dailyTeachingLoadStageDaytimePartial, schoolDayPerWeek, numberTeacherByClass, resultForCareerAndRemunerationTeachers, false, percentagesTeachersCareer);

        teacherNumberByStage.careerLevelsByYear.push(careerLevelByYear);
      }
      // }
      teachersNumberByStages.push(teacherNumberByStage);
    }
    return teachersNumberByStages;
  }

  private getCareerLevelByYear(
    classNumberByYear: ClassNumberByYear,
    dailyTeachingLoadStageNocturnal: number,
    dailyTeachingLoadStageIntegral: number,
    dailyTeachingLoadStageDaytimePartial: number,
    schoolDayPerWeek: number,
    numberTeacherByClass: number,
    resultForCareerAndRemunerationTeachers: CareerAndRemunerationTeachers,
    calculateWithCareerLevel: boolean = false,
    percentagesTeachersCareer: Array<PercentageTeacherCareer> = undefined
  ): CareerLevelByYear {

    const careerLevelsByYear: CareerLevelByYear = new CareerLevelByYear();
    let teacherNumberNocturnalTotal: number = 0;
    let teacherNumberIntegralTotal: number = 0;
    let teacherNumberDaytimePartialTotal: number = 0;

    for (let i = 0; i < resultForCareerAndRemunerationTeachers.teacherFormationLevels.levels.length; i++) {
      const percTeacherDistributionCareerLevels = resultForCareerAndRemunerationTeachers.teacherFormationLevels.levels[i].percTeacherDistributionCareerLevel;
      const totalJourney = resultForCareerAndRemunerationTeachers.teacherFormationLevels.levels[i].journeyTotal;
      const journeyWithInteraction = resultForCareerAndRemunerationTeachers.teacherFormationLevels.levels[i].journeyWithInteraction / 100;
      const journey = totalJourney * journeyWithInteraction;

      const dailyTeachingLoadNocturnal: number = (classNumberByYear.classNumberNocturnal) * dailyTeachingLoadStageNocturnal;
      const weeklyTeachingLoadNocturnal: number = dailyTeachingLoadNocturnal * schoolDayPerWeek;

      const dailyTeachingLoadIntegral: number = (classNumberByYear.classNumberIntegral) * dailyTeachingLoadStageIntegral;
      const weeklyTeachingLoadIntegral: number = dailyTeachingLoadIntegral * schoolDayPerWeek;

      const dailyTeachingLoadDaytimePartial: number = (classNumberByYear.classNumberDaytimePartial) * dailyTeachingLoadStageDaytimePartial;
      const weeklyTeachingLoadDaytimePartial: number = dailyTeachingLoadDaytimePartial * schoolDayPerWeek;

      for (let j = 0; j < percTeacherDistributionCareerLevels.length; j++) {

        let percentageDistribution;

        if (percTeacherDistributionCareerLevels[j].year === classNumberByYear.year && journey > 0) {

          if (percentagesTeachersCareer !== undefined) {
            const percentageTeacherCareer = _.find(percentagesTeachersCareer, pTc => pTc.formationLevelId ===
              resultForCareerAndRemunerationTeachers.teacherFormationLevels.levels[i].formationLevel.id);

            percentageDistribution = percentageTeacherCareer !== undefined ? percentageTeacherCareer.percentage / 100 : 0;

          } else {
            percentageDistribution = (percTeacherDistributionCareerLevels[j].percentage) / 100;
          }

          const teacherNumberNocturnal: number = (((weeklyTeachingLoadNocturnal / journey) * numberTeacherByClass * percentageDistribution));
          const teacherNumberIntegral: number = (((weeklyTeachingLoadIntegral / journey) * numberTeacherByClass * percentageDistribution));
          const teacherNumberDaytimePartial: number = (((weeklyTeachingLoadDaytimePartial / journey) * numberTeacherByClass * percentageDistribution));

          teacherNumberNocturnalTotal += teacherNumberNocturnal;
          teacherNumberIntegralTotal += teacherNumberIntegral;
          teacherNumberDaytimePartialTotal += teacherNumberDaytimePartial;
        }

      }

    }

    careerLevelsByYear.teacherNumberShiftIntegral = Math.round(teacherNumberIntegralTotal);
    careerLevelsByYear.teacherNumberShiftPartial = Math.round(teacherNumberDaytimePartialTotal + teacherNumberNocturnalTotal);
    careerLevelsByYear.teacherNumberTotal = Math.round(teacherNumberNocturnalTotal) + Math.round(teacherNumberIntegralTotal) + Math.round(teacherNumberDaytimePartialTotal);

    if (teacherNumberNocturnalTotal + teacherNumberIntegralTotal + teacherNumberDaytimePartialTotal < 1) {
      careerLevelsByYear.teacherNumberShiftIntegral = Math.ceil(teacherNumberIntegralTotal);
      careerLevelsByYear.teacherNumberShiftPartial = Math.ceil(teacherNumberDaytimePartialTotal + teacherNumberNocturnalTotal);
      careerLevelsByYear.teacherNumberTotal = Math.ceil(teacherNumberIntegralTotal) + Math.ceil(teacherNumberDaytimePartialTotal + teacherNumberNocturnalTotal);
    }

    careerLevelsByYear.year = classNumberByYear.year;
    careerLevelsByYear.careerLevels = calculateWithCareerLevel ? this.getCareerLevels(careerLevelsByYear.teacherNumberTotal,
      classNumberByYear.year, resultForCareerAndRemunerationTeachers) : undefined;

    return careerLevelsByYear;

  }

  private getCareerLevelStage(teacherNumberBySerie: Array<TeacherNumberBySerieCalc>): Array<CareerLevelByYear> {

    const careersLevelsByYear: Array<CareerLevelByYear> = new Array<CareerLevelByYear>();

    for (let i = 0; i < teacherNumberBySerie.length; i++) {

      for (let j = 0; j < teacherNumberBySerie[i].careerLevelsByYear.length; j++) {

        const teacherNumberByYear = teacherNumberBySerie[i].careerLevelsByYear[j];
        const careerLevelYear: CareerLevelByYear = _.find(careersLevelsByYear, cLY => cLY.year === teacherNumberByYear.year);

        if (careerLevelYear === undefined) {
          careersLevelsByYear.push(new CareerLevelByYear({
            year: teacherNumberByYear.year,
            teacherNumberTotal: teacherNumberByYear.teacherNumberTotal,
            teacherNumberShiftPartial: teacherNumberByYear.teacherNumberShiftPartial,
            teacherNumberShiftIntegral: teacherNumberByYear.teacherNumberShiftIntegral
          }));
        } else {
          careerLevelYear.teacherNumberTotal += teacherNumberByYear.teacherNumberTotal;
          careerLevelYear.teacherNumberShiftPartial += teacherNumberByYear.teacherNumberShiftPartial;
          careerLevelYear.teacherNumberShiftIntegral += teacherNumberByYear.teacherNumberShiftIntegral;
        }
      }
    }

    return careersLevelsByYear;
  }

  private getCareerLevels(teacherNumberTotal: number, year: number, resultForCareerAndRemunerationTeachers: CareerAndRemunerationTeachers): Array<CareerLevel> {

    const careerLevels: Array<CareerLevel> = new Array<CareerLevel>();
    let percTeacherDistributionCareerLevel: number = 0;

    for (const teacherFormationLevel of resultForCareerAndRemunerationTeachers.teacherFormationLevels.levels) {

      percTeacherDistributionCareerLevel = 0;

      for (let i = 0; i < teacherFormationLevel.percTeacherDistributionCareerLevel.length; i++) {
        if (teacherFormationLevel.percTeacherDistributionCareerLevel[i].year === year) {
          percTeacherDistributionCareerLevel = teacherFormationLevel.percTeacherDistributionCareerLevel[i].percentage;
          break;
        }
      }

      careerLevels.push(new CareerLevel({
        sequence: teacherFormationLevel.sequence,
        denomination: teacherFormationLevel.denomination,
        quantity: teacherNumberTotal * (percTeacherDistributionCareerLevel / 100)
      }));
    }

    return careerLevels;
  }
}
