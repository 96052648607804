import { PriceList } from '../../../../shared/entities/price-list';

export class FullPriceListItem extends PriceList {
    id: number;
    description: string;
    unitPrice: number;
    isGroupTitle: boolean;

    constructor(init?: Partial<FullPriceListItem>) {
        super();
        this.isGroupTitle = false;
        Object.assign(this, init);
    }

}
