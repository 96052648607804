export class StaffNumberByYear {

  year: number;
  value: number;

  constructor(init?: Partial<StaffNumberByYear>) {
    Object.assign(this, init);
  }

}
