import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';

import { Functionality } from 'app/shared/entities/functionality/functionality';
import { SessionService } from 'app/shared/services/session/session.service';
import { UtilitiesService } from 'app/shared/services/utilities/utilities.service';
import { NavigableComponentService } from 'app/simulator/shared/entities/base/navigable-component-service';
import { EnrollmentByStageSeriesBySchool } from '../../enrollment-by-stage-series-by-school/entities/enrollment-by-stage-series-by-school';
import { ResultEnrollmentProjection } from '../entities/result-enrollment-projection';
import { ResultSerieEnrollments } from '../entities/serie-enrollments-by-school';
import { ResultStageEnrollments } from '../entities/stage-enrollments-by-school';
import { EnrollmentBySchool } from '../../enrollment-by-stage-series-by-school/entities/enrollment-by-school';
import { StageEnum } from 'app/shared/entities/enums/stage.enum';
import { DiagnosticoDeMatriculaPorEscola } from '../../enrollment-by-stage-series-by-school/entities/diagnostico-de-matricula-por-escola';
import { Footnote } from 'app/shared/components/footnote/entities/footnote';

@Injectable({
  providedIn: 'root'
})
export class ResultEnrollmentProjectionService implements NavigableComponentService {

  constructor(
    private utilitiesService: UtilitiesService,
    private sessionService: SessionService
  ) { }

  getData(): Observable<ResultEnrollmentProjection> {

    const resultEnrollmentProjection: ResultEnrollmentProjection = new ResultEnrollmentProjection();
    const enrollmentBySchool: EnrollmentBySchool = this.sessionService.getItem<EnrollmentBySchool>(Functionality.enrollmentByStageAndSeriesBySchool.key);
    const yearsSimulation = this.utilitiesService.getSimulationYears();
    let anoMatricula: number;
    resultEnrollmentProjection.noteInfo = new Footnote({
      indice: 1,
      note: 'O % de matrícula em tempo integral é aplicado apenas nas matrículas diurnas e não no total de matrículas que aparece na tela, que contabaliza também as matrículas noturnas.'
    });

    resultEnrollmentProjection.year = yearsSimulation[0];

    return this.utilitiesService.getAno().pipe(
      mergeMap(ano => {
        anoMatricula = ano;
        return this.utilitiesService.getEtapasDeEnsino().pipe(
          map(etapas => {

            etapas.map(stage => {

              let enrollmentsStageDay: number = 0;
              let enrollmentsStageNight: number = 0;
              let enrollmentStageFullTime: number = 0;
              let percentageIntegral: number;

              for (let school = 0; school < enrollmentBySchool.diagnosticosMatriculaPorEscola.length; school++) {

                for (let schoolStage = 0; schoolStage < enrollmentBySchool.diagnosticosMatriculaPorEscola[school].etapasEnsino.length; schoolStage++) {
                  const etapaEnsino = enrollmentBySchool.diagnosticosMatriculaPorEscola[school].etapasEnsino;
                  if (etapaEnsino[schoolStage].codEtapaEnsino === stage.id) {
                    if (etapaEnsino[schoolStage]) {

                      percentageIntegral = etapaEnsino[schoolStage].percentualMatriculasIntegral;

                      enrollmentStageFullTime += etapaEnsino[schoolStage].numeroMatriculasIntegral > 0 ? etapaEnsino[schoolStage].numeroMatriculasIntegral : 0;
                      enrollmentsStageDay += etapaEnsino[schoolStage].numeroMatriculasDiurno > 0 ? etapaEnsino[schoolStage].numeroMatriculasDiurno : 0;
                      enrollmentsStageNight += etapaEnsino[schoolStage].numeroMatriculasNoturno > 0 ? etapaEnsino[schoolStage].numeroMatriculasNoturno : 0;

                    }
                  }
                }
              }

              const resultStageEnrollments: ResultStageEnrollments = new ResultStageEnrollments();

              resultStageEnrollments.id = stage.id;
              resultStageEnrollments.description = stage.description;
              resultStageEnrollments.totalEnrollmentDay = enrollmentsStageDay;
              resultStageEnrollments.totalEnrollmentNigth = enrollmentsStageNight;
              resultStageEnrollments.totalEnrollmentFullTime = enrollmentStageFullTime;
              resultStageEnrollments.totalMatriculaPorEtapa = enrollmentsStageDay + enrollmentsStageNight + enrollmentStageFullTime;

              if ((resultStageEnrollments.totalMatriculaPorEtapa) > 0) {
                resultStageEnrollments.percentageFullTime = 100 * (enrollmentStageFullTime / resultStageEnrollments.totalMatriculaPorEtapa);
              } else {
                resultStageEnrollments.percentageFullTime = 0;
              }
              resultEnrollmentProjection.resultEnrollmentProjection.push(resultStageEnrollments);
            });
            resultEnrollmentProjection.year = Number(anoMatricula) + 1;
            return resultEnrollmentProjection;
          }));
      }));
  }
}
