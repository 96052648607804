import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';
import { AdministrativeItemCosting } from './administrative-item-costing';

export class AdministrativeAreaCosting extends NavigableComponentData {

  administrativesItensCosting: Array<AdministrativeItemCosting> = new Array<AdministrativeItemCosting>();
  materialActionCostingPercentage: number;
  teacherTrainingCostingPercentage: number;
  schoolsOperationCostingPercentage: number;
  administrativeAreaCostingPercentage: number;
  totalPercentage: number;

  constructor(init?: Partial<AdministrativeAreaCosting>) {
    super();
    Object.assign(this, init);
  }
}
