import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import * as _ from 'lodash';

import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { HttpService } from '../../../../shared/services/http/http.service';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { CollaborationScheme } from '../entities/collaboration-scheme';
import { CollaborationSchemesByStage } from '../entities/collaboration-schemes-by-stage';
import { SelectLocation } from '../../../select-location/entities/select-location';
import { SessionService } from '../../../../shared/services/session/session.service';
import { AdmDependencyEnum } from '../../../../shared/entities/enums/adm-dependency.enum';
import { EnrollmentProjection } from '../../enrollment-projection/entities/enrollment-projection';
import { EnrollmentProjectionByLocation } from '../../enrollment-projection/entities/enrollment-projection-by-location';
import { ViewEnrollmentByStageSeries } from '../../view-enrollment-by-stage-series/entities/view-enrollment-by-stage-series';
import { CurrentYearService } from '../../../shared/services/current-year/current-year.service';
import { NavigableComponentService } from '../../../shared/entities/base/navigable-component-service';
import { SourceInformationEnum } from './../../../../shared/entities/enums/source-information.enum';
import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { ResultEnrollmentProjection } from '../../result-enrollment-projection/entities/result-enrollment-projection';
import { ResultStageEnrollments } from '../../result-enrollment-projection/entities/stage-enrollments-by-school';

@Injectable({
  providedIn: 'root'
})
export class CollaborationSchemeService implements NavigableComponentService {

  constructor(private httpService: HttpService, private utilitiesService: UtilitiesService, private sessionService: SessionService, private currentYearService: CurrentYearService) { }

  public getData(): Observable<CollaborationScheme> {

    const collaborationScheme: CollaborationScheme = new CollaborationScheme();
    let collaborationSchemesByStage: Array<CollaborationSchemesByStage> = new Array<CollaborationSchemesByStage>();

    collaborationScheme.collaborationSchemeByStage = new Array<CollaborationSchemesByStage>();
    collaborationScheme.totalCurrentPublicEnrollment = 0;
    collaborationScheme.totalProposalPublicEnrollment = 0;

    return this.utilitiesService.getAno().pipe(
      mergeMap(ano => {
        const anoMatricula: number = parseInt(ano.toString(), 10);
        return this.utilitiesService.getEtapasDeEnsino().pipe(
          map(stages => {
            collaborationSchemesByStage = stages.map(stage => new CollaborationSchemesByStage({
              id: stage.id,
              stageDescription: stage.description,
              numberCurrentPublicEnrollment: 0,
              numberProposalPublicEnrollment: 0,
              federalPercentage: 0,
              statePercentage: 0,
              municipalPercentage: 0
            }));

            const enrollmentOfferYear: number = this.currentYearService.getEnrollmentCurrentYear();

            collaborationScheme.yearCurrentPublicEnrollment = anoMatricula; // enrollmentOfferYear;
            collaborationScheme.yearProposalPublicEnrollment = anoMatricula; // enrollmentOfferYear + 1;

            const resultForEnrollmentProjection2: ResultEnrollmentProjection = this.sessionService.getItem<ResultEnrollmentProjection>(Functionality.resultEnrollmentProjection.key);
            //console.log(resultForEnrollmentProjection2);
            const resultForViewEnrollmentProjection: ViewEnrollmentByStageSeries =
              this.sessionService.getItem<ViewEnrollmentByStageSeries>(Functionality.viewEnrollmentByStageAndSeries.key);
            const resultForSelectLocation: SelectLocation = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);

            for (const stage of stages) {

              const collaboration: CollaborationSchemesByStage = _.find(collaborationSchemesByStage, c => c.id === stage.id);

              const stagesAdmDependencyLocationEnrollments = _.find(resultForViewEnrollmentProjection.stagesAdmDependencyLocationEnrollments, s => s.id === stage.id);

              const totalEnrollmentsByAdmDependencyFederal = _.find(stagesAdmDependencyLocationEnrollments.totalEnrollmentsByAdmDependency, t => t.id === AdmDependencyEnum.Federal);
              const totalEnrollmentsByAdmDependencyState = _.find(stagesAdmDependencyLocationEnrollments.totalEnrollmentsByAdmDependency, t => t.id === AdmDependencyEnum.State);
              const totalEnrollmentsByAdmDependencyMunicipal = _.find(stagesAdmDependencyLocationEnrollments.totalEnrollmentsByAdmDependency, t => t.id === AdmDependencyEnum.Municipal);
              const totalCurrentPublicEnrollment = (totalEnrollmentsByAdmDependencyFederal.quantity +
                totalEnrollmentsByAdmDependencyState.quantity + totalEnrollmentsByAdmDependencyMunicipal.quantity);

              if (collaboration) {
                const enrollmentProjectionByLocation2: ResultEnrollmentProjection = resultForEnrollmentProjection2;
                const stageEnrollment2 = _.find(enrollmentProjectionByLocation2.resultEnrollmentProjection, r => r.id === stage.id);

                if (stageEnrollment2) {
                  collaboration.numberCurrentPublicEnrollment = stageEnrollment2.totalEnrollmentCurrent;

                  const stagesEnrollmentsProposal = _.find(enrollmentProjectionByLocation2.resultEnrollmentProjection, r => r.id === stage.id);

                  // collaboration.numberProposalPublicEnrollment =
                  //  _.find(stagesEnrollmentsProposal.totalEnrollments, s => s.year === collaborationScheme.yearProposalPublicEnrollment).quantity;
                  collaboration.numberProposalPublicEnrollment = stagesEnrollmentsProposal.totalEnrollmentDay + stagesEnrollmentsProposal.totalEnrollmentNigth;

                  collaboration.federalPercentage = ((totalEnrollmentsByAdmDependencyFederal.quantity / totalCurrentPublicEnrollment) * 100);
                  collaboration.statePercentage = ((totalEnrollmentsByAdmDependencyState.quantity / totalCurrentPublicEnrollment) * 100);
                  collaboration.municipalPercentage = ((totalEnrollmentsByAdmDependencyMunicipal.quantity / totalCurrentPublicEnrollment) * 100);
                }

              }

              collaborationScheme.collaborationSchemeByStage.push(collaboration);
              collaborationScheme.totalCurrentPublicEnrollment = collaborationScheme.collaborationSchemeByStage.reduce((prevVal, elem) => prevVal + elem.numberCurrentPublicEnrollment, 0);
              collaborationScheme.totalProposalPublicEnrollment = collaborationScheme.collaborationSchemeByStage.reduce((prevVal, elem) => prevVal + elem.numberProposalPublicEnrollment, 0);
            }

            collaborationScheme.sourceInformations = this.getSourceInformations();
            collaborationScheme.infoNote = new Footnote({
              indice: 3,
              note: 'Os percentuais da divisão de responsabilidade são baseados no diagnóstico do último Censo Escolar referente ao ' +
                'percentual de matrículas em cada dependência administrativa, mas podem ser alterados pelo usuário.'
            });

            return collaborationScheme;
          }));
      }));
  }

  getSourceInformations(): Array<Footnote> {

    const footNotes: Array<Footnote> = new Array<Footnote>();

    footNotes.push(new Footnote({
      indice: 1,
      sourceInformation: SourceInformationEnum.enrollment
    }));
    footNotes.push(new Footnote({
      indice: 2,
      sourceInformation: SourceInformationEnum.enrollment
    }));
    return footNotes;
  }
}
