import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { OperationType } from '../../../entities/enums/operation-type.enum';
import { BlockUiService } from '../../block-ui/block-ui.service';
import { SessionService } from '../../session/session.service';
import { AuthService } from '../../auth/auth.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {

  private messagesMap: Map<OperationType, string> = new Map<OperationType, string>([
    [OperationType.enrollment, ' de matrículas'],
    [OperationType.school, ' de escolas'],
    [OperationType.teacher, ' de professores'],
    [OperationType.classroom, ' de salas de aula'],
    [OperationType.population, ' de população'],
    [OperationType.pibPerCapita, ' de PIB per capita'],
    [OperationType.idhm, ' de IDHM'],
    [OperationType.siope, ' do SIOPE'],
    [OperationType.infrastructure, ' de infraestrutura'],
    [OperationType.pqr, ' de PQR'],
    [null, '']
  ]);

  constructor(private blockUiService: BlockUiService, private sessionService: SessionService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const operationMessage: string = this.getOperationMessage(req.url);
    const blockMessage: string = `Obtendo dados${operationMessage}...`;
    const authToken: string = this.sessionService.getItem<string>(AuthService.tokenSessionKey);

    this.blockUiService.start(blockMessage);

    if (authToken) {
      req = req.clone({ setHeaders: { Authorization: `Bearer ${authToken}` } });
    }

    // const startedTime: number = Date.now();

    return next
      .handle(req).pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            this.blockUiService.stop();
            // this.logHttpResponseElapsedTime(req, startedTime);
          }
        }),
        catchError((err: HttpErrorResponse) => this.handleError(err)));
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    const notProfileUnauthorizedError: boolean = !err.url.endsWith('/user/me') && err.status !== 401;

    this.blockUiService.stop(notProfileUnauthorizedError);

    const error: any = { message: '', errorBody: err.error };
    let errorMessage: string;

    if (err.error instanceof Error) {
      error.message = err.error.message;
    } else {
      switch (err.status) {
        case 0:
        case 500:
          errorMessage = '500 - Could not communicate with server.';
          break;

        case 404:
          errorMessage = `${err.status} - Information not found.`;
          break;

        case 401:
          errorMessage = `${err.status} - User unauthorized.`;
          break;

        default:
          errorMessage = `${err.status} - ${err.statusText || ''}`;
          break;
      }

      error.message = errorMessage;
    }

    return throwError(error);
  }

  private logHttpResponseElapsedTime(req: HttpRequest<any>, startedTime: number): void {
    const elapsedTime = Date.now() - startedTime;
    // tslint:disable-next-line:no-console
    //console.log(`Request for ${req.urlWithParams} took ${elapsedTime} ms.`);
  }

  private getOperationMessage(url: string): string {
    let operationType: OperationType = null;

    if (url.indexOf('/enrollment') > -1) {
      operationType = OperationType.enrollment;
    } else if (url.indexOf('/school') > -1) {
      operationType = OperationType.school;
    } else if (url.indexOf('/teacher') > -1) {
      operationType = OperationType.teacher;
    } else if (url.indexOf('/classroom') > -1) {
      operationType = OperationType.classroom;
    } else if (url.indexOf('/population') > -1) {
      operationType = OperationType.population;
    } else if (url.indexOf('/pibpercapita') > -1) {
      operationType = OperationType.pibPerCapita;
    } else if (url.indexOf('/idhm') > -1) {
      operationType = OperationType.idhm;
    } else if (url.indexOf('/siope') > -1) {
      operationType = OperationType.siope;
    } else if (url.indexOf('/infrastructure') > -1) {
      operationType = OperationType.infrastructure;
    } else if (url.indexOf('/pqr') > -1) {
      operationType = OperationType.pqr;
    }

    return this.messagesMap.get(operationType);
  }
}
