import { SerieEnrollments } from './serie-enrollments';
import { Enrollment } from './enrollment';

export class StageEnrollments {
    id: number;
    description: string;
    totalOutOfSchoolPopulation: number;
    totalCurrentOffers: number;
    seriesEnrollments: Array<SerieEnrollments>;
    totalEnrollments: Array<Enrollment>;

    constructor(init?: Partial<StageEnrollments>) {
        Object.assign(this, init);
    }
}
