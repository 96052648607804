import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import * as _ from 'lodash';

import { NavigableComponentService } from '../../../shared/entities/base/navigable-component-service';
import { ViewEnrollmentByStageSeries } from '../entities/view-enrollment-by-stage-series';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { HttpService } from '../../../../shared/services/http/http.service';
import { SerieAdmDependencyLocationEnrollments } from '../entities/serie-adm-dependency-location-enrollments';
import { StageAdmDependencyLocationEnrollments } from '../entities/stage-adm-dependency-location-enrollments';
import { LocationEnrollment } from '../entities/location-enrollment';
import { Location } from '../../../../shared/entities/location';
import { AdmDependency } from '../../../../shared/entities/adm-dependency';
import { StageAndSeries } from '../../../../shared/entities/stage-and-series';
import { AdmDependencyEnrollment } from '../entities/adm-dependency-enrollment';
import { CurrentYearService } from '../../../shared/services/current-year/current-year.service';
import { OutOfSchoolPopulationService } from '../../../shared/services/out-of-school-population/out-of-school-population.service';
import { Footnote } from '../../../../shared/components/footnote/entities/footnote';
import { SourceInformationEnum } from '../../../../shared/entities/enums/source-information.enum';
import { DiagnosticoMatriculaPorEtapa } from '../entities/diagnostico-matricula-por-etapa';

@Injectable({
  providedIn: 'root'
})
export class ViewEnrollmentByStageSeriesService implements NavigableComponentService {

  constructor(
    private utilitiesService: UtilitiesService,
    private httpService: HttpService,
    private currentYearService: CurrentYearService,
    private outOfSchoolPopulationService: OutOfSchoolPopulationService) { }

  getData(): Observable<ViewEnrollmentByStageSeries> {
    let enrollmentCurrentYear: number; /* = this.currentYearService.getEnrollmentCurrentYear();*/
    let outOfSchoolPopulationCurrentYear: number; /* = this.currentYearService.getOutOfSchoolPopulationCurrentYear();*/
    const viewEnrollmentByStageSeries = new ViewEnrollmentByStageSeries({
      totals: new StageAdmDependencyLocationEnrollments({
        description: 'TOTAL',
        totalEnrollmentsByAdmDependency: new Array<AdmDependencyEnrollment>(),
        totalEnrollmentsByLocation: new Array<LocationEnrollment>()
      }),
      sourceInformations: new Array<Footnote>(
        // new Footnote({ indice: 8, sourceInformation: SourceInformationEnum.outOfSchoolPopulation, remarks: 'xxxResultados disponíveis somente para Brasil, estados e municípios das capitais' }),
        new Footnote({ indice: 1, sourceInformation: SourceInformationEnum.enrollment })
      ),
      enrollmentNote: new Footnote({
        indice: 2,
        note: 'A contagem de matrículas segue os mesmos critérios adotados pelo INEP: (a) O mesmo aluno pode ter mais de uma matrícula; (b) ' +
          'Não inclui matrículas de turmas de Atendimento Complementar e Atendimento Educacional Especializado (AEE); (c) Inclui matrículas do Ensino ' +
          'Regular, Especial e/ ou Educação de Jovens e Adultos(EJA).'
      }),
      enrollmentCurrentYear: enrollmentCurrentYear,
      outOfSchoolPopulationCurrentYear: outOfSchoolPopulationCurrentYear
    });

    return this.utilitiesService.getDependenciasAdmDetalhada().pipe(
      mergeMap(admDependencies => {
        viewEnrollmentByStageSeries.admDependencies = admDependencies.map(admDependency => admDependency.description);

        return this.utilitiesService.getLocalidades().pipe(
          mergeMap(locations => {
            viewEnrollmentByStageSeries.locations = locations.map(location => location.description);

            return this.utilitiesService.getAno().pipe(
              mergeMap(ano => {
                viewEnrollmentByStageSeries.enrollmentCurrentYear = ano;
                enrollmentCurrentYear = ano;
                outOfSchoolPopulationCurrentYear = ano;


                return this.httpService.getApiEndpointUFG().pipe(
                  mergeMap(endpointUFG => {

                    const localidade = this.utilitiesService.getTipoDependenciaUFG();
                    const matriculaUrlUFG: string = `${endpointUFG}/diagnostico-de-matricula/${localidade}`;


                    return this.httpService.get<any>(matriculaUrlUFG).pipe(
                      map(diagnosticoMatriculas => {

                        this.setDiagnosticoMatricula(viewEnrollmentByStageSeries, diagnosticoMatriculas);
                        this.setDiagnosticoMatriculaTotal(viewEnrollmentByStageSeries);


                        return viewEnrollmentByStageSeries;

                      }));


                  }));

              }));
          }));
      }));
  }

  setOutOfSchoolPopulation(viewEnrollmentByStageSeries: ViewEnrollmentByStageSeries): Observable<void> {
    return this.outOfSchoolPopulationService.getOutOfSchoolPopulations().pipe(
      map(outOfSchoolPopulations => {
        if (outOfSchoolPopulations && outOfSchoolPopulations.length > 0) {
          viewEnrollmentByStageSeries.notesOutOfSchoolPopulation = new Array<Footnote>();
          viewEnrollmentByStageSeries.totals.totalOutOfSchoolPopulation = 0;

          for (const stage of viewEnrollmentByStageSeries.stagesAdmDependencyLocationEnrollments) {
            let totalStageOutOfSchoolPopulation: number = 0;

            for (const serie of stage.seriesAdmDependencyLocationEnrollments) {
              serie.outOfSchoolPopulations = this.outOfSchoolPopulationService.getOutOfSchoolPopulationBasedOnSerie(serie.id);

              if (serie.outOfSchoolPopulations) {
                for (const outOfSchoolPopulationBySerie of serie.outOfSchoolPopulations) {
                  const outOfSchoolPopulation: any = _.first(outOfSchoolPopulations.filter(x => x.pfe_id === outOfSchoolPopulationBySerie.id));

                  if (outOfSchoolPopulation) {
                    outOfSchoolPopulationBySerie.value = outOfSchoolPopulation.total;
                    outOfSchoolPopulationBySerie.noteText = outOfSchoolPopulation.pfe_name;

                    totalStageOutOfSchoolPopulation += outOfSchoolPopulation.total;
                  }

                  if (outOfSchoolPopulationBySerie.noteIndice) {
                    viewEnrollmentByStageSeries.notesOutOfSchoolPopulation.push(new Footnote({ indice: outOfSchoolPopulationBySerie.noteIndice, note: outOfSchoolPopulationBySerie.noteText }));
                  }
                }
              }
            }

            stage.totalOutOfSchoolPopulation = totalStageOutOfSchoolPopulation;
            viewEnrollmentByStageSeries.totals.totalOutOfSchoolPopulation += stage.totalOutOfSchoolPopulation;
          }
        } else {
          if (viewEnrollmentByStageSeries.sourceInformations && viewEnrollmentByStageSeries.sourceInformations.length > 0) {
            for (let i = 0; i < viewEnrollmentByStageSeries.sourceInformations.length; i++) {
              viewEnrollmentByStageSeries.sourceInformations[i].indice = i + 1;
            }
          }

          if (viewEnrollmentByStageSeries.enrollmentNote) {
            viewEnrollmentByStageSeries.enrollmentNote.indice = viewEnrollmentByStageSeries.sourceInformations.length + 1;
          }
        }
      })
    );
  }

  private setStagesAndSeries(
    viewEnrollmentByStageSeries: ViewEnrollmentByStageSeries,
    stagesAndSeries: Array<StageAndSeries>,
    admDependencies: Array<AdmDependency>,
    locations: Array<Location>): void {

    viewEnrollmentByStageSeries.stagesAdmDependencyLocationEnrollments = stagesAndSeries.map(stageAndSeries =>
      new StageAdmDependencyLocationEnrollments({
        id: stageAndSeries.id,
        description: stageAndSeries.description,
        seriesAdmDependencyLocationEnrollments: stageAndSeries.series.map(serie => new SerieAdmDependencyLocationEnrollments({
          id: serie.id,
          description: serie.description,
          enrollmentsByAdmDependency: admDependencies.map(admDependency => new AdmDependencyEnrollment({ id: admDependency.id, quantity: 0 })),
          enrollmentsByLocation: locations.map(location => new LocationEnrollment({ id: location.id, quantity: 0 }))
        })),
        totalEnrollmentsByAdmDependency: new Array<AdmDependencyEnrollment>(),
        totalEnrollmentsByLocation: new Array<LocationEnrollment>()
      })
    );
  }

  private setSerieEnrollments(
    viewEnrollmentByStageSeries: ViewEnrollmentByStageSeries,
    schoolYearsEnrollments: Array<any>,
    admDependencyEnrollments: Array<any>,
    locationEnrollments: Array<any>): void {

    for (const stageEnrollment of viewEnrollmentByStageSeries.stagesAdmDependencyLocationEnrollments) {
      for (const serieEnrollment of stageEnrollment.seriesAdmDependencyLocationEnrollments) {
        const enrollmentsOfSchoolYear =
          _.first(schoolYearsEnrollments.filter(schoolYearsEnrollment => schoolYearsEnrollment.school_year_id === serieEnrollment.id));

        serieEnrollment.totalEnrollments = enrollmentsOfSchoolYear ? enrollmentsOfSchoolYear.total : 0;

        const enrollmentsOfSerieAdmDependency = admDependencyEnrollments.filter(admDependencyEnrollment => admDependencyEnrollment.school_year_id === serieEnrollment.id);

        for (const enrollmentByAdmDependency of serieEnrollment.enrollmentsByAdmDependency) {
          const enrollmentsOfAdmDependency =
            _.first(enrollmentsOfSerieAdmDependency.filter(enrollmentOfSerieAdmDependency =>
              enrollmentOfSerieAdmDependency.adm_dependency_detailed_id === enrollmentByAdmDependency.id));

          enrollmentByAdmDependency.quantity = enrollmentsOfAdmDependency ? enrollmentsOfAdmDependency.total : 0;
        }

        const enrollmentsOfSerieLocation = locationEnrollments.filter(locationEnrollment => locationEnrollment.school_year_id === serieEnrollment.id);

        for (const enrollmentByLocation of serieEnrollment.enrollmentsByLocation) {
          const enrollmentsOfLocation =
            _.first(enrollmentsOfSerieLocation.filter(enrollmentOfSerieLocation => enrollmentOfSerieLocation.location_id === enrollmentByLocation.id));

          enrollmentByLocation.quantity = enrollmentsOfLocation ? enrollmentsOfLocation.total : 0;
        }
      }
    }
  }

  private calculateTotals(viewEnrollmentByStageSeries: ViewEnrollmentByStageSeries): void {
    const firstStage = _.first(viewEnrollmentByStageSeries.stagesAdmDependencyLocationEnrollments) as StageAdmDependencyLocationEnrollments;
    const firstSerie = _.first(firstStage.seriesAdmDependencyLocationEnrollments) as SerieAdmDependencyLocationEnrollments;
    const admDependencyIds: Array<number> = firstSerie.enrollmentsByAdmDependency.map(enrollmentsByAdmDependency => enrollmentsByAdmDependency.id);
    const locationIds: Array<number> = firstSerie.enrollmentsByLocation.map(enrollmentsByLocation => enrollmentsByLocation.id);

    for (const stageAdmDependencyLocationEnrollments of viewEnrollmentByStageSeries.stagesAdmDependencyLocationEnrollments) {
      stageAdmDependencyLocationEnrollments.totalEnrollments =
        stageAdmDependencyLocationEnrollments.seriesAdmDependencyLocationEnrollments.reduce((previous, current) =>
          new SerieAdmDependencyLocationEnrollments({ totalEnrollments: previous.totalEnrollments + current.totalEnrollments })).totalEnrollments;

      // Stage - Total enrollments of adm. dependencies.
      for (const admDependencyId of admDependencyIds) {
        const enrollmentsOfAdmDependency: Array<AdmDependencyEnrollment> =
          [].concat(...stageAdmDependencyLocationEnrollments.seriesAdmDependencyLocationEnrollments.map(serieAdmDependencyLocationEnrollments =>
            serieAdmDependencyLocationEnrollments.enrollmentsByAdmDependency.filter(enrollmentByAdmDependency => enrollmentByAdmDependency.id === admDependencyId))) as Array<AdmDependencyEnrollment>;

        const totalEnrollmentsOfAdmDependency =
          enrollmentsOfAdmDependency.reduce((previous, current) => new AdmDependencyEnrollment({ id: admDependencyId, quantity: previous.quantity + current.quantity }));

        stageAdmDependencyLocationEnrollments.totalEnrollmentsByAdmDependency.push(totalEnrollmentsOfAdmDependency);
      }

      // Stage - Total enrollments of locations.
      for (const locationId of locationIds) {
        const enrollmentsOfLocation: Array<LocationEnrollment> =
          [].concat(...stageAdmDependencyLocationEnrollments.seriesAdmDependencyLocationEnrollments.map(serieAdmDependencyLocationEnrollments =>
            serieAdmDependencyLocationEnrollments.enrollmentsByLocation.filter(enrollmentByLocation => enrollmentByLocation.id === locationId))) as Array<LocationEnrollment>;

        const totalEnrollmentsOfLocation =
          enrollmentsOfLocation.reduce((previous, current) => new LocationEnrollment({ id: locationId, quantity: previous.quantity + current.quantity }));

        stageAdmDependencyLocationEnrollments.totalEnrollmentsByLocation.push(totalEnrollmentsOfLocation);
      }
    }

    // Grand total.
    this.calculateGrandTotals(viewEnrollmentByStageSeries, admDependencyIds, locationIds);
  }

  private calculateGrandTotals(viewEnrollmentByStageSeries: ViewEnrollmentByStageSeries, admDependencyIds: Array<number>, locationIds: Array<number>): void {
    viewEnrollmentByStageSeries.totals.totalEnrollments =
      viewEnrollmentByStageSeries.stagesAdmDependencyLocationEnrollments.reduce((previous, current) =>
        new StageAdmDependencyLocationEnrollments({ totalEnrollments: previous.totalEnrollments + current.totalEnrollments })).totalEnrollments;

    for (const admDependencyId of admDependencyIds) {
      const enrollmentsOfAdmDependency: Array<AdmDependencyEnrollment> =
        [].concat(...viewEnrollmentByStageSeries.stagesAdmDependencyLocationEnrollments.map(stageAdmDependencyLocationEnrollments =>
          stageAdmDependencyLocationEnrollments.totalEnrollmentsByAdmDependency.filter(totalEnrollmentByAdmDependency =>
            totalEnrollmentByAdmDependency.id === admDependencyId))) as Array<AdmDependencyEnrollment>;

      const totalEnrollmentsOfAdmDependency =
        enrollmentsOfAdmDependency.reduce((previous, current) => new AdmDependencyEnrollment({ id: admDependencyId, quantity: previous.quantity + current.quantity }));

      viewEnrollmentByStageSeries.totals.totalEnrollmentsByAdmDependency.push(totalEnrollmentsOfAdmDependency);
    }

    for (const locationId of locationIds) {
      const enrollmentsOfLocation: Array<LocationEnrollment> =
        [].concat(...viewEnrollmentByStageSeries.stagesAdmDependencyLocationEnrollments.map(stageAdmDependencyLocationEnrollments =>
          stageAdmDependencyLocationEnrollments.totalEnrollmentsByLocation.filter(totalEnrollmentByLocation =>
            totalEnrollmentByLocation.id === locationId))) as Array<LocationEnrollment>;

      const totalEnrollmentsOfLocation =
        enrollmentsOfLocation.reduce((previous, current) => new LocationEnrollment({ id: locationId, quantity: previous.quantity + current.quantity }));

      viewEnrollmentByStageSeries.totals.totalEnrollmentsByLocation.push(totalEnrollmentsOfLocation);
    }
  }

  private getRequestOptions(enrollmentCurrentYear: number): any {
    const filtersLocation: Array<string> = this.utilitiesService.getSelectLocationFilter();

    let filters: Array<string> = new Array<string>(
      `min_year:"${enrollmentCurrentYear}"`,
      `max_year:"${enrollmentCurrentYear}"`,
      'period_not:[99]'
    );

    filters = filters.concat(filtersLocation);
    filters.push(this.utilitiesService.getAdmDependencyFilter());

    return this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([
      ['dims', 'school_year'],
      ['filter', filters.join(',')]
    ]));
  }

  private getRequestOptionsAllDependencies(enrollmentCurrentYear: number): any {
    const filtersLocation: Array<string> = this.utilitiesService.getSelectLocationFilter();

    let filters: Array<string> = new Array<string>(
      `min_year:"${enrollmentCurrentYear}"`,
      `max_year:"${enrollmentCurrentYear}"`,
      `adm_dependency:["1","2","3","4","5","6","7"]`,
      'period_not:[99]'
    );

    filters = filters.concat(filtersLocation);

    return this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([
      ['dims', 'school_year'],
      ['filter', filters.join(',')]
    ]));
  }

  private setDiagnosticoMatricula(viewEnrollmentByStageSeries: ViewEnrollmentByStageSeries, diagnosticoMatriculas: any): void {

    const diagnosticosMatriculaPorEtapa: Array<DiagnosticoMatriculaPorEtapa> = new Array<DiagnosticoMatriculaPorEtapa>();

    for (const diagnostico of diagnosticoMatriculas.content) {
      diagnosticosMatriculaPorEtapa.push(
        new DiagnosticoMatriculaPorEtapa(
          {
            idEtapaEnsino: diagnostico.codTipoDepenAdmEscolasPrivadas,
            descEtapaEnsino: diagnostico.etapaEnsino,
            totalMatriculas: diagnostico.total,
            matriculaFederal: diagnostico.dependenciaFederal,
            matriculaEstadual: diagnostico.dependenciaEstadual,
            matriculaMunicipal: diagnostico.dependenciaMunicipal,
            matriculaConvSemFim: diagnostico.dependenciaConveniadaSemFinsLucrativos,
            matriculaConvComFim: diagnostico.dependenciaConveniadaComFinsLucrativos,
            matriculaNConvSemFim: diagnostico.dependenciaNaoConveniadaSemFinsLucrativos,
            matriculaNConvComFim: diagnostico.dependenciaNaoConveniadaComFinsLucrativos,
            matriculaSemDetalhamento: diagnostico.depenciaSemDetalhamento,
            matriculaUrbana: diagnostico.urbana,
            matriculaRural: diagnostico.rural
          }
        )

      );
    }

    viewEnrollmentByStageSeries.diagnosticosMatriculaPorEtapa = diagnosticosMatriculaPorEtapa;
  }

  private setDiagnosticoMatriculaTotal(viewEnrollmentByStageSeries: ViewEnrollmentByStageSeries): void {

    const diagnosticoMatriculaTotal: DiagnosticoMatriculaPorEtapa = new DiagnosticoMatriculaPorEtapa({
      descEtapaEnsino: 'TOTAL',
      totalMatriculas: 0,
      matriculaFederal: 0,
      matriculaEstadual: 0,
      matriculaMunicipal: 0,
      matriculaConvSemFim: 0,
      matriculaConvComFim: 0,
      matriculaNConvSemFim: 0,
      matriculaNConvComFim: 0,
      matriculaSemDetalhamento: 0,
      matriculaUrbana: 0,
      matriculaRural: 0
    });

    for (let i = 0; i < viewEnrollmentByStageSeries.diagnosticosMatriculaPorEtapa.length; i++) {
      diagnosticoMatriculaTotal.totalMatriculas += viewEnrollmentByStageSeries.diagnosticosMatriculaPorEtapa[i].totalMatriculas;
      diagnosticoMatriculaTotal.matriculaFederal += viewEnrollmentByStageSeries.diagnosticosMatriculaPorEtapa[i].matriculaFederal;
      diagnosticoMatriculaTotal.matriculaEstadual += viewEnrollmentByStageSeries.diagnosticosMatriculaPorEtapa[i].matriculaEstadual;
      diagnosticoMatriculaTotal.matriculaMunicipal += viewEnrollmentByStageSeries.diagnosticosMatriculaPorEtapa[i].matriculaMunicipal;
      diagnosticoMatriculaTotal.matriculaConvSemFim += viewEnrollmentByStageSeries.diagnosticosMatriculaPorEtapa[i].matriculaConvSemFim;
      diagnosticoMatriculaTotal.matriculaConvComFim += viewEnrollmentByStageSeries.diagnosticosMatriculaPorEtapa[i].matriculaConvComFim;
      diagnosticoMatriculaTotal.matriculaNConvSemFim += viewEnrollmentByStageSeries.diagnosticosMatriculaPorEtapa[i].matriculaNConvSemFim;
      diagnosticoMatriculaTotal.matriculaNConvComFim += viewEnrollmentByStageSeries.diagnosticosMatriculaPorEtapa[i].matriculaNConvComFim;
      diagnosticoMatriculaTotal.matriculaSemDetalhamento += viewEnrollmentByStageSeries.diagnosticosMatriculaPorEtapa[i].matriculaSemDetalhamento;
      diagnosticoMatriculaTotal.matriculaUrbana += viewEnrollmentByStageSeries.diagnosticosMatriculaPorEtapa[i].matriculaUrbana;
      diagnosticoMatriculaTotal.matriculaRural += viewEnrollmentByStageSeries.diagnosticosMatriculaPorEtapa[i].matriculaRural;
    }

    viewEnrollmentByStageSeries.diagnosticoMatriculaTotal = diagnosticoMatriculaTotal;
  }
}
