import { Component, Injector, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { CustoPorEscola } from './entities/custo-por-escola';
import { CustoPorEscolaService } from './services/custo-por-escola.service';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { MatomoTracker } from 'ngx-matomo';

@Component({
    selector: 'app-custo-por-escola',
    templateUrl: './custo-por-escola.component.html',
    styleUrls: ['./custo-por-escola.component.scss']
})

/*
export class CustoPorEscolaComponent extends BaseNavigableComponent<CustoPorEscola> {

    data: CustoPorEscola = new CustoPorEscola();
    functionality: FunctionalityInfo = Functionality.custoPorEscola;
    inconsistencies: Array<Inconsistency>;

    constructor(
        private injector: Injector,
        private custoPorEscolaService: CustoPorEscolaService
    ) {
        super(injector);
    }

    processData() {
        return this.custoPorEscolaService.getCustoPorEscola();
    }

    simulationLevel(): boolean {
        return this.custoPorEscolaService.isSimulationCity();
    }

    downloadCsv(): void {
        this.custoPorEscolaService.downloadCsvPorEscola();
    }
}
*/

@Component({
    selector: 'app-custo-por-escola',
    templateUrl: './custo-por-escola.component.html',
    styleUrls: ['./custo-por-escola.component.scss']
})
export class CustoPorEscolaComponent implements OnInit {

    functionality: FunctionalityInfo = Functionality.custoPorEscola

    constructor(private matomoTracker: MatomoTracker, private custoPorEscolaService: CustoPorEscolaService) {
    }

    ngOnInit() {
        this.matomoTracker.setCustomUrl('/quality');
        this.matomoTracker.trackPageView();
    }

    downloadCsv(): void {
        this.custoPorEscolaService.downloadCsvPorEscola();
    }

}
