import { ItemCostExpenseReportByYear } from './item-cost-expense-report-by-year';

export class ItemCostExpenseReportByShift {

  id: number;
  description: string;
  itemsCostExpenseReportByYears: Array<ItemCostExpenseReportByYear>;

  constructor(init?: Partial<ItemCostExpenseReportByShift>) {
    Object.assign(this, init);
  }

}
