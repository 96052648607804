import { NavigableComponentData } from "app/simulator/shared/entities/base/navigable-component-data";
import { ResultStageEnrollments } from "./stage-enrollments-by-school";
import { Footnote } from "app/shared/components/footnote/entities/footnote";

export class ResultEnrollmentProjection extends NavigableComponentData {

    resultEnrollmentProjection: Array<ResultStageEnrollments> = new Array<ResultStageEnrollments>();
    year: number;
    noteInfo: Footnote;

    constructor(init?: Partial<ResultEnrollmentProjection>) {
        super();
        Object.assign(this, init);
    }
}
