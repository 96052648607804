import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';
import { AdministrativeAreaCosting } from './administrative-area-costing';

export class AdministrativeItemCosting extends NavigableComponentData {

  sequence: number;
  denomination: string;
  percentage: number;

  constructor(init?: Partial<AdministrativeItemCosting>) {
    super();
    Object.assign(this, init);
  }

}