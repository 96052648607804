export class State {

  id: number;
  description: string;
  abbreviation: string;

  constructor(init?: Partial<State>) {
    Object.assign(this, init);
  }

}
