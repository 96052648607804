import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { SessionService } from '../../../../shared/services/session/session.service';
import { Additional } from '../entities/additional';
import { NavigableComponentService } from '../../../shared/entities/base/navigable-component-service';
import { UtilitiesService } from 'app/shared/services/utilities/utilities.service';
import { SimulationType } from 'app/simulator/simulator/entities/enums/simulation-type.enum';

@Injectable({
  providedIn: 'root'
})
export class AdditionalsService implements NavigableComponentService {

  constructor(private sessionService: SessionService) { }

  getData(): Observable<Additional> {
    return of(this.sessionService.getItem<Additional>(Functionality.additionals.pqrKey));
  }

  getResultSimulationRoute(): Array<string> {
    const simulationType: SimulationType = this.sessionService.getItem<SimulationType>(UtilitiesService.simulationTypeSessionKey);
    let route: Array<string>;

    switch (simulationType) {
      case SimulationType.caq:
        route = Functionality.caqReport.route;
        break;

      case SimulationType.planning:
      case SimulationType.planningByStateSphereAdm:
      case SimulationType.planningByCitySphereAdm:

        route = Functionality.budgetForecastReport.route;
        break;

      case SimulationType.financing:
      case SimulationType.financingFederatedEntitiesByStateSphereAdm:
        route = Functionality.custoPorEscola.route;
        break;
      case SimulationType.financingFederatedEntitiesByCitySphereAdm:
        route = Functionality.custoPorEscola.route;
        break;
      case SimulationType.financingFederatedEntitiesGroupByCityOrState:
        route = Functionality.financingFederatedEntitiesReport.route;
        break;

      default:
        route = undefined;
        break;
    }

    return route;
  }

}
