import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, mergeMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { ColumnReportByLocation } from '../entities/column-report-by-location';
import { ColumnReportByShift } from '../entities/column-report-by-shifts';
import { ItemCostExpenseReport } from '../entities/item-cost-expense-report';
import { ColumnReport } from '../entities/column-report';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { HttpService } from '../../../../shared/services/http/http.service';
import { ItemsCostExpensesReport } from '../entities/items-cost-expenses-report';
import { ItemCostExpenseReportByShift } from '../entities/item-cost-expense-report-by-shift';
import { ItemCostExpenseReportByYear } from '../entities/item-cost-expense-report-by-year';
import { CalculateClassNumberService } from '../../../shared/services/calculate-class-number/calculate-class-number.service';
import { CalculateTeacherNumberService } from '../../../shared/services/calculate-teacher-number/calculate-teacher-number.service';
import { CalculateEmployeeEstimateService } from '../../../shared/services/calculate-employee-estimate/calculate-employee-estimate.service';
import { CalculateCostDriveService } from '../../../shared/services/calculate-cost-drive/calculate-cost-drive.service';
import { CalculateCostSharingService } from '../../../shared/services/calculate-cost-sharing/calculate-cost-sharing.service';
import { ItemCostService } from '../../../shared/services/calculate-student-cost/services/item-cost.service';
import { StudentCost } from '../../../shared/services/calculate-student-cost/entities/student-cost';
import { ShiftEnum } from '../../../../shared/entities/enums/shift-enum';
import { Location } from '../../../../shared/entities/location';
import { Stage } from '../../../../shared/entities/stage';
import { ItemCostExpenseReportByLocation } from '../entities/item-cost-expense-report-by-location';
import { ItemCostExpenseReportByStage } from '../entities/item-cost-expense-report-by-stage';
import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { SchoolsOperation } from '../../../quality-conditions/schools-operation/entities/schools-operation';
import { SessionService } from '../../../../shared/services/session/session.service';
import { EnrollmentProjection } from '../../../access-and-offer/enrollment-projection/entities/enrollment-projection';
import { ItemCostEnum } from '../../../../shared/entities/enums/item-cost.enum';
import { OfferGoalEnrollmentFullTime } from '../../../quality-conditions/offer-goal-enrollment-full-time/entities/offer-goal-enrollment-full-time';
import { StudentCostService } from '../../../shared/services/calculate-student-cost/services/student-cost.service';
import { TotalizerStudentCost } from '../../../shared/services/calculate-student-cost/entities/totalizer-student-cost';
import { EnrollmentProjectionByLocationService } from '../../../access-and-offer/enrollment-projection/services/enrollment-projection-by-location.service';
import { CsvService } from '../../../../shared/services/csv/csv.service';
import { CsvHeader } from '../../../../shared/services/csv/entities/csv-header';
import { Csv } from '../../../../shared/services/csv/entities/csv';
import { StageEnum } from './../../../../shared/entities/enums/stage.enum';
import { ClassroomExistingByLocation } from './../../../quality-conditions/new-room-building/entities/classroom-existing-by-location';
import { ClassroomExistingByCity } from './../../../quality-conditions/new-room-building/entities/classroom-existing-by-city';
import { NewRoomsBuildings } from './../../../quality-conditions/new-room-building/entities/new-rooms-buildings';
import { DemandClassRoom } from './../../../quality-conditions/new-room-building/entities/demand-class-room';
import { SchoolToBeBuiltByLocation } from './../../../shared/services/calculate-class-number/entities/school-to-be-built-by-location';
import { SelectLocation } from './../../../select-location/entities/select-location';
import { DemandsClassroomsEnum } from './../../../quality-conditions/new-room-building/entities/enums/demandsClassrooms.enum';
import { EnrollmentAndClassAndClassroom } from './../../financing-funds-report/entities/enrollment-and-class-and-classroom';
import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { AdministrativeAreaCosting } from 'app/simulator/quality-conditions/administrative-area-costing/entities/administrative-area-costing';

@Injectable({
  providedIn: 'root'
})
export class ItemCostExpenseReportService {

  constructor(
    private utilitiesService: UtilitiesService,
    private httpService: HttpService,
    private csvService: CsvService,
    private calculateClassNumberService: CalculateClassNumberService,
    private calculateTeacherNumberService: CalculateTeacherNumberService,
    private calculateEmployeeEstimateService: CalculateEmployeeEstimateService,
    private calculateCostDriveService: CalculateCostDriveService,
    private calculateCostSharingService: CalculateCostSharingService,
    private itemCostService: ItemCostService,
    private sessionService: SessionService,
    private studentCostService: StudentCostService,
    private enrollmentProjectionByLocationService: EnrollmentProjectionByLocationService
  ) { }

  getItemCostExpenseReport(): Observable<ItemsCostExpensesReport> {

    const itemsCostExpenseReports: ItemsCostExpensesReport = new ItemsCostExpensesReport({
      itemsCostExpensesReport: new Array<ItemCostExpenseReport>()
    });

    const resultForSchoolOperation: SchoolsOperation = this.sessionService.getItem<SchoolsOperation>(Functionality.schoolsOperation.key);
    const resultForSelectLocation: SelectLocation = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);
    const resultForNewRoomBuilding: NewRoomsBuildings = this.sessionService.getItem<NewRoomsBuildings>(Functionality.newRoomBuilding.key);
    const enrollmentAndClassAndClassroom: EnrollmentAndClassAndClassroom = this.sessionService.getItem<EnrollmentAndClassAndClassroom>(Functionality.enrollmentAndClassAndClassroom.key);
    const resultForadministrativeAreaCosting: AdministrativeAreaCosting = this.sessionService.getItem<AdministrativeAreaCosting>(Functionality.administrativeAreaCosting.key);

    return this.httpService.get<Array<any>>('assets/data/item-cost.data.json').pipe(
      mergeMap(itemsCostExpenses => {
        return this.utilitiesService.getStages().pipe(
          mergeMap(stages => {
            return this.utilitiesService.getLocations().pipe(
              mergeMap(locations => {
                return this.enrollmentProjectionByLocationService.getEnrollmentByStagesSeriesByLocation(locations).pipe(
                  mergeMap(() => {
                    let enrollment: EnrollmentProjection = undefined;
                    let considerQuantityTeacherCareer: boolean = false;
                    if (!resultForSelectLocation.selectedCity) {
                      enrollment = enrollmentAndClassAndClassroom.enrollmentProjection;
                      considerQuantityTeacherCareer = true;
                    }
                    return this.calculateClassNumberService.calculateClassNumber(locations, stages).pipe(
                      mergeMap(calculatedClassNumber => {
                        return this.calculateTeacherNumberService.calculateTeacherNumber(calculatedClassNumber).pipe(
                          mergeMap(calculatedTeacherNumber => {
                            let schoolsToBeBuilt: Array<SchoolToBeBuiltByLocation> = undefined;
                            if (resultForSelectLocation.selectedCity) {
                              const demandClassRoom: DemandClassRoom = _.find(resultForNewRoomBuilding.newRooms.demandClassRoomByYear.demandClassRooms,
                                dCL => dCL.id === DemandsClassroomsEnum.ClassroomsExisting);
                              const classroomExisting = this.getClassroomExisting(demandClassRoom);
                              schoolsToBeBuilt = this.calculateClassNumberService.getSchoolsToBeBuilt(calculatedClassNumber.classesNumberByLocations,
                                classroomExisting);
                            }
                            return this.calculateEmployeeEstimateService.calculateEmployeeEstimate(locations, null, null, schoolsToBeBuilt).pipe(
                              mergeMap(calculatedEmployeeEstimate => {
                                return this.calculateCostSharingService.calculateCostSharing(locations, calculatedEmployeeEstimate).pipe(
                                  mergeMap(calculateCostSharing => {
                                    return this.calculateCostDriveService.calculateCostDrive(enrollment).pipe(
                                      mergeMap(calculateCostDrive => {
                                        return this.itemCostService.calculateItemCost(locations, stages, calculatedTeacherNumber, calculatedEmployeeEstimate, calculateCostSharing, calculateCostDrive,
                                          enrollment, false, true, false, false, false, undefined, considerQuantityTeacherCareer).pipe(
                                            mergeMap(itemCostAll => {
                                              return this.itemCostService.calculateItemCost(locations, stages, calculatedTeacherNumber, calculatedEmployeeEstimate, calculateCostSharing,
                                                calculateCostDrive, enrollment, false, false, false, true, true, undefined, considerQuantityTeacherCareer).pipe(
                                                  mergeMap(itemCostMDEWithoutAssetsAndServices => {
                                                    return this.itemCostService.calculateItemCost(locations, stages, calculatedTeacherNumber, calculatedEmployeeEstimate, calculateCostSharing,
                                                      calculateCostDrive, enrollment, false, false, false, false, false, undefined, considerQuantityTeacherCareer).pipe(
                                                        mergeMap(itemCostWithoutAssetsAndServices => {
                                                          return this.itemCostService.calculateItemCost(locations, stages, calculatedTeacherNumber, calculatedEmployeeEstimate, calculateCostSharing,
                                                            calculateCostDrive, enrollment, false, true, true, true, true, undefined, considerQuantityTeacherCareer).pipe(
                                                              mergeMap(costOfAssetsAndServices => {
                                                                return this.studentCostService.calculateStudentCost(locations, stages, itemCostMDEWithoutAssetsAndServices,
                                                                  costOfAssetsAndServices, enrollment).pipe(
                                                                    mergeMap(totalizerStudentsCostMDE => {
                                                                      return this.studentCostService.calculateStudentCost(locations, stages, itemCostWithoutAssetsAndServices,
                                                                        costOfAssetsAndServices, enrollment).pipe(
                                                                          map(totalizerStudentsCostTotal => {

                                                                            const items = this.getCostAdministrativeArea(itemsCostExpenses, resultForadministrativeAreaCosting);
                                                                            for (let i = 0; i < items.length; i++) {
                                                                              const itemCostExpense = items[i];

                                                                              if (itemCostExpense.able) {
                                                                                itemsCostExpenseReports.itemsCostExpensesReport.push(new ItemCostExpenseReport({
                                                                                  id: itemCostExpense.id,
                                                                                  description: itemCostExpense.description,
                                                                                  itemNumber: itemCostExpense.item_number,
                                                                                  header: itemCostExpense.header,
                                                                                  mde: itemCostExpense.mde,
                                                                                  totalGeneral: itemCostExpense.total_general,
                                                                                  groupTotalGeneral: itemCostExpense.group_total_general,
                                                                                  totalMDE: itemCostExpense.total_MDE,
                                                                                  custoAlunoMDE: itemCostExpense.custo_aluno_MDE,
                                                                                  foodsAndCookingGas: itemCostExpense.foods_and_cooking_gas,
                                                                                  numeroMatriculas: itemCostExpense.numero_matriculas,
                                                                                  custoAlunoAno: itemCostExpense.custo_aluno_ano,
                                                                                  parent_id: itemCostExpense.parent_id,
                                                                                  itemsCostExpenseReportByStages: this.getItemsCostExpenseReportByStages(stages, locations, itemCostExpense,
                                                                                    itemCostAll),
                                                                                  itemCostExpenseTotal: new ItemCostExpenseReportByYear()
                                                                                }));

                                                                                /*if (itemCostExpense.id === ItemCostEnum.BensServicos) {
                                                                                  for (let j = 0; j < resultForSchoolOperation.costs.length; j++) {
                                                                                    const itemNumber: number = parseFloat(itemCostExpense.item_number + '.' + (j + 1));
                                                                                    const itemDescription: string = resultForSchoolOperation.costs[j].denomination;
                                                                                    itemsCostExpenseReports.itemsCostExpensesReport.push(new ItemCostExpenseReport({
                                                                                      description: itemDescription,
                                                                                      itemNumber: itemNumber,
                                                                                      parent_id: ItemCostEnum.BensServicos,
                                                                                      itemsCostExpenseReportByStages: this.getItemsCostExpenseReportByStages(stages, locations, null, itemCostAll,
                                                                                        itemDescription),
                                                                                      itemCostExpenseTotal: new ItemCostExpenseReportByYear()
                                                                                    }));
                                                                                  }
                                                                                }*/
                                                                              }
                                                                            }

                                                                            this.setSumItemCostGroups(itemsCostExpenseReports, totalizerStudentsCostMDE, totalizerStudentsCostTotal,
                                                                              itemCostMDEWithoutAssetsAndServices, costOfAssetsAndServices);

                                                                            itemsCostExpenseReports.sourceInformationPQR = this.getSourceInformationPQR();
                                                                            return itemsCostExpenseReports;
                                                                          }));
                                                                    }));
                                                              }));
                                                        }));
                                                  }));
                                            }));
                                      }));
                                  }));
                              }));
                          }));
                      }));
                  }));
              }));
          }));
      }));
  }

  getItemsCostExpenseReportByStages(stages: Array<Stage>, locations: Array<Location>, itemCostExpense: any, itemCost: Array<StudentCost>, itemCostDescription: String = undefined):
    Array<ItemCostExpenseReportByStage> {

    const itemsCostExpensesReportByStages: Array<ItemCostExpenseReportByStage> = new Array<ItemCostExpenseReportByStage>();
    for (let i = 0; i < stages.length; i++) {
      itemsCostExpensesReportByStages.push(new ItemCostExpenseReportByStage({
        id: stages[i].id,
        description: stages[i].description,
        itemsCostExpenseReportByLocations: this.getItemsCostExpenseReportByLocations(locations, stages[i].id, itemCostExpense, itemCost, itemCostDescription)
      }));
    }

    return itemsCostExpensesReportByStages;
  }

  getItemsCostExpenseReportByLocations(locations: Array<Location>, stageId: number, itemCostExpense: any, itemCost: Array<StudentCost>, itemCostDescription: String):
    Array<ItemCostExpenseReportByLocation> {

    const itemsCostExpenseReportByLocations: Array<ItemCostExpenseReportByLocation> = new Array<ItemCostExpenseReportByLocation>();

    for (let i = 0; i < locations.length; i++) {
      itemsCostExpenseReportByLocations.push(new ItemCostExpenseReportByLocation({
        id: locations[i].id,
        description: locations[i].description,
        itemsCostExpenseReportByShifts: this.getItemsCostExpenseReportByShift(locations[i].id, stageId, itemCostExpense, itemCost, itemCostDescription)
      }));
    }
    return itemsCostExpenseReportByLocations;
  }

  getItemsCostExpenseReportByShift(locationId: number, stageId: number, itemCostExpense: any, itemCost: Array<StudentCost>, itemCostDescription: String): Array<ItemCostExpenseReportByShift> {

    const itemCostExpenseReportByShift: Array<ItemCostExpenseReportByShift> = new Array<ItemCostExpenseReportByShift>();

    itemCostExpenseReportByShift.push(new ItemCostExpenseReportByShift({
      id: ShiftEnum.Parcial,
      description: 'Parcial',
      itemsCostExpenseReportByYears: this.getItemCostExpenseReportByYear(locationId, stageId, itemCostExpense, itemCost, ShiftEnum.Parcial, itemCostDescription)
    }), new ItemCostExpenseReportByShift({
      id: ShiftEnum.Integral,
      description: 'Integral',
      itemsCostExpenseReportByYears: this.getItemCostExpenseReportByYear(locationId, stageId, itemCostExpense, itemCost, ShiftEnum.Integral, itemCostDescription)
    }));
    return itemCostExpenseReportByShift;
  }

  getItemCostExpenseReportByYear(locationId: number, stageId: number, itemCostExpense: any, itemCost: Array<StudentCost>, shiftId: number, itemCostDescription: String):
    Array<ItemCostExpenseReportByYear> {

    const itemCostExpenseReportByYear: Array<ItemCostExpenseReportByYear> = new Array<ItemCostExpenseReportByYear>();
    for (let i = 0; i < itemCost.length; i++) {
      const itemsCostByLocations = itemCost[i].studentsCostByLocations;
      for (let j = 0; j < itemsCostByLocations.length; j++) {
        if (locationId === itemsCostByLocations[j].id) {
          const itemsCostByStages = itemsCostByLocations[j].studentsCostByStages;
          for (let k = 0; k < itemsCostByStages.length; k++) {
            if (stageId === itemsCostByStages[k].id) {
              const itemsCostBySubitems = itemsCostByStages[k].studentsCostBySubitems;
              let accumulatedValue: number = 0;
              let yearItemCost: number = 0;
              for (let l = 0; l < itemsCostBySubitems.length; l++) {
                if (itemCostExpense != null) {
                  if (itemCostExpense.itemCostParent_id !== ItemCostEnum.AdministrativeCosts) {
                    if ((itemCostExpense.id === itemsCostBySubitems[l].itemCost_id && itemsCostBySubitems[l].itemCostParent_id !== ItemCostEnum.AdministrativeCosts) ||
                      (itemCostExpense.id === itemsCostBySubitems[l].itemCostParent_id && !itemCostExpense.header)) {
                      const itemCostByYear = _.first(itemsCostBySubitems[l].studentsCostByYear);
                      yearItemCost = itemCostByYear.year;
                      if (shiftId === ShiftEnum.Parcial) {
                        accumulatedValue += itemCostByYear.costShiftPartial;
                      } else if (shiftId === ShiftEnum.Integral) {
                        accumulatedValue += itemCostByYear.costShiftIntegral;
                      }
                    }
                  } else {
                    if (itemCostExpense.id === itemsCostBySubitems[l].itemCost_id && itemsCostBySubitems[l].itemCostParent_id === ItemCostEnum.AdministrativeCosts) {
                      const itemCostByYear = _.first(itemsCostBySubitems[l].studentsCostByYear);
                      yearItemCost = itemCostByYear.year;
                      if (shiftId === ShiftEnum.Parcial) {
                        accumulatedValue += itemCostByYear.costShiftPartial;
                      } else if (shiftId === ShiftEnum.Integral) {
                        accumulatedValue += itemCostByYear.costShiftIntegral;
                      }
                    }
                  }

                } else {
                  if (itemsCostBySubitems[l].itemCostParent_id === ItemCostEnum.BensServicos) {
                    if (itemsCostBySubitems[l].priceList_subitem.trim() === itemCostDescription.trim()) {
                      const itemCostByYear = _.first(itemsCostBySubitems[l].studentsCostByYear);
                      yearItemCost = itemCostByYear.year;
                      if (shiftId === ShiftEnum.Parcial) {
                        accumulatedValue = itemCostByYear.costShiftPartial;
                      } else if (shiftId === ShiftEnum.Integral) {
                        accumulatedValue = itemCostByYear.costShiftIntegral;
                      }
                      if (accumulatedValue === 0) {
                        accumulatedValue = null;
                      }
                      itemCostExpenseReportByYear.push(new ItemCostExpenseReportByYear({ year: yearItemCost, value: accumulatedValue }));
                      return itemCostExpenseReportByYear;
                    }
                  }
                }
              }
              if (accumulatedValue === 0) {
                accumulatedValue = null;
              }
              itemCostExpenseReportByYear.push(new ItemCostExpenseReportByYear({ year: yearItemCost, value: accumulatedValue }));
            }
          }
        }
      }
    }
    return itemCostExpenseReportByYear;
  }

  setSumItemCostGroups(itemsCostExpensesReport: ItemsCostExpensesReport, totalizerStudentsCostMDE: TotalizerStudentCost, totalizerStudentsCostTotal: TotalizerStudentCost,
    itemCostMDEWithoutAssetsAndServices: Array<StudentCost>, costOfAssetsAndServices: Array<StudentCost>): void {

    const itemsCostExpenseReport = itemsCostExpensesReport.itemsCostExpensesReport.filter(itemCostExpense => itemCostExpense.header);

    for (let i = 0; i < itemsCostExpenseReport.length; i++) {
      const itemCostHeader = itemsCostExpenseReport[i];
      for (let j = 0; j < itemsCostExpensesReport.itemsCostExpensesReport.length; j++) {
        const itemCostExpensesReport = itemsCostExpensesReport.itemsCostExpensesReport[j];
        if (itemCostHeader.id === itemCostExpensesReport.parent_id) {
          for (let k = 0; k < itemCostExpensesReport.itemsCostExpenseReportByStages.length; k++) {
            const itemsCostExpensesReportByLocations = itemCostExpensesReport.itemsCostExpenseReportByStages[k].itemsCostExpenseReportByLocations;
            for (let l = 0; l < itemsCostExpensesReportByLocations.length; l++) {
              const itemsCostExpensesReportByShifts = itemsCostExpensesReportByLocations[l].itemsCostExpenseReportByShifts;
              for (let m = 0; m < itemsCostExpensesReportByShifts.length; m++) {
                const itemsCostExpensesReportByYears = _.first(itemsCostExpensesReportByShifts[m].itemsCostExpenseReportByYears);
                const itemsCostHeaderExpensesReportByYears = _.first(itemCostHeader.itemsCostExpenseReportByStages[k].itemsCostExpenseReportByLocations[l].
                  itemsCostExpenseReportByShifts[m].itemsCostExpenseReportByYears);
                if (itemsCostExpensesReportByYears.value > 0) {
                  itemsCostHeaderExpensesReportByYears.value += itemsCostExpensesReportByYears.value;
                }
              }
            }
          }
        }
      }
    }

    this.setTotalMDEItemsCost(itemsCostExpensesReport, itemCostMDEWithoutAssetsAndServices, costOfAssetsAndServices);
    this.setVerticalTotalItemCost(itemsCostExpensesReport);
    this.setPercentsItemsCost(itemsCostExpensesReport);
    this.setEnrollmentTotal(itemsCostExpensesReport);
    this.setStudentCostMDE(itemsCostExpensesReport, totalizerStudentsCostMDE);
    this.setStudentCostTotal(itemsCostExpensesReport, totalizerStudentsCostTotal);
    this.setHorizontalTotalItemCost(itemsCostExpensesReport);
    this.setPercentsItemsCostTotal(itemsCostExpensesReport);
  }

  setVerticalTotalItemCost(itemsCostExpensesReport: ItemsCostExpensesReport): void {

    const itemsCostExpenseReport = itemsCostExpensesReport.itemsCostExpensesReport.filter(itemCostExpense => itemCostExpense.header && !itemCostExpense.totalGeneral);
    const itemsCostTotalGeral = _.find(itemsCostExpensesReport.itemsCostExpensesReport, itemCostExpense => itemCostExpense.totalGeneral);

    for (let i = 0; i < itemsCostExpenseReport.length; i++) {
      if (itemsCostExpenseReport[i].groupTotalGeneral) {
        const itemsCostExpenseReportByStages = itemsCostExpenseReport[i].itemsCostExpenseReportByStages;
        for (let j = 0; j < itemsCostExpenseReportByStages.length; j++) {
          const itemsCostExpensesReportByLocations = itemsCostExpenseReportByStages[j].itemsCostExpenseReportByLocations;
          for (let k = 0; k < itemsCostExpensesReportByLocations.length; k++) {
            const itemsCostExpensesReportByShifts = itemsCostExpensesReportByLocations[k].itemsCostExpenseReportByShifts;
            for (let l = 0; l < itemsCostExpensesReportByShifts.length; l++) {
              const itemsCostExpensesReportByYear = itemsCostExpensesReportByShifts[l].itemsCostExpenseReportByYears;
              for (let m = 0; m < itemsCostExpensesReportByYear.length; m++) {
                if (itemsCostExpensesReportByYear[m].value > 0) {
                  itemsCostTotalGeral.itemsCostExpenseReportByStages[j].itemsCostExpenseReportByLocations[k].itemsCostExpenseReportByShifts[l].itemsCostExpenseReportByYears[m].value
                    += itemsCostExpensesReportByYear[m].value;
                }
              }
            }
          }
        }
      }
    }
  }

  setTotalMDEItemsCost(itemsCostExpensesReport: ItemsCostExpensesReport, itemCostMDEWithoutAssetsAndServices: Array<StudentCost>, costOfAssetsAndServices: Array<StudentCost>): void {

    const itemsCostTotalMDE = _.find(itemsCostExpensesReport.itemsCostExpensesReport, itemCostExpense => itemCostExpense.totalMDE);

    for (let i = 0; i < itemsCostExpensesReport.itemsCostExpensesReport.length; i++) {
      if (itemsCostExpensesReport.itemsCostExpensesReport[i].mde) {
        const itemsCostExpenseReportByStages = itemsCostExpensesReport.itemsCostExpensesReport[i].itemsCostExpenseReportByStages;
        for (let j = 0; j < itemsCostExpenseReportByStages.length; j++) {
          const itemsCostExpensesReportByLocations = itemsCostExpenseReportByStages[j].itemsCostExpenseReportByLocations;
          for (let k = 0; k < itemsCostExpensesReportByLocations.length; k++) {
            const itemsCostExpensesReportByShifts = itemsCostExpensesReportByLocations[k].itemsCostExpenseReportByShifts;
            for (let l = 0; l < itemsCostExpensesReportByShifts.length; l++) {
              const itemsCostExpensesReportByYear = itemsCostExpensesReportByShifts[l].itemsCostExpenseReportByYears;
              for (let m = 0; m < itemsCostExpensesReportByYear.length; m++) {
                if (itemsCostExpensesReportByYear[m].value > 0) {
                  itemsCostTotalMDE.itemsCostExpenseReportByStages[j].itemsCostExpenseReportByLocations[k].itemsCostExpenseReportByShifts[l].itemsCostExpenseReportByYears[m].value
                    += itemsCostExpensesReportByYear[m].value;
                }
                itemsCostTotalMDE.itemsCostExpenseReportByStages[j].itemsCostExpenseReportByLocations[k].itemsCostExpenseReportByShifts[l].itemsCostExpenseReportByYears[m].percentValue
                  = null;
              }
            }
          }
        }
      }
    }
  }

  getItemCostAdministracaoSupervisaoRede(itemCostMDE: Array<StudentCost>, locationId: number, stageId: number, shiftId: number): number {

    const itemCostExpensesMDE = _.first(itemCostMDE);
    const itemCostMDELocation = _.find(itemCostExpensesMDE.studentsCostByLocations, iL => iL.id === locationId);
    const itemCostMDEStage = _.find(itemCostMDELocation.studentsCostByStages, iS => iS.id === stageId);
    const itemCostMDESubItem = _.find(itemCostMDEStage.studentsCostBySubitems, iSub => iSub.itemCost_id === ItemCostEnum.AdministracaoSupervisaoRede);
    const itemCostMDEByYear = _.first(itemCostMDESubItem.studentsCostByYear);
    return shiftId === ShiftEnum.Parcial ? itemCostMDEByYear.costShiftPartial : itemCostMDEByYear.costShiftIntegral;
  }

  setStudentCostMDE(itemsCostExpensesReport: ItemsCostExpensesReport, totalizerStudentsCostMDE: TotalizerStudentCost): void {

    const itemsCostCustoAlunoMDE = _.find(itemsCostExpensesReport.itemsCostExpensesReport, itemCostExpense => itemCostExpense.custoAlunoMDE);

    for (let i = 0; i < totalizerStudentsCostMDE.totalizersStudentsCostByStages.length; i++) {
      const totalizersStudentsCostByLocations = totalizerStudentsCostMDE.totalizersStudentsCostByStages[i].totalizersStudentsCostByLocations;
      for (let j = 0; j < totalizersStudentsCostByLocations.length; j++) {
        const totalizersStudentsCostByShifts = totalizersStudentsCostByLocations[j].totalizersStudentsCostByShifts;
        for (let k = 0; k < totalizersStudentsCostByShifts.length; k++) {
          const totalizersStudentsCostByYear = _.first(totalizersStudentsCostByShifts[k].totalizersStudentsCostByYear);
          const itemCostCustoAlunoMDEByYear = _.first(itemsCostCustoAlunoMDE.itemsCostExpenseReportByStages[i].itemsCostExpenseReportByLocations[j].
            itemsCostExpenseReportByShifts[k].itemsCostExpenseReportByYears);
          itemCostCustoAlunoMDEByYear.value = totalizersStudentsCostByYear.cost > 0 ? totalizersStudentsCostByYear.cost : null;
          itemCostCustoAlunoMDEByYear.percentValue = null;
        }
      }
    }
  }

  setStudentCostTotal(itemsCostExpensesReport: ItemsCostExpensesReport, totalizerStudentsCostTotal: TotalizerStudentCost): void {

    const itemsCostCustoAluno = _.find(itemsCostExpensesReport.itemsCostExpensesReport, itemCostExpense => itemCostExpense.custoAlunoAno);
    for (let i = 0; i < totalizerStudentsCostTotal.totalizersStudentsCostByStages.length; i++) {
      const totalizersStudentsCostByLocations = totalizerStudentsCostTotal.totalizersStudentsCostByStages[i].totalizersStudentsCostByLocations;
      for (let j = 0; j < totalizersStudentsCostByLocations.length; j++) {
        const totalizersStudentsCostByShifts = totalizersStudentsCostByLocations[j].totalizersStudentsCostByShifts;
        for (let k = 0; k < totalizersStudentsCostByShifts.length; k++) {
          const totalizersStudentsCostByYear = _.first(totalizersStudentsCostByShifts[k].totalizersStudentsCostByYear);
          const itemCostCustoAlunoTotalByYear = _.first(itemsCostCustoAluno.itemsCostExpenseReportByStages[i].itemsCostExpenseReportByLocations[j].
            itemsCostExpenseReportByShifts[k].itemsCostExpenseReportByYears);
          itemCostCustoAlunoTotalByYear.value = totalizersStudentsCostByYear.cost > 0 ? totalizersStudentsCostByYear.cost : null;
          itemCostCustoAlunoTotalByYear.percentValue = null;
        }
      }
    }
  }

  setPercentsItemsCost(itemCostExpenseReport: ItemsCostExpensesReport): void {

    const itemsCostTotalMDE = _.find(itemCostExpenseReport.itemsCostExpensesReport, itemCostExpense => itemCostExpense.totalMDE);

    const itemsCostExpenseReport = itemCostExpenseReport.itemsCostExpensesReport.filter(itemCostExpense =>
      (!itemCostExpense.totalGeneral && !itemCostExpense.foodsAndCookingGas));

    for (let i = 0; i < itemsCostExpenseReport.length; i++) {
      const itemsCostExpenseReportByStages = itemsCostExpenseReport[i].itemsCostExpenseReportByStages;
      for (let j = 0; j < itemsCostExpenseReportByStages.length; j++) {
        const itemsCostExpensesReportByLocations = itemsCostExpenseReportByStages[j].itemsCostExpenseReportByLocations;
        for (let k = 0; k < itemsCostExpensesReportByLocations.length; k++) {
          const itemsCostExpensesReportByShifts = itemsCostExpensesReportByLocations[k].itemsCostExpenseReportByShifts;
          for (let l = 0; l < itemsCostExpensesReportByShifts.length; l++) {
            const itemsCostExpensesReportByYears = _.first(itemsCostExpensesReportByShifts[l].itemsCostExpenseReportByYears);
            const itemsCostTotalMDEExpensesReportByYears = _.first(itemsCostTotalMDE.itemsCostExpenseReportByStages[j].itemsCostExpenseReportByLocations[k].
              itemsCostExpenseReportByShifts[l].itemsCostExpenseReportByYears);
            itemsCostExpensesReportByYears.percentValue = (itemsCostTotalMDEExpensesReportByYears.value > 0) && itemsCostExpensesReportByYears.value > 0 ?
              parseFloat(((itemsCostExpensesReportByYears.value / itemsCostTotalMDEExpensesReportByYears.value) * 100).toFixed(2)) : null;
          }
        }
      }
    }
  }

  setEnrollmentTotal(itemsCostExpensesReport: ItemsCostExpensesReport): void {

    const resultsForOfferGoalEnrollmentFullTime: OfferGoalEnrollmentFullTime = this.sessionService.getItem<OfferGoalEnrollmentFullTime>(Functionality.offerGoalEnrollmentFullTime.key);
    let resultForEnrollmentProjectionByLocation: EnrollmentProjection;
    const itemCostEnrollment = _.find(itemsCostExpensesReport.itemsCostExpensesReport, itemCostExpense => itemCostExpense.numeroMatriculas);
    const resultForSelectLocation: SelectLocation = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);

    if (resultForSelectLocation.selectedCity) {
      resultForEnrollmentProjectionByLocation = this.sessionService.getItem<EnrollmentProjection>(Functionality.enrollmentProjectionByLocation.key);
    } else {
      resultForEnrollmentProjectionByLocation = this.sessionService.getItem<EnrollmentAndClassAndClassroom>(Functionality.enrollmentAndClassAndClassroom.key).enrollmentProjection;
    }

    for (let i = 0; i < resultForEnrollmentProjectionByLocation.enrollmentsProjectionsByLocations.length; i++) {
      const enrollmentLocationId = resultForEnrollmentProjectionByLocation.enrollmentsProjectionsByLocations[i].id;
      const enrollmentProjectionByStages = resultForEnrollmentProjectionByLocation.enrollmentsProjectionsByLocations[i].stagesEnrollments;
      for (let j = 0; j < enrollmentProjectionByStages.length; j++) {
        const enrollmentBystage = _.first(enrollmentProjectionByStages[j].totalEnrollments);
        for (let l = 0; l < itemCostEnrollment.itemsCostExpenseReportByStages.length; l++) {
          const itemsCostByStages = itemCostEnrollment.itemsCostExpenseReportByStages[l];
          if (itemsCostByStages.id === enrollmentProjectionByStages[j].id) {
            for (let m = 0; m < itemsCostByStages.itemsCostExpenseReportByLocations.length; m++) {
              const itemsCostByLocations = itemsCostByStages.itemsCostExpenseReportByLocations[m];
              if (itemsCostByLocations.id === enrollmentLocationId) {
                const offerGoalEnrollmentIntegralByStage = _.find(resultsForOfferGoalEnrollmentFullTime.fullTime, offerGoal => offerGoal.id === enrollmentProjectionByStages[j].id);

                for (let n = 0; n < itemsCostByLocations.itemsCostExpenseReportByShifts.length; n++) {
                  const offerGoalStage = offerGoalEnrollmentIntegralByStage ? offerGoalEnrollmentIntegralByStage.offerGoal : 0;
                  const totalEnrollment = enrollmentBystage.quantity;
                  const totalEnrollmentNocturnal = enrollmentBystage.quantityNocturnal;
                  // const totalEnrollmentIntegral = Math.ceil((totalEnrollment - totalEnrollmentNocturnal) * (offerGoalStage / 100));
                  const totalEnrollmentIntegral = ((totalEnrollment) * (offerGoalStage / 100));
                  const totalEnrollmentPartial = (totalEnrollment - totalEnrollmentIntegral);
                  const itemCostByYears = _.first(itemsCostByLocations.itemsCostExpenseReportByShifts[n].itemsCostExpenseReportByYears);

                  itemCostByYears.percentValue = undefined;
                  if (itemsCostByLocations.itemsCostExpenseReportByShifts[n].id === ShiftEnum.Parcial) {
                    itemCostByYears.value = totalEnrollmentPartial > 0 ? totalEnrollmentPartial : undefined;
                  } else if (itemsCostByLocations.itemsCostExpenseReportByShifts[n].id === ShiftEnum.Integral) {
                    itemCostByYears.value = totalEnrollmentIntegral > 0 ? totalEnrollmentIntegral : undefined;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  setHorizontalTotalItemCost(itemCostExpenseReport: ItemsCostExpensesReport): void {

    const itemsCostExpenseReport = itemCostExpenseReport.itemsCostExpensesReport.filter(itemCostExpense =>
      (!itemCostExpense.custoAlunoAno && !itemCostExpense.custoAlunoMDE));

    for (let i = 0; i < itemsCostExpenseReport.length; i++) {
      const itemsCostExpenseReportByStages = itemsCostExpenseReport[i].itemsCostExpenseReportByStages;

      let totalItemCost: number = 0;
      for (let j = 0; j < itemsCostExpenseReportByStages.length; j++) {
        const itemsCostExpensesReportByLocations = itemsCostExpenseReportByStages[j].itemsCostExpenseReportByLocations;
        for (let k = 0; k < itemsCostExpensesReportByLocations.length; k++) {
          const itemsCostExpensesReportByShifts = itemsCostExpensesReportByLocations[k].itemsCostExpenseReportByShifts;
          for (let l = 0; l < itemsCostExpensesReportByShifts.length; l++) {
            const itemsCostExpensesReportByYears = _.first(itemsCostExpensesReportByShifts[l].itemsCostExpenseReportByYears);
            if (itemsCostExpensesReportByYears.value !== undefined) {
              totalItemCost += itemsCostExpensesReportByYears.value;
            }
          }
        }
      }
      if (totalItemCost > 0) {
        itemsCostExpenseReport[i].itemCostExpenseTotal.value = totalItemCost;
      }
    }

    // set custo aluno e custo aluno MDE
    const itemsCostExpenseReportTotalCost = _.find(itemCostExpenseReport.itemsCostExpensesReport, iCE => iCE.totalGeneral);
    const itemsCostExpenseReportTotalCostMDE = _.find(itemCostExpenseReport.itemsCostExpensesReport, iCE => iCE.totalMDE);
    const itemsCostExpenseReportStudentsCost = _.find(itemCostExpenseReport.itemsCostExpensesReport, iCE => iCE.custoAlunoAno);
    const itemsCostExpenseReportStudentsCostMDE = _.find(itemCostExpenseReport.itemsCostExpensesReport, iCE => iCE.custoAlunoMDE);
    const itemsCostExpenseReportEnrollment = _.find(itemCostExpenseReport.itemsCostExpensesReport, iCE => iCE.numeroMatriculas);

    itemsCostExpenseReportStudentsCost.itemCostExpenseTotal.value = itemsCostExpenseReportTotalCost.itemCostExpenseTotal.value / itemsCostExpenseReportEnrollment.itemCostExpenseTotal.value;
    itemsCostExpenseReportStudentsCostMDE.itemCostExpenseTotal.value = itemsCostExpenseReportTotalCostMDE.itemCostExpenseTotal.value / itemsCostExpenseReportEnrollment.itemCostExpenseTotal.value;
  }

  setPercentsItemsCostTotal(itemCostExpenseReport: ItemsCostExpensesReport): void {

    const itemsCostTotalMDE = _.find(itemCostExpenseReport.itemsCostExpensesReport, itemCostExpense => itemCostExpense.totalMDE);
    const itemCostExpenseTotalGeral: number = itemsCostTotalMDE.itemCostExpenseTotal.value;

    const itemsCostExpenseReport = itemCostExpenseReport.itemsCostExpensesReport.filter(itemCostExpense =>
    (!itemCostExpense.totalGeneral
      && !itemCostExpense.numeroMatriculas && !itemCostExpense.custoAlunoAno
      && !itemCostExpense.custoAlunoMDE && !itemCostExpense.foodsAndCookingGas
    ));

    for (let i = 0; i < itemsCostExpenseReport.length; i++) {

      const itemCostExpenseTotal: number = itemsCostExpenseReport[i].itemCostExpenseTotal.value;

      itemsCostExpenseReport[i].itemCostExpenseTotal.percentValue = itemCostExpenseTotalGeral > 0 && itemCostExpenseTotal > 0 ?
        parseFloat(((itemsCostExpenseReport[i].itemCostExpenseTotal.value / itemsCostTotalMDE.itemCostExpenseTotal.value)
          * 100).toFixed(2)) : null;
    }
  }

  getColumnsReport(): Observable<Array<ColumnReport>> {

    const columnsReport: Array<ColumnReport> = new Array<ColumnReport>();

    return this.utilitiesService.getStages().pipe(
      switchMap(stages => {
        return this.utilitiesService.getLocations().pipe(
          map(locations => {

            for (let i = 0; i < stages.length; i++) {
              const stage = stages[i];

              const columnReport: ColumnReport = new ColumnReport({
                stage_id: stage.id,
                stage_description: stage.description,
                columnsReportByLocations: new Array<ColumnReportByLocation>()
              });

              for (let j = 0; j < locations.length; j++) {
                const location = locations[j];
                columnReport.columnsReportByLocations.push(new ColumnReportByLocation({
                  id: location.id,
                  description: location.description,
                  columnsReportByShifts: this.getShiftsReport()
                }));
              }
              columnsReport.push(columnReport);
            }
            return columnsReport;
          }));
      }));
  }

  downloadCsv(itemsCostExpensesReport: ItemsCostExpensesReport): Observable<void> {
    return this.getColumnsReport().pipe(
      map(columnsReport => {
        const header: Array<CsvHeader> = new Array<CsvHeader>();
        const data: Array<any> = new Array<any>();
        const realSymbol: string = '(R$)';
        const percentSymbol: string = '(%)';

        header.push(new CsvHeader({ key: 'itemNumber', label: 'Item' }));
        header.push(new CsvHeader({ key: 'description', label: 'Descrição' }));
        header.push(new CsvHeader({ key: 'itemCostExpenseTotalValue', label: `Total Geral ${realSymbol}` }));
        header.push(new CsvHeader({ key: 'itemCostExpenseTotalPercentValue', label: `Total Geral ${percentSymbol}` }));

        for (const columnReport of columnsReport) {
          for (const columnReportByLocation of columnReport.columnsReportByLocations) {
            for (const columnReportByShift of columnReportByLocation.columnsReportByShifts) {
              if (!(columnReport.stage_id === StageEnum.eja && columnReportByShift.id === ShiftEnum.Integral)) {
                header.push(new CsvHeader({
                  key: `${columnReport.stage_id}#${columnReportByLocation.id}#${columnReportByShift.id}#Value`,
                  label: `${columnReport.stage_description} ${columnReportByLocation.description} ${columnReportByShift.description} ${realSymbol}`
                }));

                header.push(new CsvHeader({
                  key: `${columnReport.stage_id}#${columnReportByLocation.id}#${columnReportByShift.id}#PercentValue`,
                  label: `${columnReport.stage_description} ${columnReportByLocation.description} ${columnReportByShift.description} ${percentSymbol}`
                }));
              }
            }
          }
        }

        for (const itemCostExpenseReport of itemsCostExpensesReport.itemsCostExpensesReport) {
          const itemsCostExpensesData: any = {
            itemNumber: `${itemCostExpenseReport.itemNumber}`,
            description: itemCostExpenseReport.description,
            itemCostExpenseTotalValue: this.utilitiesService.roundNumber(itemCostExpenseReport.itemCostExpenseTotal.value, 0),
            itemCostExpenseTotalPercentValue: itemCostExpenseReport.itemCostExpenseTotal.percentValue
          };

          for (const itemCostExpenseReportByStage of itemCostExpenseReport.itemsCostExpenseReportByStages) {
            for (const itemCostExpenseReportByLocation of itemCostExpenseReportByStage.itemsCostExpenseReportByLocations) {
              for (const itemCostExpenseReportByShift of itemCostExpenseReportByLocation.itemsCostExpenseReportByShifts) {
                for (const itemCostExpenseReportByYear of itemCostExpenseReportByShift.itemsCostExpenseReportByYears) {
                  if (!(itemCostExpenseReportByStage.id === StageEnum.eja && itemCostExpenseReportByShift.id === ShiftEnum.Integral)) {
                    itemsCostExpensesData[`${itemCostExpenseReportByStage.id}#${itemCostExpenseReportByLocation.id}#${itemCostExpenseReportByShift.id}#Value`] =
                      this.utilitiesService.roundNumber(itemCostExpenseReportByYear.value, 0);

                    itemsCostExpensesData[`${itemCostExpenseReportByStage.id}#${itemCostExpenseReportByLocation.id}#${itemCostExpenseReportByShift.id}#PercentValue`] =
                      itemCostExpenseReportByYear.percentValue;
                  }
                }
              }
            }
          }

          data.push(itemsCostExpensesData);
        }

        const csv = new Csv({ header: header, data: data, name: 'Custos por item de despesa' });
        this.csvService.download(csv);
      })
    );
  }

  private getShiftsReport(): Array<ColumnReportByShift> {

    return new Array<ColumnReportByShift>(
      new ColumnReportByShift({ id: ShiftEnum.Parcial, description: 'Parcial' }),
      new ColumnReportByShift({ id: ShiftEnum.Integral, description: 'Integral' })
    );
  }

  private getClassroomExisting(demandClassRoom: DemandClassRoom): Array<ClassroomExistingByCity> {

    const classroomsExistingByCities: Array<ClassroomExistingByCity> = new Array<ClassroomExistingByCity>();
    const classroomsExistingByLocations: Array<ClassroomExistingByLocation> = new Array<ClassroomExistingByLocation>();

    for (let i = 0; i < demandClassRoom.quantityDemandClassRoomLocation.length; i++) {

      const quantityDemandClassRoom = demandClassRoom.quantityDemandClassRoomLocation[i];

      classroomsExistingByLocations.push(new ClassroomExistingByLocation({
        location: quantityDemandClassRoom.location,
        quantityClassroomExisting: quantityDemandClassRoom.value
      }));
    }

    classroomsExistingByCities.push(new ClassroomExistingByCity({
      classroomExistingByLocations: classroomsExistingByLocations
    }));

    return classroomsExistingByCities;
  }

  private getSourceInformationPQR(): Footnote {

    const footNote: Footnote = new Footnote({
      note: 'A proposta de valores do PQR foi realizada pela equipe do projeto. Desse modo, não são decisões do governo federal, ' +
        'dos governos estaduais ou municipais. Por consequência, os resultados do SimCAQ decorrem dos parâmetros de qualidade propostos ' +
        'não vinculam responsabilidade de repasses de recursos financeiros por parte dos entes federativos.'
    });
    return footNote;
  }

  private getCostAdministrativeArea(itemsCostExpenses: Array<any>, administrativeAreaCosting: AdministrativeAreaCosting): Array<any> {

    const itemsCostExpensesAux = itemsCostExpenses.filter(ice => (ice.item_number === ItemCostEnum.Pessoal || ice.parent_id === ItemCostEnum.Pessoal));
    const itemsCostExpensesAux2: Array<any> = itemsCostExpenses.filter(ice => (ice.item_number !== ItemCostEnum.Pessoal && ice.parent_id !== ItemCostEnum.Pessoal && ice.able));

    for (let i = 0; i < administrativeAreaCosting.administrativesItensCosting.length; i++) {

      itemsCostExpensesAux.push(({
        id: administrativeAreaCosting.administrativesItensCosting[i].sequence,
        item_number: administrativeAreaCosting.administrativesItensCosting[i].sequence + 1,
        itemCostParent_id: ItemCostEnum.AdministrativeCosts,
        description: administrativeAreaCosting.administrativesItensCosting[i].denomination,
        header: false,
        able: true,
        mde: true,
        administrativeCost: true
      }));
    }

    let totalItems = itemsCostExpensesAux.filter(ice => (ice.parent_id !== ItemCostEnum.Pessoal)).length + 1;

    for (let i = 0; i < itemsCostExpensesAux2.length; i++) {
      itemsCostExpensesAux2[i].item_number = totalItems;
      itemsCostExpensesAux.push(itemsCostExpensesAux2[i]);
      totalItems++;
    }
    return itemsCostExpensesAux;
  }
}
