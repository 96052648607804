import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Inconsistency } from '../../shared/components/inconsistency/entities/inconsistency';
import { Functionality } from '../../shared/entities/functionality/functionality';
import { BaseNavigableComponent } from '../../shared/entities/base/base-navigable-component';
import { FunctionalityInfo } from '../../shared/entities/functionality/functionality-info';
import { SimulationTimeService } from './services/simulation-time.service';
import { SimulationTime } from './entities/simulation-time';

@Component({
  selector: 'app-simulation-time',
  templateUrl: './simulation-time.component.html',
  styleUrls: ['./simulation-time.component.scss']
})
export class SimulationTimeComponent extends BaseNavigableComponent<SimulationTime> {

  data: SimulationTime = new SimulationTime();
  functionality: FunctionalityInfo = Functionality.simulationTime;
  inconsistencies: Array<Inconsistency>;
  simulationTimes: Array<number> = new Array<number>();

  constructor(private injector: Injector, private simulationTimeService: SimulationTimeService) {
    super(injector);
  }

  processData(): Observable<SimulationTime> {
    return this.simulationTimeService.getData();
  }

  processSpecificBehaviors() {
    super.processSpecificBehaviors();

    this.simulationTimeService.getSimulationTimes().pipe(
      takeUntil(this.unsubscribe))
      .subscribe(simulationTimes => this.simulationTimes = simulationTimes);
  }

  setSimulationTime(simulationTime: number): void {
    this.data.time = simulationTime;
    this.simulationTimeService.notifySimulationTimeObservers(simulationTime);
  }

}
