import { Component, OnInit, Input } from '@angular/core';

import { Functionality } from '../../../../../shared/entities/functionality/functionality';
import { FunctionalityRoute } from '../../../../../shared/entities/functionality/functionality-route';

@Component({
  selector: 'app-additional-results',
  templateUrl: './additional-results.component.html',
  styleUrls: ['./additional-results.component.scss']
})
export class AdditionalResultsComponent implements OnInit {

  @Input() itemCostExpenseReport: boolean;
  @Input() pqr: boolean;
  @Input() edit: boolean;

  itemCostExpenseReportRoute: Array<string> = FunctionalityRoute.itemCostExpenseReport;
  pqrRoute: Array<string> = FunctionalityRoute.pqr;

  constructor() { }

  ngOnInit() {
  }

}
