import { Injectable } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { SessionService } from '../session/session.service';

@Injectable({
  providedIn: 'root'
})
export class BlockUiService {

  @BlockUI() private blockUi: NgBlockUI;

  private blockUiCount: number = 0;
  private blockUiMessage: string = '';

  constructor(private sessionService: SessionService) { }

  start(message?: string) {
    this.processMessage(message);

    this.blockUi.start(this.blockUiMessage);
    this.blockUiCount++;
  }

  stop(force: boolean = false) {
    if (!force) {
      this.blockUiCount--;

      if (this.blockUiCount <= 0) {
        this.stopBlockUi();
      }
    } else {
      this.stopBlockUi();
    }

  }

  private stopBlockUi(): void {
    this.blockUiCount = 0;
    this.blockUi.stop();
    this.blockUiMessage = '';
  }

  private processMessage(message: string): void {
    if (this.blockUiMessage) {
      if (message) {
        if (this.blockUiMessage.indexOf(message) === -1) {
          this.blockUiMessage += `\n${message}`;
        }
      }
    } else {
      this.blockUiMessage = message;
    }
  }

}
