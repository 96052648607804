import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { StudentExpense } from './student-expense';
import { TotalizerStudentCostByYear } from '../../../shared/services/calculate-student-cost/entities/totalizer-student-cost-by-year';
import { TotalizerStudentCostByStage } from '../../../shared/services/calculate-student-cost/entities/totalizer-student-cost-by-stage';
import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';
import { RelatorioCaq } from './relatorio-caq';

export class CaqReport extends NavigableComponentData {

  ufId: number;
  ufDescription: string;
  cityId: number;
  cityDescription: string;
  entityId: number;
  entityDescription: string;
  admDependencyName: string;
  locationEntity: string;
  years: Array<number>;
  enrollmentCurrentYear: number;
  totalizersStudentsCostByStages: Array<TotalizerStudentCostByStage>;
  totalizersStudentsCostByYearTotal: Array<TotalizerStudentCostByYear>;
  studentsExpenses: Array<StudentExpense>;
  sourceInformations: Array<Footnote>;
  sourceInformation: Footnote;
  sourceInformationPQR: Footnote;
  datasByEntity: Array<any>;
  relatorioCaq: RelatorioCaq;

  constructor(init?: Partial<CaqReport>) {
    super();
    Object.assign(this, init);
  }
}
