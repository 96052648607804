import { NgModule } from '@angular/core';

import { SimulatorRoutingModule } from './simulator-routing.module';
import { AccessAndOfferModule } from './access-and-offer/access-and-offer.module';
import { QualityConditionsModule } from './quality-conditions/quality-conditions.module';
import { ChargesAndAdditionalsModule } from './charges-and-additionals/charges-and-additionals.module';
import { ResultsModule } from './results/results.module';
import { SharedModule } from '../shared/shared.module';
import { PqrComponent } from './pqr/pqr.component';
import { SelectLocationComponent } from './select-location/select-location.component';
import { SimulationTimeComponent } from './simulation-time/simulation-time.component';
import { SimulatorComponent } from './simulator/simulator.component';
import { BrMapComponent } from './select-location/br-map/br-map.component';
import { RequiredDataAlertComponent } from './shared/components/modal/required-data-alert/required-data-alert.component';
import { InconsistencyConfirmComponent } from './shared/components/modal/inconsistency-confirm/inconsistency-confirm.component';
import { ProcessingSimulationComponent } from './shared/components/processing-simulation/processing-simulation.component';
import { SimulationTagComponent } from './shared/components/simulation-tag/simulation-tag.component';
import { StepNavigatorComponent } from './shared/components/step-navigator/step-navigator.component';

@NgModule({
  imports: [
    SharedModule,
    AccessAndOfferModule,
    QualityConditionsModule,
    ChargesAndAdditionalsModule,
    ResultsModule,
    SimulatorRoutingModule
  ],
  entryComponents: [
    RequiredDataAlertComponent,
    InconsistencyConfirmComponent
  ],
  declarations: [
    SimulatorComponent,
    SelectLocationComponent,
    SimulationTimeComponent,
    BrMapComponent,
    RequiredDataAlertComponent,
    InconsistencyConfirmComponent,
    PqrComponent,
    ProcessingSimulationComponent,
    SimulationTagComponent,
    StepNavigatorComponent
  ]
})
export class SimulatorModule { }
