import { TotalizerStudentCostByYear } from './totalizer-student-cost-by-year';
import { TotalizerStudentCostByStage } from './totalizer-student-cost-by-stage';

export class TotalizerStudentCost {

  totalizersStudentsCostByYearTotal: Array<TotalizerStudentCostByYear>;
  totalizersStudentsCostByStages: Array<TotalizerStudentCostByStage>;

  constructor(init?: Partial<TotalizerStudentCost>) {
    Object.assign(this, init);
  }

}
