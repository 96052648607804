export class AlunoPorTurmaDiagnostivo {

    mediaAlunosPorTurmaCrecheUrbana: number;
    mediaAlunosPorTurmaPreEscolaUrbana: number;
    mediaAlunosPorTurmaEnsinoFundamentalAnosIniciaisUrbana: number;
    mediaAlunosPorTurmaEnsinoFundamentalAnosFinaisUrbana: number;
    mediaAlunosPorTurmaEnsinoMedioUrbana: number;
    mediaAlunosPorTurmaEjaUrbana: number;
    mediaAlunosPorTurmaCrecheRural: number;
    mediaAlunosPorTurmaPreEscolaRural: number;
    mediaAlunosPorTurmaEnsinoFundamentalAnosIniciaisRural: number;
    mediaAlunosPorTurmaEnsinoFundamentalAnosFinaisRural: number;
    mediaAlunosPorTurmaEnsinoMedioRural: number;
    mediaAlunosPorTurmaEjaRural: number;

    constructor(init?: Partial<AlunoPorTurmaDiagnostivo>) {
        Object.assign(this, init);
    }

}
