import { Component, OnInit } from '@angular/core';

import { Functionality } from '../../../shared/entities/functionality/functionality';

@Component({
  selector: 'app-charges-and-additionals',
  templateUrl: './charges-and-additionals.component.html',
  styleUrls: ['./charges-and-additionals.component.scss']
})
export class ChargesAndAdditionalsComponent implements OnInit {

  chargesAndAddiotionalsName: string = Functionality.chargesandadditionals.name;

  constructor() { }

  ngOnInit() {
  }

}
