import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';

import { BaseUnsubscribe } from '../../../shared/entities/base/base-unsubscribe';
import { SimulatorService } from '../../simulator/services/simulator.service';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { FunctionalityRoute } from '../../../shared/entities/functionality/functionality-route';
import { SimulationType } from '../../simulator/entities/enums/simulation-type.enum';
import { SessionService } from '../../../shared/services/session/session.service';
import { UtilitiesService } from '../../../shared/services/utilities/utilities.service';
import { AuthService } from '../../../shared/services/auth/auth.service';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent extends BaseUnsubscribe implements OnInit {

  homeRoute: Array<string> = [FunctionalityRoute.homeRoute];
  isItemCostExpenseReport: boolean;

  constructor(private router: Router, private simulatorService: SimulatorService, private sessionService: SessionService, private authService: AuthService) {
    super();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.unsubscribe))
      .subscribe((event: NavigationEnd) => {
        this.isItemCostExpenseReport = event.url.indexOf('itemcostexpensereport') > 0;

        const simulationType: SimulationType = this.sessionService.getItem(UtilitiesService.simulationTypeSessionKey);

        if (simulationType === SimulationType.financing) {
          this.authService.getUserInfo().pipe(
            takeUntil(this.unsubscribe))
            .subscribe(userInfo => {
              if (userInfo.admin || userInfo.researcher) {
                this.navigateToResultSimulation(event.url);
              } else {
                this.router.navigate([FunctionalityRoute.homeRoute], { queryParamsHandling: 'merge' });
              }
            });
        } else {
          this.navigateToResultSimulation(event.url);
        }
      });
  }

  ngOnInit() {
  }

  private navigateToResultSimulation(url: string): void {
    if (url.indexOf(Functionality.caqReport.key) !== -1 ||
      url.indexOf(Functionality.budgetForecastReport.key) !== -1 ||
      url.indexOf(Functionality.financingFundsReport.key) !== -1 ||
      url.indexOf(Functionality.financingFederatedEntitiesReport.key) !== -1 ||
      url.indexOf(Functionality.custoPorEscola.key) !== -1 ||
      url.endsWith(FunctionalityRoute.resultsRoute)) {
      this.simulatorService.navigateToResultSimulation();
    }
  }

}
