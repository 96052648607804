import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { StudentClass } from './student-class';
import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';
import { AlunoPorTurmaDiagnostivo } from './alunos-por-turma-diagnostico';

export class NumberStudentClass extends NavigableComponentData {

    studentClasses: Array<StudentClass> = new Array<StudentClass>();
    alunosPorTurmaDiagnostico: AlunoPorTurmaDiagnostivo;
    sourceInformation: Footnote;
    noteInfo: Footnote;
    currentYear: number;

    constructor(init?: Partial<NumberStudentClass>) {
        super();
        Object.assign(this, init);
    }

}