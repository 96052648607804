import { Component, Injector, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PqrComponentData } from '../../shared/entities/base/pqr-component-data';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { OfferGoalEnrollmentFullTimeService } from './services/offer-goal-enrollment-full-time.service';
import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { OfferGoalEnrollmentFullTime } from './entities/offer-goal-enrollment-full-time';
import { NavigableComponentData } from '../../shared/entities/base/navigable-component-data';

@Component({
  selector: 'app-offer-goal-enrollment-full-time',
  templateUrl: './offer-goal-enrollment-full-time.component.html',
  styleUrls: ['./offer-goal-enrollment-full-time.component.scss']
})
export class OfferGoalEnrollmentFullTimeComponent extends BaseNavigableComponent<OfferGoalEnrollmentFullTime> implements PqrComponentData {

  data: OfferGoalEnrollmentFullTime = new OfferGoalEnrollmentFullTime();
  functionality: FunctionalityInfo = Functionality.offerGoalEnrollmentFullTime;
  inconsistencies: Array<Inconsistency>;
  pqrAdminMode: boolean;
  pqrMode: boolean;
  pqrModalMode: boolean;
  componentType: Type<PqrComponentData> = OfferGoalEnrollmentFullTimeComponent;

  constructor(private offerGoalEnrollmentFullTimeService: OfferGoalEnrollmentFullTimeService, private injector: Injector) {
    super(injector);
  }

  processData(): Observable<OfferGoalEnrollmentFullTime> {
    return this.offerGoalEnrollmentFullTimeService.getData();
  }

  getPqrData(): NavigableComponentData {
    return this.data;
  }

  processSpecificBehaviors() {
    if (!this.pqrMode) {
      this.offerGoalEnrollmentFullTimeService.setFullTime(this.data).pipe(
        takeUntil(this.unsubscribe))
        .subscribe();
    }
  }

  setPqrData(pqrData: any) {
    this.data = pqrData;
    this.offerGoalEnrollmentFullTimeService.setFullTime(this.data).pipe(
      takeUntil(this.unsubscribe))
      .subscribe();
  }

  processValidation(): void {
    super.processValidation();

    for (const offerGoalEnrollmentFullTime of this.data.fullTime) {

      if (isNaN(offerGoalEnrollmentFullTime.offerGoal) || offerGoalEnrollmentFullTime.offerGoal === undefined || offerGoalEnrollmentFullTime.offerGoal === null
        || offerGoalEnrollmentFullTime.offerGoal < 0 || offerGoalEnrollmentFullTime.offerGoal > 100) {
        this.inconsistencies.push(new Inconsistency({
          message: 'O percentual de matrículas em turno integral da etapa ' + offerGoalEnrollmentFullTime.stageDescription + ' não é válida. Informe um valor de 0% a 100%.'
        }));
      }
    }

  }

}
