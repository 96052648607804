
export class CapitalExpenseByYear {

  year: number;
  value: number;

  constructor(init?: Partial<CapitalExpenseByYear>) {
    Object.assign(this, init);
  }
}
