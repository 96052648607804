import { Component, Injector, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PqrComponentData } from '../../shared/entities/base/pqr-component-data';
import { TeacherTrainingService } from './services/teacher-training.service';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { TeacherTraining } from './entities/teacher-training';
import { NavigableComponentData } from '../../shared/entities/base/navigable-component-data';

@Component({
  selector: 'app-teacher-training',
  templateUrl: './teacher-training.component.html',
  styleUrls: ['./teacher-training.component.scss']
})
export class TeacherTrainingComponent extends BaseNavigableComponent<TeacherTraining> implements PqrComponentData {

  data: TeacherTraining = new TeacherTraining();
  functionality: FunctionalityInfo = Functionality.teacherTraining;
  inconsistencies: Array<Inconsistency>;
  pqrAdminMode: boolean;
  pqrMode: boolean;
  pqrModalMode: boolean;
  componentType: Type<PqrComponentData> = TeacherTrainingComponent;
  referenceDateMask: any = this.utilitiesService.getReferenceDateMask();

  constructor(private teacherTrainingService: TeacherTrainingService, private injector: Injector) {
    super(injector);
  }

  processData(): Observable<TeacherTraining> {
    return this.teacherTrainingService.getData();
  }

  processSpecificBehaviors(): void {
    if (!this.pqrMode) {
      this.teacherTrainingService.setTotalNumberTeacherEmployee(this.data);
    }
  }

  getPqrData(): NavigableComponentData {
    return this.data;
  }

  setPqrData(pqrData: any) {
    this.data = pqrData;
  }

  processValidation(): void {
    super.processValidation();

    if (isNaN(this.data.employeeTrainingValue) || this.data.employeeTrainingValue === null || this.data.employeeTrainingValue === undefined) {
      this.inconsistencies.push(new Inconsistency({ message: 'O valor unitário da formação continuada de funcionários deve ser informado.' }));
    }

    if (isNaN(this.data.teacherTrainingValue) || this.data.teacherTrainingValue === null || this.data.teacherTrainingValue === undefined) {
      this.inconsistencies.push(new Inconsistency({ message: 'O valor unitário da formação continuada de professores deve ser informado.' }));
    }
  }

}
