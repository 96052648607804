import { ClassNumberByStage } from './class-number-by-stage';

export class ClassNumberByLocation {

  id: number;
  description: string;
  classesNumberByStages: Array<ClassNumberByStage>;

  constructor(init?: Partial<ClassNumberByLocation>) {
    Object.assign(this, init);
  }
}
