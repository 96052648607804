import { QuantityByAdmDependencyAndLocation } from './quantity-by-adm-dependency-and-location';
import { Idhm } from './idhm';
import { PibPerCapita } from './pib-per-capita';
import { Sociodemographic } from './sociodemographic';
import { DadosSocioDemograficos } from './dados-socio-demograficos';

export class SelectedLocationInfo {

    sociodemographic: Array<Sociodemographic>;
    pibPerCapitaByLevel: PibPerCapita;
    idhmByLevel: Idhm;
    schoolByAdmDependencyAndLocation: QuantityByAdmDependencyAndLocation;
    enrollmentByAdmDependencyAndLocation: QuantityByAdmDependencyAndLocation;

    dadosSocioDemograficos: DadosSocioDemograficos;

    constructor(init?: Partial<SelectedLocationInfo>) {
        Object.assign(this, init);
    }
}
