import { AdditionalItem } from './additional-item';
import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';

export class Additional extends NavigableComponentData {

    additionals: Array<AdditionalItem> = new Array<AdditionalItem>();

    constructor(init?: Partial<AdditionalItem>) {
        super();
        Object.assign(this, init);
    }
}
