
export class TotalizerStudentCostByYear {

  year: number;
  cost: number;
  costMinimum: number;
  costMaximum: number;
  costMedian: number;
  weightingFactor: number;
  costs: Array<number>;
  cityCostMinimum: Array<string>;
  cityCostMedia: Array<string>;
  cityCostMaximum: Array<string>;
  enrollmentQuantity: number;
  schoolQuantityCost: number;

  constructor(init?: Partial<TotalizerStudentCostByYear>) {
    Object.assign(this, init);
  }
}
