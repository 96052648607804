export enum DatasCsvEnum {
  Caq = 1,
  StudentExpenses = 2,
  OfferDimensions = 3,
  ExpensesBasicEducation = 4,
  ExpensesFederativeEntity = 5,
  ExpenseTotal = 6,
  ExpenseByStage = 7,
  CaqByEntity = 8
}
