import { FormationLevel } from '../../../../shared/entities/formation-level';
import { TeacherDistributionCareerLevel } from './teacher-distribution-career-level';
import { PriceList } from '../../../../shared/entities/price-list';
import { UnitOfMeasurementEnum } from '../../../../shared/entities/enums/unit-of-measurement.enum';
import { AccountPlanEnum } from '../../../../shared/entities/enums/account-plan.enum';
import { ExpenseTypeEnum } from '../../../../shared/entities/enums/expense-type.enum';
import { PriceIndiceEnum } from '../../../../shared/entities/enums/price-indice.enum';

export class TeacherFormationLevel extends PriceList {
    formationLevel: FormationLevel;
    diagnosticGrossMonthlyRemuneration: number;
    grossMonthlyRemuneration: number;
    percTeacherDistributionCareerLevel: Array<TeacherDistributionCareerLevel>;
    initialTeacherPercentage: number;
    diagnostic: number;
    journeyTotal: number;
    journeyNoInteraction: number;
    journeyWithInteraction: number;

    private _sequence: string;
    private _denomination: string;

    get denomination(): string {
        if (!this._denomination) {
            this._denomination = this.sequence;
        }

        return this._denomination;
    }

    set denomination(denomination: string) {
        this._denomination = denomination;
    }

    get sequence(): string {
        return this._sequence;
    }

    set sequence(sequence: string) {
        this._sequence = sequence.indexOf('P') > -1 ? sequence : `P${sequence}`;
    }

    constructor(init?: Partial<TeacherFormationLevel>) {
        super();

        this.unitOfMeasurements = [UnitOfMeasurementEnum.professorMes];
        this.accountPlans = [AccountPlanEnum.Pessoal_Encargos];
        this.expenseTypes = [ExpenseTypeEnum.Corrente];
        this.priceIndices = [PriceIndiceEnum.ICV];
        this.multiplier = 13.3;

        Object.assign(this, init);
    }

    toJSON(): any {
        const json = {};
        Object.keys(this).forEach(key => json[(key[0] !== '_' ? key : key.replace('_', ''))] = this[key]);
        return json;
    }
}
