import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';

export class DiagnosticoMatriculaPorEtapa extends NavigableComponentData {

    idEtapaEnsino: number;
    descEtapaEnsino: string;
    totalMatriculas: number;
    matriculaFederal: number;
    matriculaEstadual: number;
    matriculaMunicipal: number;
    matriculaConvSemFim: number;
    matriculaConvComFim: number;
    matriculaNConvSemFim: number;
    matriculaNConvComFim: number;
    matriculaSemDetalhamento: number;
    matriculaUrbana: number;
    matriculaRural: number;

    constructor(init?: Partial<DiagnosticoMatriculaPorEtapa>) {
        super();
        Object.assign(this, init);
    }
}
