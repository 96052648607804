import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { FullTime } from './full-time';
import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';

export class OfferGoalEnrollmentFullTime extends NavigableComponentData {

    fullTime: Array<FullTime> = new Array<FullTime>();
    sourceInformation: Footnote;
    infoNote: Footnote;
    currentYear: number;

    constructor(init?: Partial<OfferGoalEnrollmentFullTime>) {
        super();
        Object.assign(this, init);
    }

}
