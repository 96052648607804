import { Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import * as _ from 'lodash';
import { map, switchMap } from 'rxjs/operators';

import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { SessionService } from '../../../../shared/services/session/session.service';
import { DailyTeachingLoad } from '../entities/daily-teaching-load';
import { NavigableComponentService } from '../../../shared/entities/base/navigable-component-service';
import { HttpService } from '../../../../shared/services/http/http.service';
import { DailyTeachingLoadDiagnostic } from '../entities/daily-teaching-load-diagnostic';
import { CurrentYearService } from '../../../shared/services/current-year/current-year.service';
import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { SourceInformationEnum } from './../../../../shared/entities/enums/source-information.enum';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { PeriodTeachingLoadEnum } from '../entities/enums/period-teaching-load.enum';

@Injectable({
  providedIn: 'root'
})
export class DailyTeachingLoadService implements NavigableComponentService {

  constructor(private httpService: HttpService, private sessionService: SessionService, private currentYearService: CurrentYearService, private utilitiesService: UtilitiesService) { }

  getData(): Observable<DailyTeachingLoad> {
    return of(this.sessionService.getItem<DailyTeachingLoad>(Functionality.dailyTeachingLoad.pqrKey));
  }

  /*getData(): Observable<DailyTeachingLoad> {
    let dailyTeachingLoad = new DailyTeachingLoad();
    dailyTeachingLoad = this.sessionService.getItem<DailyTeachingLoad>(Functionality.dailyTeachingLoad.pqrKey);
    dailyTeachingLoad.currentYear = this.currentYearService.getDailyChargeAmountCurrentYear();
    dailyTeachingLoad.sourceInformation = new Footnote({ indice: 1, sourceInformation: SourceInformationEnum.dailyChargeAmount });

    dailyTeachingLoad.noteInfo = new Footnote({
      indice: 2,
      note: 'São consideradas turmas diurnas parciais aquelas com jornada diária de ensino inferior a 7 horas e cujas atividades terminam até às 17 horas. ' +
        'São consideradas turmas noturnas aquelas cujas atividades se iniciam a partir das 17 horas. ' +
        'Não são consideradas as matrículas de Educação Infantil (Creche e Pré-escola) em turmas noturnas.'
    });

    return of(dailyTeachingLoad);
  }

  getDiagnostic(data: DailyTeachingLoad): Observable<any> {

    const observables: Array<Observable<any>> = new Array<Observable<any>>();

    for (const shiftId in PeriodTeachingLoadEnum) {
      if (Number(shiftId)) {
        observables.push(this.getDiagnosticByShift(Number(shiftId)).pipe(
          map(d => {
            for (let i = 0; i < d.length; i++) {
              const stage = _.find(data.teachingLoads, tl => tl.id === d[i].stageId);
              if (stage !== undefined) {
                const shift = _.find(stage.shifts, sh => sh.id === d[i].shiftId);
                if (shift !== undefined) {
                  shift.diagnostic = d[i].diagnostic;
                }
              }
            }
          })));
      }
    }

    return forkJoin(observables).pipe(
      map(diagnosticResult => {
        return diagnosticResult;
      })
    );
  }

  getDiagnosticByShift(shiftId: number): Observable<Array<DailyTeachingLoadDiagnostic>> {

    const currentYear: number = this.currentYearService.getDailyChargeAmountCurrentYear();
    const filtersLocation: Array<string> = this.utilitiesService.getSelectLocationFilter();

    let filters: Array<string>;
    let options: any;

    filters = new Array<string>(
      `min_year:"${currentYear}"`,
      `max_year:"${currentYear}"`
    );

    filters.push(this.utilitiesService.getAdmDependencyFilter());

    if (shiftId === PeriodTeachingLoadEnum.diurnoParcial) {
      filters.push(`period:["1","2"]`);
    } else if (shiftId === PeriodTeachingLoadEnum.tempoIntegral) {
      filters.push(`period:["4"]`);
    } else { // noturno
      filters.push(`period:["3"]`);
    }

    filters = filters.concat(filtersLocation);

    options = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([
      ['dims', 'education_level_short'],
      ['filter', filters.join(',')]
    ]));

    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/daily_charge_amount`, options).pipe(
          map(diagnostics => diagnostics.map(diagnostic =>
            new DailyTeachingLoadDiagnostic({
              stageId: diagnostic.education_level_short_id,
              shiftId: shiftId,
              diagnostic: diagnostic.average_class_duration
            }))));
      }));
  }
  */
}

