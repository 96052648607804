import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {

  @Output() download: EventEmitter<void> = new EventEmitter<void>();
  @Input() textDownload: string = 'Download CSV';

  constructor() { }

  ngOnInit() {
  }

  emitDownload(): void {
    this.download.emit();
  }

}
