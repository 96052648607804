import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { Functionality } from 'app/shared/entities/functionality/functionality';
import { SessionService } from 'app/shared/services/session/session.service';
import { UtilitiesService } from 'app/shared/services/utilities/utilities.service';
import { CareerAndRemunerationTeachers } from 'app/simulator/quality-conditions/career-and-remuneration-teachers/entities/career-and-remuneration-teachers';
import { DailyTeachingLoad } from 'app/simulator/quality-conditions/daily-teaching-load/entities/daily-teaching-load';
import { NumberStudentClass } from 'app/simulator/quality-conditions/number-student-class/entities/number-student-class';
import { OfferGoalEnrollmentFullTime } from 'app/simulator/quality-conditions/offer-goal-enrollment-full-time/entities/offer-goal-enrollment-full-time';
import { SchoolDayPerWeek } from 'app/simulator/quality-conditions/school-day-per-week/entities/school-day-per-week';
import { WorkJourneyTeacher } from 'app/simulator/quality-conditions/work-journey-teacher/entities/work-journey-teacher';
import { NavigableComponentService } from 'app/simulator/shared/entities/base/navigable-component-service';
import { EnrollmentByStageSeriesBySchool } from '../entities/enrollment-by-stage-series-by-school';
import { CreateProjectionsService } from 'app/simulator/shared/services/create-projections/create-projections.service';
import { EnrollmentAndClassAndClassroom } from 'app/simulator/results/financing-federated-entities-report/entities/enrollment-and-class-and-classroom';
import { EnrollmentBySchoolLocation } from '../entities/enrollment-by-school-location';
import { StageEnrollmentsBySchool } from '../entities/stage-enrollments-by-school';
import { EnrollmentProjection } from '../../enrollment-projection/entities/enrollment-projection';
import { StageEnrollments } from '../../enrollment-projection/entities/stage-enrollments';
import { SerieEnrollments } from '../../enrollment-projection/entities/serie-enrollments';
import { SerieEnrollmentsBySchool } from '../entities/serie-enrollments-by-school';
import { EnrollmentBySchool } from '../entities/enrollment-by-school';
import { StageAndSeries } from 'app/shared/entities/stage-and-series';
import { StageEnum } from 'app/shared/entities/enums/stage.enum';
import { PercentageTeacherCareer } from '../entities/percentage_teacher_career';
import { SelectLocation } from 'app/simulator/select-location/entities/select-location';
import { MatriculaPorEtapaEnsino } from '../entities/matricula-por-etapa-ensino';
import { DiagnosticoDeMatriculaPorEscola } from '../entities/diagnostico-de-matricula-por-escola';
import { HttpService } from 'app/shared/services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class EnrollmentByStageSeriesBySchoolService implements NavigableComponentService {

  constructor(
    private utilitiesService: UtilitiesService,
    private sessionService: SessionService,
    private httpService: HttpService,
    private createProjectionsService: CreateProjectionsService,
  ) { }

  getData(): Observable<EnrollmentBySchool> {

    const enrollmentBySchool: EnrollmentBySchool = new EnrollmentBySchool();
    const resultForSelectLocation: SelectLocation = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);

    return this.utilitiesService.getEtapasDeEnsino().pipe(
      mergeMap(resultStages => {

        if (resultForSelectLocation.selectedCity) {

          return this.getMatriculaPorEtapaEnsino().pipe(
            map(resultMatriculaPorEtapaEnsino => {

              for (let i = 0; i < resultMatriculaPorEtapaEnsino.length; i++) {
                enrollmentBySchool.diagnosticosMatriculaPorEscola.push(new DiagnosticoDeMatriculaPorEscola({
                  codMec: resultMatriculaPorEtapaEnsino[i].codMec,
                  codMun: resultMatriculaPorEtapaEnsino[i].codMun,
                  codUf: resultMatriculaPorEtapaEnsino[i].codUf,
                  nomeInstituicao: resultMatriculaPorEtapaEnsino[i].nomeInstituicao,
                  etapasEnsino: this.getEtapasEnsino(resultMatriculaPorEtapaEnsino[i].etapasEnsino)
                }));
              }
              return enrollmentBySchool;
            }));
        }

        return of(enrollmentBySchool);
      }));

  }

  getEtapasEnsino(etapasEnsino: Array<MatriculaPorEtapaEnsino>) {
    const resultEtapasEnsino: Array<MatriculaPorEtapaEnsino> = new Array<MatriculaPorEtapaEnsino>();
    let matriculaDiurna: number;
    let matriculaIntegral: number;
    let matriculaNoturna: number;

    for (let i = 0; i < etapasEnsino.length; i++) {
      matriculaDiurna = etapasEnsino[i].numeroMatriculasDiurno != null ? etapasEnsino[i].numeroMatriculasDiurno : 0;
      matriculaIntegral = etapasEnsino[i].numeroMatriculasIntegral != null ? etapasEnsino[i].numeroMatriculasIntegral : 0;
      matriculaNoturna = etapasEnsino[i].numeroMatriculasNoturno != null ? etapasEnsino[i].numeroMatriculasNoturno : 0;

      resultEtapasEnsino.push(new MatriculaPorEtapaEnsino({
        codEtapaEnsino: etapasEnsino[i].codEtapaEnsino,
        descricao: etapasEnsino[i].descricao,
        numeroMatriculasDiurno: matriculaDiurna,
        numeroMatriculasNoturno: matriculaNoturna,
        numeroMatriculasIntegral: matriculaIntegral,
        percentualMatriculasIntegral: this.getPercentageIntegral(matriculaIntegral, matriculaDiurna),
        totalMatriculasDiurno: matriculaDiurna + matriculaIntegral
      }));

    }
    return resultEtapasEnsino;
  }

  getEnrollmentBySchoolLocation(enrollmentProjection: EnrollmentProjection, resultStages: Array<StageAndSeries>): Array<EnrollmentBySchoolLocation> {

    const enrollmentBySchoolLocations: Array<EnrollmentBySchoolLocation> = new Array<EnrollmentBySchoolLocation>();

    for (let i = 0; i < enrollmentProjection.enrollmentsProjectionsByLocations.length; i++) {

      enrollmentBySchoolLocations.push(new EnrollmentBySchoolLocation({
        id: enrollmentProjection.enrollmentsProjectionsByLocations[i].id,
        description: enrollmentProjection.enrollmentsProjectionsByLocations[i].description,
        stagesEnrollmentsByScholl: this.getStageEnrollmentsBySchools(enrollmentProjection.enrollmentsProjectionsByLocations[i].stagesEnrollments, resultStages),
        totalExistingClassrooms: enrollmentProjection.enrollmentsProjectionsByLocations[i].totalExistingClassrooms
      }));
    }
    return enrollmentBySchoolLocations;
  }

  getStageEnrollmentsBySchools(stagesEnrollments: Array<StageEnrollments>, resultStages: Array<StageAndSeries>): Array<StageEnrollmentsBySchool> {

    const stageEnrollmentsBySchools: Array<StageEnrollmentsBySchool> = new Array<StageEnrollmentsBySchool>();

    for (let i = 0; i < resultStages.length; i++) {

      const idStage: number = resultStages[i].id;
      const descriptionStage: string = resultStages[i].description;
      let totalEnrollmentDay: number = 0;
      let totalEnrollmentNight: number = 0;
      let totalEnrollmentCurrent: number = 0;
      let percentageIntegral: number = 0;
      let serieEnrollments: Array<SerieEnrollments> = new Array<SerieEnrollments>();

      for (let j = 0; j < stagesEnrollments.length; j++) {
        if (resultStages[i].id === stagesEnrollments[j].id) {
          totalEnrollmentDay = stagesEnrollments[j].totalEnrollments[0].quantity - stagesEnrollments[j].totalEnrollments[0].quantityNocturnal;
          totalEnrollmentNight = stagesEnrollments[j].totalEnrollments[0].quantityNocturnal;
          totalEnrollmentCurrent = totalEnrollmentDay + totalEnrollmentNight;
          percentageIntegral = stagesEnrollments[j].integralPercentage;
          if (stagesEnrollments[j].seriesEnrollments) {
            serieEnrollments = stagesEnrollments[j].seriesEnrollments;
          }
        }

      }

      stageEnrollmentsBySchools.push(new StageEnrollmentsBySchool({
        id: idStage,
        description: descriptionStage,
        serieEnrollmentsBySchool: this.getSerieEnrollmentsBySchools(serieEnrollments, resultStages[i]),
        totalEnrollmentDay: totalEnrollmentDay,
        totalEnrollmentNight: totalEnrollmentNight,
        totalEnrollmentCurrent: totalEnrollmentCurrent,
        percentageIntegral: percentageIntegral

      }));
    }

    return stageEnrollmentsBySchools;
  }

  getSerieEnrollmentsBySchools(seriesEnrollments: Array<SerieEnrollments>, resultStage: StageAndSeries): Array<SerieEnrollmentsBySchool> {
    const serieEnrollmentsBySchools: Array<SerieEnrollmentsBySchool> = new Array<SerieEnrollmentsBySchool>();

    if (resultStage.id === StageEnum.creche) {

      for (let i = 0; i < resultStage.series.length; i++) {

        const idSerie: number = resultStage.series[i].id;
        const descriptionSerie: string = resultStage.series[i].description;
        let totalEnrollmentDay: number = 0;
        let totalEnrollmentNight: number = 0;

        if (seriesEnrollments) {
          for (let j = 0; j < seriesEnrollments.length; j++) {
            if (resultStage.series[i].id === seriesEnrollments[j].id) {
              totalEnrollmentDay = seriesEnrollments[j].enrollments[0].quantity - seriesEnrollments[j].enrollments[0].quantityNocturnal;
              totalEnrollmentNight = seriesEnrollments[j].enrollments[0].quantityNocturnal;
            }

          }
        }

        serieEnrollmentsBySchools.push(new SerieEnrollmentsBySchool({
          id: idSerie,
          description: descriptionSerie,
          totalEnrollmentDay: totalEnrollmentDay,
          totalEnrollmentNight: totalEnrollmentNight
        }));

      }
    }
    return serieEnrollmentsBySchools;
  }

  getMatriculaPorEtapaEnsino(): Observable<Array<DiagnosticoDeMatriculaPorEscola>> {

    return this.httpService.getApiEndpointUFG().pipe(
      mergeMap(endpointUFG => {

        const tipoDependencia = this.utilitiesService.getTipoDependencia();
        const matriculaUrlUFG: string = `${endpointUFG}/diagnostico-de-matricula-de-escola/${tipoDependencia}`;

        return this.httpService.get<any>(matriculaUrlUFG).pipe(
          map(diagnosticoMatriculas => {

            return diagnosticoMatriculas.diagnosticos;

          }));

      }));

  }

  getEnrollmentAndClassAndClassroom(): Observable<Array<EnrollmentAndClassAndClassroom>> {
    const resultForOffergoalenrollmentfulltime: OfferGoalEnrollmentFullTime = this.sessionService.getItem<OfferGoalEnrollmentFullTime>(Functionality.offerGoalEnrollmentFullTime.pqrKey);
    const resultForNumberstudentclass: NumberStudentClass = this.sessionService.getItem<NumberStudentClass>(Functionality.numberStudentClass.pqrKey);
    const enrollmentAndClassAndClassrooms: Array<EnrollmentAndClassAndClassroom> = new Array<EnrollmentAndClassAndClassroom>();
    const resultForSchoolDayPerWeek: SchoolDayPerWeek = this.sessionService.getItem<SchoolDayPerWeek>(Functionality.schoolDayPerWeek.pqrKey);
    const resultForDailyTeachingLoad: DailyTeachingLoad = this.sessionService.getItem<DailyTeachingLoad>(Functionality.dailyTeachingLoad.pqrKey);
    const resultForCareerAndRemunerationTeachers: CareerAndRemunerationTeachers = this.sessionService.getItem<CareerAndRemunerationTeachers>(Functionality.careerAndRemunerationTeachers.pqrKey);
    const resultForWorkJourneyTeacher: WorkJourneyTeacher = this.sessionService.getItem<WorkJourneyTeacher>(Functionality.workJourneyTeacher.pqrKey);

    return this.utilitiesService.getClassAndClassroomsAndEnrollment(resultForNumberstudentclass, resultForOffergoalenrollmentfulltime, resultForSchoolDayPerWeek, resultForDailyTeachingLoad,
      resultForCareerAndRemunerationTeachers, resultForWorkJourneyTeacher, false, true).pipe(
        map(classAndClassroomNumberAndEnrollment => {

          for (let i = 0; i < classAndClassroomNumberAndEnrollment.length; i++) {

            const classAndClassroomNumberAndEnrollmentByLocations = classAndClassroomNumberAndEnrollment[i].locations;

            const enrollmentAndClassAndClassroom: EnrollmentAndClassAndClassroom = new EnrollmentAndClassAndClassroom({
              state_id: classAndClassroomNumberAndEnrollment[i].state_id,
              state_description: classAndClassroomNumberAndEnrollment[i].state_name,
              city_id: classAndClassroomNumberAndEnrollment[i].city_id,
              city_description: classAndClassroomNumberAndEnrollment[i].city_name,
              school_id: classAndClassroomNumberAndEnrollment[i].school_id,
              school_description: classAndClassroomNumberAndEnrollment[i].school_name,
              adm_dependency_id: classAndClassroomNumberAndEnrollment[i].adm_dependency_id,
              adm_dependency_name: classAndClassroomNumberAndEnrollment[i].adm_dependency_name,
              enrollmentProjection: this.createProjectionsService.getEnrollmentProjection(classAndClassroomNumberAndEnrollment[i]),
              hasEnrollment: this.existEnrollment(classAndClassroomNumberAndEnrollmentByLocations),
              percentageTeacherCareer: this.getPercentageTeacherCareer(classAndClassroomNumberAndEnrollment[i].percentage_teacher_career)
            });

            enrollmentAndClassAndClassrooms.push(enrollmentAndClassAndClassroom);

          }
          return enrollmentAndClassAndClassrooms;
        }));
  }

  calculateTotal(stageEnrollmentsBySchool: StageEnrollmentsBySchool): void {

    let totalEnrollmentDay: number = 0;

    for (let i = 0; i < stageEnrollmentsBySchool.serieEnrollmentsBySchool.length; i++) {
      totalEnrollmentDay += (1 * stageEnrollmentsBySchool.serieEnrollmentsBySchool[i].totalEnrollmentDay);
    }

    stageEnrollmentsBySchool.totalEnrollmentDay = totalEnrollmentDay;
  }

  private getPercentageTeacherCareer(resultaPercentagesTeachersCareer: Array<any>): Array<PercentageTeacherCareer> {

    const percentagesTeachersCareer: Array<PercentageTeacherCareer> = new Array<PercentageTeacherCareer>();

    for (let i = 0; i < resultaPercentagesTeachersCareer.length; i++) {

      percentagesTeachersCareer.push(new PercentageTeacherCareer({
        formationLevelId: resultaPercentagesTeachersCareer[i].formation_level_id,
        percentage: resultaPercentagesTeachersCareer[i].percentage
      }));
    }
    return percentagesTeachersCareer;
  }

  private existEnrollment(datas: Array<any>): boolean {

    for (let i = 0; i < datas.length; i++) {
      const data = datas[i];

      if (data.education_level.length > 0) {
        return true;
      }
    }
    return false;
  }

  private getPercentageIntegral(numeroMatriculaIntegral: number, numeroMatriculaDiurna: number,): any {

    let percentualMatriculaIntegral: number;
    if (numeroMatriculaIntegral > 0) {
      if (numeroMatriculaDiurna === 0 || numeroMatriculaDiurna === null || numeroMatriculaDiurna === undefined) {
        percentualMatriculaIntegral = 100;
      } else {
        const totalMatriculas = numeroMatriculaDiurna > 0 ? numeroMatriculaIntegral + numeroMatriculaDiurna : numeroMatriculaIntegral;
        percentualMatriculaIntegral = (numeroMatriculaIntegral / totalMatriculas) * 100;
        // percentualMatriculaIntegral = (numeroMatriculaIntegral / numeroMatriculaDiurna) * 100;
      }
    }
    return percentualMatriculaIntegral;
  }
}
