import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { ColumnReport } from './entities/column-report';
import { ItemCostExpenseReportService } from './services/item-cost-expense-report.service';
import { ItemsCostExpensesReport } from './entities/items-cost-expenses-report';
import { ShiftEnum } from '../../../shared/entities/enums/shift-enum';
import { StageEnum } from '../../../shared/entities/enums/stage.enum';

@Component({
  selector: 'app-item-cost-expense-report',
  templateUrl: './item-cost-expense-report.component.html',
  styleUrls: ['./item-cost-expense-report.component.scss']
})
export class ItemCostExpenseReportComponent extends BaseNavigableComponent<ItemsCostExpensesReport> {

  data: ItemsCostExpensesReport = new ItemsCostExpensesReport();
  functionality: FunctionalityInfo = Functionality.itemCostExpenseReport;
  inconsistencies: Array<Inconsistency>;
  stageEJA: number = StageEnum.eja;
  shiftIntegral: number = ShiftEnum.Integral;
  columnsReport: Array<ColumnReport> = new Array<ColumnReport>();

  constructor(private injector: Injector, private itemCostExpenseReportService: ItemCostExpenseReportService) {
    super(injector);
  }

  processData(): Observable<ItemsCostExpensesReport> {
    return this.itemCostExpenseReportService.getItemCostExpenseReport();
  }

  processSpecificBehaviors(): void {
    this.itemCostExpenseReportService.getColumnsReport().pipe(
      takeUntil(this.unsubscribe))
      .subscribe(columnsReport => {
        this.columnsReport = columnsReport;
      });
  }

  downloadCsv(): void {
    this.itemCostExpenseReportService.downloadCsv(this.data).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe();
  }

  getPreviousStepLabel(): string {
    return 'Voltar';
  }

}
