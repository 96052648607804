import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { StaffNumber } from './entities/staff-number';
import { StaffNumbersService } from './service/staff-numbers.service';

@Component({
  selector: 'app-staff-number',
  templateUrl: './staff-number.component.html',
  styleUrls: ['./staff-number.component.scss']

})

export class StaffNumberComponent extends BaseNavigableComponent<StaffNumber> {

  data: StaffNumber = new StaffNumber();
  functionality: FunctionalityInfo = Functionality.staffNumber;
  inconsistencies: Array<Inconsistency>;

  constructor(private staffNumberService: StaffNumbersService, private injector: Injector) {
    super(injector);
  }

  processData(): Observable<StaffNumber> {
    return this.staffNumberService.getData();
  }

  processSpecificBehaviors(): void {
    // preenche o diagnóstico
    this.staffNumberService.getDiagnostic(this.data).pipe(
      takeUntil(this.unsubscribe))
      .subscribe();
  }

}
