export class TeacherFormation {

  sequence: string;
  denomination: string;
  idFormationLevel: number;
  descriptionFormationLevel: string;
  journeyNoInteraction: number;
  journeyTotal: number;
  journeyWithInteraction: number;

  constructor(init?: Partial<TeacherFormation>) {
    Object.assign(this, init);
  }
}
