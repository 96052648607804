
export class CostDriveByYear {

  year: number;
  value_shift_integral: number;
  value_shift_partial: number;

  constructor(init?: Partial<CostDriveByYear>) {
    Object.assign(this, init);
  }
}
