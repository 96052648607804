import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Inconsistency } from '../../../../../shared/components/inconsistency/entities/inconsistency';

@Component({
  selector: 'app-inconsistency-confirm',
  templateUrl: './inconsistency-confirm.component.html',
  styleUrls: ['./inconsistency-confirm.component.scss']
})
export class InconsistencyConfirmComponent implements OnInit {

  inconsistencies: Array<Inconsistency>;
  confirmed: Observable<boolean>;

  private confirmedMessenger: Subject<boolean> = new Subject<boolean>();

  constructor(public bsModalRef: BsModalRef) {
    this.confirmed = this.confirmedMessenger.asObservable();
  }

  ngOnInit() {
  }

  yes(): void {
    this.confirmedMessenger.next(true);
    this.bsModalRef.hide();
  }

  no(): void {
    this.confirmedMessenger.next(false);
    this.bsModalRef.hide();
  }

  hide(): void {
    this.confirmedMessenger.next(false);
    this.bsModalRef.hide();
  }

}
