export class BudgetForecastReportByYear {

  year: number;
  value: number;
  percentValue: number;

  constructor(init?: Partial<BudgetForecastReportByYear>) {
    Object.assign(this, init);
  }
}
