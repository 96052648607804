import { NavigableComponentData } from '../../shared/entities/base/navigable-component-data';

export class SimulatorItemData {

    key: string;
    data: NavigableComponentData;

    constructor(init?: Partial<SimulatorItemData>) {
        Object.assign(this, init);
    }
}
