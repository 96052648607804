import { Injectable } from '@angular/core';

import { StorageType } from './entities/enums/storage-type.enum';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private readonly keyPreffix: string = 'simcaq.';
  private readonly tokenSessionKey: string = 'auth.token';
  private readonly defaultStorageType: StorageType = StorageType.localStorage;

  constructor() { }

  getItem<T>(key: string, storageType: StorageType = this.defaultStorageType): T {
    return JSON.parse(window[storageType].getItem(this.getKey(key))) as T;
  }

  setItem(key: string, value: any, storageType: StorageType = this.defaultStorageType): void {
    window[storageType].setItem(this.getKey(key), JSON.stringify(value));
  }

  removeItem(key: string, storageType: StorageType = this.defaultStorageType): void {
    window[storageType].removeItem(this.getKey(key));
  }

  clear(namespaceKey: string = '', storageType: StorageType = this.defaultStorageType): void {
    if (namespaceKey === 'simulation') {
      const tokenKey: string = `${this.keyPreffix}${this.tokenSessionKey}`;
      const tokenValue: string = window[storageType].getItem(tokenKey);

      window[storageType].clear();

      if (tokenValue) {
        window[storageType].setItem(tokenKey, tokenValue);
      }
    } else {
      const keyPreffix: string = `${this.keyPreffix}${namespaceKey}`;

      for (let i = window[storageType].length - 1; i >= 0; i--) {
        const key = window[storageType].key(i);

        if (key.startsWith(keyPreffix)) {
          window[storageType].removeItem(key);
        }
      }
    }
  }

  private getKey(key: string): string {
    return `${this.keyPreffix}${key}`;
  }

}
