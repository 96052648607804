export enum OperationType {
    enrollment = 1,
    school = 2,
    teacher = 3,
    classroom = 4,
    population = 5,
    pibPerCapita = 6,
    idhm = 7,
    siope = 8,
    infrastructure = 9,
    pqr = 10
}
