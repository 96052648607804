import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

import { SessionService } from '../../../../shared/services/session/session.service';
import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { EnrollmentProjectionService } from './enrollment-projection.service';
import { EnrollmentProjection } from '../entities/enrollment-projection';
import { Location } from '../../../../shared/entities/location';
import { CalculateClassNumberService } from './../../../shared/services/calculate-class-number/calculate-class-number.service';
import { CreateProjectionsService } from './../../../shared/services/create-projections/create-projections.service';
import { NumberStudentClass } from './../../../quality-conditions/number-student-class/entities/number-student-class';
import { EnrollmentAndClassAndClassroom } from './../../../results/financing-funds-report/entities/enrollment-and-class-and-classroom';
import { OfferGoalEnrollmentFullTime } from './../../../quality-conditions/offer-goal-enrollment-full-time/entities/offer-goal-enrollment-full-time';
import { UtilitiesService } from './../../../../shared/services/utilities/utilities.service';
import { SelectLocation } from './../../../select-location/entities/select-location';
import { Stage } from './../../../../shared/entities/stage';
import { StageAndSeries } from './../../../../shared/entities/stage-and-series';
import { ClassNumberByStage } from './../../../shared/services/calculate-class-number/entities/class-number-by-stage';
import { ClassNumberByLocation } from './../../../shared/services/calculate-class-number/entities/class-number-by-location';
import { ClassNumber } from './../../../shared/services/calculate-class-number/entities/class-number';
import { StageEnrollments } from '../../../access-and-offer/enrollment-projection/entities/stage-enrollments';
import { EnrollmentProjectionByLocation } from './../entities/enrollment-projection-by-location';
import { StageEnum } from './../../../../shared/entities/enums/stage.enum';
import { WorkJourneyTeacher } from './../../../quality-conditions/work-journey-teacher/entities/work-journey-teacher';
import { CareerAndRemunerationTeachers } from './../../../quality-conditions/career-and-remuneration-teachers/entities/career-and-remuneration-teachers';
import { DailyTeachingLoad } from './../../../quality-conditions/daily-teaching-load/entities/daily-teaching-load';
import { SchoolDayPerWeek } from './../../../quality-conditions/school-day-per-week/entities/school-day-per-week';
import { CreateTeacherNumberCalcService } from './../../../shared/services/create-teacher-number-calc/create-teacher-number-calc.service';
import { TeacherNumberCalc } from 'app/simulator/shared/services/calculate-teacher-number/entities/teacher-number-calc';
import { TeacherNumberByStageCalc } from './../../../shared/services/calculate-teacher-number/entities/teacher-number-by-stage-calc';
import { TeacherNumberByLocationCalc } from './../../../shared/services/calculate-teacher-number/entities/teacher-number-by-location-calc';

@Injectable({
  providedIn: 'root'
})
export class EnrollmentProjectionByLocationService {

  constructor(private enrollmentProjectionService: EnrollmentProjectionService,
    private sessionService: SessionService,
    private utilitiesService: UtilitiesService,
    private createProjectionsService: CreateProjectionsService,
    private calculateClassNumberService: CalculateClassNumberService,
    private createTeacherNumberCalcService: CreateTeacherNumberCalcService
  ) { }

  getEnrollmentByStagesSeriesByLocation(locations: Array<Location>, stages: Array<Stage> = undefined, dimsBrasilByState: boolean = false,
    sphereAdm: number = undefined
  ): Observable<EnrollmentProjection> {
    const resultsEnrollmentProjectionByLocation: EnrollmentProjection = this.sessionService.getItem<EnrollmentProjection>(Functionality.enrollmentProjectionByLocation.key);
    const resultForSelectLocation: SelectLocation = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);
    const resultsEnrollmentAndClassAndClassroom: EnrollmentAndClassAndClassroom = this.sessionService.getItem<EnrollmentAndClassAndClassroom>(Functionality.enrollmentAndClassAndClassroom.key);

    if (resultForSelectLocation.selectedCity) { // municípios utilizam a tela de projeção
      if (resultsEnrollmentProjectionByLocation) {
        return of(resultsEnrollmentProjectionByLocation);
      } else {
        return this.enrollmentProjectionService.getData(locations).pipe(
          map(enrollmentsProjectionsByLocations => {
            this.sessionService.setItem(Functionality.enrollmentProjectionByLocation.key, enrollmentsProjectionsByLocations);
            return enrollmentsProjectionsByLocations;
          }));
      }
    } else { // estados e Brasil utilizam a rota
      if (resultsEnrollmentAndClassAndClassroom) {
        return of(resultsEnrollmentAndClassAndClassroom.enrollmentProjection);
      } else {
        return this.getEnrollmentAndClassAndClassroom(dimsBrasilByState).pipe(
          map(enrollmentAndClassAndClassroom => {
            this.sessionService.setItem(Functionality.enrollmentAndClassAndClassroom.key, _.first(enrollmentAndClassAndClassroom));
            return _.first(enrollmentAndClassAndClassroom).enrollmentProjection;
          }));
      }
    }
  }

  getEnrollmentAndClassAndClassroom(dimsBrasilByState: boolean = false): Observable<Array<EnrollmentAndClassAndClassroom>> {

    const resultForOffergoalenrollmentfulltime: OfferGoalEnrollmentFullTime = this.sessionService.getItem<OfferGoalEnrollmentFullTime>(Functionality.offerGoalEnrollmentFullTime.key);
    const resultForNumberstudentclass: NumberStudentClass = this.sessionService.getItem<NumberStudentClass>(Functionality.numberStudentClass.key);
    const enrollmentAndClassAndClassrooms: Array<EnrollmentAndClassAndClassroom> = new Array<EnrollmentAndClassAndClassroom>();
    const resultForSchoolDayPerWeek: SchoolDayPerWeek = this.sessionService.getItem<SchoolDayPerWeek>(Functionality.schoolDayPerWeek.key);
    const resultForDailyTeachingLoad: DailyTeachingLoad = this.sessionService.getItem<DailyTeachingLoad>(Functionality.dailyTeachingLoad.key);
    let resultForCareerAndRemunerationTeachers: CareerAndRemunerationTeachers = this.sessionService.getItem<CareerAndRemunerationTeachers>(Functionality.careerAndRemunerationTeachers.key);
    const resultForWorkJourneyTeacher: WorkJourneyTeacher = this.sessionService.getItem<WorkJourneyTeacher>(Functionality.workJourneyTeacher.key);

    if (!resultForCareerAndRemunerationTeachers) {
      resultForCareerAndRemunerationTeachers = this.sessionService.getItem<CareerAndRemunerationTeachers>(Functionality.careerAndRemunerationTeachers.pqrKey);
    }

    return this.utilitiesService.getClassAndClassroomsAndEnrollment(resultForNumberstudentclass, resultForOffergoalenrollmentfulltime, resultForSchoolDayPerWeek, resultForDailyTeachingLoad,
      resultForCareerAndRemunerationTeachers, resultForWorkJourneyTeacher, dimsBrasilByState, false).pipe(
        map(classAndClassroomNumberAndEnrollment => {

          for (let i = 0; i < classAndClassroomNumberAndEnrollment.length; i++) {

            const classAndClassroomNumberAndEnrollmentByLocations = classAndClassroomNumberAndEnrollment[i].locations;

            const enrollmentAndClassAndClassroom: EnrollmentAndClassAndClassroom = new EnrollmentAndClassAndClassroom({
              state_id: classAndClassroomNumberAndEnrollment[i].state_id ? classAndClassroomNumberAndEnrollment[i].state_id : undefined,
              state_description: classAndClassroomNumberAndEnrollment[i].state_name ? classAndClassroomNumberAndEnrollment[i].state_name : undefined,
              enrollmentProjection: this.createProjectionsService.getEnrollmentProjection(classAndClassroomNumberAndEnrollment[i]),
              classNumber: this.calculateClassNumberService.getClassNumberCalculated(classAndClassroomNumberAndEnrollmentByLocations),
              teacherNumber: this.createTeacherNumberCalcService.getTeacherNumberCalculated(classAndClassroomNumberAndEnrollmentByLocations)
            });

            enrollmentAndClassAndClassrooms.push(enrollmentAndClassAndClassroom);
          }
          return enrollmentAndClassAndClassrooms;
        }));
  }

  getEmptyEnrollmentAndClassAndClassroom(locations: Array<Location>, stages: Array<Stage>, stagesAndSeries: Array<StageAndSeries>): EnrollmentAndClassAndClassroom {

    return new EnrollmentAndClassAndClassroom({
      enrollmentProjection: this.createProjectionsService.getEmptyEnrollmentProjection(locations, stages),
      classNumber: this.calculateClassNumberService.getEmptyClassNumber(locations, stagesAndSeries),
      teacherNumber: this.createTeacherNumberCalcService.getEmptyTeacherNumberCalculated(locations, stagesAndSeries)
    });
  }

  sumResultOfEnrollmentAndClassAndClassroom(emptyEnrollmentAndClassAndClassroom: EnrollmentAndClassAndClassroom,
    enrollmentAndClassAndClassroom: Array<EnrollmentAndClassAndClassroom>) {

    // Localidades do array vazio
    for (let i = 0; i < emptyEnrollmentAndClassAndClassroom.enrollmentProjection.enrollmentsProjectionsByLocations.length; i++) {
      const enrollmentEmptyProjectionByLocation = emptyEnrollmentAndClassAndClassroom.enrollmentProjection.enrollmentsProjectionsByLocations[i];
      const enrollmentsProjectionsByLocations: Array<EnrollmentProjectionByLocation> = new Array<EnrollmentProjectionByLocation>();

      // Localidade do array retornado pela rota
      for (let j = 0; j < enrollmentAndClassAndClassroom.length; j++) {
        enrollmentsProjectionsByLocations.push(enrollmentAndClassAndClassroom[j].enrollmentProjection.enrollmentsProjectionsByLocations
          .find(enrollmentProjectionByLocation => enrollmentProjectionByLocation.id === enrollmentEmptyProjectionByLocation.id));
      }

      // varre cada etapa do array vazio preenchendo com as matrículas da rota
      for (let k = 0; k < enrollmentEmptyProjectionByLocation.stagesEnrollments.length; k++) {
        const emptyStageEnrollment = enrollmentEmptyProjectionByLocation.stagesEnrollments[k];

        const stagesEnrollments: Array<StageEnrollments> = new Array<StageEnrollments>();
        // cria um array com etapas iguais
        for (let l = 0; l < enrollmentsProjectionsByLocations.length; l++) {
          stagesEnrollments.push(enrollmentsProjectionsByLocations[l].stagesEnrollments.find(stageEnrollment => stageEnrollment.id === emptyStageEnrollment.id));
        }
        emptyStageEnrollment.totalCurrentOffers += stagesEnrollments.reduce((total, stage) => stage.totalCurrentOffers + total, 0);

        // varre o array com etapas iguais somando as matrículas
        for (let l = 0; l < stagesEnrollments.length; l++) {

          for (let m = 0; m < stagesEnrollments[l].totalEnrollments.length; m++) {
            const totalEnrollment = stagesEnrollments[l].totalEnrollments[m];
            const emptyTotalEnrollment = emptyStageEnrollment.totalEnrollments.find(e => e.year === totalEnrollment.year);
            const emptyTotalEnrollmentProjection = enrollmentEmptyProjectionByLocation.totalsEnrollmentProjection.find(e => e.year === totalEnrollment.year);

            emptyTotalEnrollment.quantity += totalEnrollment.quantity;
            emptyTotalEnrollment.quantityNocturnal += totalEnrollment.quantityNocturnal;
            emptyTotalEnrollmentProjection.quantity += totalEnrollment.quantity;
            emptyTotalEnrollmentProjection.quantityNocturnal += totalEnrollment.quantityNocturnal;
          }
        }
      }
    }
    this.sessionService.setItem(Functionality.enrollmentAndClassAndClassroom.key, emptyEnrollmentAndClassAndClassroom);
  }

  sumResultOfClassNumber(emptyClassNumber: ClassNumber,
    enrollmentAndClassAndClassroom: Array<EnrollmentAndClassAndClassroom>) {

    // Localidades do array vazio
    for (let i = 0; i < emptyClassNumber.classesNumberByLocations.length; i++) {
      const emptyClassNumberByLocation = emptyClassNumber.classesNumberByLocations[i];
      const classesNumberByLocations: Array<ClassNumberByLocation> = new Array<ClassNumberByLocation>();

      // Localidade do array retornado pela rota
      for (let j = 0; j < enrollmentAndClassAndClassroom.length; j++) {
        classesNumberByLocations.push(enrollmentAndClassAndClassroom[j].classNumber.classesNumberByLocations
          .find(cN => cN.id === emptyClassNumberByLocation.id));
      }

      // varre cada etapa do array vazio preenchendo com as matrículas da rota
      for (let k = 0; k < emptyClassNumberByLocation.classesNumberByStages.length; k++) {
        const emptyClassNumberByStage = emptyClassNumberByLocation.classesNumberByStages[k];

        const classesNumberByStages: Array<ClassNumberByStage> = new Array<ClassNumberByStage>();
        // cria um array com etapas iguais
        for (let l = 0; l < classesNumberByLocations.length; l++) {
          classesNumberByStages.push(classesNumberByLocations[l].classesNumberByStages.find(c => c.id === emptyClassNumberByStage.id));
        }

        if (emptyClassNumberByStage.id === StageEnum.creche) {
          for (let l = 0; l < classesNumberByStages.length; l++) {
            for (let m = 0; m < classesNumberByStages[l].classNumberBySerie.length; m++) {
              const classNumberBySerie = classesNumberByStages[l].classNumberBySerie[m];
              for (let o = 0; o < classNumberBySerie.classesNumberByYear.length; o++) {
                const classNumberByYear = classNumberBySerie.classesNumberByYear[o];
                const emptyClassNumberBySerie = emptyClassNumberByStage.classNumberBySerie.find(s => s.id === classNumberBySerie.id);
                const emptyClassNumberByYear = emptyClassNumberBySerie.classesNumberByYear.find(e => e.year === classNumberByYear.year);
                emptyClassNumberByYear.classNumber += classNumberByYear.classNumber;
                emptyClassNumberByYear.classNumberDaytimePartial += classNumberByYear.classNumberDaytimePartial;
                emptyClassNumberByYear.classNumberIntegral += classNumberByYear.classNumberIntegral;
                emptyClassNumberByYear.classNumberNocturnal += classNumberByYear.classNumberNocturnal;
                emptyClassNumberByYear.classroomDemand += classNumberByYear.classroomDemand;
              }
            }
          }
        }

        // varre o array com etapas iguais somando as matrículas
        for (let l = 0; l < classesNumberByStages.length; l++) {

          for (let m = 0; m < classesNumberByStages[l].classesNumberByYear.length; m++) {
            const classNumberByYear = classesNumberByStages[l].classesNumberByYear[m];
            const emptyClassNumberByYear = emptyClassNumberByStage.classesNumberByYear.find(e => e.year === classNumberByYear.year);
            emptyClassNumberByYear.classNumber += classNumberByYear.classNumber;
            emptyClassNumberByYear.classNumberDaytimePartial += classNumberByYear.classNumberDaytimePartial;
            emptyClassNumberByYear.classNumberIntegral += classNumberByYear.classNumberIntegral;
            emptyClassNumberByYear.classNumberNocturnal += classNumberByYear.classNumberNocturnal;
            emptyClassNumberByYear.classroomDemand += classNumberByYear.classroomDemand;
          }
        }
      }
    }
  }

  sumResultOfTeacherNumber(emptyTeacherNumber: Array<TeacherNumberCalc>,
    enrollmentAndClassAndClassroom: Array<EnrollmentAndClassAndClassroom>) {

    const emptyTeacherNumberCalc = _.first(emptyTeacherNumber);

    for (let i = 0; i < emptyTeacherNumberCalc.teachersNumberByLocationsCalc.length; i++) {
      const emptyTeacherNumberLocation = emptyTeacherNumberCalc.teachersNumberByLocationsCalc[i];
      const teachersNumberByLocationsCalc: Array<TeacherNumberByLocationCalc> = new Array<TeacherNumberByLocationCalc>();

      for (let j = 0; j < enrollmentAndClassAndClassroom.length; j++) {
        const teacherNumberCalc = enrollmentAndClassAndClassroom[j].teacherNumber;

        const eTN = _.first(teacherNumberCalc).teachersNumberByLocationsCalc.find(tN => tN.id === emptyTeacherNumberLocation.id);
        teachersNumberByLocationsCalc.push(eTN);
      }

      for (let k = 0; k < emptyTeacherNumberLocation.teachersNumberByStagesCalc.length; k++) {
        const emptyTeacherNumberByStage = emptyTeacherNumberLocation.teachersNumberByStagesCalc[k];

        const teacherNumberByStages: Array<TeacherNumberByStageCalc> = new Array<TeacherNumberByStageCalc>();

        for (let l = 0; l < teachersNumberByLocationsCalc.length; l++) {
          teacherNumberByStages.push(teachersNumberByLocationsCalc[l].teachersNumberByStagesCalc.find(c => c.id === emptyTeacherNumberByStage.id));
        }

        for (let l = 0; l < teacherNumberByStages.length; l++) {

          for (let m = 0; m < teacherNumberByStages[l].careerLevelsByYear.length; m++) {
            const teacherNumberByYear = teacherNumberByStages[l].careerLevelsByYear[m];
            const emptyTeacherNumberByYear = emptyTeacherNumberByStage.careerLevelsByYear.find(e => e.year === teacherNumberByYear.year);
            emptyTeacherNumberByYear.teacherNumberTotal += teacherNumberByYear.teacherNumberTotal;
            emptyTeacherNumberByYear.teacherNumberShiftPartial += teacherNumberByYear.teacherNumberShiftPartial;
            emptyTeacherNumberByYear.teacherNumberShiftIntegral += teacherNumberByYear.teacherNumberShiftIntegral;

            for (let n = 0; n < teacherNumberByYear.careerLevels.length; n++) {
              const careerLevel = teacherNumberByYear.careerLevels[n];
              const emptyCareerLevel = emptyTeacherNumberByYear.careerLevels.find(c => c.sequence === careerLevel.sequence);
              emptyCareerLevel.quantity += careerLevel.quantity;
              emptyCareerLevel.totalTeacherPartialCareer += careerLevel.totalTeacherPartialCareer;
              emptyCareerLevel.totalTeacherFullPeriodCareer += careerLevel.totalTeacherFullPeriodCareer;
            }
          }
        }
      }
    }
  }
}
