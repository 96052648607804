import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { ProjectionNumberOfTeachers } from '../entities/projection-number-of-teachers';
import { TeacherDistributionCareerLevel } from '../entities/teacher-distribution-career-level';
import { TeacherFormationLevel } from '../entities/teacher-formation-level';
import { CareerAndRemunerationTeachers } from '../entities/career-and-remuneration-teachers';
import { SessionService } from '../../../../shared/services/session/session.service';
import { TeacherNumber } from '../../teacher-number/entities/teacher-number';
import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { TeacherNumberByStage } from '../../teacher-number/entities/teacher-number-by-stage';
import { TeacherFormationLevels } from '../entities/teacher-formation-levels';
import { NavigableComponentService } from '../../../shared/entities/base/navigable-component-service';
import { SourceInformationEnum } from './../../../../shared/entities/enums/source-information.enum';
import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { HttpService } from '../../../../shared/services/http/http.service';
import { CurrentYearService } from '../../../shared/services/current-year/current-year.service';
import { FormationLevelEnum } from './../../../../shared/entities/enums/formation-level.enum';
import { WorkJourneyTeacher } from '../../work-journey-teacher/entities/work-journey-teacher';
import { TipoDependencia } from 'app/shared/entities/enums/tipo-dependencia';

@Injectable({
  providedIn: 'root'
})
export class CareerAndRemunerationTeachersService implements NavigableComponentService {

  constructor(private httpService: HttpService, private utilitiesService: UtilitiesService, private sessionService: SessionService, private currentYearService: CurrentYearService) { }

  getData(pqrMode: boolean = false): Observable<CareerAndRemunerationTeachers> {

    const simulationYears = this.getSimulationYears();
    const careerAndRemunerationTeachers: CareerAndRemunerationTeachers = new CareerAndRemunerationTeachers({ years: simulationYears });
    const workJourneyTeacher: WorkJourneyTeacher = this.sessionService.getItem<WorkJourneyTeacher>(Functionality.workJourneyTeacher.key);
    const workJourneyTeacherPQR: WorkJourneyTeacher = this.sessionService.getItem<WorkJourneyTeacher>(Functionality.workJourneyTeacher.pqrKey);

    // careerAndRemunerationTeachers.journeyTotal = workJourneyTeacher ? workJourneyTeacher.journeyTotal : 0;
    // careerAndRemunerationTeachers.journeyTotalPQR = workJourneyTeacherPQR.journeyTotal;

    return this.utilitiesService.getFormationLevels().pipe(
      mergeMap(formationLevels => {

        careerAndRemunerationTeachers.formationLevels = formationLevels;

        // Fonte:
        careerAndRemunerationTeachers.sourceInformation = new Footnote({ indice: 1, sourceInformation: SourceInformationEnum.teacher });

        careerAndRemunerationTeachers.sourceNotes = new Array<Footnote>(
          // new Footnote({
          //   indice: 1,
          //   note: 'No nível de formação "Médio" foram somados os docentes com os seguintes níveis de formação: ‘Ensino Fundamental completo’, ' +
          //     '‘Ensino Fundamental incompleto’, ‘Ensino Médio’ e ‘Ensino Médio na modalidade normal’.'
          // }),
          new Footnote({
            indice: 2,
            note: 'A contagem do número de professores considera os profissionais informados no Censo Escolar nas funções de ‘Docente’ e de ‘Docente Titular’ - ' +
              'coordenador de tutoria (de módulo ou disciplina) – EaD. São considerados apenas os professores que atuam em turmas cujo tipo de atendimento não seja ' +
              'de Atividade Complementar ou de Atendimento Educacional Especializado (AEE). Os docentes são contados uma única vez em cada Município, portanto, o total ' +
              'não representa a soma das etapas de ensino, pois o mesmo docente pode atuar em mais de uma unidade de agregação.'
          })
        );

        careerAndRemunerationTeachers.currentYear = this.currentYearService.getTeacherCurrentYear();

        return this.getTeacherFormationLevels().pipe(
          mergeMap(teacherFormationLevels => {
            if (simulationYears.length !== UtilitiesService.maxSimulationTime) {
              const quantityOfItemsToBeRemoved: number = UtilitiesService.maxSimulationTime - simulationYears.length;

              for (const teacherFormationLevel of teacherFormationLevels.levels) {
                teacherFormationLevel.percTeacherDistributionCareerLevel.splice(-quantityOfItemsToBeRemoved, quantityOfItemsToBeRemoved);
              }
            }

            // alteração para ufg - deixar somente o níve de formção "Superior"
            if (teacherFormationLevels.levels.length > 1) {
              teacherFormationLevels.levels.splice(0, 1);
              teacherFormationLevels.levels.splice(1, 1);
              teacherFormationLevels.levels.splice(1, 1);
              teacherFormationLevels.levels.splice(1, 1);
              teacherFormationLevels.levels[0].denomination = 'P1';
              teacherFormationLevels.levels[0].sequence = 'P1';
            }
            // ******************** */

            // issue #614
            // if (!pqrMode) {
            //   for (const teacherFormationLevel of teacherFormationLevels.levels) {
            //     teacherFormationLevel.grossMonthlyRemuneration =
            //       this.utilitiesService.roundNumber(
            //         (teacherFormationLevel.grossMonthlyRemuneration * (careerAndRemunerationTeachers.journeyTotal * 100 / careerAndRemunerationTeachers.journeyTotalPQR)) / 100, 2);
            //   }
            // }

            careerAndRemunerationTeachers.teacherFormationLevels = teacherFormationLevels;
            if (!pqrMode) {
              return this.getDiagnostic().pipe(
                map((diagnostics) => {
                  this.setPercTeacherDistributionCareerLevel(careerAndRemunerationTeachers, diagnostics);
                  return careerAndRemunerationTeachers;
                }));
            } else {
              return of(careerAndRemunerationTeachers);
            }
          }));
      }));
  }

  getDiagnostic(dimState: boolean = false): Observable<any> {

    // const teacherCurrentYear: number = this.currentYearService.getTeacherCurrentYear();
    // const filtersLocation: Array<string> = this.utilitiesService.getSelectLocationFilter();

    // let filters: Array<string>;
    // let options: any;

    // filters = new Array<string>(
    //   `min_year:"${teacherCurrentYear}"`,
    //   `max_year:"${teacherCurrentYear}"`
    // );

    // filters.push(this.utilitiesService.getAdmDependencyFilter());

    // if (!dimState) {
    //   filters = filters.concat(filtersLocation);

    //   options = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([
    //     ['dims', 'education_type'],
    //     ['filter', filters.join(',')]
    //   ]));
    // } else {
    //   options = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([
    //     ['dims', 'state,education_type'],
    //     ['filter', filters.join(',')]
    //   ]));
    // }

    // return this.httpService.getApiEndpoint().pipe(
    //   switchMap(apiEndpoint => {
    //     return this.httpService.get<Array<any>>(`${apiEndpoint}/teacher`, options).pipe(
    //       map(diagnostics => { 
    //         console.log("diagnostics-c3", diagnostics)
    //         return diagnostics; 
    //       }));
    //   }));
    const tipoDependencia = this.utilitiesService.getTipoDependenciaUFG();

    return this.httpService.getApiEndpointUFG().pipe(
      switchMap(apiEndpoint => {

        return this.httpService.get<any>(`${apiEndpoint}/quantitativo-docentes/${tipoDependencia}`).pipe(
          map(diagnostics => {

            const result = [{ 'total': diagnostics.content.quantidade, 'name': 'Brasil', 'year': diagnostics.content.ano, 'education_type_id': 6, 'education_type_name': 'Superior' }];
            return result
              ;
          }));
      }));

  }

  getNewTeacherFormationLevel(currentSequence: number): TeacherFormationLevel {
    const simulationYears = this.getSimulationYears();
    return new TeacherFormationLevel({
      sequence: (currentSequence + 1).toString(),
      percTeacherDistributionCareerLevel: simulationYears.map(year => new TeacherDistributionCareerLevel({ year: year }))
    });
  }

  removeTeacherFormationLevel(teacherFormationLevels: Array<TeacherFormationLevel>, sequence: string): void {
    let levelRemoved: boolean = false;
    let sequenceCount: number = teacherFormationLevels.length - 1;

    for (let i = teacherFormationLevels.length - 1; i >= 0; i--) {
      const teacherFormationLevel = teacherFormationLevels[i];

      if (!levelRemoved && teacherFormationLevel.sequence === sequence) {
        teacherFormationLevels.splice(i, 1);
        levelRemoved = true;
      } else {
        teacherFormationLevel.sequence = sequenceCount.toString();
        sequenceCount--;
      }
    }
  }

  processTotals(careerAndRemunerationTeachers: CareerAndRemunerationTeachers): void {

    careerAndRemunerationTeachers.totals = new Array<TeacherDistributionCareerLevel>();

    for (const year of careerAndRemunerationTeachers.years) {
      const teacherFormationLevelOfYear: TeacherFormationLevel = this.getTeacherFormationLevelOfYear(careerAndRemunerationTeachers, year);

      const totalPercentageOfYear: number =
        this.utilitiesService.roundNumber((teacherFormationLevelOfYear.percTeacherDistributionCareerLevel.map((percDistributionLevel: TeacherDistributionCareerLevel) =>
          percDistributionLevel.percentage).reduce((a, b) => {
            return a + b;
          })), 2);

      careerAndRemunerationTeachers.totals.push(new TeacherDistributionCareerLevel({ year: year, percentage: totalPercentageOfYear * 1e0 / 1e0 }));
    }
  }

  // getProjectionOfTheNumberOfTeachers(): Array<ProjectionNumberOfTeachers> {
  //   const projectionsNumberOfTeachers: Array<ProjectionNumberOfTeachers> = new Array<ProjectionNumberOfTeachers>();
  //   const resultsForTeacherNumber: TeacherNumber = this.sessionService.getItem<TeacherNumber>(Functionality.teacherNumber.key);
  //   let totalTeacherNumber: TeacherNumberByStage;

  //   totalTeacherNumber = resultsForTeacherNumber ? _.last(resultsForTeacherNumber.resultForTeacherNumber) : new TeacherNumberByStage();
  //   if (totalTeacherNumber.teacherNumberByYear) {
  //     for (const totalTeacher of totalTeacherNumber.teacherNumberByYear) {
  //       projectionsNumberOfTeachers.push(new ProjectionNumberOfTeachers({ year: totalTeacher.year, quantity: totalTeacher.value }));
  //     }
  //   } else {
  //     const simulationYears = this.getSimulationYears();

  //     simulationYears.map(year => {
  //       projectionsNumberOfTeachers.push(new ProjectionNumberOfTeachers({ year: year, quantity: 0 }));
  //     });
  //   }

  //   return projectionsNumberOfTeachers;
  // }

  getTeacherFormationLevelsInObject(teacherFormationLevels: Array<TeacherFormationLevel>): Array<TeacherFormationLevel> {
    return teacherFormationLevels.map(teacherFormationLevel => new TeacherFormationLevel({
      sequence: teacherFormationLevel.sequence,
      denomination: teacherFormationLevel.denomination,
      formationLevel: teacherFormationLevel.formationLevel,
      journeyTotal: teacherFormationLevel.journeyTotal,
      journeyNoInteraction: teacherFormationLevel.journeyNoInteraction,
      journeyWithInteraction: teacherFormationLevel.journeyWithInteraction,
      initialTeacherPercentage: teacherFormationLevel.initialTeacherPercentage,
      grossMonthlyRemuneration: teacherFormationLevel.grossMonthlyRemuneration,
      percTeacherDistributionCareerLevel: teacherFormationLevel.percTeacherDistributionCareerLevel,
      referenceDate: teacherFormationLevel.referenceDate,
      diagnostic: teacherFormationLevel.diagnostic
    }));
  }

  setPercTeacherDistributionCareerLevel(data: CareerAndRemunerationTeachers, diagnostics: any) {

    const newDiagnostic: Array<any> = new Array<any>();
    let totalTeacherLevel: number = 0;
    this.clearDiagnosticAndPercTeacherDistributionCareerLevel(data);

    // alteração ufg
    data.currentYear = diagnostics[0].year;

    for (let i = 0; i < diagnostics.length; i++) {

      let educationTypeId: Number;

      if (diagnostics[i].education_type_id === 1 || diagnostics[i].education_type_id === 2 || diagnostics[i].education_type_id === 3 || diagnostics[i].education_type_id === 4) {
        educationTypeId = 2;
      } else if (diagnostics[i].education_type_id === 5 || diagnostics[i].education_type_id === 6) {
        educationTypeId = 4;
      } else if (diagnostics[i].education_type_id === 7 || diagnostics[i].education_type_id === 8) {
        educationTypeId = 6;
      } else if (diagnostics[i].education_type_id === 9 || diagnostics[i].education_type_id === 10) {
        educationTypeId = 7;
      } else if (diagnostics[i].education_type_id === 11 || diagnostics[i].education_type_id === 12) {
        educationTypeId = 8;
      }

      const obj = _.find(newDiagnostic, n => n.education_type_id === educationTypeId);
      if (obj === undefined) {
        newDiagnostic.push({ education_type_id: educationTypeId, total: diagnostics[i].total });
      } else {
        obj.total += diagnostics[i].total;
      }
      totalTeacherLevel += diagnostics[i].total;
    }

    if (data.teacherFormationLevels !== undefined) {

      for (let i = 0; i < newDiagnostic.length; i++) {
        const educationTypeId: number = newDiagnostic[i].education_type_id;
        const objData = _.find(data.teacherFormationLevels.levels, tfl => tfl.formationLevel.id === educationTypeId);
        if (objData !== undefined) {
          objData.diagnostic = newDiagnostic[i].total;
        }

        const tdc: Array<TeacherDistributionCareerLevel> = new Array<TeacherDistributionCareerLevel>();
        let percentageValue = this.utilitiesService.roundNumber(((objData.diagnostic * 100) / totalTeacherLevel), 1);
        let auxPercentageValue: number = 0;

        objData.initialTeacherPercentage = percentageValue;

        if (educationTypeId === 2) {
          auxPercentageValue = percentageValue;
          percentageValue = 0;
        } else if (educationTypeId === 4) {
          percentageValue += auxPercentageValue;
          auxPercentageValue = 0;
        }

        for (const year of data.years) {
          tdc.push({ year: year, percentage: percentageValue });
        }
        // data.teacherFormationLevels.levels[i].percTeacherDistributionCareerLevel = new Array<TeacherDistributionCareerLevel>();
        // data.teacherFormationLevels.levels[i].percTeacherDistributionCareerLevel = tdc;
        objData.percTeacherDistributionCareerLevel = tdc;
      }

      const total: Array<TeacherDistributionCareerLevel> = new Array<TeacherDistributionCareerLevel>();
      data.totals = total;

      this.verifyAndSetPercents(data);
      this.processTotals(data);
    }

  }

  populateDiagnostic(data: CareerAndRemunerationTeachers): Observable<any> {
    return this.getDiagnostic().pipe(
      map((diagnostics) => {
        this.setPercTeacherDistributionCareerLevel(data, diagnostics);
      }));
  }

  private getTeacherFormationLevels(): Observable<TeacherFormationLevels> {
    const teacherFormationLevelsPqrData: TeacherFormationLevels = this.sessionService.getItem<TeacherFormationLevels>(Functionality.careerAndRemunerationTeachers.pqrKey);
    teacherFormationLevelsPqrData.levels = this.getTeacherFormationLevelsInObject(teacherFormationLevelsPqrData.levels);

    return of(teacherFormationLevelsPqrData);
  }

  private getTeacherFormationLevelOfYear(careerAndRemunerationTeachers: CareerAndRemunerationTeachers, year: number): TeacherFormationLevel {

    if (careerAndRemunerationTeachers.teacherFormationLevels.levels.length > 1) {
      return careerAndRemunerationTeachers.teacherFormationLevels.levels.reduce((a, b) => {

        return new TeacherFormationLevel({
          percTeacherDistributionCareerLevel: a.percTeacherDistributionCareerLevel.filter(percentage => percentage.year === year)
            .concat(b.percTeacherDistributionCareerLevel.filter(percentage => percentage.year === year))
        });
      });
    }

    return new TeacherFormationLevel({
      percTeacherDistributionCareerLevel: careerAndRemunerationTeachers.teacherFormationLevels.levels[0].percTeacherDistributionCareerLevel.filter(percentage => percentage.year === year)
    });
  }

  private getSimulationYears(): Array<number> {
    return this.utilitiesService.getSimulationYears(!this.utilitiesService.isFullPqrRoute() ? 0 : UtilitiesService.maxSimulationTime);
  }

  private clearDiagnosticAndPercTeacherDistributionCareerLevel(data: CareerAndRemunerationTeachers) {

    const yearsSimulation: Array<number> = this.utilitiesService.getSimulationYears();

    for (let i = 0; i < data.teacherFormationLevels.levels.length; i++) {
      data.teacherFormationLevels.levels[i].diagnostic = 0;
      data.teacherFormationLevels.levels[i].percTeacherDistributionCareerLevel = yearsSimulation.map(yearSimulation =>
        new TeacherDistributionCareerLevel({ year: yearSimulation, percentage: 0 }));
    }
  }

  private verifyAndSetPercents(careerAndRemunerationTeachers: CareerAndRemunerationTeachers): void {

    for (const year of careerAndRemunerationTeachers.years) {
      const teacherFormationLevelOfYear: TeacherFormationLevel = this.getTeacherFormationLevelOfYear(careerAndRemunerationTeachers, year);

      const totalPercentageOfYear: number =
        this.utilitiesService.roundNumber(((teacherFormationLevelOfYear.percTeacherDistributionCareerLevel.map((percDistributionLevel: TeacherDistributionCareerLevel) =>
          percDistributionLevel.percentage).reduce((a, b) => {
            return a + b;
          }))), 2);

      if (totalPercentageOfYear !== 100) {

        let formation = _.find(careerAndRemunerationTeachers.teacherFormationLevels.levels, l => l.formationLevel.id === FormationLevelEnum.Superior);
        if (formation.diagnostic === 0) {
          formation = _.find(careerAndRemunerationTeachers.teacherFormationLevels.levels, l => l.formationLevel.id === FormationLevelEnum.Medio);
        }

        for (let i = 0; i < careerAndRemunerationTeachers.teacherFormationLevels.levels.length; i++) {
          const level = careerAndRemunerationTeachers.teacherFormationLevels.levels[i];

          for (let j = 0; j < level.percTeacherDistributionCareerLevel.length; j++) {
            const percentage: number = level.percTeacherDistributionCareerLevel[j].percentage;
            if (level.formationLevel.id === formation.formationLevel.id && percentage !== undefined) {
              level.percTeacherDistributionCareerLevel[j].percentage =
                totalPercentageOfYear < 100 ? percentage + (100 - totalPercentageOfYear) : percentage - (totalPercentageOfYear - 100);
            }
          }
        }
      }
    }
  }

}
