import { Component, OnInit } from '@angular/core';
import { BaseUnsubscribe } from 'app/shared/entities/base/base-unsubscribe';
import { AuthService } from 'app/shared/services/auth/auth.service';
import { UserInfo } from 'app/shared/services/auth/entities/user-info';
import { SessionService } from 'app/shared/services/session/session.service';
import { SimulationType } from 'app/simulator/simulator/entities/enums/simulation-type.enum';
import { SimulatorService } from 'app/simulator/simulator/services/simulator.service';
import { BsModalService } from 'ngx-bootstrap';
import { MatomoTracker } from 'ngx-matomo';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'caq',
  templateUrl: './caq.component.html',
  styleUrls: ['./caq.component.scss']
})

export class CaqComponent extends BaseUnsubscribe implements OnInit {


  userInfo: UserInfo = new UserInfo();

  readonly caq: SimulationType = SimulationType.caq;

  nomeHost: string = "";
  constructor(private simulatorService: SimulatorService, private authService: AuthService,
    private bsModalService: BsModalService, private matomoTracker: MatomoTracker, private sessionService: SessionService) {
      super();
  }

  ngOnInit() {

    this.nomeHost = window.location.hostname.toLowerCase()
    this.sessionService.clear();
    this.matomoTracker.setCustomUrl('/caq');
    this.matomoTracker.trackPageView();


    this.verifyUserInfo();

    this.authService.userLoggedObserver.pipe(
      takeUntil(this.unsubscribe))
      .subscribe(userIsLoggedIn => {
        if (userIsLoggedIn) {
          this.verifyUserInfo();
        } else {
          this.userInfo = new UserInfo();
        }
      });

  }

  private verifyUserInfo(): void {
    this.authService.getUserInfo().pipe(
      takeUntil(this.unsubscribe))
      .subscribe(userInfo => this.userInfo = userInfo);
  }

  startSimulation(simulationType: SimulationType): void {
    //if (simulationType === SimulationType.caq) {
      this.matomoTracker.trackEvent('simulation', 'click', 'Simulacao CAQ');
    //} else {
    //  this.matomoTracker.trackEvent('simulation', 'click', 'Simulacao Orçamento educacional');
    //}

    this.simulatorService.startSimulation(simulationType);
  }

}
