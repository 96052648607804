import { SerieEnrollmentsBySchool } from './serie-enrollments-by-school';

export class StageEnrollmentsBySchool {
    id: number;
    description: string;
    serieEnrollmentsBySchool: Array<SerieEnrollmentsBySchool>;
    totalEnrollmentDay: number;
    totalEnrollmentNight: number;
    totalEnrollmentCurrent: number;
    percentageIntegral: number;

    constructor(init?: Partial<StageEnrollmentsBySchool>) {
        Object.assign(this, init);
    }
}
