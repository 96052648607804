import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { PricesIndices } from './entities/prices-indices';
import { PriceIndicesService } from './services/price-indices.service';

@Component({
  selector: 'app-price-indices',
  templateUrl: './price-indices.component.html',
  styleUrls: ['./price-indices.component.scss']
})
export class PriceIndicesComponent extends BaseNavigableComponent<PricesIndices> {

  data: PricesIndices = new PricesIndices();
  functionality: FunctionalityInfo = Functionality.priceIndices;
  inconsistencies: Array<Inconsistency>;

  yearsSimulation: Array<Number>;

  constructor(private priceIndicesService: PriceIndicesService, private injector: Injector) {
    super(injector);
  }

  processData(): Observable<PricesIndices> {
    return this.priceIndicesService.getData();
  }

  processValidation(): void {
    super.processValidation();

    for (const indice of this.data.indices) {
      for (const year of indice.years) {

        if (isNaN(year.value) || year.value === undefined || year.value === null
          || year.value < 0 || year.value > 100) {
          this.inconsistencies.push(new Inconsistency({ message: `A porcentagem do ${indice.description} não é valido. Informe um valor de 0% a 100%.` }));
        }
      }
    }
  }
}
