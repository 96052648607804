import { Injectable } from '@angular/core';
import { Observable, forkJoin, of, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { NavigableComponentService } from '../../shared/entities/base/navigable-component-service';
import { CurrentYearService } from '../../shared/services/current-year/current-year.service';
import { HttpService } from '../../../shared/services/http/http.service';
import { SessionService } from '../../../shared/services/session/session.service';
import { UtilitiesService } from '../../../shared/services/utilities/utilities.service';
import { DescriptionValue } from '../entities/description-value';
import { Sociodemographic } from '../entities/sociodemographic';
import { State } from '../entities/state';
import { City } from '../entities/city';
import { PibPerCapita } from '../entities/pib-per-capita';
import { Idhm } from '../entities/idhm';
import { SelectedLocationInfo } from '../entities/selected-location-info';
import { SelectLocation } from '../entities/select-location';
import { LocationEnum } from '../../../shared/entities/enums/location.enum';
import { AdmDependencyAndLocation } from '../entities/adm-dependency-and-location';
import { QuantityByAdmDependencyAndLocation } from '../entities/quantity-by-adm-dependency-and-location';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { Footnote } from '../../../shared/components/footnote/entities/footnote';
import { SourceInformationEnum } from '../../../shared/entities/enums/source-information.enum';
import { DadosSocioDemograficos } from '../entities/dados-socio-demograficos';

@Injectable({
  providedIn: 'root'
})
export class SelectLocationService implements NavigableComponentService {

  selectedLocationObserver: Observable<string>;
  selectedSimulatioTypeObserver: Observable<string>;

  private selectedLocationMessenger: Subject<string> = new Subject<string>();
  private selectedSimulatioTypeMessenger: Subject<string> = new Subject<string>();

  private readonly pibPerCapitaPartialUrl: string = 'pibpercapita';
  private readonly idhmPartialUrl: string = 'idhm';

  constructor(private httpService: HttpService, private utilitiesService: UtilitiesService, private sessionService: SessionService, private currentYearService: CurrentYearService) {
    this.selectedLocationObserver = this.selectedLocationMessenger.asObservable();
    this.selectedSimulatioTypeObserver = this.selectedSimulatioTypeMessenger.asObservable();
  }

  getData(): Observable<SelectLocation> {
    const selectLocation: SelectLocation = new SelectLocation();
    return of(selectLocation);
  }

  getSelectedLocationInfo(selectLocation: SelectLocation): Observable<SelectedLocationInfo> {

    const selectedLocationInfo: SelectedLocationInfo = new SelectedLocationInfo();
    const enrollmentCurrentYear: number = this.currentYearService.getEnrollmentCurrentYear();
    const schoolCurrentYear: number = this.currentYearService.getSchoolCurrentYear();
    const state: State = selectLocation.selectedState;
    const city: City = selectLocation.selectedCity;
    const selectLocationObservables: Array<Observable<any>> = new Array<Observable<any>>();
    const localidadeSelecionada: string = this.getLocalidadeSelecionada(state, city);

    if (!city) {
      selectLocationObservables.push(this.getQuintis(state).pipe(
        switchMap(quintis => {
          return this.getDadosSocioDemograficos(state, city, quintis.levels).pipe(
            map(dadosSociodemograficos => {
              dadosSociodemograficos.pibPercapitaPorQuintil = quintis.levels;
              selectedLocationInfo.sociodemographic = this.getSociodemographic(state, city, dadosSociodemograficos);
              selectedLocationInfo.pibPerCapitaByLevel = this.getPerCapitaByLevel(state, city, dadosSociodemograficos);
              selectedLocationInfo.idhmByLevel = this.getIdhmByLevel(state, city, dadosSociodemograficos);
            }));
        })));
    } else {
      // Sociodemographic info.
      selectLocationObservables.push(this.getDadosSocioDemograficos(state, city).pipe(
        map(dadosSociodemograficos => {
          selectedLocationInfo.sociodemographic = this.getSociodemographic(state, city, dadosSociodemograficos);
          selectedLocationInfo.pibPerCapitaByLevel = this.getPerCapitaByLevel(state, city, dadosSociodemograficos);
          selectedLocationInfo.idhmByLevel = this.getIdhmByLevel(state, city, dadosSociodemograficos);
        })));
    }

    // School by adm. dependency and location.
    const schoolRequestOptions: any = this.getRequestOptionsWithParams(schoolCurrentYear, state, city);
    const schoolSourceInfo: Footnote = new Footnote({ sourceInformation: SourceInformationEnum.school, indice: 5 });

    const schoolInfoNote: Footnote = new Footnote({
      indice: 6,
      note: 'A contagem do número de estabelecimentos considera apenas aqueles informados como ‘em atividade’ no ano do Censo e ' +
        'que tinham pelo menos uma matrícula de Ensino Regular, Educação de Jovens e Adultos (EJA) e/ou Educação Profissional. '
    });

    selectLocationObservables.push(this.httpService.getApiEndpointUFG().pipe(
      switchMap(apiEndpointUFG => {
        return this.getQuantidadePorDependenciaAdmLocalidade(`${apiEndpointUFG}/informacoes-educacionais/numero-escolas/${localidadeSelecionada}`, schoolSourceInfo, schoolInfoNote, 'Escola').pipe(
          map(schoolByAdmDependencyAndLocation => {
            selectedLocationInfo.schoolByAdmDependencyAndLocation = schoolByAdmDependencyAndLocation;
          }));
      })));

    // Enrollment by adm. dependency and location.
    const enrollmentRequestOptions: any = this.getRequestOptionsWithParams(enrollmentCurrentYear, state, city);
    const enrollmentSourceInfo: Footnote = new Footnote(); // new Footnote({ sourceInformation: SourceInformationEnum.enrollment, indice: state || city ? 7 : 6 });

    const enrollmentInfoNote: Footnote = new Footnote({
      indice: 7,
      note: 'A contagem de matrículas segue os mesmos critérios adotados pelo INEP: (a) O mesmo aluno pode ter mais de uma ' +
        'matrícula; (b) Não inclui matrículas de turmas de Atendimento Complementar e Atendimento Educacional Especializado (AEE); (c) Inclui ' +
        'matrículas do Ensino Regular, Especial e/ ou Educação de Jovens e Adultos(EJA).'
    });

    selectLocationObservables.push(this.httpService.getApiEndpointUFG().pipe(
      switchMap(apiEndpointUFG => {
        return this.getQuantidadePorDependenciaAdmLocalidade(`${apiEndpointUFG}/informacoes-educacionais/numero-matriculas/${localidadeSelecionada}`, enrollmentSourceInfo,
          enrollmentInfoNote, 'Matricula').pipe(
            map(enrollmentByAdmDependencyAndLocation => selectedLocationInfo.enrollmentByAdmDependencyAndLocation = enrollmentByAdmDependencyAndLocation));
      })));

    return forkJoin(selectLocationObservables)
      .pipe(map(() => selectedLocationInfo));
  }

  getStates(): Observable<Array<State>> {
    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/state`).pipe(
          map(states => {
            return states.map(state => new State({ value: state.id.toString(), label: state.name.toUpperCase() })).sort((s1, s2) => s1.label > s2.label ? 1 : -1);
          }));
      }));
  }

  getEstados(): Observable<Array<State>> {
    return this.httpService.getApiEndpointUFG().pipe(
      switchMap(endpointUFG => {
        return this.httpService.get<any>(`${endpointUFG}/estados?size=27`).pipe(
          map(estados => {
            const result: Array<State> = new Array<State>();
            for (let i = 0; i < estados._embedded.estados.length; i++) {
              result.push(new State({
                value: estados._embedded.estados[i].codUf.toString(),
                label: estados._embedded.estados[i].nomeUf.toUpperCase()
              }));
            }
            return result.sort((s1, s2) => s1.label > s2.label ? 1 : -1);
          }));
      }));
  }

  getCities(state?: State): Observable<Array<City>> {
    const requestOptions = state
      ? this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([['filter', `state:"${state.value}"`]]))
      : undefined;

    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/city`, requestOptions).pipe(
          map(cities => cities.map(city => new City({ value: city.id.toString(), label: city.name }))));
      }));
  }

  getCidades(state?: State): Observable<Array<City>> {
    return this.httpService.getApiEndpointUFG().pipe(
      switchMap(endpointUFG => {
        const url: string = state ? `municipios/search/findMunicipioByCodUf?codUf=${state.value}` : 'municipios';
        return this.httpService.get<any>(`${endpointUFG}/${url}`).pipe(
          map(municipios => {
            const result: Array<City> = new Array<City>();
            for (let i = 0; i < municipios._embedded.municipios.length; i++) {
              if (municipios._embedded.municipios[i].codMun.toString() !== '2605459') { // Não exibe o município Fernando de Noronha
                result.push(new City({
                  value: municipios._embedded.municipios[i].codMun.toString(),
                  label: municipios._embedded.municipios[i].nomeMun.toUpperCase()
                }));
              }
            }
            return result;
          }));
      }));
  }

  notifySelectedLocationObservers(selectLocation: SelectLocation): void {
    if (selectLocation === undefined) {
      selectLocation = this.sessionService.getItem(Functionality.selectLocation.key);
    }

    const selectedLocation = this.processSelectedLocationNotificationText(selectLocation);

    this.selectedLocationMessenger.next(selectedLocation);
  }

  notifySelectedSimulationTyleObservers(simulationType: number): void {
    this.selectedSimulatioTypeMessenger.next(this.processSimulationTypeNotificationText(simulationType));
  }

  private processSelectedLocationNotificationText(selectLocation: SelectLocation): string {
    return this.utilitiesService.getSelectLocationName(selectLocation);
  }

  private processSimulationTypeNotificationText(simulationType: number): string {
    return this.utilitiesService.getSimulationTypeName(simulationType);
  }

  private getSociodemographic(state: State, city: City, dados: DadosSocioDemograficos): Array<Sociodemographic> {
    const sociodemographics: Array<Sociodemographic> = new Array<Sociodemographic>();
    const pibPercapitaSourceInfo: Footnote = new Footnote({ sourceInformation: SourceInformationEnum.pibPerCapita, indice: 1 });
    const pibPerCapitaNote: Footnote = new Footnote({
      indice: 4,
      note: 'O PIB per capita é calculado pelo IBGE em nível municipal. Portanto, o PIB per capita em nível nacional é resultado da soma dos PIB municipais ' +
        'dividido pela soma da população de todos os municípios. Da mesma forma, o PIB per capita no nível estadual é resultado da soma do PIB dos municípios de ' +
        'cada estado dividido pela soma das respectivas populações.'
    });

    sociodemographics.push(new Sociodemographic({ description: 'População estimada', value: dados.quantidadePopulacao, footnote: pibPercapitaSourceInfo }));
    sociodemographics.push(new Sociodemographic({ description: 'PIB per capita', value: dados.pibPercapita, footnote: pibPerCapitaNote }));

    if (state || city) {
      const idhmSourceInfo: Footnote = new Footnote({ sourceInformation: SourceInformationEnum.idhmPnud, indice: 2 });
      sociodemographics.push(new Sociodemographic({ description: 'IDHM', value: dados.valorIdhm, footnote: idhmSourceInfo }));
    }
    return sociodemographics;
  }

  private getSociodemographicData(state: State, city: City, urlSufix: string, currentYear: number, description: string, footnote: Footnote, round: boolean = false): Observable<Sociodemographic> {
    const requestOptions: any = this.getRequestOptionsWithParams(currentYear, state, city);

    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/${urlSufix}`, requestOptions).pipe(
          map(arrayData => {
            const data: any = _.first(arrayData);
            let value: number = this.utilitiesService.toNumber(data.total);

            if (round) {
              value = this.utilitiesService.roundNumber(value, 0);
            }

            return new Sociodemographic({ description: description, value: value, footnote: footnote });
          }));
      }));
  }

  private getPerCapitaByLevel(state: State, city: City, dados: DadosSocioDemograficos): PibPerCapita {

    if (dados.pibPercapitaPorQuintil) {
      const pibPerCapita: PibPerCapita = new PibPerCapita({ levels: new Array<DescriptionValue>() });

      pibPerCapita.levels = dados.pibPercapitaPorQuintil;

      /*const pibPerCapitaNote = new Footnote({
        indice: state || city ? 4 : 3,
        note: 'Quando a localidade selecionada é o Brasil, são exibidos os quintis considerando-se os municípios brasileiros. Quando uma UF é ' +
          'selecionada, os quintis exibidos referem-se aos municípios da UF escolhida. Quando um município é selecionado, é exibido apenas o quintil à ' +
          'que ele pertence, considerando-se todos os municípios brasileiros.'
      });*/

      const pibPerCapitaNote = new Footnote();

      pibPerCapita.footnote = pibPerCapitaNote;

      return pibPerCapita;
    } else {
      return undefined;
    }

  }

  private getIdhmByLevel(state: State, city: City, dados: DadosSocioDemograficos): Idhm {

    const valuesIDHM = new DescriptionValue({ description: dados.nivelIdhm });
    const levelsIDHM: Array<DescriptionValue> = new Array<DescriptionValue>();
    levelsIDHM.push(valuesIDHM);
    const idhm = new Idhm({ levels: levelsIDHM });

    return idhm;
  }

  private getQuantityByAdmDependencyAndLocation(url: string, requestOptions: any, source: Footnote, note: Footnote): Observable<QuantityByAdmDependencyAndLocation> {
    const quantityByAdmDependencyAndLocation: QuantityByAdmDependencyAndLocation = new QuantityByAdmDependencyAndLocation();

    requestOptions.params = requestOptions.params.set('dims', 'adm_dependency_detailed,location');

    return this.httpService.get<Array<any>>(url, requestOptions).pipe(
      map(totalOfQuantityByAdmDependencyAndLocation => {
        const totalOfQuantityByAdmDependencyAndLocationGroup = _.groupBy(totalOfQuantityByAdmDependencyAndLocation, 'adm_dependency_detailed_name');
        const admDependencies: Array<string> = Object.keys(totalOfQuantityByAdmDependencyAndLocationGroup);

        quantityByAdmDependencyAndLocation.source = source;
        quantityByAdmDependencyAndLocation.note = note;

        for (const admDependency of admDependencies) {
          const admDependencyLocations: Array<any> = totalOfQuantityByAdmDependencyAndLocationGroup[admDependency];
          const admDependencyAndLocations = new AdmDependencyAndLocation({ admDependencyDescription: admDependency });

          for (const location of admDependencyLocations) {
            if (location.location_id === LocationEnum.urban) {
              admDependencyAndLocations.urbanQuantity = location.total;
            } else {
              admDependencyAndLocations.ruralQuantity = location.total;
            }
          }

          admDependencyAndLocations.totalQuantity = admDependencyAndLocations.urbanQuantity + admDependencyAndLocations.ruralQuantity;

          quantityByAdmDependencyAndLocation.admDependenciesAndLocations.push(admDependencyAndLocations);
        }

        const urbanTotal: AdmDependencyAndLocation = quantityByAdmDependencyAndLocation.admDependenciesAndLocations.reduce((previous, current) => new AdmDependencyAndLocation({
          urbanQuantity: previous.urbanQuantity + current.urbanQuantity
        }));

        const ruralTotal: AdmDependencyAndLocation = quantityByAdmDependencyAndLocation.admDependenciesAndLocations.reduce((previous, current) => new AdmDependencyAndLocation({
          ruralQuantity: previous.ruralQuantity + current.ruralQuantity
        }));

        const grandTotal: AdmDependencyAndLocation = new AdmDependencyAndLocation({
          admDependencyDescription: 'Total',
          urbanQuantity: urbanTotal.urbanQuantity,
          ruralQuantity: ruralTotal.ruralQuantity
        });

        grandTotal.totalQuantity = grandTotal.urbanQuantity + grandTotal.ruralQuantity;

        quantityByAdmDependencyAndLocation.admDependenciesAndLocations.push(grandTotal);

        return quantityByAdmDependencyAndLocation;
      }));
  }

  private getRequestOptionsWithParams(currentYear: number, state: State, city: City): any {
    const filters: Array<string> = new Array<string>(`min_year:"${currentYear}"`, `max_year:"${currentYear}"`);
    const searchParams: Map<string, string> = new Map<string, string>();

    if (city) {
      searchParams.set('dims', 'city');
      filters.push(`city:"${city.value}"`);
    } else if (state) {
      searchParams.set('dims', 'state');
      filters.push(`state:"${state.value}"`);
    }

    searchParams.set('filter', filters.join(','));

    return this.httpService.getRequestOptionsWithSearchParams(searchParams);
  }

  private getDadosSocioDemograficos(state: State, city: City, quintis: Array<DescriptionValue> = undefined): Observable<DadosSocioDemograficos> {

    const dadosSocioDemograficos: DadosSocioDemograficos = new DadosSocioDemograficos();
    const localidadeSelecionada: string = this.getLocalidadeSelecionada(state, city);

    return this.httpService.getApiEndpointUFG().pipe(
      switchMap(apiEndpointUFG => {
        return this.httpService.get<any>(`${apiEndpointUFG}/informacoes-socio-demograficas/${localidadeSelecionada}`).pipe(
          map(dadosSocioEcon => {

            let quintilLocalidade: Array<DescriptionValue> = new Array<DescriptionValue>();
            if (quintis === undefined) {
              quintilLocalidade.push(new DescriptionValue({ description: dadosSocioEcon.content.nivelProdutoInternoBruto.dscClassificacao, value: dadosSocioEcon.content.vlrQuintil }));
            } else {
              quintilLocalidade = quintis;
            }

            dadosSocioDemograficos.quantidadePopulacao = dadosSocioEcon.content.quantidadePopulacao;
            dadosSocioDemograficos.pibPercapita = dadosSocioEcon.content.pibPerCapita;
            dadosSocioDemograficos.valorIdhm = dadosSocioEcon.content.valorIdh;
            dadosSocioDemograficos.nivelIdhm = dadosSocioEcon.content.nivelIdh;
            dadosSocioDemograficos.pibPercapitaPorQuintil = quintilLocalidade;
            // dadosSocioDemograficos.vlrQuintil = dadosSocioEcon.content.nivelProdutoInternoBruto ? dadosSocioEcon.content.vlrQuintil : undefined;

            return dadosSocioDemograficos;
          }));
      }));
  }

  private getQuantidadePorDependenciaAdmLocalidade(url: string, source: Footnote, note: Footnote, dadoConsulta: string): Observable<QuantityByAdmDependencyAndLocation> {

    const quantityByAdmDependencyAndLocation: QuantityByAdmDependencyAndLocation = new QuantityByAdmDependencyAndLocation();

    return this.httpService.get<any>(url).pipe(
      map(dadosEscolasMatriculas => {
        const dadosEscolasMatriculasOrdenados = _.sortBy(dadosEscolasMatriculas.content, ['idDependenciaAdministrativa']);
        const totalOfQuantityByAdmDependencyAndLocationGroup = _.groupBy(dadosEscolasMatriculasOrdenados, 'dependenciaAdministrativa');
        const admDependencies: Array<string> = Object.keys(totalOfQuantityByAdmDependencyAndLocationGroup);

        quantityByAdmDependencyAndLocation.source = source;
        quantityByAdmDependencyAndLocation.note = note;

        for (const admDependency of admDependencies) {
          const admDependencyLocations: Array<any> = totalOfQuantityByAdmDependencyAndLocationGroup[admDependency];
          const admDependencyAndLocations = new AdmDependencyAndLocation({ admDependencyDescription: admDependency });

          for (const location of admDependencyLocations) {
            if (location.idLocalizacao === LocationEnum.urban) {
              admDependencyAndLocations.urbanQuantity = dadoConsulta === 'Matricula' ? location.numeroMatriculas : location.numeroEscolas;
            } else {
              admDependencyAndLocations.ruralQuantity = dadoConsulta === 'Matricula' ? location.numeroMatriculas : location.numeroEscolas;
            }
          }

          admDependencyAndLocations.totalQuantity = admDependencyAndLocations.urbanQuantity + admDependencyAndLocations.ruralQuantity;

          quantityByAdmDependencyAndLocation.admDependenciesAndLocations.push(admDependencyAndLocations);
        }

        const urbanTotal: AdmDependencyAndLocation = quantityByAdmDependencyAndLocation.admDependenciesAndLocations.reduce((previous, current) => new AdmDependencyAndLocation({
          urbanQuantity: previous.urbanQuantity + current.urbanQuantity
        }));

        const ruralTotal: AdmDependencyAndLocation = quantityByAdmDependencyAndLocation.admDependenciesAndLocations.reduce((previous, current) => new AdmDependencyAndLocation({
          ruralQuantity: previous.ruralQuantity + current.ruralQuantity
        }));

        const grandTotal: AdmDependencyAndLocation = new AdmDependencyAndLocation({
          admDependencyDescription: 'Total',
          urbanQuantity: urbanTotal.urbanQuantity,
          ruralQuantity: ruralTotal.ruralQuantity
        });

        grandTotal.totalQuantity = grandTotal.urbanQuantity + grandTotal.ruralQuantity;

        quantityByAdmDependencyAndLocation.admDependenciesAndLocations.push(grandTotal);

        return quantityByAdmDependencyAndLocation;
      }));
  }

  private getLocalidadeSelecionada(state: State, city: City): string {
    let localidade: string = 'nacional';

    if (state) {
      localidade = `estado/${state.value}`;
    }

    if (city) {
      localidade = `municipio/${city.value}`;
    }

    return localidade;
  }

  private getQuintis(state: State): Observable<PibPerCapita> {

    const localidadeSelecionada: string = state ? `uf/${state.value}` : 'nacional';
    const pibPerCapita: PibPerCapita = new PibPerCapita({ levels: new Array<DescriptionValue>() });

    return this.httpService.getApiEndpointUFG().pipe(
      switchMap(apiEndpointUFG => {
        return this.httpService.get<any>(`${apiEndpointUFG}/quintis/socioeconomicos/${localidadeSelecionada}`).pipe(
          map(quintis => {

            for (let i = 0; i < quintis.content.length; i++) {
              const quintil = quintis.content[i];
              pibPerCapita.levels.push(new DescriptionValue({ description: quintil.nivelProdutoInternoBruto.dscClassificacao, value: quintil.vlrQuintil }));
            }
            return pibPerCapita;
          }));
      }));
  }
}
